import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteProject } from "../../services/projectService";
import BigSpinner from "../BigSpinner/BigSpinner";
import ProjectsRead from "../ProjectRead/ProjectRead";

import {
  ProjectsDeleteContainer,
  DeleteButton,
  CancelButton,
  ButtonBox,
  SuccessText,
  ErrorText,
} from "./ProjectDelete.styles";

const ProjectDelete = ({ project, $resetView }: any) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const removeProject = async () => {
    setProcessing(true);

    const response = await deleteProject(project.id);

    if (!response.error) {
      setDeleteSuccess(true);
      setTimeout(() => navigate("/manage/projects"), 2000);
    } else setError(true);
  };

  if (processing) return <BigSpinner />;
  if (deleteSuccess)
    return (
      <SuccessText>
        Project Deleted successfully. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <ProjectsDeleteContainer>
      <h2>Are you sure you want to delete this?</h2>
      <ProjectsRead {...project} />
      {error && (
        <ErrorText>There was a problem deleting this. Try again.</ErrorText>
      )}
      <ButtonBox>
        <DeleteButton size="lg" onClick={removeProject}>
          Delete
        </DeleteButton>
        <CancelButton size="lg" onClick={$resetView}>
          Cancel
        </CancelButton>
      </ButtonBox>
    </ProjectsDeleteContainer>
  );
};

export default ProjectDelete;
