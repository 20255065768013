import React, { useEffect } from "react";
import { AccordionGroupContainer } from "../../pages/Dashboard/Dashboard.styles";
import AccordionComponent, {
  AccordionComponentProps,
} from "../Accordion/Accordion";
import SegmentedControlComponent, {
  SegmentedControlProps,
} from "../SegmentedControl/SegmentedControl";
import { SegmentedControlContainer } from "../SegmentedControl/SegmentedControl.styles";
import { store } from "../../store";
import { saveToLocalStorage } from "../../services/localStorageService";
import { LOCAL_STORAGE_FILTERS } from "../../globals/constants";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addIndexFilter } from "../../store/filterSlice";
import { UnfilledShiftsTitle } from "./UnfilledShifts.styles";

export type UnfilledShiftsProps = {
  filterData: SegmentedControlProps;
  tableData: AccordionComponentProps;
  title?: string;
};

export const UnfilledShiftsDisplay = (props: UnfilledShiftsProps) => {
  const filters = useAppSelector(
    (state: any) => (state.filters.forIndex["dashboard"] || {}).value || []
  );
  const dispatch = useAppDispatch();
  const initializeFilters = () => {
    const unfilledFilter = filters.find((f: any) => {
      return (
        f.column === "total_amount" &&
        f.operator === "greater_than" &&
        f.pattern === "$filled_amount"
      );
    });
    if (!unfilledFilter) {
      dispatch(
        addIndexFilter({
          index: "dashboard",
          newFilter: {
            column: "total_amount",
            operator: "greater_than",
            pattern: "$filled_amount",
          },
        })
      );
    }
  };

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      saveToLocalStorage(LOCAL_STORAGE_FILTERS, store.getState().filters);
    });
    initializeFilters();
    return unsubscribe;
  }, []);

  return (
    <AccordionGroupContainer className="unfilledShiftsAccordionContainer">
      {props.title && <UnfilledShiftsTitle>{props.title}</UnfilledShiftsTitle>}
      {!!props.filterData.items.length && (
        <SegmentedControlContainer className="unfilledShiftsSegmentedContainer">
          <SegmentedControlComponent {...props.filterData} />
        </SegmentedControlContainer>
      )}
      {!!props.tableData.sections.length && (
        <AccordionComponent {...props.tableData} />
      )}
      {!props.tableData.sections.length && <h3>No data</h3>}
    </AccordionGroupContainer>
  );
};
