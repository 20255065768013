import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SiteRequirementDetailsContainer } from "./SiteRequirementDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import SiteRequirementRead from "../../components/SiteRequirementRead/SiteRequirementRead";
import SiteRequirementDelete from "../../components/SiteRequirementDelete/SiteRequirementDelete";
import { getSiteRequirementById } from "../../services/siteRequirementService";
import SiteRequirementUpdate from "../../components/SiteRequirementUpdate/SiteRequirementUpdate";

export const SiteRequirementDetails = () => {
  let { siteRequirementId } = useParams();
  const [SiteRequirement, setSiteRequirement] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Site Requirement`);
    if (!Object.entries(SiteRequirement).length && siteRequirementId)
      getSiteRequirementById(siteRequirementId)
        .then((res) => {
          if (res.value) {
            setSiteRequirement(res.value);
            setError(false);
            setTitle(`SiteRequirement (${res.value.name})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
  });

  const resetView = () => setActiveTab("view");

  const renderTab = (siteRequirement: any) => {
    switch (activeTab) {
      case "view":
        return <SiteRequirementRead {...siteRequirement} />;
      case "update":
        return (
          <SiteRequirementUpdate {...siteRequirement} $resetView={resetView} />
        );
      case "delete":
        return (
          <SiteRequirementDelete
            siteRequirement={siteRequirement}
            $resetView={resetView}
          />
        );
      default:
        return <SiteRequirementRead {...SiteRequirement} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(SiteRequirement).length) {
    return (
      <>
        <SiteRequirementDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(SiteRequirement)}</TabContent>
        </SiteRequirementDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
