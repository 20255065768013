import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupProjectWorkers = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/projectWorkers?term=${searchTerm}`);

export const getProjectWorkers = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.projectWorkers;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/projectWorkers${filterString}`);
};

export const getProjectWorkerById = async (projectWorkerId: string) =>
  await protectedFetch(`${API_ROOT}/projectWorkers/${projectWorkerId}`);

export const createProjectWorker = async (newProjectWorker: any) => {
  return await protectedFetch(`${API_ROOT}/projectWorkers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newProjectWorker),
  });
};

export const updateProjectWorker = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/projectWorkers/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteProjectWorker = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/projectWorkers/${id}`, {
    method: "DELETE",
  });
};
