import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ShiftInviteIndexContainer,
  TableLimited,
} from "./ShiftInviteIndex.styles";
import { getShiftInvites } from "../../services/shiftInviteService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { ShiftInvite } from "../../models/ShiftInvite";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

const tz = require("timezone/loaded"); //until Temporal JS comes out

export const ShiftInviteIndex = () => {
  const [shiftInvites, setShiftInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["shiftInvites"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["shiftInvites"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["shiftInvites"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["shiftInvites"].limit
  );

  useEffect(() => {
    setTitle("Shift Invites");
    getShiftInvites().then((res: any) => {
      setShiftInvites(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={ShiftInvite.getFilterConfig()} />
      <ShiftInviteIndexContainer>
        {!!shiftInvites.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"shiftInvites"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"shiftInvites"}
                    columnName={"workerId"}
                    displayName={"Worker"}
                  />
                  <ColumnHeadWithSort
                    index={"shiftInvites"}
                    columnName={"projectWorkerId"}
                    displayName={"Project Worker"}
                  />
                  <ColumnHeadWithSort
                    index={"shiftInvites"}
                    columnName={"status"}
                    displayName={"Status"}
                  />
                  <ColumnHeadWithSort
                    index={"shiftInvites"}
                    columnName={"projectInviteId"}
                    displayName={"Project Invite"}
                  />
                  <th>Project Date</th>
                  <ColumnHeadWithSort
                    index={"shiftInvites"}
                    columnName={"createdAt"}
                    displayName={"Created At"}
                  />
                  <ColumnHeadWithSort
                    index={"shiftInvites"}
                    columnName={"updatedAt"}
                    displayName={"Updated At"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {shiftInvites.map((shiftInvite) => {
                  const {
                    id,
                    worker,
                    projectWorker,
                    projectInviteId,
                    status,
                    createdAt,
                    updatedAt,
                  }: any = shiftInvite;

                  const workerString = [
                    worker.user.firstName,
                    worker.user.lastName,
                    " | ",
                    worker.user.market.name,
                    worker.workerType.positionName,
                    `(${worker.workerType.level})`,
                  ].join(" ");

                  const startsAtParsed = tz(
                    projectWorker.projectDate.startsAt,
                    "%c",
                    "en_US",
                    projectWorker.projectDate.timezone
                  ).split(" ");

                  const endsAtParsed = tz(
                    projectWorker.projectDate.endsAt,
                    "%c",
                    "en_US",
                    projectWorker.projectDate.timezone
                  ).split(" ");

                  const projectWorkerString = `${[
                    startsAtParsed[2],
                    startsAtParsed[1] + ",",
                    startsAtParsed[4].slice(0, -3),
                    startsAtParsed[5],
                    startsAtParsed[6],
                    " | ",
                    `L${projectWorker.workerType.level}`,
                    `(${projectWorker.filledAmount}`,
                    "/",
                    `${projectWorker.totalAmount})`,
                  ].join(" ")}`;

                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>
                        <NavLink to={`/manage/workers/${String(worker.id)}`}>
                          {workerString}
                        </NavLink>
                      </td>
                      <td>
                        <NavLink
                          to={`/manage/projectWorkers/${String(
                            projectWorker.id
                          )}`}
                        >
                          {projectWorkerString}
                        </NavLink>
                      </td>
                      <td>{ShiftInvite.formatStatus(status)}</td>
                      <td>
                        <NavLink
                          to={`/manage/projectInvites/${String(
                            projectInviteId
                          )}`}
                        >
                          Open
                        </NavLink>
                      </td>
                      <td>
                        <NavLink
                          to={`/manage/projectDates/${String(
                            projectWorker.projectDate.id
                          )}`}
                        >
                          {[
                            startsAtParsed[0] + ",",
                            startsAtParsed[2],
                            startsAtParsed[1] + ",",
                            startsAtParsed[4].slice(0, -3),
                            startsAtParsed[5],
                            " - ",
                            startsAtParsed[0] + ",",
                            endsAtParsed[2],
                            endsAtParsed[1] + ",",
                            endsAtParsed[4].slice(0, -3),
                            endsAtParsed[5],
                            endsAtParsed[6],
                          ].join(" ")}
                        </NavLink>
                      </td>
                      <td>
                        {new Date(createdAt).toLocaleString(`en-US`, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </td>
                      <td>
                        {new Date(updatedAt).toLocaleString(`en-US`, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="shiftInvites"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!shiftInvites.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </ShiftInviteIndexContainer>
    </>
  );
};
