import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ProjectWorkerIndexContainer,
  TableLimited,
} from "./ProjectWorkerIndex.styles";
import { getProjectWorkers } from "../../services/projectWorkerService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { ProjectWorker } from "../../models/ProjectWorker";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

const tz = require("timezone/loaded"); //until Temporal JS comes out

export const ProjectWorkerIndex = () => {
  const [projectWorkers, setProjectWorkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["projectWorkers"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["projectWorkers"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["projectWorkers"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["projectWorkers"].limit
  );

  useEffect(() => {
    setTitle("Project Workers");
    getProjectWorkers().then((res: any) => {
      setProjectWorkers(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={ProjectWorker.getFilterConfig()} />
      <ProjectWorkerIndexContainer>
        {!!projectWorkers.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"projectWorkers"}
                    columnName={"id"}
                    displayName={"ID"}
                  />
                  <th>Related Project</th>
                  <ColumnHeadWithSort
                    index={"projectWorkers"}
                    columnName={"workerTypeId"}
                    displayName={"Worker Type"}
                  />
                  <ColumnHeadWithSort
                    index={"projectWorkers"}
                    columnName={"projectDateId"}
                    displayName={"Project Date"}
                  />
                  <ColumnHeadWithSort
                    index={"projectWorkers"}
                    columnName={"filledAmount"}
                    displayName={"Filled Amount"}
                  />
                  <ColumnHeadWithSort
                    index={"projectWorkers"}
                    columnName={"totalAmount"}
                    displayName={"Total Amount"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {projectWorkers.map((projectWorker) => {
                  const {
                    id,
                    projectDate,
                    workerType,
                    filledAmount,
                    totalAmount,
                  }: any = projectWorker;

                  const project = projectDate?.project;

                  let startsAtParsed = "";
                  let endsAtParsed = "";

                  startsAtParsed = projectDate
                    ? tz(
                        projectDate.startsAt,
                        "%c",
                        "en_US",
                        projectDate.timezone
                      ).split(" ")
                    : "";

                  endsAtParsed = projectDate
                    ? tz(
                        projectDate.endsAt,
                        "%c",
                        "en_US",
                        projectDate.timezone
                      ).split(" ")
                    : "";

                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>
                        {!!project && (
                          <NavLink
                            to={`/manage/projects/${String(project.id)}`}
                          >
                            {project.id}&nbsp;|&nbsp;
                            {project.projectName}&nbsp;-&nbsp;
                            {project.address}
                          </NavLink>
                        )}
                      </td>
                      <td>
                        <NavLink
                          to={`/manage/workerTypes/${String(workerType.id)}`}
                        >
                          {workerType.market.name}&nbsp;
                          {workerType.positionName}&nbsp; ({workerType.level})
                        </NavLink>
                      </td>
                      <td>
                        {!!projectDate && (
                          <NavLink
                            to={`/manage/projectDates/${String(
                              projectDate.id
                            )}`}
                          >
                            {[
                              startsAtParsed[2],
                              startsAtParsed[1] + ",",
                              startsAtParsed[4].slice(0, -3),
                              startsAtParsed[5],
                              " - ",
                              endsAtParsed[2],
                              endsAtParsed[1] + ",",
                              endsAtParsed[4].slice(0, -3),
                              endsAtParsed[5],
                              endsAtParsed[6],
                            ].join(" ")}
                          </NavLink>
                        )}
                      </td>
                      <td>{filledAmount}</td>
                      <td>{totalAmount}</td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="projectWorkers"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!projectWorkers.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </ProjectWorkerIndexContainer>
    </>
  );
};
