import React from "react";
import styled from "styled-components";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { colors } from "../../../globals/globals.styles";

export const SelfSortColumnHeadContainer = styled.th`
   {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
`;

export const Flex = styled.div`
   {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SortIcon = styled(FaSort)`
   {
    color: ${colors.lightGrey};
    font-size: 20px;
  }
`;

export const SortUpIcon = styled(FaSortUp)`
   {
    color: black;
    font-size: 20px;
  }
`;

export const SortDownIcon = styled(FaSortDown)`
   {
    color: black;
    font-size: 20px;
  }
`;
