import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  WorkerBlackListIndexContainer,
  TableLimited,
} from "./WorkerBlackListIndex.styles";
import { getWorkerBlackLists } from "../../services/workerBlackListService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { WorkerBlackList } from "../../models/WorkerBlackList";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

export const WorkerBlackListIndex = () => {
  const [workerBlackLists, setWorkerBlackLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["workerBlackLists"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["workerBlackLists"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["workerBlackLists"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["workerBlackLists"].limit
  );

  useEffect(() => {
    setTitle("Worker Blacklists");
    getWorkerBlackLists().then((res: any) => {
      setWorkerBlackLists(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={WorkerBlackList.getFilterConfig()} />
      <WorkerBlackListIndexContainer>
        {!!workerBlackLists.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"workerBlackLists"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"workerBlackLists"}
                    columnName={"businessId"}
                    displayName={"Business"}
                  />
                  <ColumnHeadWithSort
                    index={"workerBlackLists"}
                    columnName={"workerId"}
                    displayName={"Worker"}
                  />
                  <ColumnHeadWithSort
                    index={"workerBlackLists"}
                    columnName={"createdAt"}
                    displayName={"Created At"}
                  />
                  <ColumnHeadWithSort
                    index={"workerBlackLists"}
                    columnName={"updatedAt"}
                    displayName={"Updated At"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {workerBlackLists.map((workerBlackList) => {
                  const { id, business, worker, createdAt, updatedAt }: any =
                    workerBlackList;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>
                        <NavLink to={`/manage/businesses/${business.id}`}>
                          {business.businessName}&nbsp;(
                          {business.user.firstName}&nbsp;
                          {business.user.lastName})
                        </NavLink>
                      </td>
                      <td>
                        <NavLink to={`/manage/workers/${worker.id}`}>
                          {worker.user.firstName}&nbsp;
                          {worker.user.lastName}
                        </NavLink>
                      </td>
                      <td>{new Date(createdAt).toUTCString()}</td>
                      <td>{new Date(updatedAt).toUTCString()}</td>

                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="workerBlackLists"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!workerBlackLists.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </WorkerBlackListIndexContainer>
    </>
  );
};
