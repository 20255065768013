import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { UserIndexContainer, TableLimited } from "./UserIndex.styles";
import { getUsers } from "../../services/userService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";
import { User } from "../../models/User";
import { userStatusMapping } from "../../globals/constants";

export const UserIndex = () => {
  const [users, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["users"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["users"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["users"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["users"].limit
  );

  useEffect(() => {
    setTitle("Users");
    getUsers().then((res: any) => {
      setUser(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={User.getFilterConfig()} />
      <UserIndexContainer>
        {!!users.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"users"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"users"}
                    columnName={"firstName"}
                    displayName={"First Name"}
                  />
                  <ColumnHeadWithSort
                    index={"users"}
                    columnName={"lastName"}
                    displayName={"Last Name"}
                  />
                  <ColumnHeadWithSort
                    index={"users"}
                    columnName={"phoneNumber"}
                    displayName={"Phone"}
                  />
                  <ColumnHeadWithSort
                    index={"users"}
                    columnName={"email"}
                    displayName={"Email"}
                  />
                  <ColumnHeadWithSort
                    index={"users"}
                    columnName={"role"}
                    displayName={"Role"}
                  />
                  <ColumnHeadWithSort
                    index={"users"}
                    columnName={"status"}
                    displayName={"Status"}
                  />
                  <ColumnHeadWithSort
                    index={"users"}
                    columnName={"marketId"}
                    displayName={"Market (ID)"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => {
                  const {
                    id,
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    role,
                    business,
                    worker,
                    status,
                    market,
                    marketId,
                  }: any = user;

                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{firstName}</td>
                      <td>{lastName}</td>
                      <td>
                        <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                      </td>
                      <td>
                        <a href={`mailto:${email}`}>{email}</a>
                      </td>
                      <td>
                        {!!worker && (
                          <NavLink to={`/manage/workers/${String(worker.id)}`}>
                            Worker
                          </NavLink>
                        )}
                        {!!business && (
                          <NavLink
                            to={`/manage/businesses/${String(business.id)}`}
                          >
                            Business
                          </NavLink>
                        )}
                      </td>
                      <td>{userStatusMapping[status]}</td>
                      <td>
                        <NavLink to={`/manage/markets/${String(marketId)}`}>
                          {market.name} ({marketId})
                        </NavLink>
                      </td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="users"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!users.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </UserIndexContainer>
    </>
  );
};
