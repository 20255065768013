import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Button, Container, Row, Col, Collapse } from "reactstrap";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";

interface ErrorFallbackProps {
  error: Error;
  componentStack: ReactNode;
  resetError: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  componentStack,
  resetError,
}) => {
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  const handleSendFeedbackClick = () => {
    const eventId = Sentry.lastEventId();
    if (eventId && isMounted.current) {
      Sentry.showReportDialog({ eventId });
    }
  };

  return (
    <Container
      fluid
      style={{
        height: "100vh",
        backgroundSize: "cover",
        color: "black",
        textAlign: "center",
      }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <h1>We have encountered an error</h1>
      <p>
        Sorry for the inconvenience, the engineering team has already been
        notified.
      </p>
      <p>
        Please use the feedback button below to provide additional details about
        the actions leading up to this error and to allow us to contact you if
        we need more information.
      </p>
      <Button color="link" onClick={toggle} className="text-white">
        Show Error
      </Button>
      <Row className="w-100">
        {" "}
        <Col
          md={{ size: 2, offset: 4 }}
          className="d-flex justify-content-center mb-3 mb-md-0"
        >
          <Button
            color="primary"
            outline
            size="lg"
            onClick={() => {
              navigate("/");
              resetError();
            }}
          >
            Go to Dashboard
          </Button>
        </Col>
        <Col md={{ size: 2 }} className="d-flex justify-content-center">
          <Button color="primary" size="lg" onClick={handleSendFeedbackClick}>
            Submit Feedback
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorFallback;
