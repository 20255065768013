export class Business {
  id: number;
  userId: number;
  stripeCustomerId?: string;
  billingContact?: string;
  businessAddress?: string;
  businessName?: string;
  ninjaInvoiceKey?: string;
  typeOfBusiness?: string;
  managerTitle?: string;
  promoCode?: string;
  accountsPayableEmail?: string;

  constructor(newBusiness: IBusiness) {
    const {
      id,
      userId,
      stripeCustomerId,
      billingContact,
      businessAddress,
      businessName,
      ninjaInvoiceKey,
      typeOfBusiness,
      managerTitle,
      promoCode,
      accountsPayableEmail,
    } = newBusiness;

    this.id = id;
    this.userId = userId;
    this.stripeCustomerId = stripeCustomerId;
    this.billingContact = billingContact;
    this.businessAddress = businessAddress;
    this.businessName = businessName;
    this.ninjaInvoiceKey = ninjaInvoiceKey;
    this.typeOfBusiness = typeOfBusiness;
    this.managerTitle = managerTitle;
    this.promoCode = promoCode;
    this.accountsPayableEmail = accountsPayableEmail;
  }

  static getFilterConfig() {
    return {
      columns: [
        "id",
        "userId",
        // "stripeCustomerId",
        "billingContact",
        "businessAddress",
        "businessName",
        // "ninjaInvoiceKey",
        // "typeOfBusiness",
        // "managerTitle",
        // "promoCode",
        "accountsPayableEmail",
      ],
      indexToFilter: "businesses",
    };
  }
}

export interface IBusiness {
  id: number;
  userId: number;
  stripeCustomerId?: string;
  billingContact?: string;
  businessAddress?: string;
  businessName?: string;
  ninjaInvoiceKey?: string;
  typeOfBusiness?: string;
  managerTitle?: string;
  promoCode?: string;
  accountsPayableEmail?: string;
}
