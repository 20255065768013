import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupTimeLogs = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/timeLogs?term=${searchTerm}`);

export const getTimeLogs = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.timeLogs;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/timeLogs${filterString}`);
};

export const getTimeLogById = async (timeLogId: string) =>
  await protectedFetch(`${API_ROOT}/timeLogs/${timeLogId}`);

export const createTimeLog = async (newTimeLog: any) => {
  return await protectedFetch(`${API_ROOT}/timeLogs`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newTimeLog),
  });
};

export const updateTimeLog = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/timeLogs/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteTimeLog = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/timeLogs/${id}`, {
    method: "DELETE",
  });
};
