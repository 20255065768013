import React, { useState } from "react";
import { ProjectInvitesContainer } from "./ProjectInvites.styles";
import { ResourceNavbar } from "../../components/ResourceNavbar/ResourceNavbar";
import { TabContent } from "reactstrap";
import { ProjectInviteCreate } from "../../components/ProjectInviteCreate/ProjectInviteCreate";
import { ProjectInviteIndex } from "../../components/ProjectInviteIndex/ProjectInviteIndex";

export const ProjectInvites = () => {
  const [activeTab, setActiveTab] = useState("index");

  const renderTab = () => {
    switch (activeTab) {
      case "index":
        return <ProjectInviteIndex />;
      case "create":
        return <ProjectInviteCreate />;
      case "export":
        return <ProjectInviteIndex />;
      default:
        return <ProjectInviteIndex />;
    }
  };

  return (
    <>
      <ProjectInvitesContainer>
        <ResourceNavbar
          activeTab={activeTab}
          passActiveTab={(newValue: string) => setActiveTab(newValue)}
        />
        <TabContent>{renderTab()}</TabContent>
      </ProjectInvitesContainer>
    </>
  );
};
