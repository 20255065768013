import React, { useState } from "react";
import { Button, Input, TabPane } from "reactstrap";

import {
  ErrorText,
  WorkerBlackListUpdateContainer,
  SuccessText,
  LookupField,
  ButtonBox,
  CancelButton,
  UpdateButton,
} from "./WorkerBlackListUpdate.styles";
import isInt from "validator/lib/isInt";
import { updateWorkerBlackList } from "../../services/workerBlackListService";
import BigSpinner from "../BigSpinner/BigSpinner";
import WorkerLookupModal from "../WorkerLookupModal/WorkerLookupModal";
import BusinessLookupModal from "../BusinessLookupModal/BusinessLookupModal";

const WorkerBlackListUpdate = ({ id, business, worker, $resetView }: any) => {
  const [formData, setFormData] = useState({
    businessId: business.id,
    workerId: worker.id,
  });
  const [workerString, setWorkerString] = useState(
    `${worker.user.firstName} ${worker.user.lastName} (${worker.id})`
  );
  const [businessString, setBusinessString] = useState(business.businessName);
  const [errors, setErrors] = useState<Array<any>>([]);
  const [showWorkerLookup, setShowWorkerLookup] = useState(false);
  const [showBusinessLookup, setShowBusinessLookup] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const setWorker = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        workerId: String(id),
      }));
      setWorkerString(name || "");
      if (!formModified) setFormModified(true);
    }
    setShowWorkerLookup(false);
  };

  const setBusiness = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        businessId: String(id),
      }));
      setBusinessString(name || "");
      if (!formModified) setFormModified(true);
    }
    setShowBusinessLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    const { workerId, businessId }: any = formData;
    let foundErrors = [];

    if (!isInt(workerId || "")) foundErrors.push("workerId");
    if (!isInt(businessId || "")) foundErrors.push("businessId");
    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let response = await updateWorkerBlackList(id, formData);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <WorkerBlackListUpdateContainer>
      <TabPane>
        <LookupField>
          <Input
            type="text"
            placeholder="Select the Business >"
            invalid={errors.indexOf("businessId") !== -1}
            value={businessString}
            readOnly={true}
          />
          <Button size="sm" onClick={() => setShowBusinessLookup(true)}>
            Select Business
          </Button>
        </LookupField>
        {errors.indexOf("businessId") !== -1 && (
          <ErrorText>Invalid Business</ErrorText>
        )}
        <BusinessLookupModal
          isOpen={showBusinessLookup}
          callback={setBusiness}
        />
        <LookupField>
          <Input
            type="text"
            placeholder="Select the Worker >"
            invalid={errors.indexOf("workerId") !== -1}
            value={workerString}
            readOnly={true}
          />
          <Button size="sm" onClick={() => setShowWorkerLookup(true)}>
            Select Worker
          </Button>
        </LookupField>
        {errors.indexOf("workerId") !== -1 && (
          <ErrorText>Invalid Worker</ErrorText>
        )}
        <WorkerLookupModal isOpen={showWorkerLookup} callback={setWorker} />
        <br />
        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>There was a server issue. Please try again.</ErrorText>
        )}
      </TabPane>
    </WorkerBlackListUpdateContainer>
  );
};

export default WorkerBlackListUpdate;
