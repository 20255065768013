export class ProjectSiteRequirement {
  id: number;
  projectId: string;
  siteRequirementId: string;

  constructor(newProjectSiteRequirement: IProjectSiteRequirement) {
    const { id, projectId, siteRequirementId } = newProjectSiteRequirement;

    this.id = id;
    this.projectId = projectId;
    this.siteRequirementId = siteRequirementId;
  }

  static getFilterConfig() {
    return {
      columns: ["id", "projectId", "siteRequirementId"],
      indexToFilter: "projectSiteRequirements",
    };
  }
}

export interface IProjectSiteRequirement {
  id: number;
  projectId: string;
  siteRequirementId: string;
}
