import { API_ROOT } from "../globals/constants";
import { protectedFetch } from "./coreApiService";
import isInt from "validator/lib/isInt";

export const getAvailableWorkers = async (
  projectWorkerIds: any,
  options: any
) => {
  let showAllWorkers = !!options.showAllWorkers || false;
  let filterSameDayInvites = !!options.filterSameDayInvites || false;

  let clearedIds: any = [
    //de-dupe and validate
    ...new Set(projectWorkerIds.filter((id: any) => isInt(String(id)))),
  ].join(",");

  let url = [
    API_ROOT,
    `/tools/getAvailableWorkers?projectWorkerIds=${clearedIds}`,
    showAllWorkers ? "&showAllWorkers=true" : "",
    filterSameDayInvites ? "&filterSameDayInvites=true" : "",
  ].join("");

  return await protectedFetch(url);
};

export const batchInviteWorkers = async (newBatchInvite: any) => {
  const {
    selectedAvailableWorkers,
    selectedProjectWorkers,
    adminNotes,
    customRates,
    noOtherInvites,
  } = newBatchInvite;

  let customRateEntries = Object.keys(customRates || {});
  let parsedRates = "";

  if (customRateEntries.length) {
    parsedRates = customRateEntries
      .map((rateKey) => {
        const projectWorkerId = rateKey;
        const customRate = customRates[rateKey];

        return `${projectWorkerId}=${customRate}`;
      })
      .join(",");
  }

  let tempForm = {
    projectWorkerIds: selectedProjectWorkers || [],
    availableworkerIds: selectedAvailableWorkers || [],
    adminNotes: adminNotes?.length ? adminNotes : null,
    customRates: parsedRates,
    noOtherInvites,
  };

  return await protectedFetch(`${API_ROOT}/tools/createBatchInvite`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(tempForm),
  });
};

export const getInvitedWorkersForProjectId = async (projectId: string) =>
  await protectedFetch(
    `${API_ROOT}/tools/getInvitedWorkersForProjectId/${projectId}`
  );

export const resetPasswordForUserId = async (passwordChange: any) => {
  return await protectedFetch(`${API_ROOT}/tools/resetPasswordForUserId`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(passwordChange),
  });
};
