import React from "react";
import styled from "styled-components";
import { colors } from "../../globals/globals.styles";

export const BusinessReadContainer = styled.div`
   {
  }
`;

export const Group = styled.div`
   {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: thin solid ${colors.darkGrey};
    @media only screen and (max-width: 768px) {
      justify-content: space-between;
    }
  }
`;
export const Title = styled.span`
   {
    display: block;
    min-width: 35%;
    color: ${colors.darkGrey};
  }
`;
export const Content = styled.span`
   {
  }
`;
