import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const getProductLines = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.productLines;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/productLines${filterString}`);
};

export const getProductLineById = async (productLineId: string) =>
  await protectedFetch(`${API_ROOT}/productLines/${productLineId}`);

export const createProductLine = async (newProductLine: any) => {
  return await protectedFetch(`${API_ROOT}/productLines`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newProductLine),
  });
};

export const updateProductLine = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/productLines/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteProductLine = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/productLines/${id}`, {
    method: "DELETE",
  });
};

export const bulkDeleteProductLine = async (
  projectId: number,
  manufacturerId: any
) => {
  return await protectedFetch(
    `${API_ROOT}/productLines/bulkDeleteProductLine`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: { projectId, manufacturerId },
    }
  );
};
