import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  ForemenCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  UserField,
} from "./ForemenCreate.styles";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import isInt from "validator/lib/isInt";
import { createForeman } from "../../services/foremenService";
import UserLookupModal from "../UserLookupModal/UserLookupModal";
import { useNavigate } from "react-router-dom";

export const ForemenCreate = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    userId: "",
  });
  const [userString, setUserString] = useState("");
  const [errors, setErrors] = useState<Array<any>>([]);
  const [showUserLookup, setShowUserLookup] = useState(false);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    setFormData({
      name: "",
      phone: "",
      email: "",
      userId: "",
    });
    setUserString("");
    setErrors([]);
    setShowUserLookup(false);
    setNewId(0);
  };

  const setUser = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        userId: String(id),
      }));
      setUserString(name || "");
    }
    setShowUserLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { name, phone, email, userId }: any = formData;

    if (isEmpty(name || "")) foundErrors.push("name");
    if (!isMobilePhone(phone || "", "en-US")) foundErrors.push("phone");
    if (!isInt(userId || "")) foundErrors.push("userId");
    if (!isEmail(email || "")) foundErrors.push("email");
    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let response = await createForeman(formData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    return (
      <ForemenCreateContainer>
        <SuccessText>
          New Foreman &quot;{formData.name}&quot; successfully created.
        </SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to {formData.name}
          </Button>
        </SuccessButtonWrapper>
      </ForemenCreateContainer>
    );
  }

  return (
    <ForemenCreateContainer>
      <InputBox
        name="name"
        type="text"
        placeholder="Name"
        onChange={onChange}
        invalid={errors.indexOf("name") !== -1}
      />
      {errors.indexOf("name") !== -1 && <ErrorText>Invalid Name</ErrorText>}

      <InputBox
        name="email"
        type="email"
        placeholder="Email"
        onChange={onChange}
        invalid={errors.indexOf("email") !== -1}
      />
      {errors.indexOf("email") !== -1 && <ErrorText>Invalid Email</ErrorText>}

      <InputBox
        name="phone"
        type="text"
        placeholder="Phone"
        onChange={onChange}
        invalid={errors.indexOf("phone") !== -1}
      />
      {errors.indexOf("phone") !== -1 && (
        <ErrorText>Invalid Phone Number</ErrorText>
      )}

      <UserField>
        <Input
          type="text"
          placeholder="Select the User who will own this >"
          invalid={errors.indexOf("userId") !== -1}
          value={userString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowUserLookup(true)}>
          Select User
        </Button>
      </UserField>
      {errors.indexOf("userId") !== -1 && <ErrorText>Invalid User</ErrorText>}
      <UserLookupModal isOpen={showUserLookup} callback={setUser} />
      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </ForemenCreateContainer>
  );
};
