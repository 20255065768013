import React from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { MdDelete, MdUndo } from "react-icons/md";
import { Button, Input } from "reactstrap";
import styled from "styled-components";
import { colors } from "../../../../globals/globals.styles";

export const ProjectDateFlyoutContainer = styled.div<any>`
   {
    padding-bottom: 10px;
    color: ${(p) => (p.newDate ? `green` : "auto")} !important;
    color: ${(p) => (p.toDelete ? `red` : "auto")} !important;
  }
`;

export const TitleWrapper = styled.div`
   {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
`;

export const TitleBar = styled.div`
   {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;

    cursor: pointer;
  }
`;

export const ExpandIcon = styled(FaChevronRight)`
   {
    font-size: 20px;
  }
`;

export const ExpandedIcon = styled(FaChevronDown)`
   {
    font-size: 20px;
  }
`;

export const Group = styled.div`
   {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const Title = styled.span`
   {
    display: block;
    min-width: 20%;
    color: ${colors.darkGrey};
  }
`;

export const InputBox = styled(Input)`
   {
    margin-top: 10px;
    width: 66%;
  }
`;

export const ButtonBox = styled.div`
   {
    width: 100%;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const CancelButton = styled(MdUndo)`
   {
    font-size: 16px;
    cursor: pointer;
    margin-right: 25px;
    color: black !important;
  }
`;

export const DeleteButton = styled(MdDelete)`
   {
    color: ${colors.danger} !important;
    font-size: 16px;
    cursor: pointer;
  }
`;

export const NewWorkerButton = styled(Button)`
   {
    margin-top: 10px;
    margin-left: 40px;
  }
`;
