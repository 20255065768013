import React, { useState } from "react";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";
import { AppNavbarContainer } from "./AppNavbar.styles";
import { useAppDispatch } from "../../store/hooks";
import { logout, reset } from "../../store/authSlice";
export const AppNavbar = ({ toggleSidebar }: { toggleSidebar: () => void }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onLogout = (e: any) => {
    dispatch(logout());
    dispatch(reset());
  };

  return (
    <>
      <AppNavbarContainer>
        <Navbar color="light" expand="md" light>
          <button
            className="lg:hidden w-12 h-12 p-2 bg-transparent border-0"
            onClick={() => toggleSidebar()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-full h-full"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
          <NavbarBrand tag={RRNavLink} to="/">
            SI Admin
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="me-auto" navbar>
              <NavItem>
                <NavLink tag={RRNavLink} to="/">
                  Dashboard
                </NavLink>
              </NavItem>
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav>
                  Tools
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <RRNavLink to="/tools/resetUserPassword">
                      User Password Reset
                    </RRNavLink>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      localStorage.removeItem("steadyinstall_filters")
                    }
                  >
                    Flush Filters
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href="#" onClick={onLogout}>
                  Logout
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </AppNavbarContainer>
    </>
  );
};
