import React, { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { throttle } from "throttle-debounce";
import { lookupBusinesses } from "../../services/businessService";
import BigSpinner from "../BigSpinner/BigSpinner";
import {
  CancelWrapper,
  SearchBar,
  SearchResult,
  SearchResultText,
  SelectButton,
  ToggleContainer,
  ToggleHandle,
  ToggleSwitch,
} from "./BusinessLookupModal.styles";

const BusinessLookupModal = ({ isOpen, callback }: any) => {
  let [businesses, setBusinesses] = useState([]);
  let [selectedBusinesses, setSelectedBusinesses] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  let [loading, setloading] = useState(false);
  let [changed, setChanged] = useState(false);
  let [previewing, setPreviewing] = useState(false);

  const onChange = throttle(400, false, (e: any) => {
    const { value } = e.target;
    if (!value) return;
    if (!changed) setChanged(true);

    setloading(true);
    lookupBusinesses(value).then((res: any) => {
      setBusinesses(res.value || []);
      setloading(false);
    });
  });

  const makeSelection = (id: number, name: string) => {
    if (id) {
      const temp = [...selectedBusinesses];
      const index = temp.findIndex((e) => e.id === id);
      if (index >= 0) {
        temp.splice(index, 1);
      } else {
        temp.push({ id, name });
      }
      setSelectedBusinesses(temp);
    }
    setChanged(false);
  };

  const renderResults = () => {
    if (!businesses.length) return <p>No businesses found by that term</p>;

    return businesses.map((business, index) => {
      const { id, businessName, user }: any = business;

      return (
        <SearchResult key={index + Date.now()}>
          <SearchResultText>
            ({id})&nbsp;
            {businessName}&nbsp;|&nbsp;
            {user.firstName}&nbsp;
            {user.lastName}
          </SearchResultText>
          <ToggleContainer
            onClick={() => {
              const temp = businessName
                ? businessName
                : `${user.firstName} ${user.lastName}`;

              makeSelection(id, `${temp} (${id})`);
            }}
          >
            <ToggleSwitch isOn={!!selectedBusinesses.find((b) => b.id === id)}>
              <ToggleHandle
                isOn={!!selectedBusinesses.find((b) => b.id === id)}
              />
            </ToggleSwitch>
          </ToggleContainer>
          {/* <SelectButton
            size="sm"
            onClick={() => {
              const temp = businessName
                ? businessName
                : `${user.firstName} ${user.lastName}`;

              makeSelection(id, `${temp} (${id})`);
            }}
          >
            Select
          </SelectButton> */}
        </SearchResult>
      );
    });
  };

  const renderSelectedBusinesses = () => {
    if (!selectedBusinesses.length) return <p>No selected businesses</p>;

    return selectedBusinesses.map((business, index) => {
      const { id, name }: any = business;

      return (
        <SearchResult key={index + Date.now()}>
          <SearchResultText>
            ({id})&nbsp;
            {name}
          </SearchResultText>
          <SelectButton
            size="sm"
            onClick={() => {
              const temp = [...selectedBusinesses];
              temp.splice(index, 1);
              setSelectedBusinesses(temp);
            }}
          >
            Remove
          </SelectButton>
        </SearchResult>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} fade={false}>
      <ModalHeader>Select Business</ModalHeader>
      {!previewing ? (
        <ModalBody>
          <p>Search by an ID, Business Name, or Owner Name</p>
          <SearchBar>
            <Input
              name="businessId"
              type="text"
              placeholder="Search"
              onChange={onChange}
            />
          </SearchBar>
          {loading && <BigSpinner />}
          {!loading && renderResults()}
          <CancelWrapper>
            <Button onClick={() => callback()}>Cancel</Button>
            <Button
              disabled={selectedBusinesses.length === 0}
              onClick={() => setPreviewing(true)}
              color="primary"
            >
              Previewing selections
            </Button>
          </CancelWrapper>
        </ModalBody>
      ) : (
        <ModalBody>
          <p>Preview all selected businesses</p>
          {renderSelectedBusinesses()}
          <CancelWrapper>
            <Button onClick={() => callback()}>Cancel</Button>
            <Button onClick={() => setPreviewing(false)}>Back</Button>
            <Button
              disabled={selectedBusinesses.length === 0}
              onClick={() => callback(selectedBusinesses)}
              color="primary"
            >
              Submit selections
            </Button>
          </CancelWrapper>
        </ModalBody>
      )}
    </Modal>
  );
};

export default BusinessLookupModal;
