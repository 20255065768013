import React, { useState } from "react";
import { Button, Input, TabPane } from "reactstrap";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  ShiftInviteUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
  LookupField,
  InputBox,
} from "./ShiftInviteUpdate.styles";
import { updateShiftInvite } from "../../services/shiftInviteService";
import BigSpinner from "../BigSpinner/BigSpinner";
import isInt from "validator/lib/isInt";
import WorkerLookupModal from "../WorkerLookupModal/WorkerLookupModal";
import ProjectDateLookupModal from "../ProjectDateLookupModal/ProjectDateLookupModal";
import ProjectWorkerLookupModal from "../ProjectWorkerLookupModal/ProjectWorkerLookupModal";
import { ProjectDate } from "../../models/ProjectDate";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ShiftInviteUpdate = ({
  id,
  workerId,
  worker,
  projectWorkerId,
  projectWorker,
  status,
  $resetView,
}: any) => {
  const startsAtParsed = tz(
    projectWorker.projectDate.startsAt,
    "%c",
    "en_US",
    projectWorker.projectDate.timezone
  ).split(" ");

  const projectWorkerDisplayString = `${[
    startsAtParsed[2],
    startsAtParsed[1] + ",",
    startsAtParsed[4].slice(0, -3),
    startsAtParsed[5],
    startsAtParsed[6],
    " | ",
    `L${projectWorker.workerType.level}`,
    `(${projectWorker.filledAmount}`,
    "/",
    `${projectWorker.totalAmount})`,
  ].join(" ")}`;

  const [formData, setFormData] = useState({
    id,
    workerId,
    projectWorkerId,
    projectDateId: projectWorker.projectDate.id,
    status,
  });

  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const [errors, setErrors] = useState<Array<any>>([]);
  const [showWorkerLookup, setShowWorkerLookup] = useState(false);
  const [workerString, setWorkerString] = useState(
    [
      worker.user.firstName,
      worker.user.lastName,
      " | ",
      worker.user.market.name,
      worker.workerType.positionName,
      `(${worker.workerType.level})`,
    ].join(" ")
  );
  const [showProjectDateLookup, setShowProjectDateLookup] = useState(false);
  const [projectDateString, setProjectDateString] = useState(
    ProjectDate.getShiftStringFrom(
      projectWorker.projectDate.startsAt,
      projectWorker.projectDate.endsAt,
      projectWorker.projectDate.timezone
    )
  );
  const [showProjectWorkerLookup, setShowProjectWorkerLookup] = useState(false);
  const [projectWorkerString, setProjectWorkerString] = useState(
    projectWorkerDisplayString
  );

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const setWorker = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        workerId: String(id),
      }));
      setWorkerString(name || "");
    }
    setShowWorkerLookup(false);
  };

  const setProjectDate = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectDateId: String(id),
      }));
      setProjectDateString(name || "");
    }
    setShowProjectDateLookup(false);
  };

  const setProjectWorker = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectWorkerId: String(id),
      }));
      setProjectWorkerString(name || "");
    }
    setShowProjectWorkerLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { workerId, status, projectWorkerId, projectDateId }: any = formData;

    if (!isInt(workerId || "")) foundErrors.push("workerId");
    if (!isInt(projectDateId || "")) foundErrors.push("projectDateId");
    if (!isInt(projectWorkerId || "")) foundErrors.push("projectWorkerId");
    if (!isInt(status || "")) foundErrors.push("status");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let response = await updateShiftInvite(id, formData);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <ShiftInviteUpdateContainer>
      <TabPane>
        <Group>
          <Title>Worker</Title>
          <LookupField>
            <Input
              type="text"
              placeholder="Select a Worker >"
              invalid={errors.indexOf("workerId") !== -1}
              value={workerString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowWorkerLookup(true)}>
              Select Worker
            </Button>
          </LookupField>
          {errors.indexOf("workerId") !== -1 && (
            <ErrorText>Invalid Worker Type</ErrorText>
          )}
        </Group>
        <Group>
          <Title>Project Date</Title>
          <LookupField>
            <Input
              type="text"
              placeholder="Select a Project Date >"
              invalid={errors.indexOf("projectDateId") !== -1}
              value={projectDateString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowProjectDateLookup(true)}>
              Select Project Date
            </Button>
          </LookupField>
        </Group>
        {errors.indexOf("projectDateId") !== -1 && (
          <ErrorText>Invalid Project Date</ErrorText>
        )}
        <Group>
          <Title>Project Worker</Title>
          <LookupField>
            <Input
              type="text"
              placeholder="Select a Project Worker >"
              invalid={errors.indexOf("projectWorkerId") !== -1}
              value={projectWorkerString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowProjectWorkerLookup(true)}>
              Select Project Worker
            </Button>
          </LookupField>
        </Group>
        {errors.indexOf("projectWorkerId") !== -1 && (
          <ErrorText>Invalid Project Worker</ErrorText>
        )}
        <Group>
          <Title>Status</Title>
          <InputBox
            name="status"
            type="select"
            value={formData.status}
            onChange={onChange}
            invalid={errors.indexOf("status") !== -1}
          >
            <option>Select a status</option>
            <option value="0">Pending</option>
            <option value="1">Accepted</option>
            <option value="2">Rejected</option>
            <option value="3">Canceled</option>
            <option value="4">Waitlisted</option>
            <option value="5">Deferred</option>
            <option value="6">Undetermined</option>
          </InputBox>
          {errors.indexOf("status") !== -1 && (
            <ErrorText>Invalid Status</ErrorText>
          )}
        </Group>

        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
        <WorkerLookupModal isOpen={showWorkerLookup} callback={setWorker} />
        <ProjectDateLookupModal
          isOpen={showProjectDateLookup}
          callback={setProjectDate}
        />
        <ProjectWorkerLookupModal
          isOpen={showProjectWorkerLookup}
          callback={setProjectWorker}
        />
      </TabPane>
    </ShiftInviteUpdateContainer>
  );
};

export default ShiftInviteUpdate;
