import React from "react";
import { AppRootContainer } from "./AppRoot.styles";
import { AppWrapper } from "../AppWrapper/AppWrapper";

export const AppRoot = () => {
  return (
    <AppRootContainer>
      <AppWrapper />
    </AppRootContainer>
  );
};
