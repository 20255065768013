import React from "react";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import {
  SelectContentComponent,
  SelectIconComponent,
  SelectItemComponent,
  SelectItemIndicatorComponent,
  SelectLabelComponent,
  SelectScrollDownButtonComponent,
  SelectScrollUpButtonComponent,
  SelectSeparatorComponent,
  SelectTriggerComponent,
  SelectViewportComponent,
} from "./Select.styles";

export type SelectComponentItem = {
  value: string;
  displayText: any;
};

export type SelectComponentGroup = {
  groupLabel: string;
  items: SelectComponentItem[];
  separator?: boolean;
};

export type SelectComponentProps = {
  trigger: {
    ariaLabel?: string;
    placeholder: string;
  };
  content: SelectComponentGroup[];
  className: string;
  onValueChange?: (value: any) => void;
  value?: any;
};

const SelectItem = React.forwardRef(
  (
    { children, className, ...props }: Select.SelectItemProps,
    forwardedRef: any
  ) => {
    return (
      <SelectItemComponent
        className={classnames("SelectItem", className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <SelectItemIndicatorComponent className="SelectItemIndicator">
          <CheckIcon />
        </SelectItemIndicatorComponent>
      </SelectItemComponent>
    );
  }
);

SelectItem.displayName = "select-item";

const getSelectItems = (items: SelectComponentItem[]) => {
  return items.map((item: SelectComponentItem, index: number) => (
    <SelectItem key={`item-${index}`} value={item.value}>
      {item.displayText}
    </SelectItem>
  ));
};

const getSelectGroups = (groups: SelectComponentGroup[]) => {
  return groups.map((grp: SelectComponentGroup, index: number) => (
    <>
      <Select.Group key={`group-${index}`}>
        <SelectLabelComponent key={`label-${index}`} className="SelectLabel">
          {grp.groupLabel}
        </SelectLabelComponent>
        {getSelectItems(grp.items)}
      </Select.Group>
      {grp.separator && (
        <SelectSeparatorComponent
          key={`sep-${index}`}
          className="SelectSeparator"
        />
      )}
    </>
  ));
};

export const SelectComponent = (props: SelectComponentProps) => {
  const selectValueChanged = (val: string) => {
    if (props.onValueChange) {
      props.onValueChange(val);
    }
  };
  return (
    <Select.Root
      key="root-1"
      onValueChange={selectValueChanged}
      value={props.value}
    >
      <SelectTriggerComponent
        key="trigger-1"
        className="SelectTrigger"
        aria-label={props.trigger.ariaLabel || "select-trigger"}
      >
        <Select.Value
          key="placeholder-1"
          placeholder={props.trigger.placeholder}
        />
        <SelectIconComponent className="SelectIcon">
          <ChevronDownIcon />
        </SelectIconComponent>
      </SelectTriggerComponent>
      <Select.Portal>
        <SelectContentComponent className="SelectContent">
          <SelectScrollUpButtonComponent
            key="ssu-1"
            className="SelectScrollButton"
          >
            <ChevronUpIcon key="chevron-up-1" />
          </SelectScrollUpButtonComponent>
          <SelectViewportComponent className="SelectViewport">
            {getSelectGroups(props.content)}
          </SelectViewportComponent>
          <SelectScrollDownButtonComponent className="SelectScrollButton">
            <ChevronDownIcon />
          </SelectScrollDownButtonComponent>
        </SelectContentComponent>
      </Select.Portal>
    </Select.Root>
  );
};

export default SelectComponent;
