import React, { useEffect, useState } from "react";
import { Button, CardBody, CardTitle, FormGroup, Input } from "reactstrap";
import { ErrorText, LoginContainer, StyledCard } from "./Login.styles";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { login, reset } from "../../store/authSlice";
import BigSpinner from "../../components/BigSpinner/BigSpinner";

export const Login = () => {
  const { user, isLoading, isError, isSuccess, message } = useAppSelector(
    (state) => state.auth
  );

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const submitOnEnter = (e: any) => {
    if (e.type === "keypress" && e.charCode === 13) dispatch(login(formData));
  };

  const submitOnClick = (e: any) => {
    e.preventDefault();
    dispatch(login(formData));
  };

  useEffect(() => {
    if (isSuccess || user) {
      navigate("/");
      dispatch(reset());
    }
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  if (isLoading)
    return (
      <LoginContainer>
        <BigSpinner />
      </LoginContainer>
    );

  if (user) return <span />;

  return (
    <LoginContainer>
      <StyledCard>
        <CardBody>
          <CardTitle tag="h1">SI Admin Login</CardTitle>
          <br />
          <FormGroup>
            <Input
              id="username"
              name="username"
              type="email"
              placeholder="Username"
              onChange={onChange}
              onKeyPress={submitOnEnter}
            />
          </FormGroup>
          <FormGroup>
            <Input
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              onChange={onChange}
              onKeyPress={submitOnEnter}
            />
          </FormGroup>
          {isError && (
            <ErrorText>Invalid credentials. Please try again.</ErrorText>
          )}
          <br />
          <Button
            color="primary"
            block
            onClick={submitOnClick}
            onKeyPress={submitOnEnter}
          >
            Submit
          </Button>
        </CardBody>
      </StyledCard>
    </LoginContainer>
  );
};
