import React, { useState } from "react";
import { Button, Input, TabPane } from "reactstrap";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  TimeLogUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
  InputBox,
} from "./TimeLogUpdate.styles";
import { updateTimeLog } from "../../services/timeLogService";
import BigSpinner from "../BigSpinner/BigSpinner";
import isEmpty from "validator/lib/isEmpty";
import { BsPaintBucket } from "react-icons/bs";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const TimeLogUpdate = ({
  id,
  clockedInAt,
  clockedOutAt,
  cancelledAt,
  lunchBreakMinutes,
  sentHome,
  tookLunch,
  shiftInvite,
  $resetView,
}: any) => {
  const [formData, setFormData] = useState({
    id,
    clockedInAt,
    clockedOutAt,
    cancelledAt,
    lunchBreakMinutes: lunchBreakMinutes ? lunchBreakMinutes : 0,
    sentHome: sentHome ? sentHome : false,
    tookLunch: tookLunch ? tookLunch : false,
  });

  const { timezone } = shiftInvite.projectWorker.projectDate;
  const [errors, setErrors] = useState<Array<any>>([]);
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const clearCancel = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      cancelledAt: null,
    }));
    if (!formModified) setFormModified(true);
  };

  const toggleState = (e: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [e.target.name]: !prevState[e.target.name],
    }));
    if (!formModified) setFormModified(true);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { id, clockedInAt, clockedOutAt, lunchBreakMinutes }: any = formData;

    if (isEmpty(clockedInAt || "")) foundErrors.push("clockedInAt");
    if (isEmpty(clockedOutAt || "")) foundErrors.push("clockedOutAt");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    //convert times to UTC before submission
    let tempForm = formData;
    tempForm.clockedInAt = new Date(tz(clockedInAt, timezone)).toUTCString();
    tempForm.clockedOutAt = new Date(tz(clockedOutAt, timezone)).toUTCString();
    tempForm.lunchBreakMinutes = lunchBreakMinutes ? lunchBreakMinutes : 0;

    setProcessing(true);
    let response = await updateTimeLog(id, tempForm);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  const localizeDate = (date: any) => tz(date, timezone, "%Y-%m-%dT%H:%M");

  return (
    <TimeLogUpdateContainer>
      <TabPane>
        <Group>
          <Title>
            Clocked In:
            <br />
            <small>({timezone})</small>
          </Title>
          <InputBox
            name="clockedInAt"
            type="datetime-local"
            value={localizeDate(formData.clockedInAt)}
            onChange={onChange}
            invalid={errors.indexOf("clockedInAt") !== -1}
          />
        </Group>
        {errors.indexOf("clockedInAt") !== -1 && (
          <ErrorText>Invalid Clock In</ErrorText>
        )}

        <Group>
          <Title>
            Clocked Out:
            <br />
            <small>({timezone})</small>
          </Title>
          <InputBox
            name="clockedOutAt"
            type="datetime-local"
            value={localizeDate(formData.clockedOutAt)}
            onChange={onChange}
            invalid={errors.indexOf("clockedOutAt") !== -1}
          />
        </Group>
        {errors.indexOf("clockedOutAt") !== -1 && (
          <ErrorText>Invalid Clock Out</ErrorText>
        )}

        <Group>
          <Title>Lunch Break (minutes)</Title>
          <Input
            name="lunchBreakMinutes"
            type="text"
            value={formData.lunchBreakMinutes}
            onChange={onChange}
            invalid={errors.indexOf("lunchBreakMinutes") !== -1}
          />
        </Group>
        {errors.indexOf("lunchBreakMinutes") !== -1 && (
          <ErrorText>Invalid Value</ErrorText>
        )}
        <Group>
          <Title>Sent Home?</Title>
          <Input
            name="sentHome"
            type="checkbox"
            checked={formData.sentHome}
            onChange={toggleState}
          />
        </Group>
        <Group>
          <Title>Took Lunch?</Title>
          <Input
            name="tookLunch"
            type="checkbox"
            checked={formData.tookLunch}
            onChange={toggleState}
          />
        </Group>
        <Group>
          <Title>Cancelled At:</Title>
          <InputBox
            name="cancelledAt"
            type="datetime-local"
            value={
              formData.cancelledAt ? localizeDate(formData.cancelledAt) : ""
            }
            onChange={onChange}
            invalid={errors.indexOf("cancelledAt") !== -1}
          />
          <br />
          <Button onClick={clearCancel}>Clear</Button>
        </Group>
        {errors.indexOf("cancelledAt") !== -1 && (
          <ErrorText>Invalid Date</ErrorText>
        )}
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>There was a server issue. Please try again.</ErrorText>
        )}
        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          &nbsp;
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
      </TabPane>
    </TimeLogUpdateContainer>
  );
};

export default TimeLogUpdate;
