import React, { useState } from "react";
import { Button, Input, TabPane } from "reactstrap";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  ProjectDateUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
  InputBox,
  LookupField,
} from "./ProjectDateUpdate.styles";
import isEmpty from "validator/lib/isEmpty";
import { updateProjectDate } from "../../services/projectDateService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { timeZoneNames } from "../../globals/constants";
import isDate from "validator/lib/isDate";
import ProjectLookupModal from "../ProjectLookupModal/ProjectLookupModal";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ProjectDateUpdate = ({
  id,
  startsAt,
  endsAt,
  timezone,
  projectId,
  project,
  cancelledAt,
  $resetView,
  isFlyout,
  $onChange,
}: any) => {
  const [formData, setFormData] = useState({
    id,
    startsAt,
    endsAt,
    timezone,
    projectId,
    cancelledAt,
  });

  const [errors, setErrors] = useState<Array<any>>([]);
  const [showProjectLookup, setShowProjectLookup] = useState(false);
  const [projectString, setProjectString] = useState(
    `(${project.id}) ${project.address}`
  );
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { startsAt, endsAt, projectId, timezone }: any = formData;

    if (isDate(startsAt || "")) foundErrors.push("startsAt");
    if (isDate(endsAt || "")) foundErrors.push("endsAt");
    if (isEmpty(timezone || "")) foundErrors.push("timezone");
    if (isEmpty(projectId || "")) foundErrors.push("projectId");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    //convert times to UTC before submission
    let tempForm = formData;
    tempForm.startsAt = new Date(tz(startsAt, timezone)).toUTCString();
    tempForm.endsAt = new Date(tz(endsAt, timezone)).toUTCString();

    setProcessing(true);
    let response = await updateProjectDate(id, tempForm);
    if (!response.error) {
      if (isFlyout) $onChange();
      else {
        setChangeSuccess(true);
        setTimeout(() => location.reload(), 2000);
      }
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  const setProject = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectId: String(id),
      }));
      setProjectString(name || "");
    }
    setShowProjectLookup(false);
  };

  const formatForDatetimeLocal = (date: string) =>
    tz(date, "%Y-%m-%dT%H:%M", timezone);

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <ProjectDateUpdateContainer isFlyout>
      <TabPane>
        <Group>
          <Title>Starts at: </Title>
          <InputBox
            name="startsAt"
            type="datetime-local"
            value={formatForDatetimeLocal(formData.startsAt)}
            onChange={onChange}
            invalid={errors.indexOf("startsAt") !== -1}
          />
        </Group>
        {errors.indexOf("startsAt") !== -1 && (
          <ErrorText>Invalid Start Date</ErrorText>
        )}

        <Group>
          <Title>Ends at:</Title>
          <InputBox
            name="endsAt"
            type="datetime-local"
            value={formatForDatetimeLocal(formData.endsAt)}
            onChange={onChange}
            invalid={errors.indexOf("endsAt") !== -1}
          />
        </Group>
        {errors.indexOf("endsAt") !== -1 && (
          <ErrorText>Invalid End Date</ErrorText>
        )}

        <Group>
          <Title>Cancelled At:</Title>
          <InputBox
            name="cancelledAt"
            type="datetime-local"
            value={
              formData.cancelledAt
                ? formatForDatetimeLocal(formData.cancelledAt)
                : ""
            }
            onChange={onChange}
            invalid={errors.indexOf("cancelledAt") !== -1}
          />
        </Group>
        {errors.indexOf("cancelledAt") !== -1 && (
          <ErrorText>Invalid Date</ErrorText>
        )}
        {!isFlyout && (
          <>
            <Group>
              <Title>Timezone:</Title>
              <InputBox
                name="timezone"
                type="select"
                value={timezone}
                disabled={true}
                onChange={onChange}
                invalid={errors.indexOf("timezone") !== -1}
              >
                <option>Select a Time Zone</option>
                {timeZoneNames.map((tzName, index) => (
                  <option value={tzName} key={index}>
                    {tzName}
                  </option>
                ))}
              </InputBox>
            </Group>

            {errors.indexOf("timezone") !== -1 && (
              <ErrorText>Invalid Timezone</ErrorText>
            )}
            <Group>
              <Title>Project</Title>
              <LookupField>
                <Input
                  type="text"
                  placeholder="Select the Project >"
                  invalid={errors.indexOf("projectId") !== -1}
                  value={projectString}
                  readOnly={true}
                />
                <Button size="sm" onClick={() => setShowProjectLookup(true)}>
                  Select Project
                </Button>
              </LookupField>
            </Group>

            {errors.indexOf("projectId") !== -1 && (
              <ErrorText>Invalid Project</ErrorText>
            )}
            <ProjectLookupModal
              isOpen={showProjectLookup}
              callback={setProject}
            />
            <br />
          </>
        )}

        {errors.indexOf("$server") !== -1 && (
          <ErrorText>There was a server issue. Please try again.</ErrorText>
        )}

        {!isFlyout && (
          <ButtonBox>
            <UpdateButton
              size="lg"
              onClick={submitOnClick}
              disabled={!formModified}
            >
              Update
            </UpdateButton>
            <CancelButton size="lg" onClick={$resetView}>
              Cancel
            </CancelButton>
          </ButtonBox>
        )}
        {!isFlyout && errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
      </TabPane>
    </ProjectDateUpdateContainer>
  );
};

export default ProjectDateUpdate;
