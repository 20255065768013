import React from "react";
import { TabPane } from "reactstrap";
import { NavLink } from "react-router-dom";

import {
  Content,
  ProjectInviteReadContainer,
  Group,
  Title,
  InlineBlock,
} from "./ProjectInviteRead.styles";
import { ShiftInvite } from "../../models/ShiftInvite";
import { ProjectDate } from "../../models/ProjectDate";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ProjectInviteRead = ({
  id,
  status,
  adminNotes,
  hashId,
  project,
  worker,
  noOtherInvites,
  createdAt,
  updatedAt,
  shiftInvites,
}: any) => {
  const renderShiftInvites = (shiftInvites: any) => {
    return shiftInvites.map((shiftInvite: any, index: number) => {
      const { startsAt, timezone } = shiftInvite.projectWorker.projectDate;
      const { level } = shiftInvite.projectWorker.workerType;
      const { firstName, lastName } = worker.user;

      const startsAtParsed = tz(startsAt, "%c", "en_US", timezone).split(" ");

      const shiftInviteString = `${[
        startsAtParsed[2],
        startsAtParsed[1] + ",",
        startsAtParsed[4].slice(0, -3),
        startsAtParsed[5],
        " | ",
        `${project.id}`,
        " | ",
        `${firstName} ${lastName}`,
        " | ",
        "L" + level,
        " | ",
        ShiftInvite.formatStatus(shiftInvite.status),
      ].join(" ")}`;

      return (
        <InlineBlock key={index}>
          <NavLink to={`/manage/shiftInvites/${String(shiftInvite.id)}`}>
            {shiftInviteString}
          </NavLink>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </InlineBlock>
      );
    });
  };

  const formattedProjectDates = () => {
    const projectDates = shiftInvites.map(
      (si: any) => si.projectWorker.projectDate
    );

    return projectDates.map((pd: any, index: number) => {
      return (
        <InlineBlock key={index}>
          <NavLink to={`/manage/projectDates/${String(pd.id)}`}>
            {ProjectDate.getShiftStringFrom(
              pd.startsAt,
              pd.endsAt,
              pd.timezone
            )}
          </NavLink>
          &nbsp;
        </InlineBlock>
      );
    });
  };

  const formatDateTime = (dateTime: string) =>
    new Date(dateTime).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

  return (
    <ProjectInviteReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Worker</Title>
          <Content>
            <NavLink to={`/manage/workers/${String(worker.id)}`}>
              {worker.user.firstName}&nbsp;
              {worker.user.lastName}&nbsp;|&nbsp;
              {worker.workerType.market.name}&nbsp;
              {worker.workerType.positionName}&nbsp; ({worker.workerType.level})
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Project</Title>
          <Content>
            <NavLink to={`/manage/projects/${String(project.id)}`}>
              ID&nbsp;{project.id}
              &nbsp;-&nbsp;
              {project.address}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Status</Title>
          <Content>{status ? "Expired" : "Active"}</Content>
        </Group>
        <Group>
          <Title>Hash Id</Title>
          <Content>{hashId}</Content>
        </Group>
        <Group>
          <Title>Admin Notes</Title>
          <Content>{adminNotes}</Content>
        </Group>
        <Group>
          <Title>Restrict Other Shift Invites?</Title>
          <Content>{noOtherInvites ? "Yes" : "No"}</Content>
        </Group>
        <Group>
          <Title>Hourly Rate</Title>
          <Content>
            ${(shiftInvites[0].shiftRateCents / 100).toFixed(2)}
          </Content>
        </Group>
        <Group>
          <Title>Created At</Title>
          <Content>{formatDateTime(createdAt)}</Content>
        </Group>
        <Group>
          <Title>Updated At</Title>
          <Content>{formatDateTime(updatedAt)}</Content>
        </Group>
        <br />
        <div>
          <Title>Shift Invites</Title>
          <br />
          {renderShiftInvites(shiftInvites)}
        </div>
        <hr />
        <div>
          <Title>Project Dates</Title>
          <br />
          {formattedProjectDates()}
        </div>
      </TabPane>
    </ProjectInviteReadContainer>
  );
};

export default ProjectInviteRead;
