import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteProductLine } from "../../services/productLineService";
import BigSpinner from "../BigSpinner/BigSpinner";
import ProductLineRead from "../ProductLineRead/ProductLineRead";

import {
  ProductLineDeleteContainer,
  DeleteButton,
  CancelButton,
  ButtonBox,
  SuccessText,
  ErrorText,
} from "./ProductLineDelete.styles";

const ProductLineDelete = ({ productLine, $resetView }: any) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const removeProductLine = async () => {
    setProcessing(true);

    const response = await deleteProductLine(productLine.id);

    if (!response.error) {
      setDeleteSuccess(true);
      setTimeout(() => navigate("/manage/productLines"), 2000);
    } else setError(true);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (deleteSuccess)
    return (
      <SuccessText>ProductLine Deleted. Redirecting you shortly.</SuccessText>
    );

  return (
    <ProductLineDeleteContainer>
      <h2>Are you sure you want to delete this?</h2>
      <ProductLineRead {...productLine} />
      {error && (
        <ErrorText>There was a problem deleting this. Try again.</ErrorText>
      )}
      <ButtonBox>
        <DeleteButton size="lg" onClick={removeProductLine}>
          Delete
        </DeleteButton>
        <CancelButton size="lg" onClick={$resetView}>
          Cancel
        </CancelButton>
      </ButtonBox>
    </ProductLineDeleteContainer>
  );
};

export default ProductLineDelete;
