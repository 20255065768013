import React from "react";
import { TabPane } from "reactstrap";
import { NavLink, NavLink as RRNavlink } from "react-router-dom";

import {
  Content,
  ShiftInviteReadContainer,
  Group,
  Title,
  Image,
} from "./ShiftInviteRead.styles";
import { ShiftInvite } from "../../models/ShiftInvite";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ShiftInviteRead = ({
  id,
  worker,
  projectWorker,
  projectInviteId,
  status,
  timeLog,
  createdAt,
  updatedAt,
}: any) => {
  const workerString = [
    worker.user.firstName,
    worker.user.lastName,
    " | ",
    worker.user.market.name,
    worker.workerType.positionName,
    `(${worker.workerType.level})`,
  ].join(" ");

  const startsAtParsed = tz(
    projectWorker.projectDate.startsAt,
    "%c",
    "en_US",
    projectWorker.projectDate.timezone
  ).split(" ");

  const endsAtParsed = tz(
    projectWorker.projectDate.endsAt,
    "%c",
    "en_US",
    projectWorker.projectDate.timezone
  ).split(" ");

  const projectWorkerString = `${[
    startsAtParsed[2],
    startsAtParsed[1] + ",",
    startsAtParsed[4].slice(0, -3),
    startsAtParsed[5],
    startsAtParsed[6],
    " | ",
    `L${projectWorker.workerType.level}`,
    `(${projectWorker.filledAmount}`,
    "/",
    `${projectWorker.totalAmount})`,
  ].join(" ")}`;

  return (
    <ShiftInviteReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Worker</Title>
          <Content>
            {" "}
            <NavLink to={`/manage/workers/${String(worker.id)}`}>
              {workerString}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Project Worker</Title>
          <Content>
            {" "}
            <NavLink to={`/manage/projectWorkers/${String(projectWorker.id)}`}>
              {projectWorkerString}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Status</Title>
          <Content>{ShiftInvite.formatStatus(status)}</Content>
        </Group>
        <Group>
          <Title>Project Invite</Title>
          <Content>
            <NavLink to={`/manage/projectInvites/${String(projectInviteId)}`}>
              Open
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Project Date</Title>
          <Content>
            <NavLink
              to={`/manage/projectDates/${String(
                projectWorker.projectDate.id
              )}`}
            >
              {[
                startsAtParsed[0] + ",",
                startsAtParsed[2],
                startsAtParsed[1] + ",",
                startsAtParsed[4].slice(0, -3),
                startsAtParsed[5],
                " - ",
                startsAtParsed[0] + ",",
                endsAtParsed[2],
                endsAtParsed[1] + ",",
                endsAtParsed[4].slice(0, -3),
                endsAtParsed[5],
                endsAtParsed[6],
              ].join(" ")}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Created At</Title>
          <Content>
            {" "}
            {new Date(createdAt).toLocaleString(`en-US`, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </Content>
        </Group>
        <Group>
          <Title>Updated At</Title>
          <Content>
            {" "}
            {new Date(updatedAt).toLocaleString(`en-US`, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </Content>
        </Group>
        {!!timeLog?.id && (
          <>
            <Group>
              <Title>Time Sheet</Title>
              <Content>
                <NavLink
                  to={`/manage/timeLogs/${String(timeLog.id)}`}
                  target="_blank"
                >
                  ( Link to time log {timeLog.id} )
                </NavLink>
                <br />
                {timeLog?.timeSheetUrl ? (
                  <a
                    href={timeLog?.timeSheetUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {timeLog?.timeSheetUrl}
                  </a>
                ) : (
                  "Not yet uploaded.."
                )}
              </Content>
            </Group>
          </>
        )}
        {!!timeLog?.timeSheetUrl && (
          <>
            <p>Preview</p>
            <Image src={timeLog?.timeSheetUrl} />
          </>
        )}
      </TabPane>
    </ShiftInviteReadContainer>
  );
};

export default ShiftInviteRead;
