import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupBusinesses = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/businesses?term=${searchTerm}`);

export const getBusinesses = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.businesses;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/businesses${filterString}`);
};

export const getBusinessById = async (businessId: string) =>
  await protectedFetch(`${API_ROOT}/businesses/${businessId}`);

export const createBusiness = async (newBusiness: any) => {
  return await protectedFetch(`${API_ROOT}/businesses`, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newBusiness),
  });
};

export const updateBusiness = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/businesses/${id}`, {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteBusiness = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/businesses/${id}`, {
    method: "DELETE",
  });
};
