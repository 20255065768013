import React from "react";
import { ManageContainer } from "./Manage.styles";
import { NavLink } from "react-router-dom";

export const Manage = () => {
  return (
    <>
      <ManageContainer>
        <ul>
          <li>
            <NavLink to="/manage/businesses">Businesses</NavLink>
          </li>
          <li>
            <NavLink to="/manage/foremen">Foremen</NavLink>
          </li>
          <li>
            <NavLink to="/manage/manufacturers">Manufacturers</NavLink>
          </li>
          <li>
            <NavLink to="/manage/markets">Markets</NavLink>
          </li>
          <li>
            <NavLink to="/manage/productLines">Product Lines</NavLink>
          </li>
          <li>
            <NavLink to="/manage/projectDates">Project Dates</NavLink>
          </li>
          <li>
            <NavLink to="/manage/projectInvites">Project Invites</NavLink>
          </li>
          <li>
            <NavLink to="/manage/projectSiteRequirements">
              Project Site Requirements
            </NavLink>
          </li>
          <li>
            <NavLink to="/manage/projectWorkers">Project Workers</NavLink>
          </li>
          <li>
            <NavLink to="/manage/shiftInvites">Shift Invites</NavLink>
          </li>
          <li>
            <NavLink to="/manage/siteRequirements">Site Requirements</NavLink>
          </li>
          <li>
            <NavLink to="/manage/timeLogs">Time Logs</NavLink>
          </li>
          <li>
            <NavLink to="/manage/workers">Workers</NavLink>
          </li>
          <li>
            <NavLink to="/manage/workerBlackLists">Worker Blacklists</NavLink>
          </li>
          <li>
            <NavLink to="/manage/workerTypes">Worker Types</NavLink>
          </li>
          <li>
            <NavLink to="/manage/projects">Projects</NavLink>
          </li>
          <li>
            <NavLink to="/manage/users">Users</NavLink>
          </li>
        </ul>
      </ManageContainer>
    </>
  );
};
