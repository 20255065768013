const generateFilterQueryString = (filterIndex: any) => {
  const { value, sortBy, page, limit } = filterIndex;
  const columnFilters = value
    .reduce((acc: any, item: any) => {
      if (
        !acc.find(
          (elem: any) =>
            elem.column === item.column && elem.pattern === item.pattern
        )
      ) {
        acc.push(item);
      }
      return acc;
    }, [])
    .map((filter: any) => {
      const { column, operator, pattern } = filter;
      return `&${column}[${operator}]=${encodeURIComponent(pattern)}`;
    })
    .join("");

  const sorting = (sortBy || {}).order
    ? `&$sortBy=${sortBy.column},${sortBy.order}`
    : "";

  const pagination = `&$limit=${limit}&$page=${page}`;

  return [columnFilters, sorting, pagination].join("").slice(1);
};

export default generateFilterQueryString;
