import React, { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { throttle } from "throttle-debounce";
import { lookupShiftInvites } from "../../services/shiftInviteService";
import BigSpinner from "../BigSpinner/BigSpinner";
import {
  CancelWrapper,
  SearchBar,
  SearchResult,
  SearchResultText,
  SelectButton,
} from "./ShiftInviteLookupModal.styles";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ShiftInviteLookupModal = ({ isOpen, callback }: any) => {
  let [shiftInvites, setShiftInvites] = useState([]);
  let [loading, setloading] = useState(false);
  let [changed, setChanged] = useState(false);

  const onChange = throttle(400, false, (e: any) => {
    const { value } = e.target;
    if (!value) return;
    if (!changed) setChanged(true);

    setloading(true);
    lookupShiftInvites(value).then((res: any) => {
      setShiftInvites(res.value || []);
      setloading(false);
    });
  });

  const makeSelection = (id: number, name: string) => {
    if (id) callback(id, name);

    setChanged(false);
  };

  const renderResults = () => {
    if (!shiftInvites.length) return <p>No shift invites found by that id</p>;

    return shiftInvites.map((shiftInvite: any, index) => {
      const { startsAt, timezone } = shiftInvite.projectWorker.projectDate;
      const { level } = shiftInvite.projectWorker.workerType;
      const { address } = shiftInvite.projectInvite.project;

      const startsAtParsed = tz(startsAt, "%c", "en_US", timezone).split(" ");

      const shiftInviteString = `${[
        `(${shiftInvite.id})`,
        startsAtParsed[2],
        startsAtParsed[1] + ",",
        startsAtParsed[4].slice(0, -3),
        startsAtParsed[5],
        " | ",
        "L" + level,
        address,
      ].join(" ")}`;

      return (
        <SearchResult key={index + Date.now()}>
          <SearchResultText>{shiftInviteString}</SearchResultText>
          <SelectButton
            size="sm"
            onClick={() => makeSelection(shiftInvite.id, shiftInviteString)}
          >
            Select
          </SelectButton>
        </SearchResult>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} fade={false}>
      <ModalHeader>Select Shift Invite</ModalHeader>
      <ModalBody>
        <p>Search by a Shift Invite ID</p>
        <SearchBar>
          <Input
            name="workerId"
            type="text"
            placeholder="Search"
            onChange={onChange}
          />
        </SearchBar>
        {loading && <BigSpinner />}
        {!loading && renderResults()}
        <CancelWrapper>
          <Button onClick={() => callback()}>Cancel</Button>
        </CancelWrapper>
      </ModalBody>
    </Modal>
  );
};

export default ShiftInviteLookupModal;
