export class AutoScheduler {
  id: number;
  projectId: number;
  inviteTimeInterval: number;
  invitesPerInterval: number;
  proximity: number;
  disabledAt: number;

  constructor(newAutoScheduler: IAutoScheduler) {
    const {
      id,
      projectId,
      inviteTimeInterval,
      invitesPerInterval,
      proximity,
      disabledAt,
    } = newAutoScheduler;

    this.id = id;
    this.projectId = projectId;
    this.inviteTimeInterval = inviteTimeInterval;
    this.invitesPerInterval = invitesPerInterval;
    this.proximity = proximity;
    this.disabledAt = disabledAt;
  }

  static getFilterConfig() {
    return {
      columns: [
        "id",
        "projectId",
        "inviteTimeInterval",
        "invitesPerInterval",
        "proximity",
        "disabledAt",
      ],
      indexToFilter: "autoSchedulers",
    };
  }
}

export interface IAutoScheduler {
  id: number;
  projectId: number;
  inviteTimeInterval: number;
  invitesPerInterval: number;
  proximity: number;
  disabledAt: number;
}
