import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { NotFoundContainer } from "./NotFound.styles";

const NotFound = ({ hideBackButton, message }: any) => {
  const navigate = useNavigate();
  return (
    <NotFoundContainer>
      <h3>¯\_(ツ)_/¯</h3>
      <p>
        {message || "Whatever you're looking for.. you won't find it here."}
      </p>

      {!hideBackButton && (
        <Button size="lg" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      )}
    </NotFoundContainer>
  );
};

export default NotFound;
