import { API_ROOT, LOCAL_STORAGE_USER } from "../globals/constants";

const login = async (userData: any) => {
  const response = await fetch(`${API_ROOT}/auth`, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app/*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });

  if (response.ok) {
    const responseJson = await response.json();
    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(responseJson));
    return responseJson;
  } else throw new Error("There was a problem logging in.");
};

const logout = async () => {
  localStorage.removeItem(LOCAL_STORAGE_USER);
  return 0;
};

export default {
  login,
  logout,
};
