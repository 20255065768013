import React, { useEffect, useState } from "react";
import { SegmentedControl } from "@radix-ui/themes";

export enum SegmentedControlRadius {
  NONE = "none",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  FULL = "full",
}

export enum SegmentedControlSize {
  SMALL = "1",
  MEDIUM = "2",
  LARGE = "3",
}

export type SegmentedControlProps = {
  items: {
    label: string;
    value: string;
  }[];
  onValueChange?: (value: any) => void;
  options?: {
    radius?: SegmentedControlRadius;
    defaultValue?: string;
    size?: SegmentedControlSize;
  };
};

const getItems = (props: SegmentedControlProps) => {
  return props.items.map((item: { label: string; value: string }, index) => (
    <SegmentedControl.Item value={item.value} key={`item-${index}`}>
      {item.label}
    </SegmentedControl.Item>
  ));
};

const SegmentedControlComponent = (props: SegmentedControlProps) => {
  const [value, setValue] = useState(
    props.options?.defaultValue || (props.items[0] || {}).value
  );
  useEffect(() => {
    if (props.onValueChange) {
      props.onValueChange(value);
    }
  }, [value]);
  const size = props.options?.size || SegmentedControlSize.MEDIUM;
  const radius = props.options?.radius || SegmentedControlRadius.NONE;
  return (
    <SegmentedControl.Root
      radius={radius}
      size={size}
      value={value}
      onValueChange={(value) => {
        if (value) {
          setValue(value);
        }
      }}
    >
      {getItems(props)}
    </SegmentedControl.Root>
  );
};

export default SegmentedControlComponent;
