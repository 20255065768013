import React, { useState } from "react";
import { ProjectDatesContainer } from "./ProjectDates.styles";
import { ResourceNavbar } from "../../components/ResourceNavbar/ResourceNavbar";
import { TabContent } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import { ProjectDateCreate } from "../../components/ProjectDateCreate/ProjectDateCreate";
import { ProjectDateIndex } from "../../components/ProjectDateIndex/ProjectDateIndex";

export const ProjectDates = () => {
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || "index"
  );

  const renderTab = () => {
    switch (activeTab) {
      case "index":
        return <ProjectDateIndex />;
      case "create":
        return <ProjectDateCreate />;
      case "export":
        return <ProjectDateIndex />;
      default:
        return <ProjectDateIndex />;
    }
  };

  return (
    <>
      <ProjectDatesContainer>
        <ResourceNavbar
          activeTab={activeTab}
          passActiveTab={(newValue: string) => setActiveTab(newValue)}
        />
        <TabContent>{renderTab()}</TabContent>
      </ProjectDatesContainer>
    </>
  );
};
