import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  ProjectWorkerCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  LookupField,
} from "./ProjectWorkerCreate.styles";
import { createProjectWorker } from "../../services/projectWorkerService";
import { useNavigate, useSearchParams } from "react-router-dom";
import WorkerTypeLookupModal from "../WorkerTypeLookupModal/WorkerTypeLookupModal";
import isInt from "validator/lib/isInt";
import ProjectDateLookupModal from "../ProjectDateLookupModal/ProjectDateLookupModal";
import { ProjectDate } from "../../models/ProjectDate";
import { getProjectDateById } from "../../services/projectDateService";

export const ProjectWorkerCreate = ({
  projectDateId,
  isFlyout,
  $onChange,
}: any) => {
  const [formData, setFormData] = useState({
    workerTypeId: "",
    filledAmount: "",
    totalAmount: "",
    projectDateId: "",
  });

  const [searchParams] = useSearchParams();
  const [forProjectDateId] = useState(
    projectDateId || searchParams.get("forProjectDateId") || ""
  );

  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();
  const [showWorkerTypeLookup, setShowWorkerTypeLookup] = useState(false);
  const [workerTypeString, setWorkerTypeString] = useState("");
  const [showProjectDateLookup, setShowProjectDateLookup] = useState(false);
  const [projectDateString, setProjectDateString] = useState("");

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const setWorkerType = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        workerTypeId: String(id),
      }));
      setWorkerTypeString(name || "");
    }
    setShowWorkerTypeLookup(false);
  };

  const setProjectDate = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectDateId: String(id),
      }));
      setProjectDateString(name || "");
    }
    setShowProjectDateLookup(false);
  };

  const clearForm = () => {
    setFormData({
      workerTypeId: "",
      filledAmount: "",
      totalAmount: "",
      projectDateId: "",
    });
    setErrors([]);
    setNewId(0);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { workerTypeId, filledAmount, totalAmount, projectDateId }: any =
      formData;

    if (!isInt(workerTypeId || "")) foundErrors.push("workerTypeId");
    if (!isInt(projectDateId || "")) foundErrors.push("projectDateId");
    if (!isInt(filledAmount || "")) foundErrors.push("filledAmount");
    if (!isInt(totalAmount || "")) foundErrors.push("totalAmount");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let response = await createProjectWorker(formData);

    if (!response.error) {
      if (isFlyout) $onChange();
      setNewId(response.value.id);
    } else setErrors((errors) => [...errors, "$server"]);
  };

  useEffect(() => {
    if (!forProjectDateId) return;
    getProjectDateById(forProjectDateId).then((res: any) => {
      const { id, startsAt, endsAt, timezone } = res.value;
      setFormData({
        ...formData,
        projectDateId: id,
      });
      let shiftString = ProjectDate.getShiftStringFrom(
        startsAt,
        endsAt,
        timezone
      );
      setProjectDate(id, `(${id}) ${shiftString}`);
    });
  }, []);

  if (newId && !isFlyout) {
    return (
      <ProjectWorkerCreateContainer>
        <SuccessText>New Project Worker successfully created.</SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to new Project Worker
          </Button>
        </SuccessButtonWrapper>
      </ProjectWorkerCreateContainer>
    );
  }

  return (
    <ProjectWorkerCreateContainer isFlyout>
      <LookupField>
        <Input
          type="text"
          placeholder="Select a WorkerType >"
          invalid={errors.indexOf("workerTypeId") !== -1}
          value={workerTypeString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowWorkerTypeLookup(true)}>
          Select WorkerType
        </Button>
      </LookupField>
      {errors.indexOf("workerTypeId") !== -1 && (
        <ErrorText>Invalid Worker Type</ErrorText>
      )}
      <WorkerTypeLookupModal
        isOpen={showWorkerTypeLookup}
        callback={setWorkerType}
      />
      <br />
      {!isFlyout && (
        <>
          <LookupField>
            <Input
              type="text"
              placeholder="Select a Project Date >"
              invalid={errors.indexOf("projectDateId") !== -1}
              value={projectDateString}
              readOnly={true}
            />
            <Button
              size="sm"
              disabled={!!forProjectDateId}
              onClick={() => setShowProjectDateLookup(true)}
            >
              Select Project Date
            </Button>
          </LookupField>
          {errors.indexOf("projectDateId") !== -1 && (
            <ErrorText>Invalid Project Date</ErrorText>
          )}
        </>
      )}

      <ProjectDateLookupModal
        isOpen={showProjectDateLookup}
        callback={setProjectDate}
      />
      <br />

      <InputBox
        name="filledAmount"
        type="text"
        placeholder="Filled Amount"
        onChange={onChange}
        invalid={errors.indexOf("filledAmount") !== -1}
      />
      {errors.indexOf("filledAmount") !== -1 && (
        <ErrorText>Invalid Filled Amount</ErrorText>
      )}
      <InputBox
        name="totalAmount"
        type="text"
        placeholder="Total Amount"
        onChange={onChange}
        invalid={errors.indexOf("totalAmount") !== -1}
      />
      {errors.indexOf("totalAmount") !== -1 && (
        <ErrorText>Invalid Total Amount</ErrorText>
      )}

      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </ProjectWorkerCreateContainer>
  );
};
