import React, { useState } from "react";
import { Button, Input, Spinner } from "reactstrap";
import {
  ErrorText,
  WorkerBlackListCreateContainer,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  LookupField,
} from "./WorkerBlackListCreate.styles";
import { createWorkerBlackList } from "../../services/workerBlackListService";
import BusinessLookupModal from "../BusinessLookupModal/BusinessLookupModal";
import { useNavigate } from "react-router-dom";
import isInt from "validator/lib/isInt";
import WorkerLookupModal from "../WorkerLookupModal/WorkerLookupModal";

export const WorkerBlackListCreate = () => {
  const [formData, setFormData] = useState({
    businessId: "",
    workerId: "",
  });
  const [showBusinessLookup, setShowBusinessLookup] = useState(false);
  const [businessString, setBusinessString] = useState("");
  const [showWorkerLookup, setShowWorkerLookup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [workerString, setWorkerString] = useState("");
  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState<{ id: any; name: string }[]>([]);
  const [selected, setSelected] = useState<{ id: any; name: string }[]>([]);
  const navigate = useNavigate();

  const clearForm = () => {
    setFormData({
      businessId: "",
      workerId: "",
    });
    setErrors([]);
    setShowBusinessLookup(false);
    setShowWorkerLookup(false);
    setNewId([]);
  };

  const setBusiness = (data: { id: any; name: string }[]) => {
    if (data && data.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        businessId: String(data.map((e) => e.id).join(",")),
      }));
      setBusinessString(data.map((e) => e.name).join(", ") || "");
      setSelected(data);
    }
    setShowBusinessLookup(false);
  };

  const setWorker = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        workerId: String(id),
      }));
      setWorkerString(name || "");
    }
    setShowWorkerLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { businessId, workerId }: any = formData;
    const ids = businessId.split(",");
    if (ids.length === 0 || ids.some((id: string) => !isInt(id || "")))
      foundErrors.push("businessId");
    if (!isInt(workerId || "")) foundErrors.push("workerId");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }
    setIsSubmitting(true);
    const responses = await Promise.all(
      ids.map((id: string) =>
        createWorkerBlackList({ businessId: id, workerId })
      )
    );
    setIsSubmitting(false);

    const error = responses.some((response) => !!response.error);
    if (!error)
      setNewId(
        responses.map((response) => {
          const biz = selected.find((e) => e.id === response.value.businessId);
          return {
            id: response.value.id,
            name: biz?.name || "",
          };
        })
      );
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId.length > 0) {
    return (
      <WorkerBlackListCreateContainer>
        <SuccessText>New WorkerBlackList successfully created.</SuccessText>
        <div className="row">
          {newId.map(({ id, name }) => (
            <div key={id} className="py-1 col-12 col-md-4">
              <Button onClick={() => navigate(`./${id}`)}>
                View {name} WorkerBlackList
              </Button>
            </div>
          ))}
        </div>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
        </SuccessButtonWrapper>
      </WorkerBlackListCreateContainer>
    );
  }

  return (
    <WorkerBlackListCreateContainer>
      <LookupField>
        <Input
          type="text"
          placeholder="Select the Business >"
          invalid={errors.indexOf("businessId") !== -1}
          value={businessString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowBusinessLookup(true)}>
          Select Business
        </Button>
      </LookupField>
      {errors.indexOf("businessId") !== -1 && (
        <ErrorText>Invalid Business</ErrorText>
      )}
      <BusinessLookupModal isOpen={showBusinessLookup} callback={setBusiness} />
      <LookupField>
        <Input
          type="text"
          placeholder="Select the Worker >"
          invalid={errors.indexOf("workerId") !== -1}
          value={workerString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowWorkerLookup(true)}>
          Select Worker
        </Button>
      </LookupField>
      {errors.indexOf("workerId") !== -1 && (
        <ErrorText>Invalid Worker</ErrorText>
      )}
      <WorkerLookupModal isOpen={showWorkerLookup} callback={setWorker} />
      <br />
      <SubmitButton
        disabled={isSubmitting}
        color="primary"
        onClick={submitOnClick}
      >
        Submit {isSubmitting && <Spinner size={"sm"} />}
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </WorkerBlackListCreateContainer>
  );
};
