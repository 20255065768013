import React, { useState } from "react";
import { Button, Input, InputGroup, TabPane } from "reactstrap";
import MarketLookupModal from "../MarketLookupModal/MarketLookupModal";

import {
  ErrorText,
  UserUpdateContainer,
  SuccessText,
  Group,
  Title,
  ButtonBox,
  UpdateButton,
  CancelButton,
} from "./UserUpdate.styles";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import isInt from "validator/lib/isInt";
import isEmail from "validator/lib/isEmail";
import { updateUser } from "../../services/userService";
import BigSpinner from "../BigSpinner/BigSpinner";

const UserUpdate = ({
  id,
  firstName,
  lastName,
  phoneNumber,
  email,
  role,
  status,
  market,
  marketId,
  $resetView,
}: any) => {
  const [formData, setFormData] = useState({
    id,
    firstName,
    lastName,
    phoneNumber,
    email,
    role,
    status,
    market,
    marketId,
  });
  const [marketString, setMarketString] = useState(
    `${market.name} | ${market.timezone} - (${marketId})`
  );
  const [errors, setErrors] = useState<Array<any>>([]);
  const [showMarketLookup, setShowMarketLookup] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const setMarket = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        marketId: String(id),
      }));
      setMarketString(name || "");
    }
    setShowMarketLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    let foundErrors = [];

    const {
      firstName,
      lastName,
      phoneNumber,
      role,
      status,
      email,
      marketId,
    }: any = formData;

    if (isEmpty(firstName || "")) foundErrors.push("firstName");
    if (isEmpty(lastName || "")) foundErrors.push("lastName");
    if (!isMobilePhone(phoneNumber || "", "en-US"))
      foundErrors.push("phoneNumber");
    if (isEmpty(role || "")) foundErrors.push("role");
    if (!isInt(String(status))) foundErrors.push("status");
    if (!isInt(marketId || "")) foundErrors.push("marketId");
    if (!isEmail(email || "")) foundErrors.push("email");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    if (
      role !== "admin" &&
      formData.role === "admin" &&
      !confirm("You're making this user an ADMIN. Are you sure?")
    )
      return;

    setProcessing(true);
    let response = await updateUser(id, formData);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <UserUpdateContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Input
            name="id"
            type="text"
            value={formData.id}
            bsSize="sm"
            readOnly={true}
          />
        </Group>
        <Group>
          <Title>First Name</Title>
          <Input
            name="firstName"
            type="text"
            value={formData.firstName}
            onChange={onChange}
            invalid={errors.indexOf("firstName") !== -1}
          />
        </Group>
        {errors.indexOf("firstName") !== -1 && (
          <ErrorText>Invalid Name</ErrorText>
        )}
        <Group>
          <Title>Last Name</Title>
          <Input
            name="lastName"
            type="text"
            value={formData.lastName}
            onChange={onChange}
            invalid={errors.indexOf("lastName") !== -1}
          />
        </Group>
        {errors.indexOf("lastName") !== -1 && (
          <ErrorText>Invalid Name</ErrorText>
        )}
        <Group>
          <Title>Email</Title>
          <Input
            name="email"
            type="email"
            value={formData.email}
            onChange={onChange}
            invalid={errors.indexOf("email") !== -1}
          />
        </Group>
        {errors.indexOf("email") !== -1 && <ErrorText>Invalid Email</ErrorText>}
        <Group>
          <Title>Phone</Title>
          <Input
            name="phoneNumber"
            type="text"
            value={formData.phoneNumber}
            onChange={onChange}
            invalid={errors.indexOf("phoneNumber") !== -1}
          />
        </Group>
        {errors.indexOf("phoneNumber") !== -1 && (
          <ErrorText>Invalid Phone Number</ErrorText>
        )}
        <Group>
          <Title>Role</Title>
          <Input
            name="role"
            type="select"
            onChange={onChange}
            value={formData.role}
            invalid={errors.indexOf("role") !== -1}
          >
            <option value="">Select a Role</option>
            <option value="user">User (Business)</option>
            <option value="worker">Worker</option>
            <option value="admin">Admin</option>
          </Input>
        </Group>
        {errors.indexOf("role") !== -1 && <ErrorText>Invalid Role</ErrorText>}
        <Group>
          <Title>Status</Title>
          <Input
            name="status"
            type="select"
            onChange={onChange}
            value={formData.status}
            invalid={errors.indexOf("status") !== -1}
          >
            <option value="">Select a status</option>
            <option value="0">Pending</option>
            <option value="1">Active</option>
            <option value="2">Inactive</option>
          </Input>
        </Group>
        {errors.indexOf("status") !== -1 && <ErrorText>Invalid Role</ErrorText>}
        <Group>
          <Title>Market</Title>
          <InputGroup>
            <Input
              type="text"
              placeholder="Select Market >"
              invalid={errors.indexOf("marketId") !== -1}
              value={marketString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowMarketLookup(true)}>
              Select Market
            </Button>
          </InputGroup>
        </Group>
        {errors.indexOf("marketId") !== -1 && (
          <ErrorText>Invalid Market</ErrorText>
        )}
        <MarketLookupModal isOpen={showMarketLookup} callback={setMarket} />
        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
      </TabPane>
    </UserUpdateContainer>
  );
};

export default UserUpdate;
