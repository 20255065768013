import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";
import { colors } from "../../../globals/globals.styles";
import { Typeahead } from "react-bootstrap-typeahead";

export const SearchBar = styled.div`
   {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
`;

export const SearchResult = styled.div`
   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
`;

export const SearchResultText = styled.div`
   {
  }
`;

export const SelectButton = styled(Button)`
   {
  }
`;

export const SubmitWrapper = styled.div`
   {
    display: flex;
    justify-content: flex-end;
  }
`;

export const Group = styled.div`
   {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin-top: 10px;
    @media only screen and (max-width: 768px) {
      justify-content: space-between;
    }
  }
`;

export const Title = styled.span`
   {
    display: block;
    min-width: 20%;
    color: ${colors.darkGrey};
  }
`;

export const TypeaheadInput = styled(Typeahead)`
   {
    width: 100%;
    display: flex;
    border-style: solid;
    border-color: lightgray;
    border-width: 1px;
    border-radius: 5px;
    align-items: center;
    input {
      border: none;
    }
    .rbt-aux {
      button {
        margin-top: 8px;
        margin-right: 10px;
        width: 10px;
        height: 10px;
        font-size: xx-small;
      }
      span {
        opacity: 0%;
      }
    }
  }
`;

export const SuccessText = styled.p`
   {
    margin-top: 20px;
    color: ${colors.darkGreen};
    font-weight: bold;
  }
`;
