import React, { useEffect, useState } from "react";
import { Input, Table } from "reactstrap";
import isFloat from "validator/lib/isFloat";
import isInt from "validator/lib/isInt";
import SelfSortColumnHead from "../SelfSortColumnHead/SelfSortColumnHead";
import {
  SearchInput,
  SearchWrapper,
  ProjectWorkerTableWrapper,
} from "./ProjectWorkerTable.styles";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ProjectWorkerTable = ({ rows, reportSelections, selectAll }: any) => {
  const [processedRows, setProcessedRows] = useState<any>(rows || []);
  const [tableRows, setTableRows] = useState<any>([]);
  const [lastSortedColumn, setLastSortedColumn] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [customRates, setCustomRates] = useState<any>({});

  const onChange = (e: any) => {
    setSearchTerm(e.target.value || "");
  };

  const onRateChange = (projectWorkerId: any, customRate: any) => {
    if (!isFloat(customRate) && !isInt(customRate)) return;

    setCustomRates((prevState: any) => ({
      ...prevState,
      [projectWorkerId]: customRate,
    }));
  };

  const toggleRow = (projectWorkerId: number) => {
    if (selectedIds.some((id: any) => id == projectWorkerId))
      setSelectedIds(
        [...selectedIds].filter((rowId: any) => rowId != projectWorkerId)
      );
    else setSelectedIds([...selectedIds, projectWorkerId]);
  };

  const sortRows = (sort: any) => {
    let tempRows = [...processedRows];
    const { column, order } = sort;
    switch (order) {
      case "ASC":
        tempRows = tempRows.sort((a: any, b: any): any =>
          String(a[column]).localeCompare(String(b[column]))
        );
        break;
      case "DESC":
        tempRows = tempRows.sort((a: any, b: any): any =>
          String(b[column]).localeCompare(String(a[column]))
        );
        break;
      default:
        break;
    }
    setLastSortedColumn(column);
    setTableRows(tempRows);
  };

  const filterRowsBySearch = (searchTerm: string) => {
    let tempRows = [...rows];

    if (searchTerm)
      tempRows = tempRows.filter(
        (row: any) =>
          JSON.stringify(row).toLowerCase().indexOf(searchTerm.toLowerCase()) !=
          -1
      );

    setTableRows([...tempRows]);
  };

  useEffect(() => {
    filterRowsBySearch(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    reportSelections(selectedIds, customRates);
  }, [selectedIds, customRates]);

  useEffect(() => {
    let tempRows = rows
      .filter((row: any) => row.requested > 0)
      .map((row: any) => {
        const { endsAt, timezone, startsAt } = row;
        const startsAtParsed = tz(startsAt, "%c", "en_US", timezone).split(" ");
        const endsAtParsed = tz(endsAt, "%c", "en_US", timezone).split(" ");
        row.shiftDate = [
          startsAtParsed[2],
          startsAtParsed[1] + ",",
          startsAtParsed[4].slice(0, -3),
          startsAtParsed[5],
          " - ",
          endsAtParsed[4].slice(0, -3),
          endsAtParsed[5],
          endsAtParsed[6],
        ].join(" ");
        return row;
      });

    setTableRows([...tempRows]);
  }, []);

  useEffect(() => {
    let selectAllIds: any = [];
    tableRows.forEach((row: any) => {
      if (row.positionName === selectAll) selectAllIds.push(row.id);
    });
    setSelectedIds([...selectAllIds]);
  }, [selectAll]);

  return (
    <ProjectWorkerTableWrapper>
      <SearchWrapper>
        <div>
          <h4>Project Workers</h4>
        </div>
        <SearchInput type="text" placeholder="Search" onChange={onChange} />
      </SearchWrapper>
      <Table bordered responsive size="sm" striped>
        <thead>
          <tr>
            <th />
            <SelfSortColumnHead
              className="center"
              displayName={"Level"}
              column={"level"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "level"}
            />
            <SelfSortColumnHead
              displayName={"Shift Date"}
              column={"shiftDate"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "shiftDate"}
            />
            <SelfSortColumnHead
              displayName={"Request"}
              column={"request"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "request"}
            />
            <SelfSortColumnHead
              displayName={"Open"}
              column={"open"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "open"}
            />
            <th>Invited</th>
            <th>Accept</th>
            <th>Reject</th>
            <th>Cancel</th>
            <th>Pending</th>
            <th>Waitlisted</th>
            <th>Rate($)</th>
          </tr>
        </thead>
        <tbody>
          {!!tableRows &&
            tableRows.map((row: any, index: number) => {
              const {
                id,
                level,
                shiftDate,
                requested,
                needed,
                invited,
                accepted,
                rejected,
                canceled,
                pending,
                waitListed,
                rate,
              } = row;

              return (
                <tr key={index}>
                  <td>
                    <Input
                      type="checkbox"
                      onChange={() => toggleRow(id)}
                      checked={selectedIds.some(
                        (checkId: any) => checkId == id
                      )}
                    />
                  </td>
                  <td className="center">{level}</td>
                  <td>{shiftDate}</td>
                  <td>{requested}</td>
                  <td>{needed}</td>
                  <td>{invited}</td>
                  <td>{accepted}</td>
                  <td>{rejected}</td>
                  <td>{canceled}</td>
                  <td>{pending}</td>
                  <td>{waitListed}</td>
                  <td>
                    <Input
                      className="rateInput"
                      defaultValue={customRates[id] ? customRates[id] : rate}
                      onChange={(e) => onRateChange(id, e.target.value)}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </ProjectWorkerTableWrapper>
  );
};

export default ProjectWorkerTable;
