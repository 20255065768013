export class Manufacturer {
  id: number;
  manufacturerName: string;

  constructor(newMarket: IManufacturer) {
    const { id, manufacturerName } = newMarket;

    this.id = id;
    this.manufacturerName = manufacturerName;
  }

  static getFilterConfig() {
    return {
      columns: ["id", "manufacturerName"],
      indexToFilter: "manufacturers",
    };
  }
}

export interface IManufacturer {
  id: number;
  manufacturerName: string;
}
