import React, { useState } from "react";
import { PieChart, Pie, ResponsiveContainer } from "recharts";
import { NavLink } from "react-router-dom";
import { TabPane, Container, Col, Row } from "reactstrap";
import Chart from "./Chart";
import {
  Content,
  BusinessReadContainer,
  Group,
  Title,
} from "./BusinessRead.styles";
import { PieSectorDataItem } from "recharts/types/polar/Pie";
import { ActiveShape } from "recharts/types/util/types";

const BusinessRead = ({
  id,
  user,
  userId,
  stripeCustomerId,
  billingContact,
  businessAddress,
  businessName,
  ninjaInvoiceKey,
  typeOfBusiness,
  managerTitle,
  promoCode,
  accountsPayableEmail,
  projects,
}: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  return (
    <BusinessReadContainer>
      <TabPane>
        <Container>
          <Row>
            <Col xs="12" lg="6" md="6">
              <Group>
                <Title>ID</Title>
                <Content>{id}</Content>
              </Group>
              <Group>
                <Title>User (ID)</Title>
                <Content>
                  <NavLink to={`/manage/users/${String(userId)}`}>
                    {`${user.firstName} ${user.lastName} (${userId})`}
                  </NavLink>
                </Content>
              </Group>
              <Group>
                <Title>Stripe ID</Title>
                <Content>{stripeCustomerId}</Content>
              </Group>
              <Group>
                <Title>Contact</Title>
                <Content>
                  <a href={`tel:${billingContact}`}>{billingContact}</a>
                </Content>
              </Group>
              <Group>
                <Title>Address</Title>
                <Content>{businessAddress}</Content>
              </Group>
              <Group>
                <Title>Name</Title>
                <Content>{businessName}</Content>
              </Group>
              <Group>
                <Title>Ninja Invoice Key</Title>
                <Content>{ninjaInvoiceKey}</Content>
              </Group>
              <Group>
                <Title>Type</Title>
                <Content>{typeOfBusiness}</Content>
              </Group>
              <Group>
                <Title>Manager Title</Title>
                <Content>{managerTitle}</Content>
              </Group>
              <Group>
                <Title>Promo Code</Title>
                <Content>{promoCode}</Content>
              </Group>
              <Group>
                <Title>Email</Title>
                <Content>
                  <a href={`mailto:${accountsPayableEmail}`}>
                    {accountsPayableEmail}
                  </a>
                </Content>
              </Group>
            </Col>
            <Col xs="12" lg="6" md="6">
              <ResponsiveContainer width="100%" height="60%">
                <PieChart width={400} height={400}>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={Chart as ActiveShape<PieSectorDataItem>}
                    data={projects}
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={90}
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                  />
                </PieChart>
              </ResponsiveContainer>
              <Container className="w-100 text-capitalize">
                Projects
                <ul>
                  {projects.map((project: any) => {
                    return (
                      <li key={project.name}>
                        <NavLink to={`/manage/projects/${String(project.id)}`}>
                          {project.name}(
                          <span className="fw-bold fs-6">
                            {project.value}hrs
                          </span>
                          )
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </Container>
            </Col>
          </Row>
        </Container>
      </TabPane>
    </BusinessReadContainer>
  );
};

export default BusinessRead;
