import React, { useEffect, useState } from "react";
import { Button, Input, Spinner, TabPane } from "reactstrap";

import {
  Content,
  Group,
  Title,
  ProjectToolInviteContainer,
  NoResults,
  InfoBox,
  SuccessText,
} from "./ProjectToolInvite.styles";
import ProjectWorkerTable from "./ProjectWorkerTable/ProjectWorkerTable";
import {
  batchInviteWorkers,
  getAvailableWorkers,
  getInvitedWorkersForProjectId,
} from "../../services/toolService";
import AvailableWorkersTable from "./AvailableWorkersTable/AvailableWorkersTable";
import BigSpinner from "../BigSpinner/BigSpinner";
import InvitedWorkersTable from "./InvitedWorkersTable/InvitedWorkersTable";
import { NavLink } from "react-router-dom";

const ProjectToolInvite = ({
  id,
  projectDates,
  user,
  projectName,
  address,
}: any) => {
  const [projectWorkers, setProjectWorkers] = useState([]);
  const [selectedProjectWorkers, setSelectedProjectWorkers] = useState([]);
  const [customRates, setCustomRates] = useState({});
  const [availableWorkers, setAvailableWorkers] = useState([]);
  const [fetchingAvailableWorkers, setFetchingAvailableWorkers] =
    useState(false);
  const [selectedAvailableWorkers, setSelectedAvailableWorkers] = useState([]);
  const [invitedWorkers, setInvitedWorkers] = useState<any>([]);
  const [projectLevels, setProjectLevels] = useState<any>([]);
  const [selectedProjectLevel, setSelectedProjectLevel] = useState("");
  const [showAllWorkers, setShowAllWorkers] = useState(false);
  const [filterSameDayInvites, setFilterSameDayInvites] = useState(false);
  const [noOtherInvites, setNoOtherInvites] = useState(false);
  const [adminNotes, setAdminNotes] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showAllDates, setShowAllDates] = useState(false);

  const adminNotesOnChange = (e: any) => {
    setAdminNotes(e.target.value || "");
  };

  const populateInvitedWorkers = async () => {
    let { value, error } = await getInvitedWorkersForProjectId(id);

    if (value) setInvitedWorkers(value || []);
    if (error) {
      setInvitedWorkers([]);
      console.log("Error on getInvitedWorkersForProjectId");
    }
  };

  const populateProjectWorkers = () => {
    let projectWorkerRows: any = [];
    let levels: any = [];

    projectDates.forEach(
      ({ startsAt, endsAt, timezone, projectWorkers }: any) => {
        //ignore ProjectWorkers with past dates
        if (
          !showAllDates &&
          new Date(startsAt).getTime() + 86400000 < Date.now()
        )
          return;

        projectWorkers.forEach(
          ({
            id,
            totalAmount,
            workerType: { positionName, level, paymentCents },
            shiftInvites,
          }: any) => {
            let requested = 0,
              needed = 0,
              invited = 0,
              accepted = 0,
              rejected = 0,
              canceled = 0,
              pending = 0,
              waitListed = 0,
              rate = 0;

            requested = totalAmount;
            invited = shiftInvites.length;
            rate = +(paymentCents / 100).toFixed(2);
            shiftInvites.forEach(({ status }: any) => {
              switch (status) {
                case 0:
                  pending++;
                  break;
                case 1:
                  accepted++;
                  break;
                case 2:
                  rejected++;
                  break;
                case 3:
                  canceled++;
                  break;
                case 4:
                  waitListed++;
                  break;
                default:
                  break;
              }
            });

            needed = requested - accepted;
            projectWorkerRows.push({
              id,
              level,
              positionName,
              startsAt,
              endsAt,
              timezone,
              requested,
              needed,
              invited,
              accepted,
              rejected,
              canceled,
              pending,
              waitListed,
              rate,
            });
            levels.push(positionName);
          }
        );
      }
    );

    setProjectWorkers(projectWorkerRows);
    setProjectLevels([...new Set(levels)]);
  };

  useEffect(() => {
    if (selectedProjectWorkers.length) {
      setFetchingAvailableWorkers(true);
      getAvailableWorkers(selectedProjectWorkers, {
        showAllWorkers,
        filterSameDayInvites,
      })
        .then((res) => {
          console.log(res.logging);
          if (res.value) setAvailableWorkers(res.value);
        })
        .finally(() => setFetchingAvailableWorkers(false));
    } else setAvailableWorkers([]);
  }, [selectedProjectWorkers, showAllWorkers, filterSameDayInvites]);

  useEffect(() => {
    populateProjectWorkers();
    populateInvitedWorkers();
  }, []);

  useEffect(() => {
    populateProjectWorkers();
  }, [showAllDates]);

  const selectAllByProjectLevel = (projectLevel: string) => {
    if (projectLevel === selectedProjectLevel) setSelectedProjectLevel("");
    else setSelectedProjectLevel(projectLevel);
  };

  const resetAll = () => {
    setSelectedProjectWorkers([]);
    setSelectedAvailableWorkers([]);
    setSelectedProjectLevel("");
    setShowSuccess(false);
  };

  const toggleShowAllWorkers = () => setShowAllWorkers(!showAllWorkers);

  const toggleFilterSameDayInvites = () =>
    setFilterSameDayInvites(!filterSameDayInvites);

  const toggleNoOtherInvites = () => setNoOtherInvites(!noOtherInvites);
  const toggleShowAllDates = () => setShowAllDates(!showAllDates);

  const submitBatchInvite = async (e: any) => {
    setIsSubmitting(true);

    let { value, error } = await batchInviteWorkers({
      selectedAvailableWorkers: selectedAvailableWorkers.join(","),
      selectedProjectWorkers: selectedProjectWorkers.join(","),
      adminNotes,
      customRates,
      noOtherInvites,
    });

    resetAll();
    setIsSubmitting(false);
    if (value) {
      setShowSuccess(true);
      setTimeout(() => {
        resetAll();
      }, 2000);
    }
    if (error) alert("Something went wrong: " + error);
  };

  if (showSuccess)
    return (
      <ProjectToolInviteContainer>
        <SuccessText>{`New batch invite successfully created.`}</SuccessText>;
      </ProjectToolInviteContainer>
    );

  if (isSubmitting) return <BigSpinner />;

  return (
    <ProjectToolInviteContainer>
      <TabPane>
        <h3>Manage Invites</h3>
        <InfoBox>
          <NavLink to={`/manage/businesses/${String(user.business.id)}`}>
            Business:&nbsp;
            {`${user.business.businessName || "(no name set)"} (${
              user.business.id
            })`}
          </NavLink>
          <p>Name:&nbsp;{projectName}</p>
          <p>Address:&nbsp;{address}</p>
        </InfoBox>
        {!!projectLevels.length &&
          projectLevels.map((projectLevel: any, index: number) => {
            return (
              <Group key={index}>
                <Title>Select all {projectLevel}</Title>
                <Content>
                  <Input
                    type="checkbox"
                    onChange={() => selectAllByProjectLevel(projectLevel)}
                    checked={selectedProjectLevel === projectLevel}
                  />
                </Content>
              </Group>
            );
          })}
        <Group>
          <Title>Show All Workers</Title>
          <Content>
            <Input
              type="checkbox"
              onChange={() => toggleShowAllWorkers()}
              checked={showAllWorkers}
            />
          </Content>
        </Group>
        <Group>
          <Title>Exclude Identical Project Invites</Title>
          <Content>
            <Input
              type="checkbox"
              onChange={() => toggleFilterSameDayInvites()}
              checked={filterSameDayInvites}
            />
          </Content>
        </Group>
        <Group>
          <Title>Restrict Invites To Other Shifts</Title>
          <Content>
            <Input
              type="checkbox"
              onChange={() => toggleNoOtherInvites()}
              checked={noOtherInvites}
            />
          </Content>
        </Group>
        <Group>
          <Title>Show All Dates</Title>
          <Content>
            <Input
              type="checkbox"
              onChange={() => toggleShowAllDates()}
              checked={showAllDates}
            />
          </Content>
        </Group>
        {!!projectWorkers.length && (
          <ProjectWorkerTable
            rows={projectWorkers}
            selectAll={selectedProjectLevel ? selectedProjectLevel : false}
            reportSelections={(projectWorkerIds: any, rates: any) => {
              setSelectedProjectWorkers(projectWorkerIds);
              setCustomRates(rates);
            }}
          />
        )}
        <br />
        {!!selectedProjectWorkers.length && fetchingAvailableWorkers && (
          <Spinner size={"lg"} />
        )}

        {!!selectedProjectWorkers.length &&
          !!availableWorkers.length &&
          !fetchingAvailableWorkers && (
            <AvailableWorkersTable
              rows={[...availableWorkers]}
              reportSelections={(availableWorkerIds: any) => {
                setSelectedAvailableWorkers(availableWorkerIds);
              }}
            />
          )}

        {!!selectedProjectWorkers.length &&
          !availableWorkers.length &&
          !fetchingAvailableWorkers && (
            <NoResults>No available workers by that criteria.</NoResults>
          )}

        {!!selectedAvailableWorkers.length && (
          <>
            <h5>Admin Notes</h5>
            <Input
              type="textarea"
              name="adminNotes"
              onChange={adminNotesOnChange}
            />
            <Button color="primary" size="lg" onClick={submitBatchInvite}>
              Invite Workers
            </Button>
          </>
        )}
        {!!invitedWorkers.length && (
          <InvitedWorkersTable rows={[...invitedWorkers]} />
        )}
      </TabPane>
    </ProjectToolInviteContainer>
  );
};

export default ProjectToolInvite;
