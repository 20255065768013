import { createSlice, current } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { LOCAL_STORAGE_FILTERS } from "../globals/constants";
import generateFilterQueryString from "../services/filterService";
import { getFromLocalStorage } from "../services/localStorageService";
import { stat } from "fs";

const localStorageFiltersByIndex = getFromLocalStorage(LOCAL_STORAGE_FILTERS);
export const generateDefaultFilterState = () => {
  return {
    value: [],
    sortBy: { column: "", order: "" },
    limit: 50,
    page: 1,
    filterParams: "",
  };
};
export const generateDefaultFilterStateWithLocalSortColumns = () => {
  return {
    value: [],
    sortBy: { column: "", order: "" },
    limit: 50,
    page: 1,
    filterParams: "",
    localSortColumns: {},
  };
};

const filterSlice = createSlice({
  name: "filters",
  initialState: localStorageFiltersByIndex || {
    forIndex: {
      autoSchedulers: generateDefaultFilterState(),
      dashboard: generateDefaultFilterStateWithLocalSortColumns(),
      foremen: generateDefaultFilterState(),
      users: generateDefaultFilterState(),
      markets: generateDefaultFilterState(),
      manufacturers: generateDefaultFilterState(),
      workerTypes: generateDefaultFilterState(),
      siteRequirements: generateDefaultFilterState(),
      businesses: generateDefaultFilterState(),
      workers: generateDefaultFilterState(),
      productLines: generateDefaultFilterState(),
      projects: generateDefaultFilterState(),
      projectDates: generateDefaultFilterState(),
      projectInvites: generateDefaultFilterState(),
      projectSiteRequirements: generateDefaultFilterState(),
      projectWorkers: generateDefaultFilterState(),
      shiftInvites: generateDefaultFilterState(),
      timeLogs: generateDefaultFilterState(),
      workerBlackLists: generateDefaultFilterState(),
      uploads: generateDefaultFilterState(),
    },
  },
  reducers: {
    addIndexFilter: (state: any, action) => {
      const { index, newFilter } = action.payload;
      const oldStateForIndex =
        state.forIndex[index] || generateDefaultFilterState();
      //Don't add duplicate filters
      if (
        oldStateForIndex.value.find(
          (item: any) =>
            item.column === newFilter.column &&
            item.operator === newFilter.operator &&
            item.pattern === newFilter.pattern
        )
      ) {
        return;
      }
      const newStateForIndex = {
        ...oldStateForIndex,
        value: [
          ...oldStateForIndex.value,
          {
            id: uuidv4(),
            ...newFilter,
          },
        ],
      };
      state.forIndex[index] = newStateForIndex;
      state.forIndex[index].filterParams = generateFilterQueryString(
        state.forIndex[index]
      );
    },
    replaceLocalSort: (state: any, action) => {
      const { index, localSortColumns } = action.payload;
      const updatedState =
        state.forIndex[index] ||
        generateDefaultFilterStateWithLocalSortColumns();
      updatedState["localSortColumns"] = localSortColumns;
      state.forIndex[index] = updatedState;
    },
    removeIndexFilter: (state: any, action) => {
      const { index, idToRemove } = action.payload;
      const updatedState =
        state.forIndex[index] || generateDefaultFilterState();
      updatedState.value = updatedState.value.filter(
        (filter: any) => filter.id !== idToRemove
      );
      updatedState.filterParams = generateFilterQueryString(updatedState);
      state.forIndex[index] = updatedState;
    },
    addOrReplaceSort: (state: any, action) => {
      const { index, newSort } = action.payload;
      const updatedState =
        state.forIndex[index] || generateDefaultFilterState();
      updatedState["sortBy"] = {
        column: newSort.column,
        order: newSort.order,
      };
      state.forIndex[index] = updatedState;
      state.forIndex[index].filterParams = generateFilterQueryString(
        state.forIndex[index]
      );
    },
    changePage: (state: any, action) => {
      const { index, page } = action.payload;
      const updatedState =
        state.forIndex[index] || generateDefaultFilterState();
      updatedState.page = page || 1;
      updatedState.filterParams = generateFilterQueryString(updatedState);
    },
    setLimit: (state: any, action) => {
      const { index, limit } = action.payload;
      const updatedState =
        state.forIndex[index] || generateDefaultFilterState();
      updatedState.limit = limit;
      updatedState.filterParams = generateFilterQueryString(updatedState);
    },
  },
});

export const {
  addIndexFilter,
  replaceLocalSort,
  removeIndexFilter,
  addOrReplaceSort,
  changePage,
  setLimit,
} = filterSlice.actions;
export default filterSlice.reducer;
