import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  TimeLogCreateContainer,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  Notice,
  LookupField,
} from "./TimeLogCreate.styles";
import { createTimeLog } from "../../services/timeLogService";
import { useNavigate } from "react-router-dom";
import isInt from "validator/lib/isInt";
import ShiftInviteLookupModal from "../ShiftInviteLookupModal/ShiftInviteLookupModal";

export const TimeLogCreate = () => {
  const [formData, setFormData] = useState({
    shiftInviteId: "",
  });
  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();
  const [showShiftInviteLookup, setShowShiftInviteLookup] = useState(false);
  const [shiftInviteString, setShiftInviteString] = useState("");

  const setShiftInvite = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        shiftInviteId: String(id),
      }));
      setShiftInviteString(name || "");
    }
    setShowShiftInviteLookup(false);
  };

  const clearForm = () => {
    setFormData({
      shiftInviteId: "",
    });
    setErrors([]);
    setNewId(0);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { shiftInviteId }: any = formData;

    if (!isInt(shiftInviteId || "")) foundErrors.push("shiftInviteId");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let response = await createTimeLog(formData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    return (
      <TimeLogCreateContainer>
        <SuccessText>New Time Log successfully created.</SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>Go to Time Log</Button>
        </SuccessButtonWrapper>
      </TimeLogCreateContainer>
    );
  }

  return (
    <TimeLogCreateContainer>
      <Notice>
        Time Logs are generated by their parent Shift Invite. Use with care.
      </Notice>

      <LookupField>
        <Input
          type="text"
          placeholder="Select a Shift Invite >"
          invalid={errors.indexOf("shiftInviteId") !== -1}
          value={shiftInviteString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowShiftInviteLookup(true)}>
          Select Shift Invite
        </Button>
      </LookupField>
      {errors.indexOf("shiftInviteId") !== -1 && (
        <ErrorText>Invalid Shift Invite</ErrorText>
      )}
      <ShiftInviteLookupModal
        isOpen={showShiftInviteLookup}
        callback={setShiftInvite}
      />

      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </TimeLogCreateContainer>
  );
};
