import React, { useState } from "react";
import { Button, Input, TabPane } from "reactstrap";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  WorkerUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
  LookupField,
} from "./WorkerUpdate.styles";
import isEmpty from "validator/lib/isEmpty";
import isInt from "validator/lib/isInt";
import { updateWorker } from "../../services/workerService";
import BigSpinner from "../BigSpinner/BigSpinner";
import isPostalCode from "validator/lib/isPostalCode";
import WorkerTypeLookupModal from "../WorkerTypeLookupModal/WorkerTypeLookupModal";
import { tryParseJSONObject } from "../../globals/helpers";

const WorkerUpdate = ({
  id,
  user,
  userId,
  workerType,
  workerTypeId,
  orientationRating,
  stripeConnectedAccountId,
  instantPayActivated,
  activeStatus,
  firstShiftWorked,
  rating,
  availability,
  zipCode,
  $resetView,
}: any) => {
  const [formData, setFormData] = useState({
    workerTypeId,
    orientationRating,
    stripeConnectedAccountId,
    instantPayActivated,
    activeStatus,
    firstShiftWorked,
    rating,
    availability,
    zipCode,
  });

  const parsedAvailability = tryParseJSONObject(availability);
  const [availabilityJson, setAvailabilityJson] = useState<any>({
    sunday: parsedAvailability?.sunday || false,
    monday: parsedAvailability?.monday || false,
    tuesday: parsedAvailability?.tuesday || false,
    wednesday: parsedAvailability?.wednesday || false,
    thursday: parsedAvailability?.thursday || false,
    friday: parsedAvailability?.friday || false,
    saturday: parsedAvailability?.saturday || false,
  });

  const userString = `${user.firstName} ${user.lastName} (${userId})`;
  const [errors, setErrors] = useState<Array<any>>([]);
  const [showWorkerTypeLookup, setShowWorkerTypeLookup] = useState(false);
  const [workerTypeString, setWorkerTypeString] = useState(
    workerType
      ? [
          workerType.market.name,
          workerType.positionName,
          `L${workerType.level}`,
          `(${workerType.id})`,
        ].join(" ")
      : ""
  );

  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const toggleState = (e: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [e.target.name]: !prevState[e.target.name],
    }));
    if (!formModified) setFormModified(true);
  };

  const toggleAvailability = (day: string) => {
    setAvailabilityJson((prevState: any) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
    if (!formModified) setFormModified(true);
  };

  const setWorkerType = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        workerTypeId: String(id),
      }));
      setWorkerTypeString(name || "");
    }
    setShowWorkerTypeLookup(false);
  };

  const submitOnClick = async (e: any) => {
    try {
      e.preventDefault();
      setErrors([]);
      setChangeSuccess(false);

      const { zipCode, workerTypeId, rating, orientationRating }: any =
        formData;
      let foundErrors = [];

      if (!isPostalCode(zipCode, "US")) foundErrors.push("zipCode");
      if (workerTypeId) {
        if (!isInt(workerTypeId)) foundErrors.push("workerTypeId");
      }
      if (isEmpty(String(rating || ""))) foundErrors.push("rating");

      if (foundErrors.length) {
        setErrors(foundErrors);
        return;
      }

      let tempForm = formData;
      tempForm.orientationRating = !!orientationRating ? orientationRating : 0;
      tempForm.availability = JSON.stringify(availabilityJson);
      setProcessing(true);

      let response = await updateWorker(id, tempForm);
      if (!response.error) {
        setChangeSuccess(true);
        setTimeout(() => location.reload(), 2000);
      } else setErrors((errors) => [...errors, "$server"]);

      setProcessing(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <WorkerUpdateContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Input type="text" value={id} bsSize="sm" readOnly={true} />
        </Group>
        <Group>
          <Title>User</Title>
          <Input
            name="id"
            type="text"
            value={userString}
            bsSize="sm"
            readOnly={true}
          />
        </Group>
        <Group>
          <Title>Worker Type</Title>
          <LookupField>
            <Input
              type="text"
              placeholder="Select a WorkerType >"
              invalid={errors.indexOf("workerTypeId") !== -1}
              value={workerTypeString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowWorkerTypeLookup(true)}>
              Select WorkerType
            </Button>
          </LookupField>
        </Group>
        {errors.indexOf("workerTypeId") !== -1 && (
          <ErrorText>Invalid Worker Type</ErrorText>
        )}
        <Group>
          <Title>Zip Code</Title>
          <Input
            name="zipCode"
            type="text"
            value={formData.zipCode}
            onChange={onChange}
            invalid={errors.indexOf("zipCode") !== -1}
          />
        </Group>
        {errors.indexOf("zipCode") !== -1 && (
          <ErrorText>Invalid Zip Code</ErrorText>
        )}
        <Group>
          <Title>Status</Title>
          <Input
            name="activeStatus"
            type="select"
            value={formData.activeStatus}
            onChange={onChange}
          >
            <option value="0">Inactive</option>
            <option value="1">Signed up</option>
            <option value="2">Attended Orientation</option>
            <option value="3">Stripe active</option>
            <option value="4">Worked</option>
          </Input>
        </Group>
        <Group>
          <Title>Orientation Rating</Title>
          <Input
            name="orientationRating"
            type="text"
            value={formData.orientationRating}
            onChange={onChange}
            invalid={errors.indexOf("orientationRating") !== -1}
          />
        </Group>
        {errors.indexOf("orientationRating") !== -1 && (
          <ErrorText>Invalid Rating</ErrorText>
        )}
        <Group>
          <Title>Rating</Title>
          <Input
            name="rating"
            type="text"
            value={formData.rating}
            onChange={onChange}
            invalid={errors.indexOf("rating") !== -1}
          />
        </Group>
        {errors.indexOf("rating") !== -1 && (
          <ErrorText>Invalid Rating</ErrorText>
        )}
        <Group>
          <Title>Stripe Id</Title>
          <Input
            name="stripeConnectedAccountId"
            type="text"
            value={formData.stripeConnectedAccountId}
            onChange={onChange}
            invalid={errors.indexOf("stripeConnectedAccountId") !== -1}
          />
        </Group>
        {errors.indexOf("stripeConnectedAccountId") !== -1 && (
          <ErrorText>Invalid Account Id</ErrorText>
        )}
        <Group>
          <Title>Instant Pay?</Title>
          <Input
            name="instantPayActivated"
            type="checkbox"
            checked={formData.instantPayActivated}
            onChange={toggleState}
          />
        </Group>
        <Group>
          <Title>Worked?</Title>
          <Input
            name="firstShiftWorked"
            type="checkbox"
            checked={formData.firstShiftWorked}
            onChange={toggleState}
          />
        </Group>
        {/* <Title>Availability</Title>
        <AvailabilityGroup>
          <Title>Sunday</Title>
          <Input
            type="checkbox"
            checked={availabilityJson.sunday}
            onChange={() => toggleAvailability("sunday")}
          />
        </AvailabilityGroup>
        <AvailabilityGroup>
          <Title>Monday</Title>
          <Input
            type="checkbox"
            checked={availabilityJson.monday}
            onChange={() => toggleAvailability("monday")}
          />
        </AvailabilityGroup>
        <AvailabilityGroup>
          <Title>Tuesday</Title>
          <Input
            type="checkbox"
            checked={availabilityJson.tuesday}
            onChange={() => toggleAvailability("tuesday")}
          />
        </AvailabilityGroup>
        <AvailabilityGroup>
          <Title>Wednesday</Title>
          <Input
            type="checkbox"
            checked={availabilityJson.wednesday}
            onChange={() => toggleAvailability("wednesday")}
          />
        </AvailabilityGroup>
        <AvailabilityGroup>
          <Title>Thursday</Title>
          <Input
            type="checkbox"
            checked={availabilityJson.thursday}
            onChange={() => toggleAvailability("thursday")}
          />
        </AvailabilityGroup>
        <AvailabilityGroup>
          <Title>Friday</Title>
          <Input
            type="checkbox"
            checked={availabilityJson.friday}
            onChange={() => toggleAvailability("friday")}
          />
        </AvailabilityGroup>
        <AvailabilityGroup>
          <Title>Saturday</Title>
          <Input
            type="checkbox"
            checked={availabilityJson.saturday}
            onChange={() => toggleAvailability("saturday")}
          />
        </AvailabilityGroup> */}

        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        <WorkerTypeLookupModal
          isOpen={showWorkerTypeLookup}
          callback={setWorkerType}
        />
      </TabPane>
    </WorkerUpdateContainer>
  );
};

export default WorkerUpdate;
