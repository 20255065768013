import { createGlobalStyle, css } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

export const GlobalStyle = createGlobalStyle`
  body {
  }
`;

export const responsiveTableFontSize = css`
   {
    font-size: inherit;
    @media only screen and (max-width: 768px) {
      font-size: 10px;
    }
  }
`;

export const colors = {
  bootstrapPrimaryBlue: "#0d6efd",
  darkGrey: "#777",
  warn: "#ff8c00",
  danger: "#be0000",
  darkGreen: "#006400",
  offWhiteRed: "#fee",
  offWhiteGreen: "#efe",
  offWhiteBlue: "#eef",
  offWhiteOrange: "#fff7ee",
  offWhiteGrey: "#eee",
  lightGrey: "#ccc",
  borderGrey: "#ced4da",
};
