import React from "react";
import { NavLink } from "reactstrap";
import styled from "styled-components";
import { colors } from "../../globals/globals.styles";

export const DetailsNavbarContainer = styled.div`
   {
    font-weight: bold;
    color: black;
    border-bottom: 3px solid
      ${(props) => {
        switch (props.color) {
          case "update":
            return colors.warn;
          case "delete":
            return colors.danger;
          case "inviteWorkers":
            return colors.darkGrey;
          case "autoScheduler":
            return "black";
          default:
            return colors.bootstrapPrimaryBlue;
        }
      }};
  }
`;

export const ViewLink = styled(NavLink)`
   {
    border-radius: 0.25rem 0.25rem 0 0 !important;
    color: black;
    &:hover {
      cursor: pointer;
      color: ${colors.bootstrapPrimaryBlue};
    }

    @media only screen and (max-width: 768px) {
      padding: 0.4rem !important;
    }
  }
`;

export const UpdateLink = styled(NavLink)`
   {
    border-radius: 0.25rem 0.25rem 0 0 !important;
    color: black;
    &.active {
      background-color: ${colors.warn} !important;
    }
    &:hover {
      cursor: pointer;
      color: ${colors.warn};
    }

    @media only screen and (max-width: 768px) {
      padding: 0.4rem !important;
    }
  }
`;

export const DeleteLink = styled(NavLink)`
   {
    border-radius: 0.25rem 0.25rem 0 0 !important;
    color: black;
    &.active {
      background-color: ${colors.danger} !important;
    }
    &:hover {
      cursor: pointer;
      color: ${colors.danger};
    }

    @media only screen and (max-width: 768px) {
      padding: 0.4rem !important;
    }
  }
`;

export const InviteWorkersLink = styled(NavLink)`
   {
    border-radius: 0.25rem 0.25rem 0 0 !important;
    color: black;
    &.active {
      background-color: ${colors.darkGrey} !important;
    }
    &:hover {
      cursor: pointer;
      color: ${colors.darkGrey};
    }

    @media only screen and (max-width: 768px) {
      padding: 0.4rem !important;
    }
  }
`;

export const AutoScheduleLink = styled(NavLink)`
   {
    border-radius: 0.25rem 0.25rem 0 0 !important;
    color: black;
    &.active {
      background-color: black !important;
    }
    &:hover {
      cursor: pointer;
      color: ${colors.darkGrey};
    }

    @media only screen and (max-width: 768px) {
      padding: 0.4rem !important;
    }
  }
`;
