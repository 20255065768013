import React, { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { throttle } from "throttle-debounce";
import { lookupProjects } from "../../services/projectService";
import BigSpinner from "../BigSpinner/BigSpinner";
import {
  CancelWrapper,
  SearchBar,
  SearchResult,
  SearchResultText,
  SelectButton,
} from "./ProjectLookupModal.styles";

const ProjectLookupModal = ({ isOpen, callback }: any) => {
  let [projects, setProjects] = useState([]);
  let [loading, setloading] = useState(false);
  let [changed, setChanged] = useState(false);

  const onChange = throttle(400, false, (e: any) => {
    const { value } = e.target;
    if (!value) return;
    if (!changed) setChanged(true);

    setloading(true);
    lookupProjects(value).then((res: any) => {
      setProjects(res.value || []);
      setloading(false);
    });
  });

  const makeSelection = (id: number, name: string) => {
    if (id) callback(id, name);

    setChanged(false);
  };

  const renderResults = () => {
    if (!projects.length) return <p>No projects found by that term</p>;

    return projects.map((project, index) => {
      const { id, address, projectName, referenceNumber } = project;

      return (
        <SearchResult key={index + Date.now()}>
          <SearchResultText>
            {projectName} / {address} ({id})
          </SearchResultText>
          <SelectButton
            size="sm"
            onClick={() =>
              makeSelection(id, `${projectName} / ${address} (${id})`)
            }
          >
            Select
          </SelectButton>
        </SearchResult>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} fade={false}>
      <ModalHeader>Select Project</ModalHeader>
      <ModalBody>
        <p>Search by an ID, Project Name, Address, or Reference Number</p>
        <SearchBar>
          <Input
            name="projectId"
            type="text"
            placeholder="Search"
            onChange={onChange}
          />
        </SearchBar>
        {loading && <BigSpinner />}
        {!loading && renderResults()}
        <CancelWrapper>
          <Button onClick={() => callback()}>Cancel</Button>
        </CancelWrapper>
      </ModalBody>
    </Modal>
  );
};

export default ProjectLookupModal;
