export class WorkerType {
  id: number;
  positionName?: string;
  levelDescription?: string;
  level?: number;
  costCents?: number;
  costCurrency?: string;
  paymentCents?: number;
  paymentCurrency?: number;

  constructor(newWorkerType: IWorkerType) {
    const {
      id,
      positionName,
      levelDescription,
      level,
      costCents,
      costCurrency,
      paymentCents,
      paymentCurrency,
    } = newWorkerType;

    this.id = id;
    this.positionName = positionName;
    this.levelDescription = levelDescription;
    this.level = level;
    this.costCents = costCents;
    this.costCurrency = costCurrency;
    this.paymentCents = paymentCents;
    this.paymentCurrency = paymentCurrency;
  }

  static getFilterConfig() {
    return {
      columns: [
        "id",
        "positionName",
        "levelDescription",
        "level",
        "costCents",
        "costCurrency",
        "paymentCents",
        "paymentCurrency",
      ],
      indexToFilter: "foremen",
    };
  }
}

export interface IWorkerType {
  id: number;
  positionName?: string;
  levelDescription?: string;
  level?: number;
  costCents?: number;
  costCurrency?: string;
  paymentCents?: number;
  paymentCurrency?: number;
}
