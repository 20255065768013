import React, { useState } from "react";
import { Button, Input, TabPane } from "reactstrap";

import {
  ErrorText,
  ProductLineUpdateContainer,
  SuccessText,
  LookupField,
  ButtonBox,
  CancelButton,
  UpdateButton,
} from "./ProductLineUpdate.styles";
import isInt from "validator/lib/isInt";
import { updateProductLine } from "../../services/productLineService";
import BigSpinner from "../BigSpinner/BigSpinner";
import ProjectLookupModal from "../ProjectLookupModal/ProjectLookupModal";
import ManufacturerLookupModal from "../ManufacturerLookupModal/ManufacturerLookupModal";

const ProductLineUpdate = ({ id, manufacturer, project, $resetView }: any) => {
  const [formData, setFormData] = useState({
    manufacturerId: manufacturer.id,
    projectId: project.id,
  });
  const [projectString, setProjectString] = useState(
    `${project.id} ${project.address}`
  );
  const [manufacturerString, setManufacturerString] = useState(
    manufacturer.manufacturerName
  );
  const [errors, setErrors] = useState<Array<any>>([]);
  const [showProjectLookup, setShowProjectLookup] = useState(false);
  const [showManufacturerLookup, setShowManufacturerLookup] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const setProject = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectId: String(id),
      }));
      setProjectString(name || "");
      if (!formModified) setFormModified(true);
    }
    setShowProjectLookup(false);
  };

  const setManufacturer = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        manufacturerId: String(id),
      }));
      setManufacturerString(name || "");
      if (!formModified) setFormModified(true);
    }
    setShowManufacturerLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    const { projectId, manufacturerId }: any = formData;
    let foundErrors = [];

    if (!isInt(projectId || "")) foundErrors.push("projectId");
    if (!isInt(manufacturerId || "")) foundErrors.push("manufacturerId");
    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let response = await updateProductLine(id, formData);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <ProductLineUpdateContainer>
      <TabPane>
        <LookupField>
          <Input
            type="text"
            placeholder="Select the Project >"
            invalid={errors.indexOf("projectId") !== -1}
            value={projectString}
            readOnly={true}
          />
          <Button size="sm" onClick={() => setShowProjectLookup(true)}>
            Select Project
          </Button>
        </LookupField>
        {errors.indexOf("projectId") !== -1 && (
          <ErrorText>Invalid Project</ErrorText>
        )}
        <ProjectLookupModal isOpen={showProjectLookup} callback={setProject} />
        <LookupField>
          <Input
            type="text"
            placeholder="Select the Manufacturer >"
            invalid={errors.indexOf("manufacturerId") !== -1}
            value={manufacturerString}
            readOnly={true}
          />
          <Button size="sm" onClick={() => setShowManufacturerLookup(true)}>
            Select Manufacturer
          </Button>
        </LookupField>
        {errors.indexOf("manufacturerId") !== -1 && (
          <ErrorText>Invalid Manufacturer</ErrorText>
        )}
        <ManufacturerLookupModal
          isOpen={showManufacturerLookup}
          callback={setManufacturer}
        />
        <br />
        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>There was a server issue. Please try again.</ErrorText>
        )}
      </TabPane>
    </ProductLineUpdateContainer>
  );
};

export default ProductLineUpdate;
