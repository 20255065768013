import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

interface ActiveBusinesses {
  month_posted: string;
  active_clients_count: number;
}

interface ActiveBusinessesGraphProps {
  data: ActiveBusinesses[];
}

const ActiveBusinessesGraph: React.FC<ActiveBusinessesGraphProps> = ({
  data,
}) => {
  const last12MonthsData = data.slice(0, 12).reverse();

  const labels = last12MonthsData.map((item) => item.month_posted);
  const datasetData = last12MonthsData.map((item) => item.active_clients_count);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Active Invoicing Client Count",
        data: datasetData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return <Line options={options} data={chartData} />;
};

export default ActiveBusinessesGraph;
