import React, { useEffect, useState } from "react";
import { ProjectsIndexContainer, TableLimited } from "./ProjectIndex.styles";
import { getProjects } from "../../services/projectService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { Project } from "../../models/Project";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";
import { NavLink } from "react-router-dom";
import { TimeLog } from "../../models/TimeLog";

export const ProjectIndex = () => {
  const [projects, setProjects] = useState([]);
  const [businessMap, setBusinessMap] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["projects"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["projects"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["projects"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["projects"].limit
  );

  useEffect(() => {
    setTitle("Projects");
    getProjects().then((res: any) => {
      setBusinessMap(res.businessMap || []);
      setProjects(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  const getBusinessFromUserId = (userId: any) => {
    let temp;
    businessMap.forEach((business: any) => {
      if (business.userId === userId) temp = business;
    });
    return temp;
  };
  return (
    <>
      <FilterBar config={Project.getFilterConfig()} />
      <ProjectsIndexContainer>
        {!!projects.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"projects"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <th>Business (ID)</th>
                  <th>Start Date</th>
                  <ColumnHeadWithSort
                    index={"projects"}
                    columnName={"projectName"}
                    displayName={"Project Name"}
                  />
                  <ColumnHeadWithSort
                    index={"projects"}
                    columnName={"address"}
                    displayName={"Address"}
                  />
                  <th>Shifts unfilled</th>
                  <th>Shifts requested</th>
                  <th>Num Days</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {projects.map((project) => {
                  const {
                    id,
                    userId,
                    projectName,
                    projectDates,
                    address,
                  }: any = project;

                  // Check if projectDates is defined and has elements
                  if (!projectDates || projectDates.length === 0) {
                    return null; // Skip rendering this project
                  }

                  const business: any = getBusinessFromUserId(userId);
                  const allProjectWorkers = projectDates
                    .map((pd: any) => pd.projectWorkers.map((pw: any) => pw))
                    .flat();
                  let filled = 0;
                  let total = 0;

                  allProjectWorkers.forEach((pw: any) => {
                    filled += pw.shiftInvites.filter(
                      (si: any) => si.status == 1
                    ).length;
                    total += pw.totalAmount;
                  });
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>
                        <NavLink
                          to={`/manage/businesses/${String(business?.id)}`}
                        >
                          {`${business?.businessName || "(no name set)"} (${
                            business?.id
                          })`}
                        </NavLink>
                      </td>
                      <td>
                        {TimeLog.parseDateWithTimezone(
                          projectDates[0].startsAt,
                          projectDates[0].timezone
                        )}
                      </td>
                      <td>{projectName}</td>
                      <td>{address}</td>
                      <td>{total - filled}</td>
                      <td>{total}</td>
                      <td>{projectDates.length || 0}</td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                        &nbsp;|&nbsp;
                        <NavLink to={`${String(id)}/?tab=inviteWorkers`}>
                          Invite Workers
                        </NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="projects"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!projects.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </ProjectsIndexContainer>
    </>
  );
};
