import React, { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { throttle } from "throttle-debounce";
import { lookupWorkerTypes } from "../../services/workerTypesService";
import BigSpinner from "../BigSpinner/BigSpinner";
import {
  CancelWrapper,
  SearchBar,
  SearchResult,
  SearchResultText,
  SelectButton,
} from "./WorkerTypeLookupModal.styles";

const WorkerTypeLookupModal = ({ isOpen, callback }: any) => {
  let [workerTypes, setWorkerTypes] = useState([]);
  let [loading, setloading] = useState(false);
  let [changed, setChanged] = useState(false);

  const onChange = throttle(400, false, (e: any) => {
    const { value } = e.target;
    if (!value) return;
    if (!changed) setChanged(true);

    setloading(true);
    lookupWorkerTypes(value).then((res: any) => {
      setWorkerTypes(res.value || []);
      setloading(false);
    });
  });

  const makeSelection = (id: number, name: string) => {
    if (id) callback(id, name);

    setChanged(false);
  };

  const renderResults = () => {
    if (!workerTypes.length) return <p>No Worker Types found by that term</p>;

    return workerTypes.map((workerType, index) => {
      const { id, market, positionName, level }: any = workerType;

      let tempString = [market.name, positionName, `L${level}`, `(${id})`].join(
        " "
      );

      return (
        <SearchResult key={index + Date.now()}>
          <SearchResultText>{tempString}</SearchResultText>
          <SelectButton size="sm" onClick={() => makeSelection(id, tempString)}>
            Select
          </SelectButton>
        </SearchResult>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} fade={false}>
      <ModalHeader>Select Worker Type</ModalHeader>
      <ModalBody>
        <p>Search by an ID, Name, or Market</p>
        <SearchBar>
          <Input
            name="workerTypeId"
            type="text"
            placeholder="Search"
            onChange={onChange}
          />
        </SearchBar>
        {loading && <BigSpinner />}
        {!loading && renderResults()}
        <CancelWrapper>
          <Button onClick={() => callback()}>Cancel</Button>
        </CancelWrapper>
      </ModalBody>
    </Modal>
  );
};

export default WorkerTypeLookupModal;
