import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  SiteRequirementIndexContainer,
  TableLimited,
} from "./SiteRequirementIndex.styles";
import { getSiteRequirements } from "../../services/siteRequirementService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { SiteRequirement } from "../../models/SiteRequirement";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

export const SiteRequirementIndex = () => {
  const [siteRequirements, setSiteRequirements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["siteRequirements"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["siteRequirements"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["siteRequirements"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["siteRequirements"].limit
  );

  useEffect(() => {
    setTitle("Site Requirements");
    getSiteRequirements().then((res: any) => {
      setSiteRequirements(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={SiteRequirement.getFilterConfig()} />
      <SiteRequirementIndexContainer>
        {!!siteRequirements.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"siteRequirements"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"siteRequirements"}
                    columnName={"name"}
                    displayName={"Name"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {siteRequirements.map((siteRequirement) => {
                  const { id, name }: any = siteRequirement;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{name}</td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="siteRequirements"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!siteRequirements.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </SiteRequirementIndexContainer>
    </>
  );
};
