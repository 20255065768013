import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ProjectDateDetailsContainer } from "./ProjectDateDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getProjectDateById } from "../../services/projectDateService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import ProjectDateRead from "../../components/ProjectDateRead/ProjectDateRead";
import ProjectDateUpdate from "../../components/ProjectDateUpdate/ProjectDateUpdate";
import ProjectDateDelete from "../../components/ProjectDateDelete/ProjectDateDelete";

export const ProjectDateDetails = ({ forId, isFlyout, $onChange }: any) => {
  const { projectDateId } = useParams();
  const [searchParams] = useSearchParams();
  const [projectDate, setProjectDate] = useState<any>({});
  const [activeTab, setActiveTab] = useState(searchParams.get("tab") || "view");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!isFlyout) setTitle(`Project Date`);
    if (!Object.entries(projectDate).length) {
      getProjectDateById(forId || projectDateId)
        .then((res) => {
          if (res.value) {
            setProjectDate(res.value);
            setError(false);
            if (!isFlyout) setTitle(`Project Date (${res.value.id})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
    }
  });

  const resetView = () => setActiveTab("view");

  const renderTab = (projectDate: any) => {
    switch (activeTab) {
      case "view":
        return <ProjectDateRead {...projectDate} isFlyout={isFlyout} />;
      case "update":
        return (
          <ProjectDateUpdate
            {...projectDate}
            isFlyout={isFlyout}
            $onChange={$onChange}
            $resetView={resetView}
          />
        );
      case "delete":
        return (
          <ProjectDateDelete
            projectDate={projectDate}
            isFlyout={isFlyout}
            $onChange={$onChange}
            $resetView={resetView}
          />
        );
      default:
        return <ProjectDateRead {...projectDate} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(projectDate).length) {
    return (
      <>
        <ProjectDateDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(projectDate)}</TabContent>
        </ProjectDateDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
