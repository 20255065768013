import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UploadDetailsContainer } from "./UploadDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getUploadById } from "../../services/uploadService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import UploadRead from "../../components/UploadRead/UploadRead";
import UploadUpdate from "../../components/UploadUpdate/UploadUpdate";
import UploadDelete from "../../components/UploadDelete/UploadDelete";

export const UploadDetails = () => {
  let { uploadId } = useParams();
  const [upload, setUpload] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Upload`);
    if (!Object.entries(upload).length && uploadId)
      getUploadById(uploadId)
        .then((res) => {
          if (res.value) {
            setUpload(res.value);
            setError(false);
            setTitle(`Upload (${uploadId})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
  });

  const resetView = () => setActiveTab("view");

  const renderTab = (upload: any) => {
    switch (activeTab) {
      case "view":
        return <UploadRead {...upload} />;
      case "update":
        return <UploadUpdate {...upload} $resetView={resetView} />;
      case "delete":
        return <UploadDelete upload={upload} $resetView={resetView} />;
      default:
        return <UploadRead {...upload} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(upload).length) {
    return (
      <>
        <UploadDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(upload)}</TabContent>
        </UploadDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
