import React from "react";
import styled from "styled-components";

export const AppWrapperContainer = styled.div`
   {
    display: block;
    width: 97%;
    height: 100vh;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const AppWrapperOutlet = styled.div`
   {
    height: 80vh;
  }
`;

export const AppWrapperSidebar = styled.div`
   {
    height: 100vh;
  }
`;

export const PaddedOutlet = styled.div`
   {
    padding: 1%;

    @media only screen and (max-width: 768px) {
      padding: 1% 2%;
    }
  }
`;
