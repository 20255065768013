import React from "react";
import { Button, TabPane } from "reactstrap";

import {
  Content,
  UploadReadContainer,
  Group,
  Title,
  Image,
} from "./UploadRead.styles";

const UploadRead = ({
  id,
  uploadableId,
  uploadableType,
  fileUpdatedAt,
  fileContentType,
}: any) => {
  const getUpload = () => {
    // using the code dsqeqsdgr should work but currently returns
    // cloud_name dsqeqsdgr is disabled
    // vxxx is the image version not the api version. it is never 1!
    // let url = "https://res.cloudinary.com/dsqeqsdgr/image/upload/v1";
    let url = "https://res.cloudinary.com/steadyinstall/image/upload";
    const type = uploadableType === "TimeLog" ? "timelogs" : "projects";

    if (fileContentType == "image/jpeg" || fileContentType == "image/png")
      return (
        <Image src={`${url}/${type}/${uploadableId}/files/${id}/original`} />
      );

    return (
      <>
        <br />
        <a
          href={`${url}/${type}/${uploadableId}/files/${id}/original`}
          target="_blank"
          rel="noreferrer"
        >
          <Button>Open Document</Button>
        </a>
      </>
    );
  };

  return (
    <UploadReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Uploadable ID</Title>
          <Content>{uploadableId}</Content>
        </Group>
        <Group>
          <Title>Type</Title>
          <Content>{uploadableType}</Content>
        </Group>
        <Group>
          <Title>Updated At</Title>
          <Content>{fileUpdatedAt}</Content>
        </Group>
        {getUpload()}
      </TabPane>
    </UploadReadContainer>
  );
};

export default UploadRead;
