import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupShiftInvites = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/shiftInvites?term=${searchTerm}`);

export const getShiftInvites = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.shiftInvites;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/shiftInvites${filterString}`);
};

export const getShiftInvitesGroupedByStatus = async () => {
  return await protectedFetch(`${API_ROOT}/shiftInvites/statuses`);
};

export const getShiftInvitesByWorkerIdGroupedByStatus = async (
  workerId: string
) => {
  return await protectedFetch(`${API_ROOT}/shiftInvites/statuses/${workerId}`);
};

export const getShiftInviteById = async (shiftInviteId: string) =>
  await protectedFetch(`${API_ROOT}/shiftInvites/${shiftInviteId}`);

export const createShiftInvite = async (newShiftInvite: any) => {
  return await protectedFetch(`${API_ROOT}/shiftInvites`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newShiftInvite),
  });
};

export const updateShiftInvite = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/shiftInvites/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteShiftInvite = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/shiftInvites/${id}`, {
    method: "DELETE",
  });
};
