import React from "react";
import styled from "styled-components";

export const DashboardContainer = styled.div`
   {
  }
`;
export const AccordionTriggerHeading = styled.div`
   {
    display: flex;
    flex-direction: column;
  }
`;

export const AccordionTriggerTitle = styled.div`
  font-size: 10px;
  padding-bottom: 2px;
`;

export const AccordionTriggerBody = styled.div`
  font-size: 14px;
`;

export const AccordionGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: -2px 2px 6px 1px rgba(100, 100, 155, 0.2);
`;
