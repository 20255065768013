import React from "react";
import { Nav, NavItem } from "reactstrap";
import {
  AutoScheduleLink,
  DeleteLink,
  DetailsNavbarContainer,
  InviteWorkersLink,
  UpdateLink,
  ViewLink,
} from "./DetailsNavbar.styles";

export const DetailsNavbar = ({
  activeTab,
  passActiveTab,
  tabsToDisplay,
}: any) => {
  return (
    <DetailsNavbarContainer color={activeTab}>
      <Nav pills>
        {tabsToDisplay.indexOf("view") != -1 && (
          <NavItem>
            <ViewLink
              className={activeTab === "view" ? "active" : ""}
              onClick={() => {
                passActiveTab("view");
              }}
            >
              View
            </ViewLink>
          </NavItem>
        )}
        {tabsToDisplay.indexOf("update") != -1 && (
          <NavItem>
            <UpdateLink
              className={activeTab === "update" ? "active" : ""}
              onClick={() => {
                passActiveTab("update");
              }}
            >
              Update
            </UpdateLink>
          </NavItem>
        )}
        {tabsToDisplay.indexOf("delete") != -1 && (
          <NavItem>
            <DeleteLink
              className={activeTab === "delete" ? "active" : ""}
              onClick={() => {
                passActiveTab("delete");
              }}
            >
              Delete
            </DeleteLink>
          </NavItem>
        )}
        {tabsToDisplay.indexOf("inviteWorkers") != -1 && (
          <NavItem>
            <InviteWorkersLink
              className={activeTab === "inviteWorkers" ? "active" : ""}
              onClick={() => {
                passActiveTab("inviteWorkers");
              }}
            >
              Invite Workers
            </InviteWorkersLink>
          </NavItem>
        )}
        {tabsToDisplay.indexOf("autoScheduler") != -1 && (
          <NavItem>
            <AutoScheduleLink
              className={activeTab === "autoScheduler" ? "active" : ""}
              onClick={() => {
                passActiveTab("autoScheduler");
              }}
            >
              Auto-Scheduler
            </AutoScheduleLink>
          </NavItem>
        )}
      </Nav>
    </DetailsNavbarContainer>
  );
};
