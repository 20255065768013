import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Table } from "reactstrap";
import SelfSortColumnHead from "../SelfSortColumnHead/SelfSortColumnHead";
import {
  SearchInput,
  SearchWrapper,
  CurrentAutoSchedulesTableWrapper,
} from "./CurrentAutoSchedulesTable.styles";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const CurrentAutoSchedulesTable = ({ rows, autoScheduledProjects }: any) => {
  const [tableRows, setTableRows] = useState<any>([]);
  const [lastSortedColumn, setLastSortedColumn] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const onChange = (e: any) => {
    setSearchTerm(e.target.value || "");
  };

  const sortRows = (sort: any) => {
    let tempRows = [...tableRows];
    const { column, order } = sort;
    switch (order) {
      case "ASC":
        tempRows = tempRows.sort((a: any, b: any): any =>
          String(a[column]).localeCompare(String(b[column]))
        );
        break;
      case "DESC":
        tempRows = tempRows.sort((a: any, b: any): any =>
          String(b[column]).localeCompare(String(a[column]))
        );
        break;
      default:
        break;
    }
    setLastSortedColumn(column);
    setTableRows(tempRows);
  };

  const filterRowsBySearch = (searchTerm: string) => {
    if (!rows) return;
    let tempRows = [...rows];

    if (searchTerm)
      tempRows = tempRows.filter(
        (row: any) =>
          JSON.stringify(row).toLowerCase().indexOf(searchTerm.toLowerCase()) !=
          -1
      );

    setTableRows(tempRows);
  };

  const formatProjectWorker = (projectWorker: any) => {
    const { id, projectDate, workerType, filledAmount, totalAmount }: any =
      projectWorker;

    const startsAtParsed = tz(
      projectDate?.startsAt,
      "%c",
      "en_US",
      projectDate?.timezone
    ).split(" ");

    return `${[
      `(${id})`,
      startsAtParsed[2],
      startsAtParsed[1],
      startsAtParsed[3] + ",",
      startsAtParsed[4].slice(0, -3),
      startsAtParsed[5],
      startsAtParsed[6],
      " | ",
      `L${workerType.level}`,
      `(${filledAmount}`,
      "/",
      `${totalAmount})`,
    ].join(" ")}`;
  };

  useEffect(() => {
    filterRowsBySearch(searchTerm);
  }, [searchTerm]);

  if (!rows) return <></>;

  return (
    <CurrentAutoSchedulesTableWrapper>
      <SearchWrapper>
        <div>
          <h4>Current AutoSchedules</h4>
        </div>
        <SearchInput type="text" placeholder="Search" onChange={onChange} />
      </SearchWrapper>
      <Table bordered responsive size="sm" striped>
        <thead>
          <tr>
            <SelfSortColumnHead
              displayName={"ID"}
              column={"id"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "id"}
            />
            <SelfSortColumnHead
              displayName={"Interval"}
              column={"inviteTimeInterval"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "inviteTimeInterval"}
            />
            <SelfSortColumnHead
              displayName={"Batch Size"}
              column={"invitesPerInterval"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "invitesPerInterval"}
            />
            <SelfSortColumnHead
              displayName={"Disabled At"}
              column={"disabledAt"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "disabledAt"}
            />
            <th>Project Worker</th>
          </tr>
        </thead>
        <tbody>
          {!!tableRows &&
            tableRows.map((row: any, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <NavLink to={`/manage/autoSchedulers/${row.id}`}>
                      {row.id}
                    </NavLink>
                  </td>
                  <td>{row.inviteTimeInterval}</td>
                  <td>{row.invitesPerInterval}</td>
                  <td>{row.disabledAt}</td>
                  <td>
                    {autoScheduledProjects
                      .filter(
                        (asp: any) =>
                          asp.autoScheduledProjectsIntervalId == row.id
                      )
                      .map((asp: any) => {
                        let temp = [
                          "/manage/projectWorkers/" + asp.projectWorker.id,
                        ].join("");

                        return (
                          <>
                            <NavLink key={index} to={temp}>
                              {formatProjectWorker(asp.projectWorker)}
                            </NavLink>
                            <br />
                          </>
                        );
                      })}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </CurrentAutoSchedulesTableWrapper>
  );
};

export default CurrentAutoSchedulesTable;
