import React, { useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { Range } from "react-range";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Badge,
  ButtonGroup,
} from "reactstrap";
import {
  FilterButton,
  RangeMinMaxIndicator,
} from "./AvailableWorkersTable.styles";

export interface Availability {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

function FilterAvailableWorkers({
  runFilter,
}: {
  runFilter: (filter: any) => void;
}) {
  const [modal, setModal] = useState(false);
  const [availableWeekdays, setAvailableWeekdays] = useState<Availability>({
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
  });
  const [workerRatingRange, setWorkerRatingRange] = useState<number[]>([0, 10]);
  const [workerDistanceRange, setWorkerDistanceRange] = useState<number[]>([
    0, 100,
  ]);
  const [workerOrientationRatingRange, setWorkerOrientationRatingRange] =
    useState<number[]>([0, 10]);

  const toggle = () => setModal(!modal);

  const executeFilter = () => {
    const payload = {
      availability: availableWeekdays,
      orientationRating: workerOrientationRatingRange,
      rating: workerRatingRange,
      distance: workerDistanceRange,
    };
    runFilter(payload);
    toggle();
  };

  return (
    <div>
      <FilterButton onClick={toggle}>
        <BiFilterAlt />
      </FilterButton>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Filter Available workers</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div>
              <Label for="workerRating">Worker rating</Label>
              <Badge style={{ marginLeft: "5px" }} color="primary">
                {workerRatingRange[0]} - {workerRatingRange[1]}
              </Badge>
            </div>
            <RangeMinMaxIndicator>
              <div>0</div>
              <Range
                step={0.001}
                min={0}
                max={10}
                values={workerRatingRange}
                onChange={(values) => setWorkerRatingRange(values)}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "6px",
                      width: "100%",
                      borderRadius: "20px",
                      backgroundColor: "#ccc",
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "20px",
                      width: "20px",
                      borderRadius: "100%",
                      backgroundColor: "#0d6efd",
                    }}
                  />
                )}
              />
              <div>10</div>
            </RangeMinMaxIndicator>
          </FormGroup>

          <FormGroup>
            <div>
              <Label for="workerRating">Worker orientation rating</Label>
              <Badge style={{ marginLeft: "5px" }} color="primary">
                {workerOrientationRatingRange[0]} -{" "}
                {workerOrientationRatingRange[1]}
              </Badge>
            </div>
            <RangeMinMaxIndicator>
              <div>0</div>
              <Range
                step={0.001}
                min={0}
                max={10}
                values={workerOrientationRatingRange}
                onChange={(values) => setWorkerOrientationRatingRange(values)}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "6px",
                      width: "100%",
                      borderRadius: "20px",
                      backgroundColor: "#ccc",
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "20px",
                      width: "20px",
                      borderRadius: "100%",
                      backgroundColor: "#0d6efd",
                    }}
                  />
                )}
              />
              <div>10</div>
            </RangeMinMaxIndicator>
          </FormGroup>

          <FormGroup>
            <div>
              <Label for="workerRating">Worker distance</Label>
              <Badge style={{ marginLeft: "5px" }} color="primary">
                {workerDistanceRange[0]} - {workerDistanceRange[1]}
              </Badge>
            </div>
            <RangeMinMaxIndicator>
              <div>0</div>
              <Range
                step={0.001}
                min={0}
                max={100}
                values={workerDistanceRange}
                onChange={(values) => setWorkerDistanceRange(values)}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "6px",
                      width: "100%",
                      borderRadius: "20px",
                      backgroundColor: "#ccc",
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "20px",
                      width: "20px",
                      borderRadius: "100%",
                      backgroundColor: "#0d6efd",
                    }}
                  />
                )}
              />
              <div>100</div>
            </RangeMinMaxIndicator>
          </FormGroup>

          <FormGroup>
            <Label for="workerRating">Worker availability</Label>
            <div>
              <ButtonGroup>
                {Object.entries(availableWeekdays).map(([day, value]) => {
                  return (
                    <Button
                      key={day}
                      size="sm"
                      color={value ? "primary" : "secondary"}
                      onClick={() => {
                        const copy = { ...availableWeekdays };
                        // @ts-ignore
                        copy[day] = !value;
                        setAvailableWeekdays({ ...copy });
                      }}
                    >
                      {day.charAt(0).toUpperCase() + day.slice(1, 3)}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={executeFilter}>
            Execute filter
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default FilterAvailableWorkers;
