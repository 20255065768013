import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteProjectWorker } from "../../services/projectWorkerService";
import BigSpinner from "../BigSpinner/BigSpinner";
import ProjectWorkerRead from "../ProjectWorkerRead/ProjectWorkerRead";

import {
  ProjectWorkersDeleteContainer,
  DeleteButton,
  CancelButton,
  ButtonBox,
  SuccessText,
  ErrorText,
} from "./ProjectWorkerDelete.styles";

const ProjectWorkersDelete = ({
  projectWorker,
  isFlyout,
  $resetView,
  $onChange,
}: any) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const removeProjectWorker = async () => {
    setProcessing(true);

    const response = await deleteProjectWorker(projectWorker.id);

    if (!response.error) {
      setDeleteSuccess(true);
      setTimeout(() => navigate("/manage/projectWorkers"), 2000);
    } else setError(true);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (deleteSuccess)
    return (
      <SuccessText>ProjectWorker Deleted. Redirecting you shortly.</SuccessText>
    );

  return (
    <ProjectWorkersDeleteContainer>
      <h2>Are you sure?</h2>
      {!isFlyout && <ProjectWorkerRead {...projectWorker} />}
      {error && (
        <ErrorText>There was a problem deleting this. Try again.</ErrorText>
      )}
      <ButtonBox>
        <DeleteButton size="lg" onClick={removeProjectWorker}>
          Delete
        </DeleteButton>
        <CancelButton size="lg" onClick={$resetView}>
          Cancel
        </CancelButton>
      </ButtonBox>
    </ProjectWorkersDeleteContainer>
  );
};

export default ProjectWorkersDelete;
