import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteBusiness } from "../../services/businessService";
import BigSpinner from "../BigSpinner/BigSpinner";
import BusinessRead from "../BusinessRead/BusinessRead";

import {
  BusinessDeleteContainer,
  DeleteButton,
  CancelButton,
  ButtonBox,
  SuccessText,
  ErrorText,
} from "./BusinessDelete.styles";

const BusinessDelete = ({ business, $resetView }: any) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const removeBusiness = async () => {
    setProcessing(true);

    const response = await deleteBusiness(business.id);

    if (!response.error) {
      setDeleteSuccess(true);
      setTimeout(() => navigate("/manage/businesses"), 2000);
    } else setError(true);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (deleteSuccess)
    return (
      <SuccessText>Business Deleted. Redirecting you shortly.</SuccessText>
    );

  return (
    <BusinessDeleteContainer>
      <h2>
        Are you sure you want to delete? <br />
        This and all its dependant records will be deleted.
      </h2>
      <BusinessRead {...business} />
      {error && (
        <ErrorText>There was a problem deleting this. Try again.</ErrorText>
      )}
      <ButtonBox>
        <DeleteButton size="lg" onClick={removeBusiness}>
          Delete
        </DeleteButton>
        <CancelButton size="lg" onClick={$resetView}>
          Cancel
        </CancelButton>
      </ButtonBox>
    </BusinessDeleteContainer>
  );
};

export default BusinessDelete;
