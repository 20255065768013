import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { WorkerIndexContainer, TableLimited } from "./WorkerIndex.styles";
import { getWorkers } from "../../services/workerService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { Worker } from "../../models/Worker";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

export const WorkerIndex = () => {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["workers"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["workers"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["workers"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["workers"].limit
  );

  useEffect(() => {
    setTitle("Workers");
    getWorkers().then((res: any) => {
      setWorkers(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={Worker.getFilterConfig()} />
      <WorkerIndexContainer>
        {!!workers.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"workers"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"workers"}
                    columnName={"userId"}
                    displayName={"User"}
                  />
                  <ColumnHeadWithSort
                    index={"workers"}
                    columnName={"workerTypeId"}
                    displayName={"Worker Type"}
                  />
                  <ColumnHeadWithSort
                    index={"workers"}
                    columnName={"rating"}
                    displayName={"Rating"}
                  />
                  <th>Availability</th>
                  <ColumnHeadWithSort
                    index={"workers"}
                    columnName={"activeStatus"}
                    displayName={"Status"}
                  />
                  <ColumnHeadWithSort
                    index={"workers"}
                    columnName={"orientationScheduledAt"}
                    displayName={"Orientation Scheduled"}
                  />
                  <ColumnHeadWithSort
                    index={"workers"}
                    columnName={"orientationDate"}
                    displayName={"Orientation Date"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {workers.map((worker) => {
                  const {
                    id,
                    user,
                    workerTypeId,
                    workerType,
                    orientationScheduledAt,
                    orientationDate,
                    activeStatus,
                    rating,
                    availability,
                  }: any = worker;

                  const userString = [user.firstName, user.lastName].join(" ");

                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>
                        <NavLink to={`/manage/users/${String(user.id)}`}>
                          {userString}
                        </NavLink>
                      </td>
                      {!!workerType && (
                        <td>
                          <NavLink
                            to={`/manage/workerTypes/${String(workerTypeId)}`}
                          >
                            <span>
                              {workerType?.market?.name || ""}&nbsp;
                              {workerType?.positionName || ""}&nbsp; (
                              {workerTypeId})
                            </span>
                          </NavLink>
                        </td>
                      )}
                      {!workerType && <td />}
                      <td>{rating || "N/A"}</td>
                      <td>{Worker.formatAvailability(availability)}</td>
                      <td>{Worker.formatStatus(activeStatus)}</td>
                      <td>{Worker.formatDate(orientationScheduledAt)}</td>
                      <td>{Worker.formatDate(orientationDate)}</td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="workers"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!workers.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </WorkerIndexContainer>
    </>
  );
};
