import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { MarketIndexContainer, TableLimited } from "./MarketIndex.styles";
import { getMarkets } from "../../services/marketService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { Market } from "../../models/Market";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

export const MarketIndex = () => {
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["markets"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["markets"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["markets"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["markets"].limit
  );

  useEffect(() => {
    setTitle("Markets");
    getMarkets().then((res: any) => {
      setMarkets(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={Market.getFilterConfig()} />
      <MarketIndexContainer>
        {!!markets.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"markets"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"markets"}
                    columnName={"name"}
                    displayName={"Name"}
                  />
                  <ColumnHeadWithSort
                    index={"markets"}
                    columnName={"timezone"}
                    displayName={"Time Zone"}
                  />
                  <ColumnHeadWithSort
                    index={"markets"}
                    columnName={"orientationLink"}
                    displayName={"Orientation Link"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {markets.map((market) => {
                  const { id, name, timezone, orientationLink }: any = market;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{name}</td>
                      <td>{timezone}</td>
                      <td>
                        <a href={`${orientationLink}`}>{orientationLink}</a>
                      </td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="markets"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!markets.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </MarketIndexContainer>
    </>
  );
};
