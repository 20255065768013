import React, { useState } from "react";
import { Nav, NavItem } from "reactstrap";
import {
  ResourceNavbarContainer,
  IndexLink,
  CreateLink,
  ExportLink,
} from "./ResourceNavbar.styles";

export const ResourceNavbar = ({ activeTab, passActiveTab }: any) => {
  return (
    <ResourceNavbarContainer color={activeTab}>
      <Nav pills>
        <NavItem>
          <IndexLink
            className={activeTab === "index" ? "active" : ""}
            onClick={() => {
              passActiveTab("index");
            }}
          >
            Index
          </IndexLink>
        </NavItem>
        <NavItem>
          <CreateLink
            className={activeTab === "create" ? "active" : ""}
            onClick={() => {
              passActiveTab("create");
            }}
          >
            Create
          </CreateLink>
        </NavItem>
        <NavItem>
          <ExportLink
            className={activeTab === "export" ? "active" : ""}
            onClick={() => {
              passActiveTab("export");
            }}
          >
            Export
          </ExportLink>
        </NavItem>
      </Nav>
    </ResourceNavbarContainer>
  );
};
