export class Market {
  id: number;
  name: string;
  timezone: string;
  orientationLink: string;

  constructor(newMarket: IMarket) {
    const { id, name, timezone, orientationLink } = newMarket;

    this.id = id;
    this.name = name;
    this.timezone = timezone;
    this.orientationLink = orientationLink;
  }

  static getFilterConfig() {
    return {
      columns: ["id", "name", "timezone", "orientationLink"],
      indexToFilter: "markets",
    };
  }
}

export interface IMarket {
  id: number;
  name: string;
  timezone: string;
  orientationLink: string;
}
