import { BsFillCaretLeftFill } from "react-icons/bs";
import { PaginationLink } from "reactstrap";
import styled from "styled-components";

export const PaginationBarContainer = styled.div`
   {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;

export const PaginationArrowLink = styled(PaginationLink)`
   {
    display: flex;
    height: 100%;
    align-items: center;
  }
`;
