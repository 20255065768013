import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AutoSchedulerDetailsContainer } from "./AutoSchedulerDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getAutoSchedulerById } from "../../services/autoSchedulerService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import AutoSchedulerRead from "../../components/AutoSchedulerRead/AutoSchedulerRead";
import AutoSchedulerUpdate from "../../components/AutoSchedulerUpdate/AutoSchedulerUpdate";
import AutoSchedulerDelete from "../../components/AutoSchedulerDelete/AutoSchedulerDelete";

export const AutoSchedulerDetails = () => {
  let { autoSchedulerId } = useParams();
  const [autoScheduler, setAutoScheduler] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`AutoScheduler`);
    if (!Object.entries(autoScheduler).length && autoSchedulerId)
      getAutoSchedulerById(autoSchedulerId)
        .then((res) => {
          if (res.value) {
            setAutoScheduler({
              ...res.value.interval,
              autoScheduledProjects: res.value.autoScheduledProjects,
            });
            setError(false);
            setTitle(`AutoScheduler (${res.value.name})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
  });

  const resetView = () => setActiveTab("view");

  const renderTab = (autoScheduler: any) => {
    switch (activeTab) {
      case "view":
        return <AutoSchedulerRead {...autoScheduler} />;
      case "update":
        return (
          <AutoSchedulerUpdate {...autoScheduler} $resetView={resetView} />
        );
      case "delete":
        return (
          <AutoSchedulerDelete
            autoScheduler={autoScheduler}
            $resetView={resetView}
          />
        );
      default:
        return <AutoSchedulerRead {...autoScheduler} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(autoScheduler).length) {
    return (
      <>
        <AutoSchedulerDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(autoScheduler)}</TabContent>
        </AutoSchedulerDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
