import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ProjectSiteRequirementIndexContainer,
  TableLimited,
} from "./ProjectSiteRequirementIndex.styles";
import { getProjectSiteRequirements } from "../../services/projectSiteRequirementService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { ProjectSiteRequirement } from "../../models/ProjectSiteRequirement";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

export const ProjectSiteRequirementIndex = () => {
  const [projectSiteRequirements, setProjectSiteRequirements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["projectSiteRequirements"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["projectSiteRequirements"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["projectSiteRequirements"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["projectSiteRequirements"].limit
  );

  useEffect(() => {
    setTitle("Project-Site Requirements");
    getProjectSiteRequirements().then((res: any) => {
      setProjectSiteRequirements(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  const formatDateTime = (dateTime: string) =>
    new Date(dateTime).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={ProjectSiteRequirement.getFilterConfig()} />
      <ProjectSiteRequirementIndexContainer>
        {!!projectSiteRequirements.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"projectSiteRequirements"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"projectSiteRequirements"}
                    columnName={"projectId"}
                    displayName={"Project"}
                  />
                  <ColumnHeadWithSort
                    index={"projectSiteRequirements"}
                    columnName={"siteRequirementId"}
                    displayName={"Site Requirement"}
                  />
                  <ColumnHeadWithSort
                    index={"projectSiteRequirements"}
                    columnName={"createdAt"}
                    displayName={"Created At"}
                  />
                  <ColumnHeadWithSort
                    index={"projectSiteRequirements"}
                    columnName={"updatedAt"}
                    displayName={"Updated At"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {projectSiteRequirements.map(
                  (projectSiteRequirement, index) => {
                    const {
                      id,
                      project,
                      siteRequirement,
                      createdAt,
                      updatedAt,
                    }: any = projectSiteRequirement;
                    return (
                      <tr key={id}>
                        <td>{id}</td>
                        <td>
                          <NavLink
                            to={`/manage/projects/${String(project.id)}`}
                          >
                            {project.id}
                            &nbsp;-&nbsp;
                            {project.address}
                          </NavLink>
                        </td>
                        <td>
                          <NavLink
                            to={`/manage/siteRequirements/${String(
                              siteRequirement.id
                            )}`}
                          >
                            {siteRequirement.name}
                          </NavLink>
                        </td>
                        <td>{formatDateTime(createdAt)}</td>
                        <td>{formatDateTime(updatedAt)}</td>
                        <td>
                          <NavLink to={String(id)}>Open</NavLink>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="projectSiteRequirements"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!projectSiteRequirements.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </ProjectSiteRequirementIndexContainer>
    </>
  );
};
