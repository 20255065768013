import React from "react";
import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import { AppRoot } from "./pages/AppRoot/AppRoot";
import ErrorFallback from "./components/ErrorFallback/ErrorFallback";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { GlobalStyle } from "./globals/globals.styles";
import { Provider } from "react-redux";
import { store } from "./store";
import "./styles/global.styles.css";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";

Sentry.init({
  dsn: "https://34c746a819fa2eb7590487d758190b17@o4505801582641152.ingest.sentry.io/4506514702008320",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
    new Sentry.Feedback({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "light",
    }),
    new CaptureConsole({
      levels: ["error"],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  // This sets the sample rate at 100%.
  // Change it to 100% while in development and then 10% in production.
  replaysSessionSampleRate: parseFloat(
    process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? "1.0"
  ),
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "local",
  release: "si-admin@" + process.env.NEXT_PUBLIC_SENTRY_VERSION,
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyle />
      <BrowserRouter>
        <Sentry.ErrorBoundary
          fallback={({ error, componentStack, resetError }) => (
            <ErrorFallback
              error={error}
              componentStack={componentStack}
              resetError={resetError}
            />
          )}
        >
          <Theme>
            <AppRoot />
          </Theme>
        </Sentry.ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
