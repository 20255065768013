export class ProjectInvite {
  id: number;
  adminNotes: string;
  status: number;
  hashId: string;
  noOtherInvites: boolean;

  constructor(newProjectInvite: IProjectInvite) {
    const { id, adminNotes, status, hashId, noOtherInvites } = newProjectInvite;

    this.id = id;
    this.adminNotes = adminNotes;
    this.status = status;
    this.hashId = hashId;
    this.noOtherInvites = noOtherInvites;
  }

  static getFilterConfig() {
    return {
      columns: ["id", "adminNotes", "status", "hashId", "noOtherInvites"],
      indexToFilter: "projectInvites",
    };
  }
}

export interface IProjectInvite {
  id: number;
  adminNotes: string;
  status: number;
  hashId: string;
  noOtherInvites: boolean;
}
