export class Project {
  id: number;
  projectName: string;
  referenceNumber?: string;
  description?: string;
  address?: string;
  unit?: string;
  siteRequirements?: string;
  pocName?: string;
  pocPhone?: string;
  pocEmail?: string;
  otherManufacturer?: string;
  totalCostPerHourCents?: number;
  totalCostPerHourCurrency?: string;
  badgingRequired?: boolean;
  badgingRequirements?: string;
  otherSiteRequirements?: string;
  cancelledAt?: string;
  projectFilledAt?: string;
  wantsReplacements?: boolean;
  startEqProjectAddress?: boolean;

  constructor(newForeman: IForeman) {
    const {
      id,
      projectName,
      referenceNumber,
      description,
      address,
      unit,
      siteRequirements,
      pocName,
      pocPhone,
      pocEmail,
      otherManufacturer,
      totalCostPerHourCents,
      totalCostPerHourCurrency,
      badgingRequired,
      badgingRequirements,
      otherSiteRequirements,
      cancelledAt,
      projectFilledAt,
      wantsReplacements,
      startEqProjectAddress,
    } = newForeman;

    this.id = id;
    this.projectName = projectName;
    this.referenceNumber = referenceNumber;
    this.description = description;
    this.address = address;
    this.unit = unit;
    this.siteRequirements = siteRequirements;
    this.pocName = pocName;
    this.pocPhone = pocPhone;
    this.pocEmail = pocEmail;
    this.otherManufacturer = otherManufacturer;
    this.totalCostPerHourCents = totalCostPerHourCents;
    this.totalCostPerHourCurrency = totalCostPerHourCurrency;
    this.badgingRequired = badgingRequired;
    this.badgingRequirements = badgingRequirements;
    this.otherSiteRequirements = otherSiteRequirements;
    this.cancelledAt = cancelledAt;
    this.projectFilledAt = projectFilledAt;
    this.wantsReplacements = wantsReplacements;
    this.startEqProjectAddress = startEqProjectAddress;
  }

  static getFilterConfig() {
    return {
      columns: ["id", "name", "phone", "email", "userId"],
      indexToFilter: "foremen",
    };
  }
}

export interface IForeman {
  id: number;
  projectName: string;
  referenceNumber?: string;
  description?: string;
  address?: string;
  unit?: string;
  siteRequirements?: string;
  pocName?: string;
  pocPhone?: string;
  pocEmail?: string;
  otherManufacturer?: string;
  totalCostPerHourCents?: number;
  totalCostPerHourCurrency?: string;
  badgingRequired?: boolean;
  badgingRequirements?: string;
  otherSiteRequirements?: string;
  cancelledAt?: string;
  projectFilledAt?: string;
  wantsReplacements?: boolean;
  startEqProjectAddress?: boolean;
}
