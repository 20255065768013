import "@radix-ui/colors/black-alpha.css";
import "@radix-ui/colors/mauve.css";
import "@radix-ui/colors/violet.css";
import {
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectLabel,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectViewport,
} from "@radix-ui/react-select";
import React from "react";
import styled from "styled-components";

export const SelectTriggerComponent = styled(SelectTrigger)`
   {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 13px;
    line-height: 1;
    height: 35px;
    gap: 5px;
    background-color: white;
    color: var(--violet-11);
    box-shadow: 0 2px 10px var(--black-a7);
  }
  &:hover {
    background-color: var(--mauve-3);
  }
  &:focus {
    box-shadow: 0 0 0 2px black;
  }
  &[data-placeholder] {
    color: var(--black-9);
  }
`;
export const SelectIconComponent = styled(SelectIcon)`
   {
    color: Var(--violet-11);
  }
`;
export const SelectContentComponent = styled(SelectContent)`
   {
    overflow: hidden;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
      0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  }
`;
export const SelectViewportComponent = styled(SelectViewport)`
   {
    padding: 5px;
  }
`;
export const SelectItemComponent = styled(SelectItem)`
   {
    font-size: 13px;
    line-height: 1;
    color: var(--black-12);
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 35px 0 25px;
    position: relative;
    user-select: none;
  }
`;
export const SelectLabelComponent = styled(SelectLabel)`
   {
    padding: 0 25px;
    font-size: 16px;
    line-height: 25px;
    color: var(--mauve-11);
    font-weight: bold;
  }
`;
export const SelectSeparatorComponent = styled(SelectSeparator)`
   {
    height: 1px;
    background-color: var(--violet-6);
    margin: 5px;
  }
`;
export const SelectItemIndicatorComponent = styled(SelectItemIndicator)`
   {
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;
export const SelectScrollUpButtonComponent = styled(SelectScrollUpButton)`
   {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    background-color: white;
    color: var(--violet-11);
    cursor: default;
  }
`;

export const SelectScrollDownButtonComponent = styled(SelectScrollDownButton)`
   {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    background-color: white;
    color: var(--violet-11);
    cursor: default;
  }
`;
