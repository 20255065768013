import React, { useEffect, useState } from "react";
import { Input, TabPane } from "reactstrap";

import {
  Content,
  Group,
  Title,
  ProjectToolAutoSchedulerContainer,
  InfoBox,
  ErrorText,
  SubmitButton,
} from "./ProjectToolAutoScheduler.styles";
import ProjectWorkerTable from "./ProjectWorkerTable/ProjectWorkerTable";
import { createAutoSchedule } from "../../services/autoSchedulerService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { NavLink } from "react-router-dom";
import isInt from "validator/lib/isInt";
import isNumeric from "validator/lib/isNumeric";
import CurrentAutoSchedulesTable from "./CurrentAutoSchedulesTable/CurrentAutoSchedulesTable";

const ProjectToolAutoScheduler = ({
  id,
  autoSchedulerIntervals,
  autoScheduledProjects,
  projectDates,
  user,
  projectName,
  address,
}: any) => {
  const [projectWorkers, setProjectWorkers] = useState([]);
  const [selectedProjectWorkers, setSelectedProjectWorkers] = useState([]);
  const [errors, setErrors] = useState<Array<any>>([]);
  const [projectLevels, setProjectLevels] = useState<any>([]);
  const [selectedProjectLevel, setSelectedProjectLevel] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    intervalInMinutes: "5",
    batchSize: "5",
    minimumRating: "9.0",
    proximity: "3",
    removeAllSameDayInvite: false,
  });

  const populateProjectWorkers = () => {
    let projectWorkerRows: any = [];
    let levels: any = [];

    projectDates.forEach(
      ({ startsAt, endsAt, timezone, projectWorkers }: any) => {
        //ignore ProjectWorkers with past dates
        if (new Date(startsAt).getTime() + 86400000 < Date.now()) return;

        projectWorkers.forEach(
          ({
            id,
            totalAmount,
            workerType: { positionName, level, paymentCents },
            shiftInvites,
          }: any) => {
            let requested = 0,
              needed = 0,
              invited = 0,
              accepted = 0,
              rejected = 0,
              canceled = 0,
              pending = 0,
              waitListed = 0,
              rate = 0;

            requested = totalAmount;
            invited = shiftInvites.length;
            rate = +(paymentCents / 100).toFixed(2);
            shiftInvites.forEach(({ status }: any) => {
              switch (status) {
                case 0:
                  pending++;
                  break;
                case 1:
                  accepted++;
                  break;
                case 2:
                  rejected++;
                  break;
                case 3:
                  canceled++;
                  break;
                case 4:
                  waitListed++;
                  break;
                default:
                  break;
              }
            });

            needed = requested - accepted;
            projectWorkerRows.push({
              id,
              level,
              positionName,
              startsAt,
              endsAt,
              timezone,
              requested,
              needed,
              invited,
              accepted,
              rejected,
              canceled,
              pending,
              waitListed,
              rate,
            });
            levels.push(positionName);
          }
        );
      }
    );

    setProjectWorkers(projectWorkerRows);
    setProjectLevels([...new Set(levels)]);
  };

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    populateProjectWorkers();
  }, []);

  const selectAllByProjectLevel = (projectLevel: string) => {
    if (projectLevel === selectedProjectLevel) setSelectedProjectLevel("");
    else setSelectedProjectLevel(projectLevel);
  };

  const resetAll = () => {
    setSelectedProjectWorkers([]);
    setSelectedProjectLevel("");
  };

  const submitForm = async (e: any) => {
    setErrors([]);
    let foundErrors: any = [];

    const { intervalInMinutes, batchSize, minimumRating }: any = formData;

    if (!isInt(intervalInMinutes || "")) foundErrors.push("intervalInMinutes");
    if (!isInt(batchSize || "")) foundErrors.push("batchSize");
    if (!isNumeric(minimumRating || "")) foundErrors.push("minimumRating");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setIsSubmitting(true);

    let { value, error }: any = await createAutoSchedule({
      selectedProjectWorkers: selectedProjectWorkers.join(","),
      intervalInMinutes,
      batchSize,
      minimumRating,
    });

    resetAll();
    setIsSubmitting(false);
    if (value) alert("Auto-Schedule successfully created.");
    if (error) alert("Something went wrong: " + error);
  };

  if (isSubmitting) return <BigSpinner />;

  return (
    <ProjectToolAutoSchedulerContainer>
      <TabPane>
        <h3>Auto-Schedule Invites</h3>
        <InfoBox>
          <NavLink to={`/manage/businesses/${String(user.business.id)}`}>
            Business:&nbsp;
            {`${user.business.businessName || "(no name set)"} (${
              user.business.id
            })`}
          </NavLink>
          <p>Name:&nbsp;{projectName}</p>
          <p>Address:&nbsp;{address}</p>
        </InfoBox>
        {!!projectLevels.length &&
          projectLevels.map((projectLevel: any, index: number) => {
            return (
              <Group key={index}>
                <Title>Select all {projectLevel}</Title>
                <Content>
                  <Input
                    type="checkbox"
                    onChange={() => selectAllByProjectLevel(projectLevel)}
                    checked={selectedProjectLevel === projectLevel}
                  />
                </Content>
              </Group>
            );
          })}
        {!!projectWorkers.length && (
          <ProjectWorkerTable
            rows={projectWorkers}
            selectAll={selectedProjectLevel ? selectedProjectLevel : false}
            reportSelections={(projectWorkerIds: any) => {
              setSelectedProjectWorkers(projectWorkerIds);
            }}
          />
        )}

        {!!selectedProjectWorkers.length && (
          <>
            <Group>
              <Title>Interval (in minutes)</Title>
              <Input
                name="intervalInMinutes"
                type="text"
                invalid={errors.indexOf("intervalInMinutes") !== -1}
                value={formData.intervalInMinutes}
                onChange={onChange}
              />
            </Group>
            {errors.indexOf("intervalInMinutes") !== -1 && (
              <ErrorText>Invalid Amount</ErrorText>
            )}
            <Group>
              <Title>Batch Size</Title>
              <Input
                name="batchSize"
                type="text"
                invalid={errors.indexOf("batchSize") !== -1}
                value={formData.batchSize}
                onChange={onChange}
              />
            </Group>
            {errors.indexOf("batchSize") !== -1 && (
              <ErrorText>Invalid Amount</ErrorText>
            )}
            <Group>
              <Title>Minimum Rating</Title>
              <Input
                name="minimumRating"
                type="text"
                invalid={errors.indexOf("minimumRating") !== -1}
                value={formData.minimumRating}
                onChange={onChange}
              />
            </Group>
            {errors.indexOf("minimumRating") !== -1 && (
              <ErrorText>Invalid Amount</ErrorText>
            )}
            <Group>
              <Title>Proximity (D)</Title>
              <Input
                name="proximity"
                type="text"
                invalid={errors.indexOf("proximity") !== -1}
                value={formData.proximity}
                onChange={onChange}
              />
            </Group>
            {errors.indexOf("proximity") !== -1 && (
              <ErrorText>Invalid Amount</ErrorText>
            )}
            {/* <Group>
              <Title>Remove all same day invites</Title>
              <Input
                name="removeAllSameDayInvite"
                type="checkbox"
                onChange={toggleState}
                checked={formData.removeAllSameDayInvite}
              />
            </Group> */}
            <br />
            <SubmitButton color="primary" size="lg" onClick={submitForm}>
              Submit
            </SubmitButton>
            <br />
          </>
        )}
        {(!autoSchedulerIntervals || !autoSchedulerIntervals.length) && (
          <h4>No AutoSchedules created yet...</h4>
        )}
        {!!autoSchedulerIntervals && !!autoSchedulerIntervals.length && (
          <CurrentAutoSchedulesTable
            rows={autoSchedulerIntervals}
            autoScheduledProjects={autoScheduledProjects}
          />
        )}
      </TabPane>
    </ProjectToolAutoSchedulerContainer>
  );
};

export default ProjectToolAutoScheduler;
