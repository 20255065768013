import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { Button, Input } from "reactstrap";

import {
  ExpandIcon,
  ExpandedIcon,
  ProjectWorkerFlyoutContainer,
  TitleBar,
  Group,
  Title,
  CancelButton,
  DeleteButton,
  TitleWrapper,
} from "./ProjectWorkerFlyout.styles";

const ProjectWorkerFlyout = ({
  id,
  uuid,
  parentUUID,
  projectDateId,
  workerType,
  availableWorkerTypes,
  workerTypeId,
  filledAmount,
  totalAmount,
  $setAction,
  $deleteAction,
  newWorker,
}: any) => {
  const [showDetails, setShowDetails] = useState(newWorker);

  const [formData, setFormData] = useState({
    id,
    projectDateId,
    workerTypeId,
    filledAmount,
    totalAmount,
    parentUUID,
    toBeDeleted: false,
  });

  const onChange = (e: any) => {
    setFormData((prevState) => {
      let temp: any = {
        ...prevState,
        [e.target.name]: e.target.value,
      };

      let operation;

      if (formData.toBeDeleted) operation = "DELETE";
      else if (id == "NEW") operation = "CREATE";
      else operation = "UPDATE";

      delete temp.toBeDeleted;

      $setAction(uuid, operation, "WORKER", temp);
      return temp;
    });
  };

  const cancelChanges = () => {
    $deleteAction(uuid);
    setFormData({
      id,
      projectDateId,
      parentUUID,
      workerTypeId,
      filledAmount,
      totalAmount,
      toBeDeleted: false,
    });
  };

  const toggleDelete = () => {
    if (!formData.toBeDeleted) {
      setFormData(() => {
        let temp: any = {
          ...formData,
          toBeDeleted: !formData.toBeDeleted,
        };

        $setAction(uuid, "DELETE", "WORKER", temp);
        return temp;
      });
    } else {
      setFormData({
        ...formData,
        toBeDeleted: !formData.toBeDeleted,
      });
    }
  };

  if (newWorker && formData.toBeDeleted) return <></>;

  if (formData.toBeDeleted)
    return (
      <ProjectWorkerFlyoutContainer toDelete={formData.toBeDeleted}>
        <TitleWrapper>
          <TitleBar
            onClick={() => setShowDetails(newWorker ? true : !showDetails)}
          >
            <div>{showDetails ? <ExpandedIcon /> : <ExpandIcon />}</div>
            <div>
              {!newWorker && (
                <>
                  {workerType.positionName}&nbsp; ({filledAmount}/{totalAmount})
                </>
              )}
              {!!newWorker && "New Worker"}
              <p>(to be deleted)</p>
            </div>
          </TitleBar>
          <div>
            <CancelButton onClick={cancelChanges} />
          </div>
        </TitleWrapper>
      </ProjectWorkerFlyoutContainer>
    );

  return (
    <ProjectWorkerFlyoutContainer newWorker={newWorker}>
      <TitleWrapper>
        <TitleBar
          onClick={() => setShowDetails(newWorker ? true : !showDetails)}
        >
          <div>{showDetails ? <ExpandedIcon /> : <ExpandIcon />}</div>
          <div>
            <span>
              {!newWorker && (
                <>
                  {workerType.positionName}&nbsp; ({filledAmount}/{totalAmount})
                </>
              )}
              {!!newWorker && "New Worker"}
            </span>
          </div>
        </TitleBar>
        <div>
          {!newWorker && <CancelButton onClick={cancelChanges} />}
          <DeleteButton onClick={toggleDelete} />
        </div>
      </TitleWrapper>

      {!!showDetails && (
        <>
          <Group>
            <Title>Worker Type</Title>
            <Input
              name="workerTypeId"
              type="select"
              placeholder="Select a WorkerType"
              value={formData.workerTypeId}
              onChange={onChange}
            >
              {!!Array.isArray(availableWorkerTypes) &&
                availableWorkerTypes.map((wt: any, index: any) => (
                  <option key={index} value={wt.id}>
                    {wt.positionName}
                  </option>
                ))}
            </Input>
          </Group>
          <Group>
            <Title>Filled / Total</Title>
            <Input
              name="filledAmount"
              type="text"
              value={formData.filledAmount}
              onChange={onChange}
            />
            <Input
              name="totalAmount"
              type="text"
              value={formData.totalAmount}
              onChange={onChange}
            />
          </Group>
        </>
      )}
    </ProjectWorkerFlyoutContainer>
  );
};

export default ProjectWorkerFlyout;
