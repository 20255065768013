import React, { useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import {
  PaginationBarContainer,
  PaginationArrowLink,
} from "./PaginationBar.styles";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";
import { useAppDispatch } from "../../store/hooks";
import { changePage } from "../../store/filterSlice";

export const PaginationBar = ({ index, totalCount, page, limit }: any) => {
  const pageCount = Math.ceil(totalCount / limit);
  const dispatch = useAppDispatch();

  if (+pageCount <= 1) return <></>;

  const renderLinks = () => {
    let links = [];

    const leftBound = page - 4 > 0 ? page - 4 : 1;
    const rightBound = page + 4 <= pageCount ? page + 4 : pageCount;

    for (let count = leftBound; count <= rightBound; count++) {
      links.push(
        <PaginationItem key={count + Date.now()} active={page === count}>
          <PaginationLink
            onClick={() =>
              dispatch(
                changePage({
                  index,
                  page: count,
                })
              )
            }
          >
            {count}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return links;
  };

  return (
    <PaginationBarContainer>
      <Pagination>
        <PaginationItem disabled={page <= 1}>
          <PaginationArrowLink
            onClick={() => {
              if (page > 1) {
                dispatch(
                  changePage({
                    index,
                    page: page--,
                  })
                );
              }
            }}
          >
            <BsFillCaretLeftFill />
          </PaginationArrowLink>
        </PaginationItem>
        {renderLinks()}
        <PaginationItem>
          <PaginationArrowLink
            onClick={() => {
              if (page < pageCount) {
                dispatch(
                  changePage({
                    index,
                    page: page++,
                  })
                );
              }
            }}
            disabled={page >= pageCount}
          >
            <BsFillCaretRightFill />
          </PaginationArrowLink>
        </PaginationItem>
      </Pagination>
    </PaginationBarContainer>
  );
};
