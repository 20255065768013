export class Foreman {
  id: number;
  name?: string;
  phone?: string;
  email?: string;
  userId: number;

  constructor(newForeman: IForeman) {
    const { id, name, phone, email, userId } = newForeman;

    this.id = id;
    this.name = name;
    this.phone = phone;
    this.email = email;
    this.userId = userId;
  }

  static getFilterConfig() {
    return {
      columns: ["id", "name", "phone", "email", "userId"],
      indexToFilter: "foremen",
    };
  }
}

export interface IForeman {
  id: number;
  name: string;
  phone: string;
  email: string;
  userId: number;
}
