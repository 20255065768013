import React from "react";
import styled from "styled-components";
import { colors } from "../../globals/globals.styles";
import { BsPlusLg } from "react-icons/bs";
import { Button } from "reactstrap";
import { IoCloseSharp } from "react-icons/io5";

export const FilterBarContainer = styled.div`
   {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }
`;

export const Label = styled.div`
   {
  }
`;

export const AddFilterButton = styled(Button)`
   {
    display: flex;
    align-items: center;
  }
`;

export const Plus = styled(BsPlusLg)`
   {
    margin-right: 5px;
  }
`;

export const FilterTile = styled.div`
   {
    color: black;
    background-color: ${colors.offWhiteGrey};
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    width: auto;
    padding: 5px;
    gap: 10px;
  }
`;

export const FilterTileClose = styled(IoCloseSharp)`
   {
    font-size: 16px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const FormError = styled.p`
   {
    color: red;
  }
`;
