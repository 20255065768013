import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { TimeLogIndexContainer, TableLimited } from "./TimeLogIndex.styles";
import { getTimeLogs } from "../../services/timeLogService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { TimeLog } from "../../models/TimeLog";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";
import { ShiftInvite } from "../../models/ShiftInvite";

const tz = require("timezone/loaded"); //until Temporal JS comes out

export const TimeLogIndex = () => {
  const [timeLogs, setTimeLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["timeLogs"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["timeLogs"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["timeLogs"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["timeLogs"].limit
  );

  useEffect(() => {
    setTitle("Time Logs");
    getTimeLogs().then((res: any) => {
      setTimeLogs(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={TimeLog.getFilterConfig()} />
      <TimeLogIndexContainer>
        {!!timeLogs.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"timeLogs"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"timeLogs"}
                    columnName={"clockedInAt"}
                    displayName={"Clocked In"}
                  />
                  <ColumnHeadWithSort
                    index={"timeLogs"}
                    columnName={"clockedOutAt"}
                    displayName={"Clocked Out"}
                  />
                  <ColumnHeadWithSort
                    index={"timeLogs"}
                    columnName={"totalDurationHours"}
                    displayName={"Total Duration Hours"}
                  />
                  <ColumnHeadWithSort
                    index={"timeLogs"}
                    columnName={"lunchBreakMinutes"}
                    displayName={"Lunch Break Minutes"}
                  />
                  <ColumnHeadWithSort
                    index={"timeLogs"}
                    columnName={"hashId"}
                    displayName={"Hash Id"}
                  />
                  <ColumnHeadWithSort
                    index={"timeLogs"}
                    columnName={"shiftInviteId"}
                    displayName={"Shift Invite"}
                  />
                  <ColumnHeadWithSort
                    index={"timeLogs"}
                    columnName={"createdAt"}
                    displayName={"Created At"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {timeLogs.map((timeLog) => {
                  const {
                    id,
                    clockedInAt,
                    clockedOutAt,
                    shiftInvite,
                    lunchBreakMinutes,
                    hashId,
                    createdAt,
                    timeSheetUrl,
                  }: any = timeLog;
                  const { startsAt, timezone } =
                    shiftInvite.projectWorker.projectDate;
                  const { firstName, lastName } = shiftInvite.worker.user;

                  let shiftInviteString = [
                    TimeLog.parseDateWithTimezone(startsAt, timezone),
                    "|",
                    shiftInvite.id,
                    "|",
                    firstName,
                    lastName,
                    "|",
                    `L${shiftInvite.worker.workerType.level}`,
                    "|",
                    ShiftInvite.formatStatus(shiftInvite.status),
                  ].join(" ");
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>
                        {TimeLog.parseDateWithTimezone(clockedInAt, timezone)}
                      </td>
                      <td>
                        {TimeLog.parseDateWithTimezone(clockedOutAt, timezone)}
                      </td>
                      <td>
                        {TimeLog.getDuration(
                          clockedInAt,
                          clockedOutAt,
                          lunchBreakMinutes
                        )}
                      </td>
                      <td>{lunchBreakMinutes}</td>
                      <td>{hashId}</td>
                      <td>
                        <NavLink
                          to={`/manage/shiftInvites/${String(shiftInvite.id)}`}
                        >
                          {shiftInviteString}
                        </NavLink>
                      </td>
                      <td>
                        {TimeLog.parseDateWithTimezone(createdAt, timezone)}
                      </td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                        {!!timeSheetUrl && (
                          <>
                            <br />
                            <a
                              href={timeSheetUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Open File
                            </a>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="timeLogs"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!timeLogs.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </TimeLogIndexContainer>
    </>
  );
};
