export class ProductLine {
  id: number;
  projectId: number;
  manufacturerId: number;

  constructor(newProductLine: IProductLine) {
    const { id, projectId, manufacturerId } = newProductLine;

    this.id = id;
    this.projectId = projectId;
    this.manufacturerId = manufacturerId;
  }

  static getFilterConfig() {
    return {
      columns: ["id", "projectId", "manufacturerId"],
      indexToFilter: "productLines",
    };
  }
}

export interface IProductLine {
  id: number;
  projectId: number;
  manufacturerId: number;
}
