import React from "react";
import { Button, Input, InputGroup } from "reactstrap";
import styled from "styled-components";
import { colors } from "../../globals/globals.styles";

export const ResetUserPasswordContainer = styled.div`
   {
  }
`;

export const UserField = styled(InputGroup)`
   {
    margin-top: 10px;
    width: 66%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const InputBox = styled(Input)`
   {
    margin-top: 10px;
    width: 66%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
`;

export const SubmitButton = styled(Button)`
   {
    width: 66%;
    font-weight: bold;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ErrorText = styled.p`
   {
    color: red;
  }
`;

export const SuccessText = styled.p`
   {
    color: green;
  }
`;

export const ButtonBox = styled.div`
   {
    width: 66%;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export const UpdateButton = styled(Button)`
   {
    width: 33%;
    display: block;

    font-weight: bold;
    background-color: ${colors.warn};
    &:hover {
      background-color: #ffa500;
    }
    @media only screen and (max-width: 768px) {
      width: 66%;
    }
  }
`;
