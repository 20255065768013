import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteWorkerBlackList } from "../../services/workerBlackListService";
import BigSpinner from "../BigSpinner/BigSpinner";
import WorkerBlackListRead from "../WorkerBlackListRead/WorkerBlackListRead";

import {
  WorkerBlackListsDeleteContainer,
  DeleteButton,
  CancelButton,
  ButtonBox,
  SuccessText,
  ErrorText,
} from "./WorkerBlackListDelete.styles";

const WorkerBlackListsDelete = ({ workerBlackList, $resetView }: any) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const removeWorkerBlackList = async () => {
    setProcessing(true);

    const response = await deleteWorkerBlackList(workerBlackList.id);

    if (!response.error) {
      setDeleteSuccess(true);
      setTimeout(() => navigate("/manage/workerBlackLists"), 2000);
    } else setError(true);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (deleteSuccess)
    return (
      <SuccessText>
        WorkerBlackList Deleted. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <WorkerBlackListsDeleteContainer>
      <h2>Are you sure you want to delete this?</h2>
      <WorkerBlackListRead {...workerBlackList} />
      {error && (
        <ErrorText>There was a problem deleting this. Try again.</ErrorText>
      )}
      <ButtonBox>
        <DeleteButton size="lg" onClick={removeWorkerBlackList}>
          Delete
        </DeleteButton>
        <CancelButton size="lg" onClick={$resetView}>
          Cancel
        </CancelButton>
      </ButtonBox>
    </WorkerBlackListsDeleteContainer>
  );
};

export default WorkerBlackListsDelete;
