import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupWorkerBlackLists = async (searchTerm: string) =>
  await protectedFetch(
    `${API_ROOT}/lookup/workerBlackLists?term=${searchTerm}`
  );

export const getWorkerBlackLists = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.workerBlackLists;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/workerBlackLists${filterString}`);
};

export const getWorkerBlackListById = async (workerBlackListId: string) =>
  await protectedFetch(`${API_ROOT}/workerBlackLists/${workerBlackListId}`);

export const createWorkerBlackList = async (newWorkerBlackList: any) => {
  return await protectedFetch(`${API_ROOT}/workerBlackLists`, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newWorkerBlackList),
  });
};

export const updateWorkerBlackList = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/workerBlackLists/${id}`, {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteWorkerBlackList = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/workerBlackLists/${id}`, {
    method: "DELETE",
  });
};
