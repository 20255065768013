import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "../../services/userService";
import BigSpinner from "../BigSpinner/BigSpinner";
import UserRead from "../UserRead/UserRead";

import {
  UserDeleteContainer,
  DeleteButton,
  CancelButton,
  ButtonBox,
  SuccessText,
  ErrorText,
} from "./UserDelete.styles";

const UserDelete = ({ user, $resetView }: any) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const removeUser = async () => {
    setProcessing(true);

    const response = await deleteUser(user.id);

    if (!response.error) {
      setDeleteSuccess(true);
      setTimeout(() => navigate("/manage/users"), 2000);
    } else setError(true);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (deleteSuccess)
    return <SuccessText>User Deleted. Redirecting you shortly.</SuccessText>;

  return (
    <UserDeleteContainer>
      <h2>
        Are you sure you want to delete? <br />
        This and all its dependant records will be deleted.
      </h2>
      <UserRead {...user} />
      {error && (
        <ErrorText>There was a problem deleting this. Try again.</ErrorText>
      )}
      <ButtonBox>
        <DeleteButton size="lg" onClick={removeUser}>
          Delete
        </DeleteButton>
        <CancelButton size="lg" onClick={$resetView}>
          Cancel
        </CancelButton>
      </ButtonBox>
    </UserDeleteContainer>
  );
};

export default UserDelete;
