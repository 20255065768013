import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ProjectDateIndexContainer,
  TableLimited,
} from "./ProjectDateIndex.styles";
import { getProjectDates } from "../../services/projectDateService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { ProjectDate } from "../../models/ProjectDate";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

const tz = require("timezone/loaded"); //until Temporal JS comes out

export const ProjectDateIndex = () => {
  const [projectDates, setProjectDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["projectDates"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["projectDates"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["projectDates"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["projectDates"].limit
  );

  useEffect(() => {
    setTitle("Project Dates");
    getProjectDates().then((res: any) => {
      setProjectDates(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={ProjectDate.getFilterConfig()} />
      <ProjectDateIndexContainer>
        {!!projectDates.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"projectDates"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"projectDates"}
                    columnName={"projectId"}
                    displayName={"Project"}
                  />
                  <ColumnHeadWithSort
                    index={"projectDates"}
                    columnName={"startsAt"}
                    displayName={"Starts at"}
                  />
                  <ColumnHeadWithSort
                    index={"projectDates"}
                    columnName={"endsAt"}
                    displayName={"Ends at"}
                  />
                  <ColumnHeadWithSort
                    index={"projectDates"}
                    columnName={"timezone"}
                    displayName={"Time Zone"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {projectDates.map((projectDate: any, index) => {
                  const startsAtParsed = tz(
                    projectDate.startsAt,
                    "%c",
                    "en_US",
                    projectDate.timezone
                  ).split(" ");
                  const endsAtParsed = tz(
                    projectDate.endsAt,
                    "%c",
                    "en_US",
                    projectDate.timezone
                  ).split(" ");

                  return (
                    <tr key={index}>
                      <td>{projectDate.id}</td>
                      <td>
                        <NavLink
                          to={`/manage/projects/${String(
                            projectDate.project.id
                          )}`}
                        >
                          {projectDate.project.id}
                          &nbsp;-&nbsp;
                          {projectDate.project.address}
                        </NavLink>
                      </td>
                      <td>
                        {[
                          startsAtParsed[0] + ",",
                          startsAtParsed[2],
                          startsAtParsed[1] + ",",
                          startsAtParsed[4].slice(0, -3),
                          startsAtParsed[5],
                          startsAtParsed[6],
                        ].join(" ")}
                      </td>
                      <td>
                        {[
                          endsAtParsed[0] + ",",
                          endsAtParsed[2],
                          endsAtParsed[1] + ",",
                          endsAtParsed[4].slice(0, -3),
                          endsAtParsed[5],
                          endsAtParsed[6],
                        ].join(" ")}
                      </td>
                      <td>{projectDate.timezone}</td>
                      <td>
                        <NavLink to={String(projectDate.id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="projectDates"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!projectDates.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </ProjectDateIndexContainer>
    </>
  );
};
