import React, { useState } from "react";
import { Button, Input, InputGroup, TabPane } from "reactstrap";
import UserLookupModal from "../UserLookupModal/UserLookupModal";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  ForemenUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
} from "./ForemenUpdate.styles";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import isInt from "validator/lib/isInt";
import isEmail from "validator/lib/isEmail";
import { updateForeman } from "../../services/foremenService";
import BigSpinner from "../BigSpinner/BigSpinner";

const ForemenUpdate = ({
  id,
  name,
  phone,
  email,
  user,
  userId,
  $resetView,
}: any) => {
  const [formData, setFormData] = useState({
    id,
    name,
    phone,
    email,
    userId,
  });
  const [userString, setUserString] = useState(
    `${user.firstName} ${user.lastName} (${userId})`
  );
  const [errors, setErrors] = useState<Array<any>>([]);
  const [showUserLookup, setShowUserLookup] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const setUser = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        userId: String(id),
      }));
      setUserString(name || "");
      if (!formModified) setFormModified(true);
    }
    setShowUserLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    const { name, phone, email, userId }: any = formData;
    let foundErrors = [];

    if (isEmpty(name || "")) foundErrors.push("name");
    if (!isMobilePhone(phone || "", "en-US")) foundErrors.push("phone");
    if (!isInt(userId || "")) foundErrors.push("userId");
    if (!isEmail(email || "")) foundErrors.push("email");
    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let response = await updateForeman(id, formData);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <ForemenUpdateContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Input
            name="id"
            type="text"
            value={formData.id}
            bsSize="sm"
            readOnly={true}
          />
        </Group>
        <Group>
          <Title>Name</Title>
          <Input
            name="name"
            type="text"
            value={formData.name}
            onChange={onChange}
            invalid={errors.indexOf("name") !== -1}
          />
        </Group>
        {errors.indexOf("name") !== -1 && <ErrorText>Invalid Name</ErrorText>}
        <Group>
          <Title>Email</Title>
          <Input
            name="email"
            type="email"
            value={formData.email}
            onChange={onChange}
            invalid={errors.indexOf("email") !== -1}
          />
        </Group>
        {errors.indexOf("email") !== -1 && <ErrorText>Invalid Email</ErrorText>}
        <Group>
          <Title>Phone</Title>
          <Input
            name="phone"
            type="text"
            value={formData.phone}
            onChange={onChange}
            invalid={errors.indexOf("phone") !== -1}
          />
        </Group>
        {errors.indexOf("phone") !== -1 && (
          <ErrorText>Invalid Phone Number</ErrorText>
        )}
        <Group>
          <Title>User (ID)</Title>
          <InputGroup>
            <Input
              type="text"
              placeholder="Select the user who will own this >"
              invalid={errors.indexOf("userId") !== -1}
              value={userString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowUserLookup(true)}>
              Select User
            </Button>
          </InputGroup>
        </Group>
        {errors.indexOf("userId") !== -1 && <ErrorText>Invalid User</ErrorText>}
        <UserLookupModal isOpen={showUserLookup} callback={setUser} />
        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
      </TabPane>
    </ForemenUpdateContainer>
  );
};

export default ForemenUpdate;
