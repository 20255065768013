import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  ProjectSiteRequirementCreateContainer,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  LookupField,
} from "./ProjectSiteRequirementCreate.styles";
import { createProjectSiteRequirement } from "../../services/projectSiteRequirementService";
import { useNavigate } from "react-router-dom";
import isInt from "validator/lib/isInt";
import ProjectLookupModal from "../ProjectLookupModal/ProjectLookupModal";
import SiteRequirementLookupModal from "../SiteRequirementLookupModal/SiteRequirementLookupModal";

export const ProjectSiteRequirementCreate = () => {
  const [formData, setFormData] = useState({
    projectId: "",
    siteRequirementId: "",
  });
  const [showProjectLookup, setShowProjectLookup] = useState(false);
  const [projectString, setProjectString] = useState("");
  const [showSiteRequirementLookup, setShowSiteRequirementLookup] =
    useState(false);
  const [siteRequirementString, setSiteRequirementString] = useState("");
  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();

  const clearForm = () => {
    setFormData({
      projectId: "",
      siteRequirementId: "",
    });
    setErrors([]);
    setNewId(0);
  };
  const setProject = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectId: String(id),
      }));
      setProjectString(name || "");
    }
    setShowProjectLookup(false);
  };

  const setSiteRequirement = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        siteRequirementId: String(id),
      }));
      setSiteRequirementString(name || "");
    }
    setShowSiteRequirementLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { projectId, siteRequirementId }: any = formData;

    if (!isInt(projectId || "")) foundErrors.push("projectId");
    if (!isInt(siteRequirementId || "")) foundErrors.push("siteRequirementId");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let response = await createProjectSiteRequirement(formData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    return (
      <ProjectSiteRequirementCreateContainer>
        <SuccessText>
          New ProjectSiteRequirement successfully created.
        </SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to ProjectSiteRequirement
          </Button>
        </SuccessButtonWrapper>
      </ProjectSiteRequirementCreateContainer>
    );
  }

  return (
    <ProjectSiteRequirementCreateContainer>
      <LookupField>
        <Input
          type="text"
          placeholder="Select the Project >"
          invalid={errors.indexOf("projectId") !== -1}
          value={projectString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowProjectLookup(true)}>
          Select Project
        </Button>
      </LookupField>
      {errors.indexOf("projectId") !== -1 && (
        <ErrorText>Invalid Project</ErrorText>
      )}
      <ProjectLookupModal isOpen={showProjectLookup} callback={setProject} />
      <LookupField>
        <Input
          type="text"
          placeholder="Select the Site Requirement >"
          invalid={errors.indexOf("siteRequirementId") !== -1}
          value={siteRequirementString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowSiteRequirementLookup(true)}>
          Select Site Requirement
        </Button>
      </LookupField>
      {errors.indexOf("siteRequirementId") !== -1 && (
        <ErrorText>Invalid Site Requirement</ErrorText>
      )}
      <SiteRequirementLookupModal
        isOpen={showSiteRequirementLookup}
        callback={setSiteRequirement}
      />
      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </ProjectSiteRequirementCreateContainer>
  );
};
