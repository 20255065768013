import { store } from "../store";
import { logout, reset } from "../store/authSlice";

export const redirectOnFailedAuth = (err?: any) => {
  store.dispatch(logout());
  store.dispatch(reset());
  throw new Error(
    err ||
      "There was a problem completing this request. Check logs for more info."
  );
};

export const generateAuth = () => {
  const state = store.getState();
  const user: any = state.auth.user;

  if (user?.value?.token) return user.value.token;
  else redirectOnFailedAuth();

  return 1;
};

export const protectedFetch = async (url: string, data?: any) => {
  try {
    let tempData = data || {};

    if (!tempData?.headers)
      tempData.headers = {
        Authorization: `Bearer ${generateAuth()}`,
      };
    else if (!tempData?.headers?.Authorization)
      tempData.headers = {
        ...tempData.headers,
        Authorization: `Bearer ${generateAuth()}`,
      };

    const res = await fetch(url, tempData);

    if (res.status === 401 || res.status === 403) redirectOnFailedAuth();

    return res.json();
  } catch (err: any) {
    throw new Error(err);
  }
};
