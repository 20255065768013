import React, { useState } from "react";
import { ProjectWorkersContainer } from "./ProjectWorkers.styles";
import { ResourceNavbar } from "../../components/ResourceNavbar/ResourceNavbar";
import { TabContent } from "reactstrap";
import { ProjectWorkerCreate } from "../../components/ProjectWorkerCreate/ProjectWorkerCreate";
import { ProjectWorkerIndex } from "../../components/ProjectWorkerIndex/ProjectWorkerIndex";
import { useSearchParams } from "react-router-dom";

export const ProjectWorkers = () => {
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || "index"
  );

  const renderTab = () => {
    switch (activeTab) {
      case "index":
        return <ProjectWorkerIndex />;
      case "create":
        return <ProjectWorkerCreate />;
      case "export":
        return <ProjectWorkerIndex />;
      default:
        return <ProjectWorkerIndex />;
    }
  };

  return (
    <>
      <ProjectWorkersContainer>
        <ResourceNavbar
          activeTab={activeTab}
          passActiveTab={(newValue: string) => setActiveTab(newValue)}
        />
        <TabContent>{renderTab()}</TabContent>
      </ProjectWorkersContainer>
    </>
  );
};
