import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ProjectWorkerDetailsContainer } from "./ProjectWorkerDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getProjectWorkerById } from "../../services/projectWorkerService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import ProjectWorkerRead from "../../components/ProjectWorkerRead/ProjectWorkerRead";
import ProjectWorkerUpdate from "../../components/ProjectWorkerUpdate/ProjectWorkerUpdate";
import ProjectWorkerDelete from "../../components/ProjectWorkerDelete/ProjectWorkerDelete";

export const ProjectWorkerDetails = ({ forId, isFlyout, $onChange }: any) => {
  let { projectWorkerId } = useParams();
  const [projectWorker, setProjectWorker] = useState<any>({});
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get("tab") || "view");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!isFlyout) setTitle(`Project Worker`);
    if (!Object.entries(projectWorker).length)
      getProjectWorkerById(forId || projectWorkerId)
        .then((res) => {
          if (res.value) {
            setProjectWorker(res.value);
            setError(false);
            if (!isFlyout) setTitle(`Project Worker (${res.value.id})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
  });

  const resetView = () => setActiveTab("view");

  const renderTab = (projectWorker: any) => {
    switch (activeTab) {
      case "view":
        return (
          <ProjectWorkerRead
            {...projectWorker}
            isFlyout={isFlyout}
            $resetView={resetView}
          />
        );
      case "update":
        return (
          <ProjectWorkerUpdate
            {...projectWorker}
            isFlyout={isFlyout}
            $onChange={$onChange}
            $resetView={resetView}
          />
        );
      case "delete":
        return (
          <ProjectWorkerDelete
            projectWorker={projectWorker}
            isFlyout={isFlyout}
            $onChange={$onChange}
            $resetView={resetView}
          />
        );
      default:
        return <ProjectWorkerRead {...projectWorker} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(projectWorker).length) {
    return (
      <>
        <ProjectWorkerDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(projectWorker)}</TabContent>
        </ProjectWorkerDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
