import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ManufacturerDetailsContainer } from "./ManufacturersDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getManufacturerById } from "../../services/manufacturerService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import ManufacturerRead from "../../components/ManufacturerRead/ManufacturerRead";
import ManufacturerUpdate from "../../components/ManufacturerUpdate/ManufacturerUpdate";
import ManufacturerDelete from "../../components/ManufacturerDelete/ManufacturerDelete";

export const ManufacturerDetails = () => {
  let { manufacturerId } = useParams();
  const [manufacturer, setManufacturer] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Manufacturer`);
    if (!Object.entries(manufacturer).length && manufacturerId)
      getManufacturerById(manufacturerId)
        .then((res) => {
          if (res.value) {
            setManufacturer(res.value);
            setError(false);
            setTitle(`Manufacturer (${res.value.manufacturerName})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
  });

  const resetView = () => setActiveTab("view");

  const renderTab = (manufacturer: any) => {
    switch (activeTab) {
      case "view":
        return <ManufacturerRead {...manufacturer} />;
      case "update":
        return <ManufacturerUpdate {...manufacturer} $resetView={resetView} />;
      case "delete":
        return (
          <ManufacturerDelete
            manufacturer={manufacturer}
            $resetView={resetView}
          />
        );
      default:
        return <ManufacturerRead {...manufacturer} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(manufacturer).length) {
    return (
      <>
        <ManufacturerDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(manufacturer)}</TabContent>
        </ManufacturerDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
