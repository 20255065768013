import React from "react";
import { Input } from "reactstrap";
import styled from "styled-components";
import { colors } from "../../../globals/globals.styles";

export const CurrentAutoSchedulesTableWrapper = styled.div`
   {
    margin-top: 20px;
    tbody tr:nth-child(odd) {
      background: ${colors.offWhiteGreen} !important;
    }
  }
`;
export const SearchWrapper = styled.div`
   {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
`;

export const SearchInput = styled(Input)`
   {
    width: 33%;
  }
`;
