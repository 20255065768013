import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupProjects = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/projects?term=${searchTerm}`);

export const getProjects = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.projects;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/projects${filterString}`);
};

export const getProjectsForTypeahead = async (searchTerm: string) => {
  const filterString = `?project_name[contains]=${searchTerm}`;
  return await protectedFetch(`${API_ROOT}/projects${filterString}`);
};

export const getProjectById = async (projectId: string) =>
  await protectedFetch(`${API_ROOT}/projects/${projectId}`);

export const createProject = async (newProject: any) => {
  return await protectedFetch(`${API_ROOT}/projects`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newProject),
  });
};

export const updateProject = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/projects/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteProject = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/projects/${id}`, {
    method: "DELETE",
  });
};

export const bulkUpdateDatesAndWorkers = async (updates: any) => {
  return await protectedFetch(
    `${API_ROOT}/projects/bulkUpdateDatesAndWorkers`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":
          "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      },
      body: JSON.stringify(updates),
    }
  );
};

export const bulkUpdateShiftInvitesForWorker = async (updates: any) => {
  return await protectedFetch(
    `${API_ROOT}/projects/bulkUpdateShiftInvitesForWorker`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":
          "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      },
      body: JSON.stringify(updates),
    }
  );
};
