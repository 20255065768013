import React, { useState } from "react";
import { ProjectSiteRequirementsContainer } from "./ProjectSiteRequirements.styles";
import { ResourceNavbar } from "../../components/ResourceNavbar/ResourceNavbar";
import { TabContent } from "reactstrap";
import { ProjectSiteRequirementCreate } from "../../components/ProjectSiteRequirementCreate/ProjectSiteRequirementCreate";
import { ProjectSiteRequirementIndex } from "../../components/ProjectSiteRequirementIndex/ProjectSiteRequirementIndex";

export const ProjectSiteRequirements = () => {
  const [activeTab, setActiveTab] = useState("index");

  const renderTab = () => {
    switch (activeTab) {
      case "index":
        return <ProjectSiteRequirementIndex />;
      case "create":
        return <ProjectSiteRequirementCreate />;
      case "export":
        return <ProjectSiteRequirementIndex />;
      default:
        return <ProjectSiteRequirementIndex />;
    }
  };

  return (
    <>
      <ProjectSiteRequirementsContainer>
        <ResourceNavbar
          activeTab={activeTab}
          passActiveTab={(newValue: string) => setActiveTab(newValue)}
        />
        <TabContent>{renderTab()}</TabContent>
      </ProjectSiteRequirementsContainer>
    </>
  );
};
