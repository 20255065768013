import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

interface WorkerActivity {
  month_year: string;
  workers_with_completed_shifts: number;
}

interface WorkerActivityGraphProps {
  data: WorkerActivity[];
}

const WorkerActivityGraph: React.FC<WorkerActivityGraphProps> = ({ data }) => {
  const last12MonthsData = data.slice(0, 12).reverse();

  const labels = last12MonthsData.map((item) => item.month_year);
  const datasetData = last12MonthsData.map(
    (item) => item.workers_with_completed_shifts
  );

  const chartData = {
    labels,
    datasets: [
      {
        label: "Workers with Completed Shifts",
        data: datasetData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return <Line options={options} data={chartData} />;
};

export default WorkerActivityGraph;
