import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  TabPane,
} from "reactstrap";
import UserLookupModal from "../UserLookupModal/UserLookupModal";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  ProjectUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
  Manufacturers,
  ManufacturerSelections,
  ManufacturerChip,
  AvailableManufacturers,
  ChipContainer,
  SiteRequirements,
  SiteRequirementSelections,
  AvailableSiteRequirements,
  DatesAndWorkers,
} from "./ProjectUpdate.styles";
import isEmpty from "validator/lib/isEmpty";
import isInt from "validator/lib/isInt";
import {
  bulkUpdateDatesAndWorkers,
  updateProject,
} from "../../services/projectService";
import BigSpinner from "../BigSpinner/BigSpinner";
import MarketLookupModal from "../MarketLookupModal/MarketLookupModal";
import ForemanLookupModal from "../ForemanLookupModal/ForemanLookupModal";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { getManufacturers } from "../../services/manufacturerService";
import {
  bulkDeleteProductLine,
  createProductLine,
} from "../../services/productLineService";
import { getSiteRequirements } from "../../services/siteRequirementService";
import {
  bulkDeleteSiteRequirement,
  createProjectSiteRequirement,
} from "../../services/projectSiteRequirementService";
import BulkDatesAndWorkers from "./BulkDatesAndWorkers/BulkDatesAndWorkers";
import { BiUserCircle } from "react-icons/bi";
import BulkWorkerShiftInviteUpdateModal from "./BulkWorkerShiftInviteUpdateModal/BulkWorkerShiftInviteUpdateModal";
import { isNumeric } from "../../globals/helpers";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ProjectUpdate = ({
  id,
  user,
  userId,
  projectName,
  referenceNumber,
  description,
  address,
  unit,
  pocName,
  pocEmail,
  pocPhone,
  otherSiteRequirements,
  siteRequirement,
  otherManufacturer,
  totalCostPerHourCents,
  totalCostPerHourCurrency,
  totalHours,
  badgingRequired,
  badgingRequirements,
  cancelledAt,
  projectFilledAt,
  market,
  startEqProjectAddress,
  wantsReplacements,
  foreman,
  productLines,
  projectSiteRequirements,
  projectDates,
  projectInvites,
  extraShiftInvites,
  preferredWorkers,
  requiredWorkers,
  $resetView,
}: any) => {
  const [formData, setFormData] = useState({
    id,
    user,
    userId,
    projectName,
    referenceNumber,
    description,
    address,
    unit,
    pocName,
    pocEmail,
    pocPhone,
    otherSiteRequirements,
    otherManufacturer,
    totalCostPerHourCents,
    totalCostPerHourCurrency,
    cancelledAt,
    projectFilledAt,
    totalHours,
    badgingRequired,
    badgingRequirements,
    market,
    startEqProjectAddress,
    siteRequirement,
    wantsReplacements,
    foreman,
    productLines,
    projectSiteRequirements,
    projectDates,
    projectInvites,
    extraShiftInvites,
    preferredWorkers,
    requiredWorkers,
  });
  const [userString, setUserString] = useState(
    `${user.firstName} ${user.lastName} (${userId})`
  );
  const [marketString, setMarketString] = useState(
    `${market?.name} | ${market?.timezone} - (${market?.id})`
  );
  const [foremanString, setForemanString] = useState(
    `${foreman?.name || "(no name set)"} (${foreman?.id})`
  );
  const [errors, setErrors] = useState<Array<any>>([]);
  const [showUserLookup, setShowUserLookup] = useState(false);
  const [showMarketLookup, setShowMarketLookup] = useState(false);
  const [showForemanLookup, setShowForemanLookup] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);
  const [datesAndWorkers, setDatesAndWorkers] = useState<any>({});
  const [allManufacturers, setAllManufacturers] = useState<Array<any>>([]);
  const [availableManufacturers, setAvailableManufacturers] = useState<
    Array<any>
  >([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState<
    Array<any>
  >([]);
  const [allSiteRequirements, setAllSiteRequirements] = useState<Array<any>>(
    []
  );
  const [availableSiteRequirements, setAvailableSiteRequirements] = useState<
    Array<any>
  >([]);
  const [selectedSiteRequirements, setSelectedSiteRequirements] = useState<
    Array<any>
  >([]);
  const [showUserBulkUpdate, setShowUserBulkUpdate] = useState(false);
  const [initialProjectSiteRequirements] = useState(
    projectSiteRequirements.map((item: any) => item.siteRequirement)
  );
  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const toggleState = (e: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [e.target.name]: !prevState[e.target.name],
    }));
    if (!formModified) setFormModified(true);
  };

  const passDatesAndWorkers = (changes: any) => {
    let temp: any = [];
    for (const action in changes) {
      const { operation, type, data } = changes[action];
      const thisAction = action;

      if (data.id != "NEW") {
        temp.push(changes[action]);
      }
      if (data.id == "NEW") {
        if (type == "DATE" && operation == "CREATE") {
          let childWorkers = [];
          for (const action in changes) {
            if (thisAction == changes[action].data.parentUUID) {
              const { filledAmount, totalAmount, workerTypeId } =
                changes[action].data;

              childWorkers.push({
                filledAmount,
                totalAmount,
                workerTypeId,
              });
            }
          }
          changes[action].data.childWorkers = childWorkers;
          temp.push(changes[action]);
        }
      }
      if (
        type == "WORKER" &&
        operation == "CREATE" &&
        data.projectDateId != "NEW"
      )
        temp.push(changes[action]);
    }
    temp = temp.map((t: any) => {
      if (t.operation === "UPDATE" && t.type === "WORKER") {
        t.data.totalAmount = Number(t.data.totalAmount);
        t.data.filledAmount = Number(t.data.filledAmount);
      }
      delete t.uuid;
      delete t.data.uuid;
      delete t.data.parentUUID;
      return t;
    });

    setDatesAndWorkers(temp);
  };

  const setUser = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        userId: String(id),
      }));
      setUserString(name || "");
      if (!formModified) setFormModified(true);
    }
    setShowUserLookup(false);
  };

  const setForeman = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        foremanId: String(id),
      }));
      setForemanString(name || "");
      if (!formModified) setFormModified(true);
    }
    setShowForemanLookup(false);
  };

  const setMarket = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        marketId: String(id),
      }));
      setMarketString(name || "");
    }
    setShowMarketLookup(false);
  };

  const formatForDatetimeLocal = (date: string) =>
    date ? date.split(".")[0] : "";

  const selectManufacturer = (manufacturer: any) => {
    createProductLine({ projectId: id, manufacturerId: manufacturer.id }).then(
      (res) => {
        setSelectedManufacturers([...selectedManufacturers, manufacturer]);
        setAvailableManufacturers(
          availableManufacturers.filter((sm: any) => sm.id != manufacturer.id)
        );
      }
    );
  };

  const removeManufacturer = (manufacturer: any) => {
    bulkDeleteProductLine(id, manufacturer.id).then((res) => {
      setAvailableManufacturers([...availableManufacturers, manufacturer]);
      setSelectedManufacturers(
        selectedManufacturers.filter((sm: any) => sm.id != manufacturer.id)
      );
    });
  };

  const selectSiteRequirement = (siteRequirement: any) => {
    setSelectedSiteRequirements([...selectedSiteRequirements, siteRequirement]);
    setAvailableSiteRequirements(
      availableSiteRequirements.filter((sm: any) => sm.id != siteRequirement.id)
    );
  };

  const removeSiteRequirement = (siteRequirement: any) => {
    setAvailableSiteRequirements([
      ...availableSiteRequirements,
      siteRequirement,
    ]);
    setSelectedSiteRequirements(
      selectedSiteRequirements.filter((sm: any) => sm.id != siteRequirement.id)
    );
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    const {
      projectName,
      referenceNumber,
      address,
      userId,
      preferredWorkers,
      requiredWorkers,
    }: any = formData;
    let foundErrors = [];
    if (!isInt(userId || "")) foundErrors.push("userId");
    if (isEmpty(projectName || "")) foundErrors.push("projectName");
    if (isEmpty(referenceNumber || "")) foundErrors.push("referenceNumber");
    if (isEmpty(address || "")) foundErrors.push("address");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let tempForm = formData;

    if (tempForm.cancelledAt && !cancelledAt) {
      tempForm.cancelledAt = new Date(
        tz(tempForm.cancelledAt, market?.timezone)
      );
    }

    if (tempForm.projectFilledAt && tempForm.projectFilledAt != projectFilledAt)
      tempForm.projectFilledAt = new Date(
        tz(projectFilledAt, market?.timezone)
      ).toUTCString();

    setProcessing(true);
    let commands = [];
    commands.push(updateProject(id, tempForm));
    if (Object.keys(datesAndWorkers).length) {
      commands.push(bulkUpdateDatesAndWorkers(datesAndWorkers));
    }
    //Work out if we have site requirements to add or remove
    const siteRequirementsToAdd = selectedSiteRequirements.filter(
      (ssr: any) => {
        const match = initialProjectSiteRequirements.find(
          (ipsr: any) => ipsr.id === ssr.id
        );
        return !match;
      }
    );
    const siteRequirementsToRemove = initialProjectSiteRequirements.filter(
      (ipsr: any) => {
        const match = selectedSiteRequirements.find(
          (ssr: any) => ssr.id === ipsr.id
        );
        return !match;
      }
    );
    siteRequirementsToAdd.forEach((sr: any) =>
      commands.push(
        createProjectSiteRequirement({
          projectId: id,
          siteRequirementId: sr.id,
        })
      )
    );
    siteRequirementsToRemove.forEach((sr: any) =>
      commands.push(bulkDeleteSiteRequirement(id, sr.id))
    );
    const responses = await Promise.all(commands);
    let errors = [];
    if (responses.some((response: any) => response.error)) {
      //return any errors
      errors = responses.map((res: any) => res.error).filter((el: any) => el);
    }
    console.log(errors);
    if (errors.length) {
      alert(`Project update had issues: ${errors.join(", ")}`);
      setErrors(errors);
    } else {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    }
    setProcessing(false);
  };

  useEffect(() => {
    if (!formModified) setFormModified(true);
  }, [datesAndWorkers]);

  useEffect(() => {
    getManufacturers().then((res: any) => {
      let available = res.value.map((pl: any) => ({
        id: pl.id,
        name: pl.manufacturerName,
      }));
      let selected = productLines.map((pl: any) => ({
        id: pl.manufacturer.id,
        name: pl.manufacturer.manufacturerName,
      }));
      setSelectedManufacturers(selected);
      setAvailableManufacturers(available);
      setAllManufacturers(available);
    });

    getSiteRequirements().then((res: any) => {
      let allSiteRequirements = res.value.map((sr: any) => ({
        id: sr.id,
        name: sr.name,
      }));
      let selectedSiteRequirements = projectSiteRequirements.map(
        (psr: any) => ({
          id: psr.siteRequirement.id,
          name: psr.siteRequirement.name,
        })
      );
      const availableSiteRequirements = allSiteRequirements.filter(
        (sr: any) => {
          const sel = selectedSiteRequirements.find(
            (ssr: any) => ssr.id === sr.id
          );
          return !sel;
        }
      );
      setSelectedSiteRequirements(selectedSiteRequirements);
      setAvailableSiteRequirements(availableSiteRequirements);
      setAllSiteRequirements(allSiteRequirements);
    });
  }, []);

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <ProjectUpdateContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Input
            name="id"
            type="text"
            value={formData.id}
            bsSize="sm"
            readOnly={true}
          />
        </Group>
        <Group>
          <Title>User (ID)</Title>
          <InputGroup>
            <Input
              type="text"
              placeholder="Select the user who will own this >"
              invalid={errors.includes("userId")}
              value={userString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowUserLookup(true)}>
              Select User
            </Button>
          </InputGroup>
        </Group>
        {errors.includes("userId") && <ErrorText>Invalid User</ErrorText>}
        <UserLookupModal isOpen={showUserLookup} callback={setUser} />
        <Group>
          <Title>Business</Title>
          <Input
            type="text"
            value={`${user.business.businessName} (${user.business.id})`}
            readOnly={true}
          />
        </Group>
        <Group>
          <Title>Project Name</Title>
          <Input
            name="projectName"
            type="text"
            value={formData.projectName}
            onChange={onChange}
            invalid={errors.includes("projectName")}
          />
        </Group>
        {errors.includes("projectName") && (
          <ErrorText>Invalid Project Name</ErrorText>
        )}
        <Group>
          <Title>Reference Number</Title>
          <Input
            name="referenceNumber"
            type="text"
            value={formData.referenceNumber}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>Description</Title>
          <Input
            name="description"
            type="textarea"
            value={formData.description}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>Address</Title>
          <Input
            name="address"
            type="text"
            value={formData.address}
            onChange={onChange}
            invalid={errors.includes("address")}
          />
        </Group>
        {errors.includes("address") && <ErrorText>Invalid Address</ErrorText>}
        <Group>
          <Title>Unit</Title>
          <Input
            name="unit"
            type="text"
            value={formData.unit}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>POC Name</Title>
          <Input
            name="pocName"
            type="text"
            value={formData.pocName}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>POC Email</Title>
          <Input
            name="pocEmail"
            type="text"
            value={formData.pocEmail}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>POC Phone</Title>
          <Input
            name="pocPhone"
            type="text"
            value={formData.pocPhone}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>Site Requirement</Title>
          <Input
            name="siteRequirement"
            type="textarea"
            value={formData.siteRequirement}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>Other Manufacturer</Title>
          <Input
            name="otherManufacturer"
            type="text"
            value={formData.otherManufacturer}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>Total cost per hour cents</Title>
          <Input
            name="totalCostPerHourCents"
            type="text"
            value={formData.totalCostPerHourCents}
            onChange={onChange}
            invalid={errors.includes("totalCostPerHourCents")}
          />
        </Group>
        {errors.includes("totalCostPerHourCents") && (
          <ErrorText>Invalid Cost</ErrorText>
        )}
        <Group>
          <Title>Total cost per hour currency</Title>
          <Input
            name="totalCostPerHourCurrency"
            type="text"
            value={formData.totalCostPerHourCurrency}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>Total Hours</Title>
          <Input
            name="totalHours"
            type="text"
            value={formData.totalHours}
            onChange={onChange}
            invalid={errors.includes("totalHours")}
          />
        </Group>
        {errors.includes("totalHours") && <ErrorText>Invalid Hours</ErrorText>}
        <Group>
          <Title>Badging Required?</Title>
          <Input
            name="badgingRequired"
            type="checkbox"
            checked={formData.badgingRequired}
            onChange={toggleState}
          />
        </Group>
        <Group>
          <Title>Bading Requirements</Title>
          <Input
            name="badgingRequirements"
            type="textarea"
            value={formData.badgingRequirements}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>Other Site Requirements</Title>
          <Input
            name="otherSiteRequirements"
            type="text"
            value={formData.otherSiteRequirements}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>Market</Title>
          <InputGroup>
            <Input
              type="text"
              placeholder="Select Market >"
              invalid={errors.includes("marketId")}
              value={marketString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowMarketLookup(true)}>
              Select Market
            </Button>
          </InputGroup>
        </Group>
        {errors.includes("marketId") && <ErrorText>Invalid Market</ErrorText>}
        <MarketLookupModal isOpen={showMarketLookup} callback={setMarket} />
        <Group>
          <Title>Cancelled at:</Title>
          <Input
            name="cancelledAt"
            type="datetime-local"
            value={formatForDatetimeLocal(formData.cancelledAt)}
            onChange={onChange}
            invalid={errors.includes("cancelledAt")}
          />
        </Group>
        {errors.includes("cancelledAt") && <ErrorText>Invalid Date</ErrorText>}
        <Group>
          <Title>Filled at:</Title>
          <Input
            name="projectFilledAt"
            type="datetime-local"
            value={formatForDatetimeLocal(formData.projectFilledAt)}
            onChange={onChange}
            invalid={errors.includes("projectFilledAt")}
          />
        </Group>
        {errors.includes("projectFilledAt") && (
          <ErrorText>Invalid Date</ErrorText>
        )}
        <Group>
          <Title>Start eq project address?</Title>
          <Input
            name="startEqProjectAddress"
            type="checkbox"
            checked={formData.startEqProjectAddress}
            onChange={toggleState}
          />
        </Group>
        <Group>
          <Title>Wants Replacements?</Title>
          <Input
            name="wantsReplacements"
            type="checkbox"
            checked={formData.wantsReplacements}
            onChange={toggleState}
          />
        </Group>
        <Group>
          <Title>Foreman</Title>
          <InputGroup>
            <Input
              type="text"
              placeholder="Select a Foreman >"
              invalid={errors.includes("foremanId")}
              value={foremanString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowForemanLookup(true)}>
              Select Foreman
            </Button>
          </InputGroup>
        </Group>
        {errors.includes("foremanId") && <ErrorText>Invalid Foreman</ErrorText>}
        <ForemanLookupModal
          userId={userId}
          isOpen={showForemanLookup}
          callback={setForeman}
        />
        <DatesAndWorkers>
          <Title>Project Dates &amp; Workers</Title>
          <br />
          <BulkDatesAndWorkers
            projectId={id}
            timezone={market?.timezone}
            projectDates={projectDates}
            market={market}
            $reportChanges={passDatesAndWorkers}
          />
        </DatesAndWorkers>
        <Button onClick={() => setShowUserBulkUpdate(!showUserBulkUpdate)}>
          <BiUserCircle />
          Bulk Worker Shift Invite Update
        </Button>
        <BulkWorkerShiftInviteUpdateModal
          isOpen={showUserBulkUpdate}
          $toggle={() => setShowUserBulkUpdate(!showUserBulkUpdate)}
          projectId={id}
          timezone={market?.timezone}
          workers={extraShiftInvites.map((si: any) => {
            const workerId = si.projectInvite.worker.id;
            const {
              id: userId,
              firstName,
              lastName,
            } = si.projectInvite.worker.user;

            return {
              workerId,
              userId,
              firstName,
              lastName,
            };
          })}
        />
        <Group>
          <Title>Required Workers</Title>
          <Input
            name="requiredWorkers"
            type="text"
            value={formData.requiredWorkers}
            invalid={errors.includes("requiredWorkers")}
            onChange={onChange}
          />
        </Group>
        <Group>
          <Title>Preferred Workers</Title>
          <Input
            name="preferredWorkers"
            type="text"
            value={formData.preferredWorkers}
            invalid={errors.includes("preferredWorkers")}
            onChange={onChange}
          />
        </Group>
        <Manufacturers>
          <Title>Manufacturers</Title>
          <ChipContainer>
            <ManufacturerSelections>
              {!!allManufacturers.length &&
                selectedManufacturers.map((m: any, index: any) => (
                  <ManufacturerChip
                    key={index}
                    color="primary"
                    size="sm"
                    onClick={() => {
                      removeManufacturer(m);
                    }}
                  >
                    <AiOutlineMinusCircle size={"18"} />
                    {allManufacturers.filter((am) => m.id == am.id)[0].name}
                  </ManufacturerChip>
                ))}
            </ManufacturerSelections>
            <hr />
            <AvailableManufacturers>
              {availableManufacturers.map((m: any, index: any) => (
                <ManufacturerChip
                  key={index}
                  color="primary"
                  size="sm"
                  outline
                  onClick={() => {
                    selectManufacturer(m);
                  }}
                >
                  <AiOutlinePlusCircle size={"18"} />
                  {m.name}
                </ManufacturerChip>
              ))}
            </AvailableManufacturers>
          </ChipContainer>
        </Manufacturers>
        <SiteRequirements>
          <Title>Site Requirements</Title>
          <ChipContainer>
            <SiteRequirementSelections>
              {!!allSiteRequirements.length &&
                selectedSiteRequirements.map((ssr: any, index: any) => (
                  <ManufacturerChip
                    data-testid="selected-site-requirement"
                    key={index}
                    size="sm"
                    onClick={() => {
                      removeSiteRequirement(ssr);
                    }}
                  >
                    <AiOutlineMinusCircle size={"18"} />
                    {
                      allSiteRequirements.filter((sr) => ssr.id == sr.id)[0]
                        .name
                    }
                  </ManufacturerChip>
                ))}
            </SiteRequirementSelections>
            <hr />
            <AvailableSiteRequirements>
              {availableSiteRequirements.map((asr: any, index: any) => (
                <ManufacturerChip
                  data-testid="available-site-requirement"
                  key={index}
                  size="sm"
                  outline
                  onClick={() => {
                    selectSiteRequirement(asr);
                  }}
                >
                  <AiOutlinePlusCircle size={"18"} />
                  {asr.name}
                </ManufacturerChip>
              ))}
            </AvailableSiteRequirements>
          </ChipContainer>
        </SiteRequirements>
        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.includes("$server") && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
      </TabPane>
    </ProjectUpdateContainer>
  );
};

export default ProjectUpdate;
