import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ForemenIndexContainer, TableLimited } from "./ForemenIndex.styles";
import { getForemen } from "../../services/foremenService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { Foreman } from "../../models/Foreman";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

export const ForemenIndex = () => {
  const [foremen, setForemen] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["foremen"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["foremen"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["foremen"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["foremen"].limit
  );

  useEffect(() => {
    setTitle("Foremen");
    getForemen().then((res: any) => {
      setForemen(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={Foreman.getFilterConfig()} />
      <ForemenIndexContainer>
        {!!foremen.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"foremen"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"foremen"}
                    columnName={"name"}
                    displayName={"Name"}
                  />
                  <ColumnHeadWithSort
                    index={"foremen"}
                    columnName={"phone"}
                    displayName={"Phone"}
                  />
                  <ColumnHeadWithSort
                    index={"foremen"}
                    columnName={"email"}
                    displayName={"Email"}
                  />
                  <ColumnHeadWithSort
                    index={"foremen"}
                    columnName={"userId"}
                    displayName={"User (ID)"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {foremen.map((foreman) => {
                  const { id, name, phone, email, userId, user }: any = foreman;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{name}</td>
                      <td>
                        <a href={`tel:${phone}`}>{phone}</a>
                      </td>
                      <td>
                        <a href={`mailto:${email}`}>{email}</a>
                      </td>
                      <td>
                        <NavLink to={`/manage/users/${String(userId)}`}>
                          {`${user?.firstName} ${user?.lastName} (${userId})`}
                        </NavLink>
                      </td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="foremen"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!foremen.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </ForemenIndexContainer>
    </>
  );
};
