import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  ProductLineCreateContainer,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  LookupField,
} from "./ProductLineCreate.styles";
import isInt from "validator/lib/isInt";
import { createProductLine } from "../../services/productLineService";
import ProjectLookupModal from "../ProjectLookupModal/ProjectLookupModal";
import ManufacturerLookupModal from "../ManufacturerLookupModal/ManufacturerLookupModal";
import { useNavigate } from "react-router-dom";

export const ProductLineCreate = () => {
  const [formData, setFormData] = useState({
    projectId: "",
    manufacturerId: "",
  });
  const [projectString, setProjectString] = useState("");
  const [manufacturerString, setManufacturerString] = useState("");
  const [showProjectLookup, setShowProjectLookup] = useState(false);
  const [showManufacturerLookup, setShowManufacturerLookup] = useState(false);
  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    setFormData({
      projectId: "",
      manufacturerId: "",
    });
    setProjectString("");
    setManufacturerString("");
    setErrors([]);
    setShowProjectLookup(false);
    setShowManufacturerLookup(false);
    setNewId(0);
  };

  const setProject = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectId: id,
      }));
      setProjectString(name || "");
    }
    setShowProjectLookup(false);
  };

  const setManufacturer = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        manufacturerId: id,
      }));
      setManufacturerString(name || "");
    }
    setShowManufacturerLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { projectId, manufacturerId }: any = formData;

    if (!isInt(projectId || "")) foundErrors.push("projectId");
    if (!isInt(manufacturerId || "")) foundErrors.push("manufacturerId");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let response = await createProductLine(formData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    return (
      <ProductLineCreateContainer>
        <SuccessText>New ProductLine successfully created.</SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to new ProductLine
          </Button>
        </SuccessButtonWrapper>
      </ProductLineCreateContainer>
    );
  }

  return (
    <ProductLineCreateContainer>
      <LookupField>
        <Input
          type="text"
          placeholder="Select the Project >"
          invalid={errors.indexOf("projectId") !== -1}
          value={projectString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowProjectLookup(true)}>
          Select Project
        </Button>
      </LookupField>
      {errors.indexOf("projectId") !== -1 && (
        <ErrorText>Invalid Project</ErrorText>
      )}
      <ProjectLookupModal isOpen={showProjectLookup} callback={setProject} />
      <LookupField>
        <Input
          type="text"
          placeholder="Select the Manufacturer >"
          invalid={errors.indexOf("manufacturerId") !== -1}
          value={manufacturerString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowManufacturerLookup(true)}>
          Select Manufacturer
        </Button>
      </LookupField>
      {errors.indexOf("manufacturerId") !== -1 && (
        <ErrorText>Invalid Manufacturer</ErrorText>
      )}
      <ManufacturerLookupModal
        isOpen={showManufacturerLookup}
        callback={setManufacturer}
      />
      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </ProductLineCreateContainer>
  );
};
