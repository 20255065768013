export class User {
  id: number;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  role?: string;
  status?: string;
  marketId?: number;

  constructor(newForeman: IForeman) {
    const { id, firstName, lastName, phone, email, role, status, marketId } =
      newForeman;

    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.email = email;
    this.role = role;
    this.status = status;
    this.marketId = marketId;
  }

  static getFilterConfig() {
    return {
      columns: [
        "id",
        "email",
        "firstName",
        "lastName",
        "role",
        "status",
        "marketId",
      ],
      indexToFilter: "users",
    };
  }
}

export interface IForeman {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  role: string;
  status: string;
  marketId: number;
}
