import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  WorkerCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  LookupField,
} from "./WorkerCreate.styles";
import { createWorker } from "../../services/workerService";
import { useNavigate } from "react-router-dom";
import WorkerTypeLookupModal from "../WorkerTypeLookupModal/WorkerTypeLookupModal";
import isInt from "validator/lib/isInt";
import isPostalCode from "validator/lib/isPostalCode";
import UserLookupModal from "../UserLookupModal/UserLookupModal";

export const WorkerCreate = () => {
  const [formData, setFormData] = useState({
    workerTypeId: "",
    filledAmount: "",
    totalAmount: "",
    projectDateId: "",
  });
  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();
  const [showWorkerTypeLookup, setShowWorkerTypeLookup] = useState(false);
  const [workerTypeString, setWorkerTypeString] = useState("");
  const [userString, setUserString] = useState("");
  const [showUserLookup, setShowUserLookup] = useState(false);

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const setWorkerType = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        workerTypeId: String(id),
      }));
      setWorkerTypeString(name || "");
    }
    setShowWorkerTypeLookup(false);
  };

  const setUser = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        userId: String(id),
      }));
      setUserString(name || "");
    }
    setShowUserLookup(false);
  };

  const clearForm = () => {
    setFormData({
      workerTypeId: "",
      filledAmount: "",
      totalAmount: "",
      projectDateId: "",
    });
    setErrors([]);
    setNewId(0);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { workerTypeId, zipCode, userId }: any = formData;

    if (!isInt(workerTypeId || "")) foundErrors.push("workerTypeId");
    if (!isInt(userId || "")) foundErrors.push("userId");
    if (!isPostalCode(zipCode || "", "US")) foundErrors.push("zipCode");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let response = await createWorker(formData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    return (
      <WorkerCreateContainer>
        <SuccessText>New Project Worker successfully created.</SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to new Project Worker
          </Button>
        </SuccessButtonWrapper>
      </WorkerCreateContainer>
    );
  }

  return (
    <WorkerCreateContainer>
      <LookupField>
        <Input
          type="text"
          placeholder="Select a WorkerType >"
          invalid={errors.indexOf("workerTypeId") !== -1}
          value={workerTypeString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowWorkerTypeLookup(true)}>
          Select WorkerType
        </Button>
      </LookupField>
      {errors.indexOf("workerTypeId") !== -1 && (
        <ErrorText>Invalid Worker Type</ErrorText>
      )}
      <WorkerTypeLookupModal
        isOpen={showWorkerTypeLookup}
        callback={setWorkerType}
      />
      <LookupField>
        <Input
          type="text"
          placeholder="Select the user this belongs to >"
          invalid={errors.indexOf("userId") !== -1}
          value={userString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowUserLookup(true)}>
          Select User
        </Button>
      </LookupField>
      {errors.indexOf("userId") !== -1 && <ErrorText>Invalid User</ErrorText>}
      <UserLookupModal isOpen={showUserLookup} callback={setUser} />

      <InputBox
        name="zipCode"
        type="text"
        placeholder="ZIP Code"
        onChange={onChange}
        invalid={errors.indexOf("zipCode") !== -1}
      />
      {errors.indexOf("zipCode") !== -1 && <ErrorText>Invalid Zip</ErrorText>}

      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </WorkerCreateContainer>
  );
};
