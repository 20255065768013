import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Collapse, TabPane } from "reactstrap";
import { ProjectDate } from "../../models/ProjectDate";
import { ShiftInvite } from "../../models/ShiftInvite";

import {
  Content,
  ProjectReadContainer,
  Group,
  Title,
  ExpandableHead,
  InlineBlock,
} from "./ProjectRead.styles";
import { getUploadsForProject } from "../../services/uploadService";
import TableComponent, { TableComponentProps } from "../Table/Table";
import TableColumnHeadWithSort from "../Table/TableColumnHeader";
import { Upload } from "../../models/Upload";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ProjectRead = ({
  id,
  user,
  userId,
  projectName,
  referenceNumber,
  description,
  address,
  unit,
  pocName,
  pocPhone,
  totalCostPerHourCents,
  totalCostPerHourCurrency,
  badgingRequired,
  badgingRequirements,
  market,
  startEqProjectAddress,
  wantsReplacements,
  foreman,
  productLines,
  projectSiteRequirements,
  siteRequirement,
  otherSiteRequirements,
  otherManufacturer,
  projectDates,
  projectInvites,
  extraShiftInvites,
  createdAt,
  updatedAt,
  preferredWorkers,
  requiredWorkers,
}: any) => {
  const [showNonAcceptedShifts, setShowNonAcceptedShifts] = useState(false);
  const [showAcceptedShifts, setShowAcceptedShifts] = useState(false);
  const [showProjectSiteRequirements, setShowProjectSiteRequirements] =
    useState(false);
  const [showBadgingRequirements, setShowBadgingRequirements] = useState(false);
  const [uploadUrl, setUploadUrl] = useState("");
  const [uploads, setUploads] = useState([]);
  const formatedCostPerHour = `$${((+totalCostPerHourCents || 0) / 100).toFixed(
    2
  )}`;

  useEffect(() => {
    getUploadsForProject(id).then((result: any) => {
      setUploads(result.value);
      setUploadUrl(badgingRequirements);
    });
  }, []);
  const formattedManufacturers = () => {
    if (!productLines || !productLines.length) return [];

    return productLines.map((pl: any, index: number) => {
      return (
        <InlineBlock key={index}>
          <NavLink to={`/manage/manufacturers/${String(pl.manufacturer.id)}`}>
            {pl.manufacturer.manufacturerName}&nbsp; ({pl.manufacturer.id})
          </NavLink>
          &nbsp;
        </InlineBlock>
      );
    });
  };

  const formattedProjectSiteRequirements = () => {
    if (!projectSiteRequirements || !projectSiteRequirements.length) return [];
    return projectSiteRequirements.map((psr: any, index: number) => {
      return (
        <InlineBlock key={index}>
          <NavLink
            to={`/manage/projectSiteRequirements/${String(
              psr.siteRequirement.id
            )}`}
          >
            {psr.siteRequirement.name}&nbsp; ({psr.siteRequirement.id})
          </NavLink>
          &nbsp;
        </InlineBlock>
      );
    });
  };

  const formattedProjectDates = () => {
    if (!projectDates || !projectDates.length) return [];

    return projectDates.map((pd: any, index: number) => {
      return (
        <InlineBlock key={index}>
          <NavLink to={`/manage/projectDates/${String(pd.id)}`}>
            {ProjectDate.getShiftStringFrom(
              pd.startsAt,
              pd.endsAt,
              pd.timezone
            )}
          </NavLink>
          &nbsp;
        </InlineBlock>
      );
    });
  };

  //get bulk data for latter tasks
  const allProjectWorkers: any = [];
  const allWorkerTypes: any = [];
  const allWorkers = projectInvites.map((pi: any) => pi.worker);

  projectDates.forEach((pd: any) => {
    pd.projectWorkers.forEach((pw: any) => {
      allProjectWorkers.push(pw);
      allWorkerTypes.push(pw.workerType);
    });
  });

  const formattedProjectWorkers = () => {
    return allProjectWorkers.map((projectWorker: any, index: number) => {
      const { id, projectDate, workerType, filledAmount, totalAmount }: any =
        projectWorker;

      const startsAtParsed = tz(
        projectDate.startsAt,
        "%c",
        "en_US",
        projectDate.timezone
      ).split(" ");

      const projectWorkerString = `${[
        `(${id})`,
        startsAtParsed[2],
        startsAtParsed[1],
        startsAtParsed[3] + ",",
        startsAtParsed[4].slice(0, -3),
        startsAtParsed[5],
        startsAtParsed[6],
        " | ",
        `L${workerType.level}`,
        `(${filledAmount}`,
        "/",
        `${totalAmount})`,
      ].join(" ")}`;

      return (
        <InlineBlock key={index}>
          <NavLink to={`/manage/projectWorkers/${String(id)}`}>
            {projectWorkerString}
          </NavLink>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </InlineBlock>
      );
    });
  };

  const formattedWorkerTypes = () => {
    return allWorkerTypes.map((workerType: any, index: number) => {
      const { id, market, level, positionName }: any = workerType;

      return (
        <InlineBlock key={index}>
          <NavLink to={`/manage/workerTypes/${String(id)}`}>
            {market?.name}&nbsp;
            {positionName}&nbsp; ({level})
          </NavLink>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </InlineBlock>
      );
    });
  };

  const formattedProjectInvites = () => {
    return projectInvites.map((pi: any, index: number) => {
      const {
        id,
        worker: {
          user: { firstName, lastName },
        },
      }: any = pi;

      return (
        <InlineBlock key={index}>
          <NavLink to={`/manage/projectInvites/${String(id)}`}>
            {firstName}&nbsp;
            {lastName}({id})
          </NavLink>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </InlineBlock>
      );
    });
  };

  const formattedWorkers = () => {
    return allWorkers.map((worker: any, index: number) => {
      const {
        id,
        user: { firstName, lastName },
        workerType,
      }: any = worker;

      return (
        <InlineBlock key={index}>
          <NavLink to={`/manage/workers/${String(id)}`}>
            {firstName}&nbsp;
            {lastName}&nbsp;|&nbsp;
            {workerType?.market?.name}&nbsp;
            {workerType?.positionName}&nbsp; ({workerType?.level})
          </NavLink>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </InlineBlock>
      );
    });
  };

  const getLocalTimeFor = (utcTime: any) => {
    const utcTimeParsed = tz(
      utcTime,
      "%c",
      "en_US",
      market ? market.timezone : "America/Phoenix"
    ).split(" ");

    return `${[
      utcTimeParsed[2],
      utcTimeParsed[1],
      utcTimeParsed[3] + ",",
      utcTimeParsed[4].slice(0, -3),
      utcTimeParsed[5],
      utcTimeParsed[6],
    ].join(" ")}`;
  };

  const getUnfilledCount = () => {
    const allProjectWorkers = projectDates
      .map((pd: any) => pd.projectWorkers.map((pw: any) => pw))
      .flat();
    let filled = 0;
    let total = 0;

    allProjectWorkers.forEach((pw: any) => {
      filled += pw.shiftInvites.filter((si: any) => si.status == 1).length;
      total += pw.totalAmount;
    });

    return total - filled;
  };

  const renderShiftInvites = (shiftInvites: any) => {
    return shiftInvites.map((shiftInvite: any, index: number) => {
      const { startsAt, timezone } = shiftInvite.projectWorker.projectDate;
      const { level } = shiftInvite.projectWorker.workerType;
      const { firstName, lastName } = shiftInvite.projectInvite.worker.user;

      const startsAtParsed = tz(startsAt, "%c", "en_US", timezone).split(" ");

      const shiftInviteString = `${[
        startsAtParsed[2],
        startsAtParsed[1] + ",",
        startsAtParsed[4].slice(0, -3),
        startsAtParsed[5],
        " | ",
        `${shiftInvite.id}`,
        " | ",
        `${firstName} ${lastName}`,
        " | ",
        "L" + level,
        " | ",
        ShiftInvite.formatStatus(shiftInvite.status),
      ].join(" ")}`;

      return (
        <InlineBlock key={index}>
          <NavLink to={`/manage/shiftInvites/${String(shiftInvite.id)}`}>
            {shiftInviteString}
          </NavLink>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </InlineBlock>
      );
    });
  };

  const UploadLink = (upload: any) => {
    const { uploadableType, uploadableId, id } = upload;
    let url = "https://res.cloudinary.com/steadyinstall/image/upload";
    const type = uploadableType === "TimeLog" ? "timelogs" : "projects";

    return (
      <a
        href={`${url}/${type}/${uploadableId}/files/${id}/original`}
        target="_blank"
        rel="noreferrer"
      >
        {`.../${type}/${uploadableId}/files/${id}`}
      </a>
    );
  };

  const badgingTableProps: TableComponentProps = {
    headers: [
      <TableColumnHeadWithSort
        key={"name"}
        index={"uploads"}
        columnName={"filename"}
        displayName={"File name"}
        sort={false}
      />,
      <TableColumnHeadWithSort
        key={"type"}
        index={"uploads"}
        columnName={"filetype"}
        displayName={"File type"}
        sort={false}
      />,
      <TableColumnHeadWithSort
        key={"url"}
        index={"uploads"}
        columnName={"url"}
        displayName={"Upload url"}
        sort={false}
      />,
    ],
    body: uploads.map((ul: any, index: number) => {
      return [
        <div key={`name-${index}`}>{ul.fileName}</div>,
        <div key={`type-${index}`}>{ul.fileContentType}</div>,
        <UploadLink key={`url-${index}`} {...ul} />,
      ];
    }),
  };

  const renderBadgingRequirements = () => {
    return (
      <InlineBlock>
        <TableComponent {...badgingTableProps} />
      </InlineBlock>
    );
  };

  return (
    <ProjectReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>User</Title>
          <Content>
            <NavLink to={`/manage/users/${String(userId)}`}>
              {user.firstName}&nbsp;
              {user.lastName}&nbsp; ({userId})
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Business</Title>
          <Content>
            <NavLink to={`/manage/businesses/${String(user.business.id)}`}>
              {`${user.business.businessName || "(no name set)"} (${
                user.business.id
              })`}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Project Name</Title>
          <Content>{projectName}</Content>
        </Group>
        <Group>
          <Title>Reference Number</Title>
          <Content>{referenceNumber}</Content>
        </Group>
        <Group>
          <Title>Description</Title>
          <div>{description}</div>
        </Group>
        <Group>
          <Title>Address</Title>
          <Content>{address}</Content>
        </Group>
        <Group>
          <Title>Unit</Title>
          <Content>{unit}</Content>
        </Group>
        <Group>
          <Title>POC Name</Title>
          <Content>{pocName}</Content>
        </Group>
        <Group>
          <Title>POC Phone</Title>
          <Content>{pocPhone}</Content>
        </Group>
        <Group>
          <Title>Total Cost Per Hour</Title>
          <Content>{formatedCostPerHour}</Content>
        </Group>
        <Group>
          <Title>Cost Currency</Title>
          <Content>{totalCostPerHourCurrency}</Content>
        </Group>
        <Group>
          <Title>Badging Required?</Title>
          <Content>{badgingRequired ? "Yes" : "No"}</Content>
        </Group>
        <Group>
          <Title>Market</Title>
          <Content>{market?.name}</Content>
        </Group>
        <Group>
          <Title>Wants Replacements?</Title>
          <Content>{wantsReplacements ? "Yes" : "No"}</Content>
        </Group>
        <Group>
          <Title>Start eq project address</Title>
          <Content>{startEqProjectAddress ? "Yes" : "No"}</Content>
        </Group>
        <Group>
          <Title>Start eq project address</Title>
          <Content>{startEqProjectAddress ? "Yes" : "No"}</Content>
        </Group>
        <Group>
          <Title>Created At</Title>
          <Content>{getLocalTimeFor(createdAt)}</Content>
        </Group>
        <Group>
          <Title>Updated At</Title>
          <Content>{getLocalTimeFor(updatedAt)}</Content>
        </Group>
        <Group>
          <Title>Foreman</Title>
          <Content>
            <NavLink to={`/manage/foremen/${String(foreman?.id)}`}>
              {foreman?.name}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Shifts unfilled</Title>
          <Content>{getUnfilledCount()}</Content>
        </Group>
        <Group>
          <Title>Manufacturers</Title>
          <Content>{formattedManufacturers()}</Content>
        </Group>
        <Group>
          <Title>Site Requirements</Title>
          <Content>{siteRequirement}</Content>
        </Group>
        <Group>
          <Title>Other Manufacturers</Title>
          <Content>{otherManufacturer}</Content>
        </Group>
        <Group>
          <Title>Other Site Requirements</Title>
          <Content>{otherSiteRequirements}</Content>
        </Group>
        <div>
          <Title>Project Dates</Title>
          <Content>{formattedProjectDates()}</Content>
        </div>
        <hr />
        <div>
          <Title>Required workers</Title>
          <div>{requiredWorkers}</div>
        </div>
        <hr />
        <br />
        <div>
          <Title>Preferred Workers</Title>
          <div>{preferredWorkers}</div>
        </div>
        <hr />
        <br />
        <div>
          <Title>Project Workers</Title>
          <div>{formattedProjectWorkers()}</div>
        </div>
        <hr />
        <br />
        <div>
          <Title>Worker Types</Title>
          <div>{formattedWorkerTypes()}</div>
        </div>
        <hr />
        <br />
        <div>
          <Title>Project Invites</Title>
          <div>{formattedProjectInvites()}</div>
        </div>
        <hr />
        <br />
        <div>
          <Title>Workers</Title>
          <div>{formattedWorkers()}</div>
        </div>
        <hr />
        <br />
        <div>
          <ExpandableHead>
            <div>Accepted Shift Invites</div>
            <Button
              onClick={() => setShowAcceptedShifts(!showAcceptedShifts)}
              size="sm"
            >
              {showAcceptedShifts ? "Hide" : "Show"}
            </Button>
          </ExpandableHead>
          <Collapse isOpen={showAcceptedShifts}>
            {renderShiftInvites(
              extraShiftInvites.filter((si: any) => si.status == 1)
            )}
          </Collapse>
        </div>
        <hr />
        <br />
        <div>
          <ExpandableHead>
            <div>Non-accepted Shift Invites</div>
            <Button
              onClick={() => setShowNonAcceptedShifts(!showNonAcceptedShifts)}
              size="sm"
            >
              {showNonAcceptedShifts ? "Hide" : "Show"}
            </Button>
          </ExpandableHead>
          <Collapse isOpen={showNonAcceptedShifts}>
            {renderShiftInvites(
              extraShiftInvites.filter((si: any) => si.status != 1)
            )}
          </Collapse>
        </div>
        <hr />
        <br />
        <ExpandableHead>
          <div>Project Site Requirements</div>
          <Button
            onClick={() =>
              setShowProjectSiteRequirements(!showProjectSiteRequirements)
            }
            size="sm"
          >
            {showProjectSiteRequirements ? "Hide" : "Show"}
          </Button>
        </ExpandableHead>
        <Collapse isOpen={showProjectSiteRequirements}>
          {formattedProjectSiteRequirements()}
        </Collapse>
        <hr />
        <br />
        <div>
          <ExpandableHead>
            <div>Badging Requirements</div>
            <Button
              onClick={() =>
                setShowBadgingRequirements(!showBadgingRequirements)
              }
              size="sm"
            >
              {showBadgingRequirements ? "Hide" : "Show"}
            </Button>
          </ExpandableHead>
          <Collapse isOpen={showBadgingRequirements}>
            {renderBadgingRequirements()}
          </Collapse>
        </div>
        <hr />
        <br />
      </TabPane>
    </ProjectReadContainer>
  );
};

export default ProjectRead;
