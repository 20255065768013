import React from "react";
import { TabPane } from "reactstrap";
import { NavLink } from "react-router-dom";

import {
  Content,
  WorkerBlackListReadContainer,
  Group,
  Title,
} from "./WorkerBlackListRead.styles";

const WorkerBlackListRead = ({
  id,
  business,
  worker,
  createdAt,
  updatedAt,
}: any) => {
  return (
    <WorkerBlackListReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Business</Title>
          <Content>
            <NavLink to={`/manage/businesses/${business.id}`}>
              {business.businessName}&nbsp;(
              {business.user.firstName}&nbsp;
              {business.user.lastName})
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Worker</Title>
          <Content>
            {" "}
            <NavLink to={`/manage/workers/${worker.id}`}>
              {worker.user.firstName}&nbsp;
              {worker.user.lastName}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Created At</Title>
          <Content>{new Date(createdAt).toUTCString()}</Content>
        </Group>
        <Group>
          <Title>Updated At</Title>
          <Content>{new Date(updatedAt).toUTCString()}</Content>
        </Group>
      </TabPane>
    </WorkerBlackListReadContainer>
  );
};

export default WorkerBlackListRead;
