import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const user: any = useAppSelector((state) => state.auth.user);

  if (!user) return <Navigate to="/login" replace />;

  return children;
};

export default RequireAuth;
