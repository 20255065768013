import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteProjectSiteRequirement } from "../../services/projectSiteRequirementService";
import BigSpinner from "../BigSpinner/BigSpinner";
import ProjectSiteRequirementRead from "../ProjectSiteRequirementRead/ProjectSiteRequirementRead";

import {
  ProjectSiteRequirementsDeleteContainer,
  DeleteButton,
  CancelButton,
  ButtonBox,
  SuccessText,
  ErrorText,
} from "./ProjectSiteRequirementDelete.styles";

const ProjectSiteRequirementsDelete = ({
  projectSiteRequirement,
  $resetView,
}: any) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const removeProjectSiteRequirement = async () => {
    setProcessing(true);

    const response = await deleteProjectSiteRequirement(
      projectSiteRequirement.id
    );

    if (!response.error) {
      setDeleteSuccess(true);
      setTimeout(() => navigate("/manage/projectSiteRequirements"), 2000);
    } else setError(true);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (deleteSuccess)
    return (
      <SuccessText>
        ProjectSiteRequirement Deleted. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <ProjectSiteRequirementsDeleteContainer>
      <h2>Are you sure you want to delete this?</h2>
      <ProjectSiteRequirementRead {...projectSiteRequirement} />
      {error && (
        <ErrorText>There was a problem deleting this. Try again.</ErrorText>
      )}
      <ButtonBox>
        <DeleteButton size="lg" onClick={removeProjectSiteRequirement}>
          Delete
        </DeleteButton>
        <CancelButton size="lg" onClick={$resetView}>
          Cancel
        </CancelButton>
      </ButtonBox>
    </ProjectSiteRequirementsDeleteContainer>
  );
};

export default ProjectSiteRequirementsDelete;
