import isInt from "validator/lib/isInt";

const tz = require("timezone/loaded"); //until Temporal JS comes out

export class TimeLog {
  static getFilterConfig() {
    return {
      columns: [
        "id",
        "clockedInAt",
        "clockedOutAt",
        "totalDurationHours",
        "tookLunch",
        "submittedAt",
        "cancelledAt",
        "hashId",
        "shiftInviteId",
      ],
      indexToFilter: "timeLogs",
    };
  }

  static parseDateWithTimezone(date: string, timezone: string) {
    const dateParsed = tz(date, "%c", "en_US", timezone).split(" ");

    return [
      dateParsed[0] + ",",
      dateParsed[2],
      dateParsed[1] + ",",
      dateParsed[4].slice(0, -3),
      dateParsed[5],
      dateParsed[6],
    ].join(" ");
  }

  static getDuration(startDate: string, endDate: string, lunchMinutes: any) {
    let checkedLunchMinutes = 0;

    if (isInt(String(lunchMinutes || ""))) checkedLunchMinutes = +lunchMinutes;

    const start: any = new Date(startDate);
    const end: any = new Date(endDate);
    const hours = (Math.abs(start - end) - lunchMinutes * 60000) / 3600000;

    return hours.toFixed(2);
  }
}

export interface ITimeLog {
  id: number;
  clockedInAt: string;
  clockedOutAt: string;
  totalDurationHours: number;
  tookLunch: boolean;
  submittedAt: string;
  cancelledAt: string;
  hashId: string;
  shiftInviteId: number;
}
