import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addOrReplaceSort } from "../../store/filterSlice";
import {
  Flex,
  SortDownIcon,
  SortIcon,
  SortUpIcon,
  TableColumnHeadWithSortContainer,
} from "./Table.styles";

const TableColumnHeadWithSort = ({
  displayName,
  columnName,
  index,
  sort,
}: any) => {
  const [sortState, setSortState] = useState("");
  const dispatch = useAppDispatch();

  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex[index].sortBy
  );

  useEffect(() => {
    if (sortBy?.column === columnName) {
      setSortState(sortBy.order);
    } else {
      setSortState("");
    }
  }, [sortBy]);

  const processSort = (sort: string) => {
    setSortState(sort);
    dispatch(
      addOrReplaceSort({
        index,
        newSort: {
          column: columnName,
          order: sort,
        },
      })
    );
  };

  const toggleSort = () => {
    switch (sortState) {
      case "":
        processSort("ASC");
        break;
      case "ASC":
        processSort("DESC");
        break;
      case "DESC":
        processSort("");
        break;
    }
  };

  const showSortIcon = () => {
    switch (sortState) {
      case "":
        return <SortIcon id={columnName} />;
      case "ASC":
        return <SortUpIcon id={columnName} />;
      case "DESC":
        return <SortDownIcon id={columnName} />;
    }
  };
  return (
    <TableColumnHeadWithSortContainer id={columnName}>
      <Flex id={columnName}>
        <span>{displayName}</span>
        {sort && <div onClick={toggleSort}>{showSortIcon()}</div>}
      </Flex>
    </TableColumnHeadWithSortContainer>
  );
};

export default TableColumnHeadWithSort;
