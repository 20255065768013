import React from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import styled from "styled-components";

export const BulkDatesAndWorkersContainer = styled.div`
   {
    padding-bottom: 10px;
  }
`;

export const TitleBar = styled.div`
   {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;

    cursor: pointer;
  }
`;

export const ExpandIcon = styled(FaChevronRight)`
   {
    font-size: 20px;
  }
`;

export const ExpandedIcon = styled(FaChevronDown)`
   {
    font-size: 20px;
  }
`;
