import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupAutoSchedulers = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/autoSchedulers?term=${searchTerm}`);

export const getAutoSchedulers = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.autoSchedulers;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/autoSchedulers${filterString}`);
};

export const getAutoSchedulerById = async (autoSchedulerId: string) =>
  await protectedFetch(`${API_ROOT}/autoSchedulers/${autoSchedulerId}`);

export const createAutoSchedule = async (newAutoSchedule: any) => {
  const {
    selectedProjectWorkers,
    intervalInMinutes,
    batchSize,
    minimumRating,
  } = newAutoSchedule;

  let tempForm = {
    projectWorkerIds: selectedProjectWorkers || [],
    intervalInMinutes,
    batchSize,
    minimumRating,
  };

  return await protectedFetch(`${API_ROOT}/autoSchedulers`, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tempForm),
  });
};

export const updateAutoScheduler = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/autoSchedulers/${id}`, {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteAutoScheduler = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/autoSchedulers/${id}`, {
    method: "DELETE",
  });
};
