import React, { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { throttle } from "throttle-debounce";
import { lookupUsers } from "../../services/userService";
import BigSpinner from "../BigSpinner/BigSpinner";
import {
  CancelWrapper,
  SearchBar,
  SearchResult,
  SearchResultText,
  SelectButton,
} from "./UserLookupModal.styles";

const UserLookupModal = ({ isOpen, callback }: any) => {
  let [users, setUsers] = useState([]);
  let [loading, setloading] = useState(false);
  let [changed, setChanged] = useState(false);

  const onChange = throttle(400, false, (e: any) => {
    const { value } = e.target;
    if (!value) return;
    if (!changed) setChanged(true);

    setloading(true);
    lookupUsers(value).then((res: any) => {
      setUsers(res.value || []);
      setloading(false);
    });
  });

  const makeSelection = (id: number, name: string) => {
    if (id) callback(id, name);

    setChanged(false);
  };

  const renderResults = () => {
    if (!users.length) return <p>No users found by that term</p>;

    return users.map((user, index) => {
      const { id, firstName, lastName } = user;

      return (
        <SearchResult key={index + Date.now()}>
          <SearchResultText>
            {firstName} {lastName} ({id})
          </SearchResultText>
          <SelectButton
            size="sm"
            onClick={() =>
              makeSelection(id, `${firstName} ${lastName} (${id})`)
            }
          >
            Select
          </SelectButton>
        </SearchResult>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} fade={false}>
      <ModalHeader>Select User</ModalHeader>
      <ModalBody>
        <p>Search by an ID, First Name, or Last Name</p>
        <SearchBar>
          <Input
            name="userId"
            type="text"
            placeholder="Search"
            onChange={onChange}
          />
        </SearchBar>
        {loading && <BigSpinner />}
        {!loading && renderResults()}
        <CancelWrapper>
          <Button onClick={() => callback()}>Cancel</Button>
        </CancelWrapper>
      </ModalBody>
    </Modal>
  );
};

export default UserLookupModal;
