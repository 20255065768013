import React from "react";
import { Table } from "reactstrap";
import styled from "styled-components";
import { responsiveTableFontSize } from "../../globals/globals.styles";

export const ProjectDateIndexContainer = styled.div`
   {
  }
`;

export const TableLimited = styled(Table)`
   {
    ${responsiveTableFontSize}

    th,
    td {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
