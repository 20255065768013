import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";

interface ToggleSwitchProps {
  isOn: boolean;
}

export const SearchBar = styled.div`
   {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
`;

export const SearchResult = styled.div`
   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
`;

export const SearchResultText = styled.div`
   {
  }
`;

export const SelectButton = styled(Button)`
   {
  }
`;

export const CancelWrapper = styled.div`
   {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;

export const ToggleContainer = styled.div`
  display: inline-block;
  cursor: pointer;
`;

export const ToggleSwitch = styled.div<ToggleSwitchProps>`
  width: 40px;
  height: 20px;
  background-color: ${(props) => (props.isOn ? "#006400" : "#ccc")};
  border-radius: 10px;
  position: relative;
  transition: background-color 0.3s ease-in-out;
`;

export const ToggleHandle = styled.div<ToggleSwitchProps>`
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: ${(props) => (props.isOn ? "20px" : "1px")};
  transition: left 0.3s ease-in-out;
`;
