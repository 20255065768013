import React from "react";
import { TabPane } from "reactstrap";
import { NavLink as RRNavlink } from "react-router-dom";

import {
  Content,
  ProductLineReadContainer,
  Group,
  Title,
} from "./ProductLineRead.styles";

const ProductLineRead = ({
  id,
  manufacturer,
  project,
  createdAt,
  updatedAt,
}: any) => {
  return (
    <ProductLineReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Project</Title>
          <Content>
            <RRNavlink to={`../manage/projects/${String(project.id)}`}>
              {project.id}
              &nbsp;-&nbsp;
              {project.address}
            </RRNavlink>
          </Content>
        </Group>
        <Group>
          <Title>Manufacturer Name</Title>
          <RRNavlink to={`../manage/manufacturers/${String(manufacturer.id)}`}>
            {manufacturer.manufacturerName}
          </RRNavlink>
          <Content></Content>
        </Group>
        <Group>
          <Title>Created At</Title>
          <Content>
            {new Date(createdAt).toLocaleString(`en-US`, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </Content>
        </Group>
        <Group>
          <Title>Updated At</Title>
          <Content>
            {new Date(updatedAt).toLocaleString(`en-US`, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </Content>
        </Group>
      </TabPane>
    </ProductLineReadContainer>
  );
};

export default ProductLineRead;
