import React from "react";
import { TabPane } from "reactstrap";

import {
  Content,
  MarketReadContainer,
  Group,
  Title,
} from "./MarketRead.styles";

const MarketRead = ({ id, name, timezone, orientationLink }: any) => {
  return (
    <MarketReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Name</Title>
          <Content>{name}</Content>
        </Group>
        <Group>
          <Title>Time Zone</Title>
          <Content>{timezone}</Content>
        </Group>
        <Group>
          <Title>Orientation Link</Title>
          <Content>
            <a href={`${orientationLink}`}>{orientationLink}</a>
          </Content>
        </Group>
      </TabPane>
    </MarketReadContainer>
  );
};

export default MarketRead;
