import React, { useState } from "react";
import { Input, TabPane } from "reactstrap";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  SiteRequirementUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
} from "./SiteRequirementUpdate.styles";
import isEmpty from "validator/lib/isEmpty";
import { updateSiteRequirement } from "../../services/siteRequirementService";
import BigSpinner from "../BigSpinner/BigSpinner";

const SiteRequirementsUpdate = ({ id, name, $resetView }: any) => {
  const [formData, setFormData] = useState({
    id,
    name,
  });
  const [errors, setErrors] = useState<Array<any>>([]);
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    const { siteRequirementName }: any = formData;
    let foundErrors = [];

    if (isEmpty(name || "")) foundErrors.push("name");
    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let response = await updateSiteRequirement(id, formData);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <SiteRequirementUpdateContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Input
            name="id"
            type="text"
            value={formData.id}
            bsSize="sm"
            readOnly={true}
          />
        </Group>
        <Group>
          <Title>Name</Title>
          <Input
            name="name"
            type="text"
            value={formData.name}
            onChange={onChange}
            invalid={errors.indexOf("name") !== -1}
          />
        </Group>
        {errors.indexOf("name") !== -1 && <ErrorText>Invalid Name</ErrorText>}
        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
      </TabPane>
    </SiteRequirementUpdateContainer>
  );
};

export default SiteRequirementsUpdate;
