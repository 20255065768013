import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ManufacturerIndexContainer,
  TableLimited,
} from "./ManufacturerIndex.styles";
import { getManufacturers } from "../../services/manufacturerService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { Manufacturer } from "../../models/Manufacturer";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

export const ManufacturerIndex = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["manufacturers"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["manufacturers"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["manufacturers"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["manufacturers"].limit
  );

  useEffect(() => {
    setTitle("Manufacturers");
    getManufacturers().then((res: any) => {
      setManufacturers(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={Manufacturer.getFilterConfig()} />
      <ManufacturerIndexContainer>
        {!!manufacturers.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"manufacturers"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"manufacturers"}
                    columnName={"manufacturerName"}
                    displayName={"Name"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {manufacturers.map((manufacturer) => {
                  const { id, manufacturerName }: any = manufacturer;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{manufacturerName}</td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="manufacturers"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!manufacturers.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </ManufacturerIndexContainer>
    </>
  );
};
