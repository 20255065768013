import React from "react";
import { BigSpinnerContainer, ModifiedSpinner } from "./BigSpinner.styles";

const BigSpinner = () => {
  return (
    <BigSpinnerContainer>
      <ModifiedSpinner />
    </BigSpinnerContainer>
  );
};

export default BigSpinner;
