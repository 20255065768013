import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ForemanDetailsContainer } from "./ForemanDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getForemanById } from "../../services/foremenService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import ForemenRead from "../../components/ForemenRead/ForemenRead";
import ForemenUpdate from "../../components/ForemenUpdate/ForemenUpdate";
import ForemenDelete from "../../components/ForemenDelete/ForemenDelete";

export const ForemanDetails = () => {
  let { foremanId } = useParams();
  const [foreman, setForeman] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Foreman`);

    if (!Object.entries(foreman).length && foremanId)
      getForemanById(foremanId)
        .then((res) => {
          if (res.value) {
            setForeman(res.value);
            setError(false);
            setTitle(`Foreman (${res.value.name})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
  });

  const resetView = () => {
    setActiveTab("view");
  };

  const renderTab = (foreman: any) => {
    switch (activeTab) {
      case "view":
        return <ForemenRead {...foreman} />;
      case "update":
        return <ForemenUpdate {...foreman} $resetView={resetView} />;
      case "delete":
        return <ForemenDelete foreman={foreman} $resetView={resetView} />;
      default:
        return <ForemenRead {...foreman} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(foreman).length) {
    return (
      <>
        <ForemanDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(foreman)}</TabContent>
        </ForemanDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
