import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteMarket } from "../../services/marketService";
import BigSpinner from "../BigSpinner/BigSpinner";
import MarketRead from "../MarketRead/MarketRead";

import {
  MarketsDeleteContainer,
  DeleteButton,
  CancelButton,
  ButtonBox,
  SuccessText,
  ErrorText,
} from "./MarketDelete.styles";

const MarketsDelete = ({ market, $resetView }: any) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const removeMarket = async () => {
    setProcessing(true);

    const response = await deleteMarket(market.id);

    if (!response.error) {
      setDeleteSuccess(true);
      setTimeout(() => navigate("/manage/markets"), 2000);
    } else setError(true);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (deleteSuccess)
    return <SuccessText>Market Deleted. Redirecting you shortly.</SuccessText>;

  return (
    <MarketsDeleteContainer>
      <h2>Are you sure you want to delete this?</h2>
      <MarketRead {...market} />
      {error && (
        <ErrorText>There was a problem deleting this. Try again.</ErrorText>
      )}
      <ButtonBox>
        <DeleteButton size="lg" onClick={removeMarket}>
          Delete
        </DeleteButton>
        <CancelButton size="lg" onClick={$resetView}>
          Cancel
        </CancelButton>
      </ButtonBox>
    </MarketsDeleteContainer>
  );
};

export default MarketsDelete;
