import React from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

export const BigSpinnerContainer = styled.div`
   {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding-top: 10%;
  }
`;

export const ModifiedSpinner = styled(Spinner)`
   {
    width: 3rem;
    height: 3rem;
  }
`;
