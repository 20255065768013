import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ProductLineIndexContainer,
  TableLimited,
} from "./ProductLineIndex.styles";
import { getProductLines } from "../../services/productLineService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { ProductLine } from "../../models/ProductLine";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

export const ProductLineIndex = () => {
  const [productLine, setProductLine] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["productLines"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["productLines"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["productLines"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["productLines"].limit
  );

  useEffect(() => {
    setTitle("Product Lines");
    getProductLines().then((res: any) => {
      setProductLine(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={ProductLine.getFilterConfig()} />
      <ProductLineIndexContainer>
        {!!productLine.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"productLines"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"productLines"}
                    columnName={"projectId"}
                    displayName={"Project"}
                  />
                  <ColumnHeadWithSort
                    index={"productLines"}
                    columnName={"manufacturerId"}
                    displayName={"Manufacturer"}
                  />
                  <ColumnHeadWithSort
                    index={"productLines"}
                    columnName={"createdAt"}
                    displayName={"Created at"}
                  />
                  <ColumnHeadWithSort
                    index={"productLines"}
                    columnName={"updatedAt"}
                    displayName={"Updated at"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {productLine.map((productLine: any, index) => {
                  return (
                    <tr key={index}>
                      <td>{productLine.id}</td>
                      <td>
                        <NavLink
                          to={`/manage/projects/${String(
                            productLine.project.id
                          )}`}
                        >
                          {productLine.project.id}
                          &nbsp;-&nbsp;
                          {productLine.project.address}
                        </NavLink>
                      </td>
                      <td>
                        <NavLink
                          to={`/manage/manufacturers/${String(
                            productLine.manufacturer.id
                          )}`}
                        >
                          {productLine.manufacturer.manufacturerName}
                        </NavLink>
                      </td>
                      <td>
                        {new Date(productLine.createdAt).toLocaleString(
                          `en-US`,
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                          }
                        )}
                      </td>
                      <td>
                        {new Date(productLine.updatedAt).toLocaleString(
                          `en-US`,
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                          }
                        )}
                      </td>
                      <td>
                        <NavLink to={String(productLine.id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="productLine"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!productLine.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </ProductLineIndexContainer>
    </>
  );
};
