import React, { useState } from "react";
import { ProductLinesContainer } from "./ProductLines.styles";
import { ProductLineIndex } from "../../components/ProductLineIndex/ProductLineIndex";
import { ResourceNavbar } from "../../components/ResourceNavbar/ResourceNavbar";
import { TabContent } from "reactstrap";
import { ProductLineCreate } from "../../components/ProductLineCreate/ProductLineCreate";

export const ProductLines = () => {
  const [activeTab, setActiveTab] = useState("index");

  const renderTab = () => {
    switch (activeTab) {
      case "index":
        return <ProductLineIndex />;
      case "create":
        return <ProductLineCreate />;
      case "export":
        return <ProductLineIndex />;
      default:
        return <ProductLineIndex />;
    }
  };

  return (
    <>
      <ProductLinesContainer>
        <ResourceNavbar
          activeTab={activeTab}
          passActiveTab={(newValue: string) => setActiveTab(newValue)}
        />
        <TabContent>{renderTab()}</TabContent>
      </ProductLinesContainer>
    </>
  );
};
