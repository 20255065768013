import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  WorkerTypeCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  MarketField,
} from "./WorkerTypeCreate.styles";
import isEmpty from "validator/lib/isEmpty";
import isInt from "validator/lib/isInt";
import { createWorkerType } from "../../services/workerTypesService";
import { useNavigate } from "react-router-dom";
import MarketLookupModal from "../MarketLookupModal/MarketLookupModal";

export const WorkerTypeCreate = () => {
  const [formData, setFormData] = useState({
    positionName: "",
    marketId: "",
    level: "",
    costCents: "",
    costCurrency: "",
    paymentCents: "",
    paymentCurrency: "",
  });
  const [marketString, setMarketString] = useState("");
  const [errors, setErrors] = useState<Array<any>>([]);
  const [showMarketLookup, setShowMarketLookup] = useState(false);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    setFormData({
      positionName: "",
      marketId: "",
      level: "",
      costCents: "",
      costCurrency: "",
      paymentCents: "",
      paymentCurrency: "",
    });
    setMarketString("");
    setErrors([]);
    setShowMarketLookup(false);
    setNewId(0);
  };

  const setMarket = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        marketId: String(id),
      }));
      setMarketString(name || "");
    }
    setShowMarketLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const {
      positionName,
      level,
      marketId,
      costCents,
      costCurrency,
      paymentCents,
      paymentCurrency,
    }: any = formData;

    if (isEmpty(positionName || "")) foundErrors.push("positionName");
    if (!isInt(marketId || "")) foundErrors.push("marketId");
    if (!isInt(String(level) || "")) foundErrors.push("level");
    if (!isInt(String(costCents) || "")) foundErrors.push("costCents");
    if (isEmpty(costCurrency || "")) foundErrors.push("costCurrency");
    if (!isInt(String(paymentCents) || "")) foundErrors.push("paymentCents");
    if (isEmpty(paymentCurrency || "")) foundErrors.push("paymentCurrency");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let response = await createWorkerType(formData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    return (
      <WorkerTypeCreateContainer>
        <SuccessText>
          New WorkerType &quot;{formData.positionName}&quot; successfully
          created.
        </SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to {formData.positionName}
          </Button>
        </SuccessButtonWrapper>
      </WorkerTypeCreateContainer>
    );
  }

  return (
    <WorkerTypeCreateContainer>
      <InputBox
        name="positionName"
        type="text"
        placeholder="Position Name"
        onChange={onChange}
        invalid={errors.indexOf("positionName") !== -1}
      />
      {errors.indexOf("positionName") !== -1 && (
        <ErrorText>Invalid Name</ErrorText>
      )}

      <InputBox
        name="level"
        type="select"
        onChange={onChange}
        invalid={errors.indexOf("level") !== -1}
      >
        <option value="0">Select a Level</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
      </InputBox>
      {errors.indexOf("level") !== -1 && <ErrorText>Invalid Level</ErrorText>}
      <MarketField>
        <Input
          type="text"
          placeholder="Select a Market >"
          invalid={errors.indexOf("marketId") !== -1}
          value={marketString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowMarketLookup(true)}>
          Select Market
        </Button>
      </MarketField>
      {errors.indexOf("marketId") !== -1 && (
        <ErrorText>Invalid Market</ErrorText>
      )}
      <MarketLookupModal isOpen={showMarketLookup} callback={setMarket} />
      <InputBox
        name="costCents"
        type="text"
        placeholder="Cost in cents"
        onChange={onChange}
        invalid={errors.indexOf("costCents") !== -1}
      />
      {errors.indexOf("costCents") !== -1 && (
        <ErrorText>Invalid Cost</ErrorText>
      )}
      <InputBox
        name="costCurrency"
        type="text"
        placeholder="Cost Currency (usually 'USD')"
        onChange={onChange}
        invalid={errors.indexOf("costCurrency") !== -1}
      />
      {errors.indexOf("costCurrency") !== -1 && (
        <ErrorText>Invalid Currency</ErrorText>
      )}
      <InputBox
        name="paymentCents"
        type="text"
        placeholder="Payment in cents"
        onChange={onChange}
        invalid={errors.indexOf("paymentCents") !== -1}
      />
      {errors.indexOf("paymentCents") !== -1 && (
        <ErrorText>Invalid Cost</ErrorText>
      )}
      <InputBox
        name="paymentCurrency"
        type="text"
        placeholder="Payment Currency (usually 'USD')"
        onChange={onChange}
        invalid={errors.indexOf("paymentCurrency") !== -1}
      />
      {errors.indexOf("paymentCurrency") !== -1 && (
        <ErrorText>Invalid Currency</ErrorText>
      )}
      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </WorkerTypeCreateContainer>
  );
};
