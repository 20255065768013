import React from "react";
import { BreadCrumbsContainer } from "./BreadCrumbs.styles";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { NavLink } from "react-router-dom";

export const BreadCrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <BreadCrumbsContainer>
      <Breadcrumb>
        {breadcrumbs.map(({ breadcrumb }: any, index) => {
          return (
            <BreadcrumbItem key={breadcrumb.key}>
              <NavLink to={breadcrumb.key}>{breadcrumb}</NavLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </BreadCrumbsContainer>
  );
};
