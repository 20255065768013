import React from "react";
import styled from "styled-components";

import { Button, Input, InputGroup } from "reactstrap";
import { colors } from "../../globals/globals.styles";

export const ForemenCreateContainer = styled.div`
   {
    padding-top: 20px;
    display: flex;
    height: 100vh;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
      align-items: center;
    }
  }
`;

export const ErrorText = styled.p`
   {
    color: red;
  }
`;

export const InputBox = styled(Input)`
   {
    margin-top: 10px;
    width: 66%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const SubmitButton = styled(Button)`
   {
    width: 66%;
    font-weight: bold;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const UserField = styled(InputGroup)`
   {
    margin-top: 10px;
    width: 66%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const SuccessText = styled.p`
   {
    color: ${colors.darkGreen};
    font-weight: bold;
  }
`;

export const SuccessButtonWrapper = styled.div`
   {
    padding-top: 20px;
    display: flex;
    gap: 10px;
  }
`;
