import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { lookupForemen } from "../../services/foremenService";
import BigSpinner from "../BigSpinner/BigSpinner";
import {
  CancelWrapper,
  SearchResult,
  SearchResultText,
  SelectButton,
} from "./ForemanLookupModal.styles";

const ForemanLookupModal = ({ isOpen, userId, callback }: any) => {
  let [foremen, setForemen] = useState([]);
  let [loading, setloading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setloading(true);
    lookupForemen(userId).then((res: any) => {
      setForemen(res.value || []);
      setloading(false);
    });
  }, []);

  const makeSelection = (id: number, name: string) => {
    if (id) callback(id, name);
  };

  const renderResults = () => {
    if (!foremen.length) return <p>No foremen found for this user. (how?)</p>;

    return foremen.map((foreman, index) => {
      const { id, name, phone, email }: any = foreman;

      return (
        <SearchResult key={index + Date.now()}>
          <SearchResultText>
            ({id})&nbsp;
            {name}&nbsp;|&nbsp;
            {phone}
          </SearchResultText>
          <SelectButton
            size="sm"
            onClick={() => {
              makeSelection(id, `${name || "(no name provided)"} (${id})`);
            }}
          >
            Select
          </SelectButton>
        </SearchResult>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} fade={false}>
      <ModalHeader>Select Foreman</ModalHeader>
      <ModalBody>
        {loading && <BigSpinner />}
        {!loading && renderResults()}
        <CancelWrapper>
          <Button onClick={() => callback()}>Cancel</Button>
        </CancelWrapper>
      </ModalBody>
    </Modal>
  );
};

export default ForemanLookupModal;
