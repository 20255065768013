import React, { useState } from "react";
import { Input, TabPane } from "reactstrap";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  AutoSchedulerUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
} from "./AutoSchedulerUpdate.styles";
import { updateAutoScheduler } from "../../services/autoSchedulerService";
import BigSpinner from "../BigSpinner/BigSpinner";
import isInt from "validator/lib/isInt";
import isNumeric from "validator/lib/isNumeric";

const AutoSchedulerUpdate = ({
  id,
  project,
  inviteTimeInterval,
  invitesPerInterval,
  proximity,
  ratingMinimum,
  disabledAt,
  // removeSameDay,
  $resetView,
}: any) => {
  const [formData, setFormData] = useState({
    inviteTimeInterval,
    invitesPerInterval,
    disabledAt,
    ratingMinimum,
    proximity,
    // removeSameDay,
  });

  const [errors, setErrors] = useState<Array<any>>([]);
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const toggleDisabled = () => {
    setFormData((prevState) => ({
      ...prevState,
      disabledAt: !prevState.disabledAt ? new Date().toISOString() : null,
    }));
    if (!formModified) setFormModified(true);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    const { inviteTimeInterval, invitesPerInterval, ratingMinimum }: any =
      formData;
    let foundErrors = [];

    if (!isInt(String(inviteTimeInterval) || ""))
      foundErrors.push("inviteTimeInterval");
    if (!isInt(String(invitesPerInterval) || ""))
      foundErrors.push("invitesPerInterval");
    if (!isNumeric(String(ratingMinimum) || ""))
      foundErrors.push("ratingMinimum");
    if (!isNumeric(String(proximity) || "")) foundErrors.push("proximity");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let response = await updateAutoScheduler(id, formData);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <AutoSchedulerUpdateContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Input name="id" type="text" value={id} bsSize="sm" readOnly={true} />
        </Group>
        <Group>
          <Title>Project</Title>
          <Input
            name="name"
            type="text"
            value={`(${project.id}) ${project.projectName} ${project.address}`}
            readOnly={true}
          />
        </Group>
        <Group>
          <Title>Interval</Title>
          <Input
            name="inviteTimeInterval"
            type="text"
            value={formData.inviteTimeInterval}
            onChange={onChange}
            invalid={errors.indexOf("inviteTimeInterval") !== -1}
          />
        </Group>
        {errors.indexOf("inviteTimeInterval") !== -1 && (
          <ErrorText>Invalid Interval</ErrorText>
        )}
        <Group>
          <Title>Batch Size</Title>
          <Input
            name="invitesPerInterval"
            type="text"
            value={formData.invitesPerInterval}
            onChange={onChange}
            invalid={errors.indexOf("invitesPerInterval") !== -1}
          />
        </Group>
        {errors.indexOf("invitesPerInterval") !== -1 && (
          <ErrorText>Invalid Batch Size</ErrorText>
        )}
        <Group>
          <Title>Minimum Rating</Title>
          <Input
            name="ratingMinimum"
            type="text"
            value={formData.ratingMinimum}
            onChange={onChange}
            invalid={errors.indexOf("ratingMinimum") !== -1}
          />
        </Group>
        <Group>
          <Title>Proximity (D)</Title>
          <Input
            name="proximity"
            type="text"
            value={formData.proximity}
            onChange={onChange}
            invalid={errors.indexOf("proximity") !== -1}
          />
        </Group>
        {errors.indexOf("proximity") !== -1 && (
          <ErrorText>Invalid Proximitye</ErrorText>
        )}
        {/* <Group>
          <Title>Remove all same day invites</Title>
          <Input
            name="removeSameDay"
            type="checkbox"
            onChange={toggleState}
            checked={formData.removeSameDay}
          />
        </Group> */}
        <Group>
          <Title>Disabled?</Title>
          <Input
            name="disabledAt"
            type="checkbox"
            onChange={() => toggleDisabled()}
            checked={formData.disabledAt}
          />
        </Group>
        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
      </TabPane>
    </AutoSchedulerUpdateContainer>
  );
};

export default AutoSchedulerUpdate;
