import React from "react";
import { TabPane } from "reactstrap";
import { NavLink } from "react-router-dom";

import {
  Content,
  TimeLogReadContainer,
  Group,
  Title,
  Image,
} from "./TimeLogRead.styles";
import { TimeLog } from "../../models/TimeLog";
import { ShiftInvite } from "../../models/ShiftInvite";

const TimeLogRead = ({
  id,
  clockedInAt,
  clockedOutAt,
  cancelledAt,
  shiftInvite,
  lunchBreakMinutes,
  hashId,
  sentHome,
  tookLunch,
  createdAt,
  updatedAt,
  timeSheetUrl,
}: any) => {
  const { startsAt, timezone } = shiftInvite.projectWorker.projectDate;
  const { firstName, lastName } = shiftInvite.worker.user;

  let shiftInviteString = [
    TimeLog.parseDateWithTimezone(startsAt, timezone),
    "|",
    shiftInvite.id,
    "|",
    firstName,
    lastName,
    "|",
    `L${shiftInvite.worker.workerType.level}`,
    "|",
    ShiftInvite.formatStatus(shiftInvite.status),
  ].join(" ");

  let workerString = `${firstName} ${lastName} (${shiftInvite.worker.id})`;

  return (
    <TimeLogReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Worker</Title>
          <Content>{workerString}</Content>
        </Group>
        <Group>
          <Title>Shift Invite</Title>
          <Content>
            {" "}
            <NavLink to={`/manage/shiftInvites/${String(shiftInvite.id)}`}>
              {shiftInviteString}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Clocked In</Title>
          <Content>
            {TimeLog.parseDateWithTimezone(clockedInAt, timezone)}
          </Content>
        </Group>
        <Group>
          <Title>Clocked Out</Title>
          <Content>
            {TimeLog.parseDateWithTimezone(clockedOutAt, timezone)}
          </Content>
        </Group>
        <Group>
          <Title>Duration (hours)</Title>
          <Content>
            {TimeLog.getDuration(clockedInAt, clockedOutAt, lunchBreakMinutes)}
          </Content>
        </Group>
        <Group>
          <Title>Took lunch?</Title>
          <Content>{tookLunch ? "Yes" : "No"}</Content>
        </Group>
        <Group>
          <Title>Lunch Break (minutes)</Title>
          <Content>{lunchBreakMinutes}</Content>
        </Group>
        <Group>
          <Title>Sent home?</Title>
          <Content>{sentHome ? "Yes" : "No"}</Content>
        </Group>
        <Group>
          <Title>Hash Id</Title>
          <Content>{hashId}</Content>
        </Group>
        <Group>
          <Title>Cancelled At</Title>
          <Content>{cancelledAt}</Content>
        </Group>
        <Group>
          <Title>Created At</Title>
          <Content>
            {TimeLog.parseDateWithTimezone(createdAt, timezone)}
          </Content>
        </Group>
        <Group>
          <Title>Updated At</Title>
          <Content>
            {TimeLog.parseDateWithTimezone(updatedAt, timezone)}
          </Content>
        </Group>
        <Group>
          <Title>Time Sheet Url</Title>
          <Content>
            {timeSheetUrl ? (
              <a href={timeSheetUrl} target="_blank" rel="noreferrer">
                {timeSheetUrl}
              </a>
            ) : (
              "N/A"
            )}
          </Content>
        </Group>
        {!!timeSheetUrl && <Image src={timeSheetUrl} />}
      </TabPane>
    </TimeLogReadContainer>
  );
};

export default TimeLogRead;
