import React from "react";
import { NavLink } from "react-router-dom";
import { TabPane } from "reactstrap";
import { userStatusMapping } from "../../globals/constants";
import { Content, UserReadContainer, Group, Title } from "./UserRead.styles";

const UserRead = ({
  id,
  firstName,
  lastName,
  phoneNumber,
  email,
  role,
  status,
  market,
  marketId,
  worker,
  business,
}: any) => {
  return (
    <UserReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>First Name</Title>
          <Content>{firstName}</Content>
        </Group>
        <Group>
          <Title>Last Name</Title>
          <Content>{lastName}</Content>
        </Group>
        <Group>
          <Title>Phone</Title>
          <Content>
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </Content>
        </Group>
        <Group>
          <Title>Email</Title>
          <Content>
            <a href={`mailto:${email}`}>{email}</a>
          </Content>
        </Group>
        <Group>
          <Title>Role</Title>
          <Content>
            {!!worker && (
              <NavLink to={`/manage/workers/${String(worker.id)}`}>
                Worker (ID {worker.id})
              </NavLink>
            )}
            {!!business && (
              <NavLink to={`/manage/businesses/${String(business.id)}`}>
                Business (ID {business.id})
              </NavLink>
            )}
          </Content>
        </Group>
        <Group>
          <Title>Status</Title>
          <Content>{userStatusMapping[status]}</Content>
        </Group>
        <Group>
          <Title>Market</Title>
          <Content>
            <NavLink to={`/manage/markets/${String(marketId)}`}>
              {market.name} ({marketId})
            </NavLink>
          </Content>
        </Group>
      </TabPane>
    </UserReadContainer>
  );
};

export default UserRead;
