import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";
import { colors } from "../../globals/globals.styles";

export const AutoSchedulersDeleteContainer = styled.div`
   {
    padding-top: 20px;
    width: 66%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ButtonBox = styled.div`
   {
    width: 100%;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export const DeleteButton = styled(Button)`
   {
    width: 33%;
    display: block;

    font-weight: bold;
    background-color: ${colors.danger};
    &:hover {
      background-color: #f00;
    }

    @media only screen and (max-width: 768px) {
      width: 66%;
    }
  }
`;

export const CancelButton = styled(Button)`
   {
    width: 33%;
    display: block;

    font-weight: bold;
    @media only screen and (max-width: 768px) {
      width: 66%;
      margin-top: 50px;
    }
  }
`;

export const SuccessText = styled.p`
   {
    margin-top: 20px;
    color: ${colors.darkGreen};
    font-weight: bold;
  }
`;

export const ErrorText = styled.p`
   {
    color: red;
  }
`;
