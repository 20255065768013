import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import {
  ErrorText,
  UploadCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
} from "./UploadCreate.styles";
import isEmpty from "validator/lib/isEmpty";
import { createUpload } from "../../services/uploadService";
import { useNavigate } from "react-router-dom";
import { Project } from "../../models/Project";
import { getProjectsForTypeahead } from "../../services/projectService";
import { getWorkersForProject } from "../../services/workerService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import moment from "moment";
import SelectComponent, { SelectComponentProps } from "../Select/Select";

type ProjectInviteWithWorkers = {
  id: string;
  worker: {
    id: string;
    user: {
      firstName: string;
      lastName: string;
    };
    shiftInvites: ShiftInviteWithTimeLog[];
  };
};

type TimeLog = {
  id: string;
  clockedIn: string;
  clockedOut: string;
};

type TimeLogs = TimeLog[];

type ShiftInviteWithTimeLog = {
  id: string;
  timeLog: TimeLog;
};

type ProjectOption = {
  id: string;
  name: string;
};

export const UploadCreate = () => {
  const [projectTypeaheadloading, setProjectTypeaheadLoading] = useState(false);
  const [projectSearchTerm, setProjectSearchTerm] = useState("");
  const [workersLoading, setWorkersLoading] = useState(false);
  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState(0);
  const [uploadType, setUploadType] = useState("");
  const [file, setFile] = useState<
    { name: string; data: string; size: number } | undefined
  >(undefined);
  const [projectOptions, setProjectOptions] = useState<Option[]>([]);
  const [selectedProjectOption, setSelectedProjectOption] = useState<Option[]>(
    []
  );
  const [workersForProject, setWorkersForProject] = useState<
    ProjectInviteWithWorkers[]
  >([]);
  const [workerId, setWorkerId] = useState("");
  const [timeLogsForWorker, setTimeLogsForWorker] = useState<TimeLogs>([]);
  const [timelogId, setTimelogId] = useState("");
  const [inputValid, setInputValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const validUploadTypes = ["Project", "TimeLog"];
    const validInput =
      validUploadTypes.includes(uploadType) &&
      selectedProjectOption.length === 1 &&
      file !== undefined &&
      (uploadType === "Project" || timelogId !== "");
    setInputValid(validInput);
  }, [uploadType, file, selectedProjectOption, workerId, timelogId]);

  useEffect(() => {
    //Query the database with this search term
    if (projectSearchTerm.length === 0) {
      return;
    }
    setProjectTypeaheadLoading(true);
    getProjectsForTypeahead(projectSearchTerm)
      .then((result: any) => {
        const options = result.value.map((project: Project) => {
          return {
            id: project.id,
            name: project.projectName,
          };
        });
        setProjectOptions(options);
      })
      .catch((err: any) => {
        console.error(`Project lookup failed ${err}`);
      })
      .finally(() => {
        setProjectTypeaheadLoading(false);
      });
  }, [projectSearchTerm]);

  useEffect(() => {
    setWorkersForProject([]);
    setWorkerId("");
  }, [projectOptions]);

  useEffect(() => {
    setWorkerId("");
  }, [workersForProject]);

  useEffect(() => {
    if (selectedProjectOption.length === 0 || uploadType === "Project") {
      setWorkersForProject([]);
      setWorkerId("");
      return;
    }
    //TODO catch and finally here
    const projectId = (selectedProjectOption[0] as ProjectOption).id;
    setWorkersLoading(true);
    getWorkersForProject(projectId).then((result: any) => {
      const workersWithTimelogs = result.value.filter((item: any) => {
        return item.worker.shiftInvites.filter((si: any) => si.timeLog !== null)
          .length;
      });
      setWorkersForProject(workersWithTimelogs);
      setWorkersLoading(false);
    });
  }, [selectedProjectOption]);

  useEffect(() => {
    if (selectedProjectOption.length === 0 || workerId === "") {
      setTimeLogsForWorker([]);
      setTimelogId("");
      return;
    }
    const timeLogsForWorkerAndProject = workersForProject
      .filter((item: any) => item.worker.id === workerId)
      .flatMap((item: ProjectInviteWithWorkers) => {
        return item.worker.shiftInvites.flatMap(
          (si: ShiftInviteWithTimeLog) => si.timeLog
        );
      })
      .filter((item: TimeLog) => item !== null);
    setTimeLogsForWorker(timeLogsForWorkerAndProject);
    setTimelogId("");
  }, [workerId]);

  const onFileChange = (e: any) => {
    try {
      const reader = new FileReader();
      const file: File = e.target.files[0];
      if (!file) {
        setFile(undefined);
        return;
      }
      console.log(`File size ${file.size}`);
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileData: any = {
          name: file.name,
          size: file.size,
          data: reader.result,
        };
        setFile(fileData);
      };
    } catch (e) {
      console.log(e);
      alert("There was an issue using this file: " + e);
    }
  };

  const clearForm = () => {
    setFile(undefined);
    setUploadType("");
    setErrors([]);
    setNewId(0);
  };

  const getWorkerOptions = () => {
    const workerOptions = workersForProject.map(
      (pi: ProjectInviteWithWorkers) => {
        return (
          <option value={pi.worker.id} key={pi.worker.id}>
            {`${pi.worker.user.firstName} ${pi.worker.user.lastName}`}
          </option>
        );
      }
    );
    workerOptions.unshift(
      <option value="" key="0">
        Select a worker
      </option>
    );
    return (
      <InputBox
        name="worker"
        type="select"
        value={workerId}
        onChange={(e: any) => setWorkerId(e.target.value)}
      >
        {workerOptions}
      </InputBox>
    );
  };

  const getTimelogOptions = () => {
    const timelogOptions = timeLogsForWorker.map((tl: TimeLog) => {
      return (
        <option value={tl.id} key={tl.id}>
          {`${tl.clockedIn} - ${tl.clockedOut}`}
        </option>
      );
    });
    timelogOptions.unshift(
      <option value="" key="0">
        Select a time log
      </option>
    );
    return (
      <InputBox
        name="timelog"
        type="select"
        value={timelogId}
        onChange={(e: any) => setTimelogId(e.target.value)}
      >
        {timelogOptions}
      </InputBox>
    );
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];
    if (isEmpty(uploadType || "")) foundErrors.push("uploadType");
    if (!file) foundErrors.push("file");
    if (selectedProjectOption.length === 0) foundErrors.push("project");
    if (uploadType === "TimeLog") {
      if (isEmpty(timelogId)) foundErrors.push("timelog");
    }
    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }
    const projectId = (selectedProjectOption[0] as ProjectOption).id;
    const uploadableId = uploadType === "TimeLog" ? timelogId : projectId;

    const payload: any = {
      uploadType,
      file: file?.name,
      data: file?.data,
      fileSize: file?.size,
      uploadableId,
      updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let response = await createUpload(payload);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    return (
      <UploadCreateContainer>
        <SuccessText>New Upload successfully created.</SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to new Upload
          </Button>
        </SuccessButtonWrapper>
      </UploadCreateContainer>
    );
  }

  const handleProjectSearchChange = (searchTerm: string) => {
    setProjectSearchTerm(searchTerm);
  };

  const selectUploadTypeProps: SelectComponentProps = {
    trigger: {
      placeholder: "Select an upload type",
    },
    content: [
      {
        groupLabel: "",
        items: [
          {
            value: "TimeLog",
            displayText: "Time Log",
          },
          {
            value: "Project",
            displayText: "Project",
          },
        ],
      },
    ],
    onValueChange: setUploadType,
    value: uploadType,
    className: "upload-type-select",
  };

  return (
    <UploadCreateContainer>
      <SelectComponent {...selectUploadTypeProps} />
      {errors.indexOf("name") !== -1 && <ErrorText>Invalid Type</ErrorText>}
      <br />
      <InputBox type="file" name="file" onChange={onFileChange} />
      {errors.indexOf("timezone") !== -1 && <ErrorText>Invalid File</ErrorText>}
      <br />
      <AsyncTypeahead
        id="projects-typeahead"
        labelKey="name"
        onChange={(option) => setSelectedProjectOption(option)}
        onSearch={handleProjectSearchChange}
        options={projectOptions}
        placeholder="Select a project"
        selected={selectedProjectOption}
        isLoading={projectTypeaheadloading}
        minLength={3}
        filterBy={() => true}
      />
      <br />
      {uploadType === "TimeLog" &&
        selectedProjectOption.length > 0 &&
        getWorkerOptions()}
      <br />
      {uploadType === "TimeLog" && workerId !== "" && getTimelogOptions()}
      <br />
      <SubmitButton
        color="primary"
        onClick={submitOnClick}
        disabled={!inputValid}
      >
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </UploadCreateContainer>
  );
};
