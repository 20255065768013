import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";

export const SearchBar = styled.div`
   {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
`;

export const SearchResult = styled.div`
   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
`;

export const SearchResultText = styled.div`
   {
  }
`;

export const SelectButton = styled(Button)`
   {
  }
`;

export const CancelWrapper = styled.div`
   {
    display: flex;
    justify-content: flex-end;
  }
`;
