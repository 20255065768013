export class ProjectWorker {
  id: number;
  workerTypeId: number;
  projectDateId: number;
  filledAmount: number;
  totalAmount: number;
  canceledAt: string;

  constructor(newProjectWorker: IProjectWorker) {
    const {
      id,
      workerTypeId,
      projectDateId,
      filledAmount,
      totalAmount,
      canceledAt,
    } = newProjectWorker;

    this.id = id;
    this.workerTypeId = workerTypeId;
    this.projectDateId = projectDateId;
    this.filledAmount = filledAmount;
    this.totalAmount = totalAmount;
    this.canceledAt = canceledAt;
  }

  static getFilterConfig() {
    return {
      columns: [
        "id",
        "workerTypeId",
        "projectDateId",
        "filledAmount",
        "totalAmount",
        "canceledAt",
      ],
      indexToFilter: "projectWorkers",
    };
  }
}

export interface IProjectWorker {
  id: number;
  workerTypeId: number;
  projectDateId: number;
  filledAmount: number;
  totalAmount: number;
  canceledAt: string;
}
