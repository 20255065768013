import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupUsers = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/users?term=${searchTerm}`);

export const getUsers = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.users;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/users${filterString}`);
};

export const getUserById = async (userId: string) =>
  await protectedFetch(`${API_ROOT}/users/${userId}`);

export const createUser = async (newUser: any) => {
  return await protectedFetch(`${API_ROOT}/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newUser),
  });
};

export const updateUser = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/users/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteUser = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/users/${id}`, {
    method: "DELETE",
  });
};
