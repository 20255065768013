import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductLineDetailsContainer } from "./ProductLineDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getProductLineById } from "../../services/productLineService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import ProductLineRead from "../../components/ProductLineRead/ProductLineRead";
import ProductLineUpdate from "../../components/ProductLineUpdate/ProductLineUpdate";
import ProductLineDelete from "../../components/ProductLineDelete/ProductLineDelete";

export const ProductLineDetails = () => {
  let { productLineId } = useParams();
  const [productLine, setProductLine] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Product Line`);

    if (!Object.entries(productLine).length && productLineId)
      getProductLineById(productLineId)
        .then((res: any) => {
          if (res.value) {
            setProductLine(res.value);
            setError(false);
            setTitle(`Product Line (${res.value.id})`);
          } else setError(true);
        })
        .catch((err: any) => {
          setError(true);
        });
  });

  const resetView = () => {
    setActiveTab("view");
  };

  const renderTab = (productLine: any) => {
    switch (activeTab) {
      case "view":
        return <ProductLineRead {...productLine} />;
      case "update":
        return <ProductLineUpdate {...productLine} $resetView={resetView} />;
      case "delete":
        return (
          <ProductLineDelete productLine={productLine} $resetView={resetView} />
        );
      default:
        return <ProductLineRead {...productLine} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(productLine).length) {
    return (
      <>
        <ProductLineDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(productLine)}</TabContent>
        </ProductLineDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
