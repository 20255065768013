const tz = require("timezone/loaded"); //until Temporal JS comes out

export class ProjectDate {
  id: number;
  startsAt: string;
  endsAt: string;
  timezone: string;

  constructor(newProjectDate: IProjectDate) {
    const { id, timezone, startsAt, endsAt } = newProjectDate;

    this.id = id;
    this.startsAt = startsAt;
    this.endsAt = endsAt;
    this.timezone = timezone;
  }

  static getFilterConfig() {
    return {
      columns: ["id", "startsAt", "endsAt", "timezone", "projectId"],
      indexToFilter: "projectDates",
    };
  }

  static getShiftStringFrom(
    startsAt: string,
    endsAt: string,
    timezone: string
  ) {
    const startsAtParsed = tz(startsAt, "%c", "en_US", timezone).split(" ");
    const endsAtParsed = tz(endsAt, "%c", "en_US", timezone).split(" ");

    return [
      startsAtParsed[0] + ",",
      startsAtParsed[2],
      startsAtParsed[1] + ",",
      startsAtParsed[4].slice(0, -3),
      startsAtParsed[5],
      startsAtParsed[6],
      " - ",
      endsAtParsed[0] + ",",
      endsAtParsed[2],
      endsAtParsed[1] + ",",
      endsAtParsed[4].slice(0, -3),
      endsAtParsed[5],
      endsAtParsed[6],
    ].join(" ");
  }
}

export interface IProjectDate {
  id: number;
  startsAt: string;
  endsAt: string;
  timezone: string;
}
