export class Upload {
  id: number;

  constructor(newUpload: IUpload) {
    const { id } = newUpload;

    this.id = id;
  }

  static getFilterConfig() {
    return {
      columns: ["id"],
      indexToFilter: "uploads",
    };
  }
}

export interface IUpload {
  id: number;
}
