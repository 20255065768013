import React, { useState } from "react";
import { Button, Input, TabPane } from "reactstrap";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  ProjectWorkerUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
  LookupField,
} from "./ProjectWorkerUpdate.styles";
import { updateProjectWorker } from "../../services/projectWorkerService";
import BigSpinner from "../BigSpinner/BigSpinner";
import WorkerTypeLookupModal from "../WorkerTypeLookupModal/WorkerTypeLookupModal";
import ProjectDateLookupModal from "../ProjectDateLookupModal/ProjectDateLookupModal";
import isInt from "validator/lib/isInt";
import { ProjectDate } from "../../models/ProjectDate";

const ProjectWorkerUpdate = ({
  id,
  projectDate,
  workerType,
  filledAmount,
  totalAmount,
  isFlyout,
  $onChange,
  $resetView,
}: any) => {
  const [formData, setFormData] = useState({
    id,
    projectDateId: projectDate.id,
    workerTypeId: workerType.id,
    filledAmount,
    totalAmount,
  });

  const [showWorkerTypeLookup, setShowWorkerTypeLookup] = useState(false);
  const [workerTypeString, setWorkerTypeString] = useState(() => {
    const { id, market, positionName, level }: any = workerType;
    return [market.name, positionName, `L${level}`, `(${id})`].join(" ");
  });
  const [showProjectDateLookup, setShowProjectDateLookup] = useState(false);
  const [projectDateString, setProjectDateString] = useState(
    ProjectDate.getShiftStringFrom(
      projectDate.startsAt,
      projectDate.endsAt,
      projectDate.timezone
    )
  );
  const [errors, setErrors] = useState<Array<any>>([]);
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const setWorkerType = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        workerTypeId: String(id),
      }));
      setWorkerTypeString(name || "");
      if (!formModified) setFormModified(true);
    }
    setShowWorkerTypeLookup(false);
  };

  const setProjectDate = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectDateId: String(id),
      }));
      setProjectDateString(name || "");
      if (!formModified) setFormModified(true);
    }
    setShowProjectDateLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    const { workerTypeId, filledAmount, totalAmount, projectDateId }: any =
      formData;
    let foundErrors = [];

    if (!isInt(workerTypeId || "")) foundErrors.push("workerTypeId");
    if (!isInt(projectDateId || "")) foundErrors.push("projectDateId");
    if (!isInt(String(filledAmount) || "")) foundErrors.push("filledAmount");
    if (!isInt(String(totalAmount) || "")) foundErrors.push("totalAmount");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let response = await updateProjectWorker(id, formData);
    if (!response.error) {
      if (isFlyout) $onChange();
      else {
        setChangeSuccess(true);
        setTimeout(() => location.reload(), 2000);
      }
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <ProjectWorkerUpdateContainer isFlyout>
      <TabPane>
        <Group>
          <Title>Worker Type</Title>
          <LookupField>
            <Input
              type="text"
              placeholder="Select a WorkerType >"
              invalid={errors.indexOf("workerTypeId") !== -1}
              value={workerTypeString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowWorkerTypeLookup(true)}>
              Select WorkerType
            </Button>
          </LookupField>

          <WorkerTypeLookupModal
            isOpen={showWorkerTypeLookup}
            callback={setWorkerType}
          />
        </Group>
        {errors.indexOf("workerTypeId") !== -1 && (
          <ErrorText>Invalid Worker Type</ErrorText>
        )}
        {!isFlyout && (
          <>
            <Group>
              <Title>Project Date</Title>
              <LookupField>
                <Input
                  type="text"
                  placeholder="Select a Project Date >"
                  invalid={errors.indexOf("projectDateId") !== -1}
                  value={projectDateString}
                  readOnly={true}
                />
                <Button
                  size="sm"
                  onClick={() => setShowProjectDateLookup(true)}
                >
                  Select Project Date
                </Button>
              </LookupField>
              <ProjectDateLookupModal
                isOpen={showProjectDateLookup}
                callback={setProjectDate}
              />
            </Group>
            {errors.indexOf("projectDateId") !== -1 && (
              <ErrorText>Invalid Project Date</ErrorText>
            )}
          </>
        )}

        <Group>
          <Title>Filled Amount</Title>
          <Input
            name="filledAmount"
            type="text"
            invalid={errors.indexOf("filledAmount") !== -1}
            value={formData.filledAmount}
            onChange={onChange}
          />
        </Group>
        {errors.indexOf("filledAmount") !== -1 && (
          <ErrorText>Invalid Filled Amount</ErrorText>
        )}
        <Group>
          <Title>Total Amount</Title>
          <Input
            name="totalAmount"
            type="text"
            invalid={errors.indexOf("totalAmount") !== -1}
            value={formData.totalAmount}
            onChange={onChange}
          />
        </Group>
        {errors.indexOf("totalAmount") !== -1 && (
          <ErrorText>Invalid Total Amount</ErrorText>
        )}

        {!isFlyout && (
          <ButtonBox>
            <UpdateButton
              size="lg"
              onClick={submitOnClick}
              disabled={!formModified}
            >
              Update
            </UpdateButton>
            <CancelButton size="lg" onClick={$resetView}>
              Cancel
            </CancelButton>
          </ButtonBox>
        )}
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
      </TabPane>
    </ProjectWorkerUpdateContainer>
  );
};

export default ProjectWorkerUpdate;
