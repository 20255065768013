import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteWorkerType } from "../../services/workerTypesService";
import BigSpinner from "../BigSpinner/BigSpinner";
import WorkerTypeRead from "../WorkerTypeRead/WorkerTypeRead";

import {
  WorkerTypeDeleteContainer,
  DeleteButton,
  CancelButton,
  ButtonBox,
  SuccessText,
  ErrorText,
} from "./WorkerTypeDelete.styles";

const WorkerTypeDelete = ({ workerType, $resetView }: any) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const removeWorkerType = async () => {
    setProcessing(true);

    const response = await deleteWorkerType(workerType.id);

    if (!response.error) {
      setDeleteSuccess(true);
      setTimeout(() => navigate("/manage/workerTypes"), 2000);
    } else setError(true);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (deleteSuccess)
    return (
      <SuccessText>WorkerType Deleted. Redirecting you shortly.</SuccessText>
    );

  return (
    <WorkerTypeDeleteContainer>
      <h2>Are you sure you want to delete this?</h2>
      <WorkerTypeRead {...workerType} />
      {error && (
        <ErrorText>There was a problem deleting this. Try again.</ErrorText>
      )}
      <ButtonBox>
        <DeleteButton size="lg" onClick={removeWorkerType}>
          Delete
        </DeleteButton>
        <CancelButton size="lg" onClick={$resetView}>
          Cancel
        </CancelButton>
      </ButtonBox>
    </WorkerTypeDeleteContainer>
  );
};

export default WorkerTypeDelete;
