import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  ProjectDateCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  Title,
  LookupField,
} from "./ProjectDateCreate.styles";
import isEmpty from "validator/lib/isEmpty";
import { timeZoneNames } from "../../globals/constants";
import { createProjectDate } from "../../services/projectDateService";
import ProjectLookupModal from "../ProjectLookupModal/ProjectLookupModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import isDate from "validator/lib/isDate";
import { getProjectById } from "../../services/projectService";

const tz = require("timezone/loaded"); //until Temporal JS comes out

export const ProjectDateCreate = ({ projectId, isFlyout, $onChange }: any) => {
  const [searchParams] = useSearchParams();
  const [forProjectId] = useState(
    projectId || searchParams.get("forProjectId") || ""
  );

  const [formData, setFormData] = useState({
    startsAt: "",
    endsAt: "",
    projectId: "",
    timezone: "",
  });
  const [showProjectLookup, setShowProjectLookup] = useState(false);
  const [projectString, setProjectString] = useState("");
  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    setFormData({
      startsAt: "",
      endsAt: "",
      projectId: "",
      timezone: "",
    });
    setErrors([]);
    setShowProjectLookup(false);
    setNewId(0);
  };

  const setProject = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectId: String(id),
      }));
      setProjectString(name || "");
    }
    setShowProjectLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { startsAt, endsAt, projectId, timezone }: any = formData;

    if (isDate(startsAt || "")) foundErrors.push("startsAt");
    if (isDate(endsAt || "")) foundErrors.push("endsAt");
    if (isEmpty(timezone || "")) foundErrors.push("timezone");
    if (isEmpty(projectId || "")) foundErrors.push("projectId");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    //convert times to UTC before submission
    let tempForm = formData;
    tempForm.startsAt = new Date(tz(startsAt, timezone)).toUTCString();
    tempForm.endsAt = new Date(tz(endsAt, timezone)).toUTCString();

    let response = await createProjectDate(tempForm);
    if (!response.error) {
      if (isFlyout) $onChange();
      setNewId(response.value.id);
    } else setErrors((errors) => [...errors, "$server"]);
  };

  useEffect(() => {
    if (!forProjectId) return;
    getProjectById(forProjectId).then((res: any) => {
      const {
        id,
        projectName,
        address,
        market: { timezone },
      } = res.value;
      setFormData({
        ...formData,
        timezone,
      });
      setProject(id, `${projectName} / ${address} (${id})`);
    });
  }, []);

  if (newId && !isFlyout) {
    return (
      <ProjectDateCreateContainer>
        <SuccessText>New ProjectDate successfully created.</SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to new ProjectDate
          </Button>
        </SuccessButtonWrapper>
      </ProjectDateCreateContainer>
    );
  }

  return (
    <ProjectDateCreateContainer isFlyout>
      <Title>Starts at:</Title>
      <InputBox
        name="startsAt"
        type="datetime-local"
        onChange={onChange}
        invalid={errors.indexOf("startsAt") !== -1}
      />
      {errors.indexOf("startsAt") !== -1 && (
        <ErrorText>Invalid Start Date</ErrorText>
      )}
      <br />
      <Title>Ends at:</Title>
      <InputBox
        name="endsAt"
        type="datetime-local"
        onChange={onChange}
        invalid={errors.indexOf("endsAt") !== -1}
      />
      {errors.indexOf("endsAt") !== -1 && (
        <ErrorText>Invalid End Date</ErrorText>
      )}
      {!isFlyout && (
        <>
          <br />
          <InputBox
            name="timezone"
            type="select"
            onChange={onChange}
            value={formData.timezone}
            disabled={!!forProjectId}
            invalid={errors.indexOf("timezone") !== -1}
          >
            <option>Select a Time Zone</option>
            {timeZoneNames.map((tzName, index) => (
              <option value={tzName} key={index}>
                {tzName}
              </option>
            ))}
          </InputBox>
          {errors.indexOf("timezone") !== -1 && (
            <ErrorText>Invalid Timezone</ErrorText>
          )}
          <LookupField>
            <Input
              type="text"
              placeholder="Select the Project >"
              invalid={errors.indexOf("projectId") !== -1}
              value={projectString}
              readOnly={true}
            />
            <Button
              size="sm"
              disabled={!!forProjectId}
              onClick={() => setShowProjectLookup(true)}
            >
              Select Project
            </Button>
          </LookupField>
          {errors.indexOf("projectId") !== -1 && (
            <ErrorText>Invalid Project</ErrorText>
          )}
          <ProjectLookupModal
            isOpen={showProjectLookup}
            callback={setProject}
          />
        </>
      )}
      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </ProjectDateCreateContainer>
  );
};
