import React from "react";
import { Routes, Route } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Login } from "./pages/Login/Login";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import NotFound from "./pages/NotFound/NotFound";
import { Manage } from "./pages/Manage/Manage";
import { Foremen } from "./pages/Foremen/Foremen";
import { ForemanDetails } from "./pages/ForemanDetails/ForemanDetails";
import { Users } from "./pages/Users/Users";
import { UserDetails } from "./pages/UserDetails/UserDetails";
import { Markets } from "./pages/Markets/Markets";
import { MarketDetails } from "./pages/MarketDetails/MarketDetails";
import { Manufacturers } from "./pages/Manufacturers/Manufacturers";
import { ManufacturerDetails } from "./pages/ManufacturerDetails/ManufacturersDetails";
import { WorkerTypes } from "./pages/WorkerTypes/WorkerTypes";
import { WorkerTypeDetails } from "./pages/WorkerTypeDetails/WorkerTypeDetails";
import { SiteRequirementDetails } from "./pages/SiteRequirementDetails/SiteRequirementDetails";
import { SiteRequirements } from "./pages/SiteRequirements/SiteRequirements";
import { Businesses } from "./pages/Businesses/Businesses";
import { BusinessDetails } from "./pages/BusinessDetails/BusinessDetails";
import { Workers } from "./pages/Workers/Workers";
import { WorkerDetails } from "./pages/WorkerDetails/WorkerDetails";
import { Projects } from "./pages/Projects/Projects";
import { ProjectDetails } from "./pages/ProjectDetails/ProjectDetails";
import { ProductLineDetails } from "./pages/ProductLineDetails/ProductLineDetails";
import { ProductLines } from "./pages/ProductLines/ProductLines";
import { ProjectDates } from "./pages/ProjectDates/ProjectDates";
import { ProjectDateDetails } from "./pages/ProjectDateDetails/ProjectDateDetails";
import { ProjectInvites } from "./pages/ProjectInvites/ProjectInvites";
import { ProjectInviteDetails } from "./pages/ProjectInviteDetails/ProjectInviteDetails";
import { ProjectSiteRequirementDetails } from "./pages/ProjectSiteRequirementDetails/ProjectSiteRequirementDetails";
import { ProjectSiteRequirements } from "./pages/ProjectSiteRequirements/ProjectSiteRequirements";
import { ProjectWorkers } from "./pages/ProjectWorkers/ProjectWorkers";
import { ProjectWorkerDetails } from "./pages/ProjectWorkerDetails/ProjectWorkerDetails";
import { ShiftInvites } from "./pages/ShiftInvites/ShiftInvites";
import { ShiftInviteDetails } from "./pages/ShiftInviteDetails/ShiftInviteDetails";
import { TimeLogs } from "./pages/TimeLogs/TimeLogs";
import { TimeLogDetails } from "./pages/TimeLogDetails/TimeLogDetails";
import { WorkerBlackLists } from "./pages/WorkerBlackLists/WorkerBlackLists";
import { WorkerBlackListDetails } from "./pages/WorkerBlackListDetails/WorkerBlackListDetails";
import { AutoSchedulers } from "./pages/AutoSchedulers/AutoSchedulers";
import { AutoSchedulerDetails } from "./pages/AutoSchedulerDetails/AutoSchedulerDetails";
import { UploadDetails } from "./pages/UploadDetails/UploadDetails";
import { Uploads } from "./pages/Uploads/Uploads";
import { ResetUserPassword } from "./pages/ResetUserPassword/ResetUserPassword";

const generateRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/manage"
        element={
          <RequireAuth>
            <Manage />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/foremen"
        element={
          <RequireAuth>
            <Foremen />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/foremen/:foremanId"
        element={
          <RequireAuth>
            <ForemanDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/users"
        element={
          <RequireAuth>
            <Users />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/users/:userId"
        element={
          <RequireAuth>
            <UserDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/markets"
        element={
          <RequireAuth>
            <Markets />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/markets/:marketId"
        element={
          <RequireAuth>
            <MarketDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/manufacturers"
        element={
          <RequireAuth>
            <Manufacturers />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/manufacturers/:manufacturerId"
        element={
          <RequireAuth>
            <ManufacturerDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/workerTypes"
        element={
          <RequireAuth>
            <WorkerTypes />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/workerTypes/:workerTypeId"
        element={
          <RequireAuth>
            <WorkerTypeDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/siteRequirements"
        element={
          <RequireAuth>
            <SiteRequirements />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/siteRequirements/:siteRequirementId"
        element={
          <RequireAuth>
            <SiteRequirementDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/businesses"
        element={
          <RequireAuth>
            <Businesses />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/businesses/:businessId"
        element={
          <RequireAuth>
            <BusinessDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/workers"
        element={
          <RequireAuth>
            <Workers />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/workers/:workerId"
        element={
          <RequireAuth>
            <WorkerDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/projects"
        element={
          <RequireAuth>
            <Projects />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/projects/:projectId"
        element={
          <RequireAuth>
            <ProjectDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/productLines"
        element={
          <RequireAuth>
            <ProductLines />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/productLines/:productLineId"
        element={
          <RequireAuth>
            <ProductLineDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/projectDates"
        element={
          <RequireAuth>
            <ProjectDates />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/projectDates/:projectDateId"
        element={
          <RequireAuth>
            <ProjectDateDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/projectInvites"
        element={
          <RequireAuth>
            <ProjectInvites />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/projectInvites/:projectInviteId"
        element={
          <RequireAuth>
            <ProjectInviteDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/projectSiteRequirements"
        element={
          <RequireAuth>
            <ProjectSiteRequirements />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/projectSiteRequirements/:projectSiteRequirementId"
        element={
          <RequireAuth>
            <ProjectSiteRequirementDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/projectWorkers"
        element={
          <RequireAuth>
            <ProjectWorkers />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/projectWorkers/:projectWorkerId"
        element={
          <RequireAuth>
            <ProjectWorkerDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/shiftInvites"
        element={
          <RequireAuth>
            <ShiftInvites />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/shiftInvites/:shiftInviteId"
        element={
          <RequireAuth>
            <ShiftInviteDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/timeLogs"
        element={
          <RequireAuth>
            <TimeLogs />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/timeLogs/:timeLogId"
        element={
          <RequireAuth>
            <TimeLogDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/workerBlackLists"
        element={
          <RequireAuth>
            <WorkerBlackLists />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/workerBlackLists/:workerBlackListId"
        element={
          <RequireAuth>
            <WorkerBlackListDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/autoSchedulers"
        element={
          <RequireAuth>
            <AutoSchedulers />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/autoSchedulers/:autoSchedulerId"
        element={
          <RequireAuth>
            <AutoSchedulerDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/uploads"
        element={
          <RequireAuth>
            <Uploads />
          </RequireAuth>
        }
      />
      <Route
        path="/manage/uploads/:uploadId"
        element={
          <RequireAuth>
            <UploadDetails />
          </RequireAuth>
        }
      />
      <Route
        path="/tools/resetUserPassword"
        element={
          <RequireAuth>
            <ResetUserPassword />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default generateRoutes;
