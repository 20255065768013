import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WorkerBlackListDetailsContainer } from "./WorkerBlackListDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getWorkerBlackListById } from "../../services/workerBlackListService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import WorkerBlackListRead from "../../components/WorkerBlackListRead/WorkerBlackListRead";
import WorkerBlackListUpdate from "../../components/WorkerBlackListUpdate/WorkerBlackListUpdate";
import WorkerBlackListDelete from "../../components/WorkerBlackListDelete/WorkerBlackListDelete";

export const WorkerBlackListDetails = () => {
  let { workerBlackListId } = useParams();
  const [workerBlackList, setWorkerBlackList] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Worker Blacklist`);
    if (!Object.entries(workerBlackList).length && workerBlackListId)
      getWorkerBlackListById(workerBlackListId)
        .then((res) => {
          if (res.value) {
            setWorkerBlackList(res.value);
            setError(false);
            setTitle(`Worker Blacklist (${res.value.id})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
  });

  const resetView = () => setActiveTab("view");

  const renderTab = (workerBlackList: any) => {
    switch (activeTab) {
      case "view":
        return <WorkerBlackListRead {...workerBlackList} />;
      case "update":
        return (
          <WorkerBlackListUpdate {...workerBlackList} $resetView={resetView} />
        );
      case "delete":
        return (
          <WorkerBlackListDelete
            workerBlackList={workerBlackList}
            $resetView={resetView}
          />
        );
      default:
        return <WorkerBlackListRead {...workerBlackList} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(workerBlackList).length) {
    return (
      <>
        <WorkerBlackListDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(workerBlackList)}</TabContent>
        </WorkerBlackListDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
