import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ProjectInviteIndexContainer,
  TableLimited,
} from "./ProjectInviteIndex.styles";
import { getProjectInvites } from "../../services/projectInviteService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { ProjectInvite } from "../../models/ProjectInvite";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

export const ProjectInviteIndex = () => {
  const [projectInvites, setProjectInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["projectInvites"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["projectInvites"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["projectInvites"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["projectInvites"].limit
  );

  useEffect(() => {
    setTitle("Project Invites");
    getProjectInvites().then((res: any) => {
      setProjectInvites(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  const formatDateTime = (dateTime: string) =>
    new Date(dateTime).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={ProjectInvite.getFilterConfig()} />
      <ProjectInviteIndexContainer>
        {!!projectInvites.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"projectInvites"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"projectInvites"}
                    columnName={"workerId"}
                    displayName={"Worker"}
                  />
                  <ColumnHeadWithSort
                    index={"projectInvites"}
                    columnName={"projectId"}
                    displayName={"Project"}
                  />
                  <ColumnHeadWithSort
                    index={"projectInvites"}
                    columnName={"status"}
                    displayName={"Status"}
                  />
                  <ColumnHeadWithSort
                    index={"projectInvites"}
                    columnName={"hashId"}
                    displayName={"Hash ID"}
                  />
                  <ColumnHeadWithSort
                    index={"projectInvites"}
                    columnName={"adminNotes"}
                    displayName={"Admin Notes"}
                  />
                  <ColumnHeadWithSort
                    index={"projectInvites"}
                    columnName={"noOtherInvites"}
                    displayName={"Restrict Other Invites"}
                  />
                  <ColumnHeadWithSort
                    index={"projectInvites"}
                    columnName={"createdAt"}
                    displayName={"Created At"}
                  />
                  <ColumnHeadWithSort
                    index={"projectInvites"}
                    columnName={"updatedAt"}
                    displayName={"Updated At"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {projectInvites.map((projectInvite, index) => {
                  const {
                    id,
                    status,
                    adminNotes,
                    hashId,
                    project,
                    worker,
                    noOtherInvites,
                    createdAt,
                    updatedAt,
                  }: any = projectInvite;

                  return (
                    <tr key={index}>
                      <td>{id}</td>
                      <td>
                        <NavLink to={`/manage/workers/${String(worker.id)}`}>
                          {worker.user.firstName}&nbsp;
                          {worker.user.lastName}&nbsp;|&nbsp;
                          {worker.workerType.market.name}&nbsp;
                          {worker.workerType.positionName}&nbsp; (
                          {worker.workerType.level})
                        </NavLink>
                      </td>
                      <td>
                        <NavLink to={`/manage/projects/${String(project.id)}`}>
                          ID&nbsp;{project.id}
                          &nbsp;-&nbsp;
                          {project.address}
                        </NavLink>
                      </td>
                      <td>{status ? "Expired" : "Active"}</td>
                      <td>{hashId}</td>
                      <td>{adminNotes}</td>
                      <td>{noOtherInvites ? "Yes" : ""}</td>
                      <td>{formatDateTime(createdAt)}</td>
                      <td>{formatDateTime(updatedAt)}</td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="projectInvites"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!projectInvites.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </ProjectInviteIndexContainer>
    </>
  );
};
