import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserDetailsContainer } from "./UserDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getUserById } from "../../services/userService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import UserRead from "../../components/UserRead/UserRead";
import UserUpdate from "../../components/UserUpdate/UserUpdate";
import UserDelete from "../../components/UserDelete/UserDelete";

export const UserDetails = () => {
  let { userId } = useParams();
  const [user, setUser] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Users`);

    if (!Object.entries(user).length && userId)
      getUserById(userId)
        .then((res: any) => {
          if (res.value) {
            setUser(res.value);
            setError(false);
            setTitle(`User (${res.value.firstName} ${res.value.lastName})`);
          } else setError(true);
        })
        .catch((err: any) => {
          setError(true);
        });
  });

  const resetView = () => {
    setActiveTab("view");
  };

  const renderTab = (user: any) => {
    switch (activeTab) {
      case "view":
        return <UserRead {...user} />;
      case "update":
        return <UserUpdate {...user} $resetView={resetView} />;
      case "delete":
        return <UserDelete user={user} $resetView={resetView} />;
      default:
        return <UserRead {...user} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(user).length) {
    return (
      <>
        <UserDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(user)}</TabContent>
        </UserDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
