import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WorkerTypeDetailsContainer } from "./WorkerTypeDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getWorkerTypeById } from "../../services/workerTypesService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import WorkerTypeRead from "../../components/WorkerTypeRead/WorkerTypeRead";
import WorkerTypeUpdate from "../../components/WorkerTypeUpdate/WorkerTypeUpdate";
import WorkerTypeDelete from "../../components/WorkerTypeDelete/WorkerTypeDelete";

export const WorkerTypeDetails = () => {
  let { workerTypeId } = useParams();
  const [workerType, setWorkerType] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Worker Type`);

    if (!Object.entries(workerType).length && workerTypeId)
      getWorkerTypeById(workerTypeId)
        .then((res) => {
          if (res.value) {
            setWorkerType(res.value);
            setError(false);
            setTitle(`Worker Type (${res.value.id})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
  });

  const resetView = () => {
    setActiveTab("view");
  };

  const renderTab = (workerType: any) => {
    switch (activeTab) {
      case "view":
        return <WorkerTypeRead {...workerType} />;
      case "update":
        return <WorkerTypeUpdate {...workerType} $resetView={resetView} />;
      case "delete":
        return (
          <WorkerTypeDelete workerType={workerType} $resetView={resetView} />
        );
      default:
        return <WorkerTypeRead {...workerType} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(workerType).length) {
    return (
      <>
        <WorkerTypeDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(workerType)}</TabContent>
        </WorkerTypeDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
