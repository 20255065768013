import React from "react";
import { NavLink } from "react-router-dom";
import { TabPane } from "reactstrap";
import { ShiftInvite } from "../../models/ShiftInvite";

import {
  Content,
  ProjectWorkerReadContainer,
  Group,
  Title,
  InlineBlock,
} from "./ProjectWorkerRead.styles";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ProjectWorkerRead = ({
  id,
  projectDate,
  workerType,
  filledAmount,
  totalAmount,
  isFlyout,
  shiftInvites,
}: any) => {
  const { project } = projectDate;

  const startsAtParsed = tz(
    projectDate.startsAt,
    "%c",
    "en_US",
    projectDate.timezone
  ).split(" ");
  const endsAtParsed = tz(
    projectDate.endsAt,
    "%c",
    "en_US",
    projectDate.timezone
  ).split(" ");

  const renderShiftInvites = (shiftInvites: any) => {
    return shiftInvites.map((shiftInvite: any, index: number) => {
      const { firstName, lastName } = shiftInvite.projectInvite.worker.user;

      const shiftInviteString = `${[
        `(${shiftInvite.id})`,
        `${firstName} ${lastName}`,
        "|",
        ShiftInvite.formatStatus(shiftInvite.status),
      ].join(" ")}`;

      return (
        <InlineBlock key={index}>
          <NavLink to={`/manage/shiftInvites/${String(shiftInvite.id)}`}>
            {shiftInviteString}
          </NavLink>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </InlineBlock>
      );
    });
  };

  return (
    <ProjectWorkerReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        {!isFlyout && (
          <Group>
            <Title>Related Project</Title>
            <Content>
              <NavLink to={`/manage/projects/${String(project.id)}`}>
                {project.id}&nbsp;|&nbsp;
                {project.projectName}&nbsp;-&nbsp;
                {project.address}
              </NavLink>
            </Content>
          </Group>
        )}
        <Group>
          <Title>Worker Type</Title>
          <Content>
            <NavLink to={`/manage/workerTypes/${String(workerType.id)}`}>
              {workerType.market.name}&nbsp;
              {workerType.positionName}&nbsp; ({workerType.level})
            </NavLink>
          </Content>
        </Group>
        {!isFlyout && (
          <Group>
            <Title>Project Date</Title>
            <Content>
              <NavLink to={`/manage/projectDates/${String(projectDate.id)}`}>
                {[
                  startsAtParsed[2],
                  startsAtParsed[1] + ",",
                  startsAtParsed[4].slice(0, -3),
                  startsAtParsed[5],
                  " - ",
                  endsAtParsed[2],
                  endsAtParsed[1] + ",",
                  endsAtParsed[4].slice(0, -3),
                  endsAtParsed[5],
                  endsAtParsed[6],
                ].join(" ")}
              </NavLink>
            </Content>
          </Group>
        )}
        <Group>
          <Title>Filled Amount</Title>
          <Content>{filledAmount}</Content>
        </Group>
        <Group>
          <Title>Total Amount</Title>
          <Content>{totalAmount}</Content>
        </Group>
        <div>
          <br />
          <p>Accepted Shift Invites</p>
          <div>
            {renderShiftInvites(
              shiftInvites.filter((si: any) => si.status == 1)
            )}
          </div>
          <hr />
          <p>Pending Shift Invites</p>
          <div>
            {renderShiftInvites(
              shiftInvites.filter((si: any) => si.status != 1)
            )}
          </div>
        </div>
      </TabPane>
    </ProjectWorkerReadContainer>
  );
};

export default ProjectWorkerRead;
