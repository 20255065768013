import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { AppNavbar } from "../AppNavbar/AppNavbar";
import Drawer from "./Drawer";
import { AppWrapperSidebar } from "./Layout.styles";

const DashboardLayout = ({ children }: { children: React.ReactNode }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-[100vh] overflow-y-hidden">
      <AppWrapperSidebar
        className={`hidden lg:block !w-[20vw] py-4 
        overflow-y-scroll`}
      >
        <Sidebar toggle={toggleSidebar} />
      </AppWrapperSidebar>

      <main className="w-full lg:w-[80vw]">
        <div className="w-full">
          <AppNavbar toggleSidebar={toggleSidebar} />
        </div>

        <div className="overflow-y-scroll h-[90vh] w-full py-1 md:px-5 px-1">
          {children}
        </div>
      </main>
      {isSidebarOpen && (
        <Drawer toggle={toggleSidebar} open={isSidebarOpen}>
          <AppWrapperSidebar className="overflow-y-scroll">
            <Sidebar toggle={toggleSidebar} />
          </AppWrapperSidebar>
        </Drawer>
      )}
    </div>
  );
};

export default DashboardLayout;
