import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { UploadIndexContainer, TableLimited } from "./UploadIndex.styles";
import { getUploads } from "../../services/uploadService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { Upload } from "../../models/Upload";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

export const UploadIndex = () => {
  const [uploads, setUploads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["uploads"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["uploads"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["uploads"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["uploads"].limit
  );

  useEffect(() => {
    setTitle("Uploads");
    getUploads().then((res: any) => {
      setUploads(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  const getUploadLink = (upload: any) => {
    const { uploadableType, uploadableId, id } = upload;
    let url = "https://res.cloudinary.com/steadyinstall/image/upload";
    const type = uploadableType === "TimeLog" ? "timelogs" : "projects";

    return (
      <a
        href={`${url}/${type}/${uploadableId}/files/${id}/original`}
        target="_blank"
        rel="noreferrer"
      >
        {`.../${type}/${uploadableId}/files/${id}`}
      </a>
    );
  };

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={Upload.getFilterConfig()} />
      <UploadIndexContainer>
        {!!uploads.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"uploads"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <th>Upload URL</th>
                  <ColumnHeadWithSort
                    index={"uploads"}
                    columnName={"fileUpdatedAt"}
                    displayName={"Last Updated"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {uploads.map((upload) => {
                  const {
                    id,
                    uploadableId,
                    uploadableType,
                    fileUpdatedAt,
                  }: any = upload;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{getUploadLink(upload)}</td>
                      <td>{fileUpdatedAt}</td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="uploads"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!uploads.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </UploadIndexContainer>
    </>
  );
};
