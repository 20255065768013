import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  ColumnHeadWithSortContainer,
  Flex,
  SortDownIcon,
  SortIcon,
  SortUpIcon,
} from "./ColumnHeadWithSort.styles";
import { addOrReplaceSort } from "../../store/filterSlice";

const ColumnHeadWithSort = ({ displayName, columnName, index }: any) => {
  const [sortState, setSortState] = useState("");
  const dispatch = useAppDispatch();

  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex[index].sortBy
  );

  useEffect(() => {
    if (sortBy.column === columnName) {
      setSortState(sortBy.order);
    } else {
      setSortState("");
    }
  }, [sortBy]);

  const processSort = (sort: string) => {
    setSortState(sort);
    dispatch(
      addOrReplaceSort({
        index,
        newSort: {
          column: columnName,
          order: sort,
        },
      })
    );
  };

  const toggleSort = () => {
    switch (sortState) {
      case "":
        processSort("ASC");
        break;
      case "ASC":
        processSort("DESC");
        break;
      case "DESC":
        processSort("");
        break;
    }
  };

  const showSortIcon = () => {
    switch (sortState) {
      case "":
        return <SortIcon />;
      case "ASC":
        return <SortUpIcon />;
      case "DESC":
        return <SortDownIcon />;
    }
  };
  return (
    <ColumnHeadWithSortContainer>
      <Flex>
        <span>{displayName}</span>
        <div onClick={toggleSort}>{showSortIcon()}</div>
      </Flex>
    </ColumnHeadWithSortContainer>
  );
};

export default ColumnHeadWithSort;
