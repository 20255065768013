import React, { useState } from "react";
import { Button, Input, InputGroup, TabPane } from "reactstrap";
import MarketLookupModal from "../MarketLookupModal/MarketLookupModal";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  WorkerTypeUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
} from "./WorkerTypeUpdate.styles";
import isEmpty from "validator/lib/isEmpty";
import isInt from "validator/lib/isInt";
import { updateWorkerType } from "../../services/workerTypesService";
import BigSpinner from "../BigSpinner/BigSpinner";

const WorkerTypeUpdate = ({
  id,
  positionName,
  marketId,
  market,
  level,
  costCents,
  costCurrency,
  paymentCents,
  paymentCurrency,
  $resetView,
}: any) => {
  const [formData, setFormData] = useState({
    id,
    positionName,
    marketId,
    market,
    level,
    costCents,
    costCurrency,
    paymentCents,
    paymentCurrency,
  });
  const [marketString, setMarketString] = useState(
    `${market.name} (${marketId})`
  );
  const [errors, setErrors] = useState<Array<any>>([]);
  const [showMarketLookup, setShowMarketLookup] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const setUser = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        marketId: String(id),
      }));
      setMarketString(name || "");
      if (!formModified) setFormModified(true);
    }
    setShowMarketLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    const {
      id,
      positionName,
      marketId,
      level,
      costCents,
      costCurrency,
      paymentCents,
      paymentCurrency,
    }: any = formData;
    let foundErrors = [];

    if (isEmpty(positionName || "")) foundErrors.push("positionName");
    if (!isInt(marketId || "")) foundErrors.push("marketId");
    if (!isInt(String(level) || "")) foundErrors.push("level");
    if (!isInt(String(costCents) || "")) foundErrors.push("costCents");
    if (isEmpty(costCurrency || "")) foundErrors.push("costCurrency");
    if (!isInt(String(paymentCents) || "")) foundErrors.push("paymentCents");
    if (isEmpty(paymentCurrency || "")) foundErrors.push("paymentCurrency");
    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let response = await updateWorkerType(id, formData);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <WorkerTypeUpdateContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Input
            name="id"
            type="text"
            value={formData.id}
            bsSize="sm"
            readOnly={true}
          />
        </Group>
        <Group>
          <Title>Name</Title>
          <Input
            name="positionName"
            type="text"
            value={formData.positionName}
            onChange={onChange}
            invalid={errors.indexOf("positionName") !== -1}
          />
        </Group>
        {errors.indexOf("positionName") !== -1 && (
          <ErrorText>Invalid Name</ErrorText>
        )}
        <Group>
          <Title>Phone</Title>
          <Input
            name="level"
            type="select"
            onChange={onChange}
            value={formData.level}
            invalid={errors.indexOf("level") !== -1}
          >
            <option value="0">Select a Level</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </Input>
        </Group>
        {errors.indexOf("level") !== -1 && <ErrorText>Invalid Level</ErrorText>}
        <Group>
          <Title>Market (ID)</Title>
          <InputGroup>
            <Input
              type="text"
              placeholder="Select a Market >"
              invalid={errors.indexOf("marketId") !== -1}
              value={marketString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowMarketLookup(true)}>
              Select Market
            </Button>
          </InputGroup>
        </Group>
        {errors.indexOf("marketId") !== -1 && (
          <ErrorText>Invalid Market</ErrorText>
        )}
        <MarketLookupModal isOpen={showMarketLookup} callback={setUser} />
        <Group>
          <Title>Cost (in cents)</Title>
          <Input
            name="costCents"
            type="text"
            value={formData.costCents}
            onChange={onChange}
            invalid={errors.indexOf("costCents") !== -1}
          />
        </Group>
        {errors.indexOf("costCents") !== -1 && (
          <ErrorText>Invalid Cost</ErrorText>
        )}
        <Group>
          <Title>Cost Currency</Title>
          <Input
            name="costCurrency"
            type="text"
            value={formData.costCurrency}
            onChange={onChange}
            invalid={errors.indexOf("costCurrency") !== -1}
          />
        </Group>
        {errors.indexOf("costCurrency") !== -1 && (
          <ErrorText>Invalid Currency</ErrorText>
        )}
        <Group>
          <Title>Payment (in cents)</Title>
          <Input
            name="paymentCents"
            type="text"
            value={formData.paymentCents}
            onChange={onChange}
            invalid={errors.indexOf("paymentCents") !== -1}
          />
        </Group>
        {errors.indexOf("paymentCents") !== -1 && (
          <ErrorText>Invalid Payment</ErrorText>
        )}
        <Group>
          <Title>Payment Currency</Title>
          <Input
            name="paymentCurrency"
            type="text"
            value={formData.paymentCurrency}
            onChange={onChange}
            invalid={errors.indexOf("paymentCurrency") !== -1}
          />
        </Group>
        {errors.indexOf("costCurrpaymentCurrencyency") !== -1 && (
          <ErrorText>Invalid Currency</ErrorText>
        )}
        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
      </TabPane>
    </WorkerTypeUpdateContainer>
  );
};

export default WorkerTypeUpdate;
