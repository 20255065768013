export class WorkerBlackList {
  static getFilterConfig() {
    return {
      columns: ["id", "businessId", "workerId", "createdAt", "updatedAt"],
      indexToFilter: "workerBlackLists",
    };
  }
}

export interface IWorkerBlackList {
  id: number;
  businessId: number;
  workerId: number;
  createdAt: string;
  updatedAt: string;
}
