import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { LOCAL_STORAGE_FILTERS } from "../../globals/constants";
import { saveToLocalStorage } from "../../services/localStorageService";
import { store } from "../../store";
import { addIndexFilter, removeIndexFilter } from "../../store/filterSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  AddFilterButton,
  FilterBarContainer,
  FilterTile,
  FilterTileClose,
  FormError,
  Label,
  Plus,
} from "./FilterBar.styles";

export const FilterBar = ({ config }: any) => {
  const { columns, indexToFilter } = config;
  const [showFilterModal, setShowFilterModal] = useState(false);
  const dispatch = useAppDispatch();

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex[indexToFilter].value
  );

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      saveToLocalStorage(LOCAL_STORAGE_FILTERS, store.getState().filters);
    });

    return unsubscribe;
  }, []);

  const [filterForm, setFilterForm] = useState({
    column: "",
    operator: "",
    pattern: "",
  });
  const [filterFormError, setFilterFormError] = useState("");

  const onChange = (e: any) => {
    setFilterForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  const clearAndExit = () => {
    toggleFilterModal();
    setFilterForm({
      column: "",
      operator: "",
      pattern: "",
    });
    setFilterFormError("");
  };

  const addNewFilter = () => {
    const { column, operator, pattern } = filterForm;

    if (column && operator && pattern) {
      dispatch(
        addIndexFilter({
          index: indexToFilter,
          newFilter: filterForm,
        })
      );
      clearAndExit();
    } else {
      setFilterFormError("Please make sure all fields are filled and valid.");
    }
  };

  const deleteFilter = (filterId: string) => {
    dispatch(
      removeIndexFilter({
        index: indexToFilter,
        idToRemove: filterId,
      })
    );
  };

  const renderColumnOptions = () => (
    <Input name="column" type="select" onChange={onChange}>
      <option></option>
      {columns.map((col: string, index: any) => (
        <option key={index}>{col}</option>
      ))}
    </Input>
  );

  const renderFilters = (filters: any) =>
    filters.map((filter: any, index: any) => {
      let { column, operator, pattern, id } = filter;

      switch (operator) {
        case "equals":
          operator = "equal to";
          break;
        case "not_equal":
          operator = "not equal to";
          break;
        case "contains":
          operator = "containing";
          break;
        case "does_not_contain":
          operator = "not containing";
          break;
        case "greater_than":
          operator = "greater than";
          break;
        case "less_than":
          operator = "less than";
          break;
      }

      return (
        <FilterTile key={`${index}${Date.now()}`}>
          {`${column} is ${operator} "${pattern}"`}
          <FilterTileClose onClick={() => deleteFilter(id)} />
        </FilterTile>
      );
    });

  return (
    <>
      <FilterBarContainer>
        {renderFilters(filters)}
        <AddFilterButton onClick={toggleFilterModal} size="sm">
          <Plus />
          Add Filter
        </AddFilterButton>
      </FilterBarContainer>
      <Modal isOpen={showFilterModal} fade={false}>
        <ModalHeader>Add Filter</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>When the column..</Label>
            {renderColumnOptions()}
          </FormGroup>
          <FormGroup>
            <Label>is..</Label>
            <Input name="operator" type="select" onChange={onChange}>
              <option></option>
              <option value="equals">equal to</option>
              <option value="not_equal">not equal to</option>
              <option value="contains">containing</option>
              <option value="does_not_contain">not containing</option>
              <option value="greater_than">greater than</option>
              <option value="less_than">less than</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>the value..</Label>
            <Input name="pattern" onChange={onChange} />
          </FormGroup>
          {!!filterFormError && <FormError>{filterFormError}</FormError>}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={addNewFilter}>
            Submit
          </Button>
          <Button onClick={clearAndExit}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
