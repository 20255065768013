import React from "react";
import { Button, Input, InputGroup } from "reactstrap";
import styled from "styled-components";
import { colors } from "../../globals/globals.styles";

export const ShiftInviteUpdateContainer = styled.div`
   {
    width: 66%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const Group = styled.div`
   {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin-top: 10px;
    @media only screen and (max-width: 768px) {
      justify-content: space-between;
    }
  }
`;
export const Title = styled.span`
   {
    display: block;
    min-width: 20%;
    color: ${colors.darkGrey};
  }
`;

export const ButtonBox = styled.div`
   {
    width: 100%;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export const CancelButton = styled(Button)`
   {
    width: 33%;
    display: block;

    font-weight: bold;
    @media only screen and (max-width: 768px) {
      margin-top: 50px;
    }
    @media only screen and (max-width: 768px) {
      width: 66%;
    }
  }
`;

export const UpdateButton = styled(Button)`
   {
    width: 33%;
    display: block;

    font-weight: bold;
    background-color: ${colors.warn};
    &:hover {
      background-color: #ffa500;
    }
    @media only screen and (max-width: 768px) {
      width: 66%;
    }
  }
`;

export const ErrorText = styled.p`
   {
    padding-left: 20%;
    color: red;
  }
`;

export const SubmitButton = styled(Button)`
   {
    width: 66%;
    font-weight: bold;
  }
`;

export const SuccessText = styled.p`
   {
    margin-top: 20px;
    color: ${colors.darkGreen};
    font-weight: bold;
  }
`;

export const LookupField = styled(InputGroup)`
   {
    margin-top: 10px;
    width: 66%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const InputBox = styled(Input)`
   {
    margin-top: 10px;
    width: 66%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;
