import React, { useEffect, useState } from "react";
import { TabPane } from "reactstrap";

import {
  Content,
  WorkerReadContainer,
  Group,
  Title,
  LargeContent,
} from "./WorkerRead.styles";
import { Worker } from "../../models/Worker";
import { NavLink } from "react-router-dom";
import AccordionComponent, {
  AccordionComponentProps,
} from "../Accordion/Accordion";
import TableComponent, { TableComponentProps } from "../Table/Table";
import TableColumnHeadWithSort from "../Table/TableColumnHeader";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addIndexFilter, removeIndexFilter } from "../../store/filterSlice";
import {
  getShiftInvites,
  getShiftInvitesByWorkerIdGroupedByStatus,
} from "../../services/shiftInviteService";
import moment from "moment";
import { PaginationBar } from "../PaginationBar/PaginationBar";
import { Spinner } from "@radix-ui/themes";

const tz = require("timezone/loaded"); //until Temporal JS comes out

type StatusGroupTotal = {
  status: number;
  cnt: number;
};

type StatusGroupTotals = StatusGroupTotal[];

const WorkerRead = ({
  id,
  user,
  userId,
  workerType,
  workerTypeId,
  orientationScheduledAt,
  orientationDate,
  orientationRating,
  stripeConnectedAccountId,
  instantPayActivated,
  activeStatus,
  firstShiftWorked,
  rating,
  availability,
  zipCode,
  projectInvites,
}: any) => {
  const userString = [user.firstName, user.lastName, `(${workerTypeId})`].join(
    " "
  );
  const [shiftInvites, setShiftInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);
  const [statusGroupTotals, setStatusGroupTotals] = useState<StatusGroupTotals>(
    []
  );
  const [accordionSectionOpen, setAccordionSectionOpen] = useState(-1);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["shiftInvites"].value
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["shiftInvites"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["shiftInvites"].limit
  );
  useEffect(() => {
    //Remove all existing filters
    filters.map((filter: any) => {
      dispatch(
        removeIndexFilter({
          index: "shiftInvites",
          idToRemove: filter.id,
        })
      );
    });
    dispatch(
      addIndexFilter({
        index: "shiftInvites",
        newFilter: {
          column: "worker_id",
          operator: "equals",
          pattern: id,
        },
      })
    );
    getShiftInvitesByWorkerIdGroupedByStatus(id).then((res: any) => {
      setStatusGroupTotals(res.value || []);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    filters
      .filter((f: any) => f.column === "status")
      .map((filter: any) => {
        dispatch(
          removeIndexFilter({
            index: "shiftInvites",
            idToRemove: filter.id,
          })
        );
      });
    if (accordionSectionOpen !== -1) {
      setLoading(true);
      dispatch(
        addIndexFilter({
          index: "shiftInvites",
          newFilter: {
            column: "status",
            operator: "equals",
            pattern: String(accordionSectionOpen),
          },
        })
      );
    }
  }, [accordionSectionOpen]);

  useEffect(() => {
    if (accordionSectionOpen === -1) {
      return;
    }
    getShiftInvites().then((res: any) => {
      setShiftInvites(res.value || []);
      setLoading(false);
    });
  }, [filters]);

  const statusIdFromLabel = (label: string): number => {
    switch (label.split(" ")[0]) {
      case "Pending":
        return 0;
      case "Deferred":
        return 5;
      case "Waitlisted":
        return 4;
      case "Cancelled":
        return 3;
      case "Accepted":
        return 1;
      case "Rejected":
        return 2;
      case "Undetermined":
        return 6;
    }
    return -1;
  };
  const accordionClick = (event: any) => {
    event.persist();
    const accordionTrigger =
      event.target.className === "AccordionTrigger"
        ? event.target
        : event.target.parentNode.className === "AccordionTrigger"
        ? event.target.parentNode
        : undefined;
    if (!accordionTrigger) {
      return;
    }
    const sectionName = accordionTrigger.innerText;
    const sectionId = statusIdFromLabel(sectionName.split(" ")[0]);
    //This may seem counterintuitive but it's what the state is showing
    //at the point the element is clicked
    const isOpen = accordionTrigger.dataset?.state === "closed";
    if (isOpen) {
      setAccordionSectionOpen(sectionId);
    } else if (accordionSectionOpen === sectionId) {
      setAccordionSectionOpen(-1);
    }
  };

  const shiftInvitesForStatus = (status: number): number => {
    const statusGroupTotal = statusGroupTotals.find(
      (sgt) => sgt.status === status
    );
    return statusGroupTotal ? statusGroupTotal.cnt : 0;
  };

  const columnHeadings = [
    {
      displayName: "ID#",
      columnName: "id",
    },
    {
      displayName: "Project Worker",
      columnName: "projectWorkerId",
    },
    {
      displayName: "Project Invite",
      columnName: "projectInviteId",
    },
    {
      displayName: "Project Date",
      columnName: "projectDate",
    },
    {
      displayName: "Created At",
      columnName: "createdAt",
    },
    {
      displayName: "Updated At",
      columnName: "updatedAt",
    },
    {
      displayName: "Actions",
      columnName: "actions",
    },
  ];
  const allHeaders = columnHeadings.map((ch, index) => (
    <TableColumnHeadWithSort
      key={`item-${index}`}
      index={"shiftInvites"}
      columnName={ch.columnName}
      displayName={ch.displayName}
      sort={index !== 4 && index !== columnHeadings.length - 1}
    />
  ));

  const getProps = (days?: number): TableComponentProps => {
    return {
      headers: allHeaders,
      body: (shiftInvites || [])
        .filter(
          (shiftInvite: any) =>
            !days ||
            moment()
              .subtract(days, "d")
              .set({
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
              })
              .isBefore(shiftInvite.createdAt)
        )
        .map((shiftInvite: any) => {
          const {
            id,
            projectWorker,
            projectInviteId,
            createdAt,
            updatedAt,
          }: any = shiftInvite;
          const startsAtParsed = tz(
            projectWorker.projectDate.startsAt,
            "%c",
            "en_US",
            projectWorker.projectDate.timezone
          ).split(" ");
          const endsAtParsed = tz(
            projectWorker.projectDate.endsAt,
            "%c",
            "en_US",
            projectWorker.projectDate.timezone
          ).split(" ");
          const projectWorkerString = `${[
            startsAtParsed[2],
            startsAtParsed[1] + ",",
            startsAtParsed[4].slice(0, -3),
            startsAtParsed[5],
            startsAtParsed[6],
            " | ",
            `L${projectWorker.workerType?.level || ""}`,
            `(${projectWorker.filledAmount}`,
            "/",
            `${projectWorker.totalAmount})`,
          ].join(" ")}`;
          return [
            id,
            <NavLink
              key={2}
              to={`/manage/projectWorkers/${String(projectWorker.id)}`}
            >
              {projectWorkerString}
            </NavLink>,
            <NavLink
              key={3}
              to={`/manage/projectInvites/${String(projectInviteId)}`}
            >
              Open
            </NavLink>,
            <NavLink
              key={4}
              to={`/manage/projectDates/${String(
                projectWorker.projectDate.id
              )}`}
            >
              {[
                startsAtParsed[0] + ",",
                startsAtParsed[2],
                startsAtParsed[1] + ",",
                startsAtParsed[4].slice(0, -3),
                startsAtParsed[5],
                " - ",
                startsAtParsed[0] + ",",
                endsAtParsed[2],
                endsAtParsed[1] + ",",
                endsAtParsed[4].slice(0, -3),
                endsAtParsed[5],
                endsAtParsed[6],
              ].join(" ")}
            </NavLink>,
            new Date(createdAt).toLocaleString(`en-US`, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }),
            new Date(updatedAt).toLocaleString(`en-US`, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }),
            <NavLink key={5} to={`/manage/shiftinvites/${String(id)}`}>
              Open
            </NavLink>,
          ];
        }),
    };
  };
  const projectInviteProps: TableComponentProps = {
    headers: [<div key={1}>Project name</div>],
    body: [
      (projectInvites || []).map((pi: any, index: number) => {
        return (
          <span key={index}>
            <NavLink to={`/manage/projectInvites/${pi.id}`}>
              {pi.project.projectName}&nbsp; #{pi.id}
            </NavLink>
            {projectInvites && projectInvites.length - 1 > index && (
              <span>&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp;&nbsp;</span>
            )}
          </span>
        );
      }),
    ],
  };

  const projectInviteAccordionProps: AccordionComponentProps = {
    sections: [
      {
        triggerElement: " ",
        content: <TableComponent {...projectInviteProps} />,
      },
    ],
  };

  const getAccordionContent = (status: number, dayLimit?: number) => {
    if (accordionSectionOpen !== status) {
      return;
    }
    if (loading) {
      return <Spinner />;
    }
    return (
      <div className="accordionContent">
        <TableComponent {...getProps(dayLimit)} />
        <PaginationBar
          index="shiftInvites"
          totalCount={resultCount}
          page={page}
          limit={limit}
        />
      </div>
    );
  };

  const shiftInviteAccordionProps: AccordionComponentProps = {
    sections: [
      {
        triggerElement: `Pending`,
        content: getAccordionContent(0, 7),
        onClick: accordionClick,
      },
      {
        triggerElement: `Deferred (${shiftInvitesForStatus(5)})`,
        content: getAccordionContent(5, 30),
        onClick: accordionClick,
      },
      {
        triggerElement: `Waitlisted (${shiftInvitesForStatus(4)})`,
        content: getAccordionContent(4),
        onClick: accordionClick,
      },
      {
        triggerElement: `Cancelled (${shiftInvitesForStatus(3)})`,
        content: getAccordionContent(3),
        onClick: accordionClick,
      },
      {
        triggerElement: `Accepted (${shiftInvitesForStatus(1)})`,
        content: getAccordionContent(1),
        onClick: accordionClick,
      },
      {
        triggerElement: `Undetermined (${shiftInvitesForStatus(6)})`,
        content: getAccordionContent(6),
        onClick: accordionClick,
      },
      {
        triggerElement: `Rejected (${shiftInvitesForStatus(2)})`,
        content: getAccordionContent(2, 7),
        onClick: accordionClick,
      },
    ],
  };

  return (
    <WorkerReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Name</Title>
          <Content>
            <NavLink to={`/manage/users/${String(user.id)}`}>
              {userString}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Type</Title>
          <Content>
            <Content>
              {!!workerType && (
                <NavLink to={`/manage/workerTypes/${String(workerTypeId)}`}>
                  {workerType?.market?.name}&nbsp;
                  {workerType?.positionName}&nbsp; ({workerTypeId})
                </NavLink>
              )}
              {!workerType && <span>Not set</span>}
            </Content>
          </Content>
        </Group>
        <Group>
          <Title>Rating</Title>
          <Content>{rating || "N/A"}</Content>
        </Group>
        <Group>
          <Title>Status</Title>
          <Content>{Worker.formatStatus(activeStatus)}</Content>
        </Group>
        <Group>
          <Title>Availability</Title>
          <Content>{Worker.formatAvailability(availability)}</Content>
        </Group>
        <Group>
          <Title>Orientation Date:</Title>
          <Content>{Worker.formatDate(orientationDate)}</Content>
        </Group>
        <Group>
          <Title>Orientation Scheduled At:</Title>
          <Content>{Worker.formatDate(orientationScheduledAt)}</Content>
        </Group>
        <Group>
          <Title>Orientation Rating:</Title>
          <Content>{orientationRating || "N/A"}</Content>
        </Group>
        <Group>
          <Title>Worker Type:</Title>
          {!!workerType && (
            <Content>
              {workerType?.market?.name}&nbsp;
              {workerType?.positionName}&nbsp; ({workerType?.id})
            </Content>
          )}
          {!workerType && <Content />}
        </Group>
        <Group>
          <Title>Stripe Connected Account</Title>
          <Content>{stripeConnectedAccountId || "N/A"}</Content>
        </Group>
        <Group>
          <Title>Instant Pay Activated</Title>
          <Content>{instantPayActivated ? "Yes" : "No"}</Content>
        </Group>
        <Group>
          <Title>Worked?</Title>
          <Content>{firstShiftWorked ? "Yes" : "No"}</Content>
        </Group>
        <Group>
          <Title>Zip Code</Title>
          <Content>{zipCode}</Content>
        </Group>
        <Group>
          <Title>Market</Title>
          {!!workerType?.market && (
            <Content>
              <NavLink to={`/manage/markets/${String(workerType?.market?.id)}`}>
                {workerType?.market?.name} ({workerType?.market?.id})
              </NavLink>
            </Content>
          )}
          {!workerType?.market && <Content />}
        </Group>
        <Title>Shift Invites</Title>
        <LargeContent>
          <AccordionComponent {...shiftInviteAccordionProps} />
        </LargeContent>
        <Title>Project Invites</Title>
        <LargeContent>
          <AccordionComponent {...projectInviteAccordionProps} />
        </LargeContent>
        {/* <Title>Blacklists</Title>
        <LargeContent>
          {workerBlackLists.map((bl: any, index: any) => {
            const { id, businessName } = bl.business;
            return (
              <>
                <NavLink key={index} to={`/manage/businesses/${id}`}>
                  {`${businessName} (${id})`}
                </NavLink>
                <br />
              </>
            );
          })}
        </LargeContent> */}
      </TabPane>
    </WorkerReadContainer>
  );
};

export default WorkerRead;
