import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupMarkets = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/markets?term=${searchTerm}`);

export const getMarkets = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.markets;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/markets${filterString}`);
};

export const getMarketById = async (marketId: string) =>
  await protectedFetch(`${API_ROOT}/markets/${marketId}`);

export const createMarket = async (newMarket: any) => {
  return await protectedFetch(`${API_ROOT}/markets`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newMarket),
  });
};

export const updateMarket = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/markets/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteMarket = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/markets/${id}`, {
    method: "DELETE",
  });
};
