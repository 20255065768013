import React, { useEffect, useState } from "react";
import {
  SelfSortColumnHeadContainer,
  Flex,
  SortDownIcon,
  SortIcon,
  SortUpIcon,
} from "./SelfSortColumnHead.styles";

const SelfSortColumnHead = ({
  displayName,
  column,
  reportSort,
  reset,
}: any) => {
  const [sortState, setSortState] = useState("");

  const processSort = (sort: string) => {
    setSortState(sort);
    reportSort({
      column,
      order: sort,
    });
  };

  useEffect(() => {
    if (sortState !== "" && reset) setSortState("");
  });

  const toggleSort = () => {
    switch (sortState) {
      case "":
        processSort("ASC");
        break;
      case "ASC":
        processSort("DESC");
        break;
      case "DESC":
        processSort("");
        break;
    }
  };

  const showSortIcon = () => {
    switch (sortState) {
      case "":
        return <SortIcon />;
      case "ASC":
        return <SortUpIcon />;
      case "DESC":
        return <SortDownIcon />;
    }
  };
  return (
    <SelfSortColumnHeadContainer>
      <Flex>
        <span>{displayName}</span>
        <div onClick={toggleSort}>{showSortIcon()}</div>
      </Flex>
    </SelfSortColumnHeadContainer>
  );
};

export default SelfSortColumnHead;
