import React from "react";
import { Button, Input } from "reactstrap";
import styled from "styled-components";
import { colors } from "../../../globals/globals.styles";

export const AvailableWorkersTableWrapper = styled.div`
   {
    margin-top: 20px;
    tbody tr:nth-child(odd) {
      background: ${colors.offWhiteOrange} !important;
    }
  }
`;
export const SearchWrapper = styled.div`
   {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
`;

export const RangeMinMaxIndicator = styled.div`
   {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
  }
`;

export const SearchFilterWrapper = styled.div`
   {
    display: flex;
    gap: 5px;
    width: 30%;
  }
`;

export const RangeThumb = styled.div`
   {
    height: 30px;
    border-radius: 100%;
    background-color: blue;
    width: 30px;
  }
`;

export const SearchInput = styled(Input)`
   {
    width: 100%;
    height: 40px;
  }
`;

export const FilterButton = styled(Button)`
   {
    height: 40px;
  }
`;
