import React from "react";
import styled from "styled-components";

export const AppRootContainer = styled.div`
   {
    display: flex;
    width: 100vw;
    height: 100vh;

    justify-content: center;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
`;
