import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Table } from "reactstrap";
import { ShiftInvite } from "../../../models/ShiftInvite";
import SelfSortColumnHead from "../SelfSortColumnHead/SelfSortColumnHead";
import {
  SearchInput,
  SearchWrapper,
  InvitedWorkersTableWrapper,
} from "./InvitedWorkersTable.styles";

const InvitedWorkersTable = ({ rows }: any) => {
  const [tableRows, setTableRows] = useState<any>([]);
  const [lastSortedColumn, setLastSortedColumn] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const onChange = (e: any) => {
    setSearchTerm(e.target.value || "");
  };

  const sortRows = (sort: any) => {
    let tempRows = [...tableRows];
    const { column, order } = sort;
    switch (order) {
      case "ASC":
        tempRows = tempRows.sort((a: any, b: any): any =>
          String(a[column]).localeCompare(String(b[column]))
        );
        break;
      case "DESC":
        tempRows = tempRows.sort((a: any, b: any): any =>
          String(b[column]).localeCompare(String(a[column]))
        );
        break;
      default:
        break;
    }
    setLastSortedColumn(column);
    setTableRows(tempRows);
  };

  const filterRowsBySearch = (searchTerm: string) => {
    let tempRows = [...rows];

    if (searchTerm)
      tempRows = tempRows.filter(
        (row: any) =>
          JSON.stringify(row).toLowerCase().indexOf(searchTerm.toLowerCase()) !=
          -1
      );

    setTableRows(tempRows);
  };

  useEffect(() => {
    filterRowsBySearch(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    let tempRows = rows.map((row: any) => {
      if (!row.worker.workerType) return;
      const { firstName, lastName } = row.worker?.user;
      const { level, positionName } = row.worker?.workerType;
      const processedShifts = row.shiftInvites.map((si: any) => {
        const date = new Date(si.projectWorker?.projectDate?.startsAt)
          .toString()
          .split(" ");
        const status = ShiftInvite.formatStatus(si.status);
        return {
          formatted: `${date[1]} ${date[2]} (${status})`,
          id: si.id,
        };
      });
      row.nameWithId = `${firstName} ${lastName} (${row.worker.id})`;
      row.shifts = processedShifts;
      row.levelPosition = `${level} - ${positionName}`;
      row.dateTimeInvited = new Date(row.createdAt).toLocaleString(`en-US`, {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
      row.displayStatus = row.status ? "Expired" : "Active";
      return row;
    });

    setTableRows(tempRows);
  }, [rows]);

  return (
    <InvitedWorkersTableWrapper>
      <SearchWrapper>
        <div>
          <h4>Invited Workers</h4>
        </div>
        <SearchInput type="text" placeholder="Search" onChange={onChange} />
      </SearchWrapper>
      <Table bordered responsive size="sm" striped>
        <thead>
          <tr>
            <SelfSortColumnHead
              displayName={"Project Invite ID"}
              column={"id"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "id"}
            />
            <SelfSortColumnHead
              displayName={"Worker (ID)"}
              column={"nameWithId"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "nameWithId"}
            />
            <SelfSortColumnHead
              displayName={"Worker Level / Position"}
              column={"levelPosition"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "levelPosition"}
            />
            <th>Shifts</th>
            <SelfSortColumnHead
              displayName={"Status"}
              column={"displayStatus"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "displayStatus"}
            />
            <SelfSortColumnHead
              displayName={"Date Invited"}
              column={"dateTimeInvited"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "dateTimeInvited"}
            />
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {!!tableRows &&
            tableRows.map((row: any, index: number) => {
              if (!row?.id) return;
              return (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>{row.nameWithId}</td>
                  <td>{row.levelPosition}</td>
                  <td>
                    {row.shifts.map((shift: any, index: number) => (
                      <span key={index}>
                        <NavLink to={`/manage/shiftInvites/${shift.id}`}>
                          {shift.formatted}
                        </NavLink>
                        &nbsp;&nbsp;&nbsp;
                      </span>
                    ))}
                  </td>
                  <td>{row.displayStatus}</td>
                  <td>{row.dateTimeInvited}</td>
                  <td>
                    <NavLink to={`/manage/projectInvites/${row.id}`}>
                      View
                    </NavLink>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </InvitedWorkersTableWrapper>
  );
};

export default InvitedWorkersTable;
