import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  UserCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  MarketField,
} from "./UserCreate.styles";
import isMobilePhone from "validator/lib/isMobilePhone";
import isStrongPassword from "validator/lib/isStrongPassword";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import isInt from "validator/lib/isInt";
import { createUser } from "../../services/userService";
import MarketLookupModal from "../MarketLookupModal/MarketLookupModal";
import { useNavigate } from "react-router-dom";

export const UserCreate = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    role: "",
    marketId: "",
    password: "",
    confirmPassword: "",
  });
  const [marketString, setMarketString] = useState("");
  const [errors, setErrors] = useState<Array<any>>([]);
  const [showMarketLookup, setShowMarketLookup] = useState(false);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      role: "",
      marketId: "",
      password: "",
      confirmPassword: "",
    });
    setMarketString("");
    setErrors([]);
    setShowMarketLookup(false);
    setNewId(0);
  };

  const setMarket = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        marketId: String(id),
      }));
      setMarketString(name || "");
    }
    setShowMarketLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors = [];

    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      role,
      marketId,
      password,
      confirmPassword,
    }: any = formData;

    if (isEmpty(firstName)) foundErrors.push("firstName");
    if (isEmpty(lastName)) foundErrors.push("lastName");
    if (!isMobilePhone(phoneNumber, "en-US")) foundErrors.push("phoneNumber");
    if (isEmpty(role || "")) foundErrors.push("role");
    if (!isInt(marketId)) foundErrors.push("marketId");
    if (!isEmail(email)) foundErrors.push("email");
    if (
      password !== confirmPassword ||
      !isStrongPassword(password, {
        minLength: 8,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 0,
        minSymbols: 0,
      })
    )
      foundErrors.push("password");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let tempFormData: any = { ...formData };
    delete tempFormData.confirmPassword;
    setFormData(tempFormData);

    let response = await createUser(tempFormData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    const { firstName, lastName } = formData;
    return (
      <UserCreateContainer>
        <SuccessText>
          {`New user "${firstName} ${lastName}" successfully created.`}
        </SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            {`Go to ${firstName} ${lastName}`}
          </Button>
        </SuccessButtonWrapper>
      </UserCreateContainer>
    );
  }

  return (
    <UserCreateContainer>
      <InputBox
        name="firstName"
        type="text"
        placeholder="First Name"
        onChange={onChange}
        invalid={errors.indexOf("firstName") !== -1}
      />
      {errors.indexOf("firstName") !== -1 && (
        <ErrorText>Invalid First Name</ErrorText>
      )}
      <InputBox
        name="lastName"
        type="text"
        placeholder="Last Name"
        onChange={onChange}
        invalid={errors.indexOf("lastName") !== -1}
      />
      {errors.indexOf("lastName") !== -1 && (
        <ErrorText>Invalid Last Name</ErrorText>
      )}

      <InputBox
        name="email"
        type="email"
        placeholder="Email"
        onChange={onChange}
        invalid={errors.indexOf("email") !== -1}
      />
      {errors.indexOf("email") !== -1 && <ErrorText>Invalid Email</ErrorText>}

      <InputBox
        name="phoneNumber"
        type="text"
        placeholder="Phone Number"
        onChange={onChange}
        invalid={errors.indexOf("phoneNumber") !== -1}
      />
      {errors.indexOf("phoneNumber") !== -1 && (
        <ErrorText>Invalid Phone Number</ErrorText>
      )}
      <InputBox
        name="role"
        type="select"
        onChange={onChange}
        invalid={errors.indexOf("role") !== -1}
      >
        <option value="">Select a Role</option>
        <option value="user">User (Business)</option>
        <option value="worker">Worker</option>
      </InputBox>
      {errors.indexOf("role") !== -1 && <ErrorText>Invalid Role</ErrorText>}
      <MarketField>
        <Input
          type="text"
          placeholder="Select the Market this user will be in >"
          invalid={errors.indexOf("marketId") !== -1}
          value={marketString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowMarketLookup(true)}>
          Select Market
        </Button>
      </MarketField>
      {errors.indexOf("marketId") !== -1 && (
        <ErrorText>Invalid Market</ErrorText>
      )}
      <MarketLookupModal isOpen={showMarketLookup} callback={setMarket} />
      <br />
      <InputBox
        name="password"
        type="password"
        placeholder="Enter password"
        onChange={onChange}
        invalid={errors.indexOf("password") !== -1}
      />
      <InputBox
        name="confirmPassword"
        type="password"
        placeholder="Confirm password"
        onChange={onChange}
        invalid={errors.indexOf("password") !== -1}
      />
      {errors.indexOf("password") !== -1 && (
        <ErrorText>
          Please enter and confirm a valid password. Use at least 8 characters.
        </ErrorText>
      )}
      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </UserCreateContainer>
  );
};
