import React, { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { throttle } from "throttle-debounce";
import { lookupProjectDates } from "../../services/projectDateService";
import BigSpinner from "../BigSpinner/BigSpinner";
import {
  CancelWrapper,
  SearchBar,
  SearchResult,
  SearchResultText,
  SelectButton,
} from "./ProjectDateLookupModal.styles";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ProjectDateLookupModal = ({ isOpen, callback }: any) => {
  let [projectDates, setProjectDates] = useState([]);
  let [loading, setloading] = useState(false);
  let [changed, setChanged] = useState(false);

  const onChange = throttle(400, false, (e: any) => {
    const { value } = e.target;
    if (!value) return;
    if (!changed) setChanged(true);

    setloading(true);
    lookupProjectDates(value).then((res: any) => {
      setProjectDates(res.value || []);
      setloading(false);
    });
  });

  const makeSelection = (id: number, name: string) => {
    if (id) callback(id, name);

    setChanged(false);
  };

  const renderResults = () => {
    if (!projectDates.length) return <p>No Project Dates found by that term</p>;

    return projectDates.map((projectDate, index) => {
      const { id, project, startsAt, endsAt, timezone }: any = projectDate;

      const startsAtParsed = tz(startsAt, "%c", "en_US", timezone).split(" ");
      const endsAtParsed = tz(endsAt, "%c", "en_US", timezone).split(" ");

      const shiftString = [
        startsAtParsed[0] + ",",
        startsAtParsed[2],
        startsAtParsed[1] + ",",
        startsAtParsed[4].slice(0, -3),
        startsAtParsed[5],
        startsAtParsed[6],
        " - ",
        endsAtParsed[0] + ",",
        endsAtParsed[2],
        endsAtParsed[1] + ",",
        endsAtParsed[4].slice(0, -3),
        endsAtParsed[5],
        endsAtParsed[6],
      ].join(" ");

      let tempString = [`${id} |`, shiftString, `(${project.address})`].join(
        " "
      );

      return (
        <SearchResult key={index + Date.now()}>
          <SearchResultText>{tempString}</SearchResultText>
          <SelectButton size="sm" onClick={() => makeSelection(id, tempString)}>
            Select
          </SelectButton>
        </SearchResult>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} fade={false}>
      <ModalHeader>Select ProjectDate</ModalHeader>
      <ModalBody>
        <p>Search by an ID, or by a Project Id, Name, or Address</p>
        <SearchBar>
          <Input
            name="projectDateId"
            type="text"
            placeholder="Search"
            onChange={onChange}
          />
        </SearchBar>
        {loading && <BigSpinner />}
        {!loading && renderResults()}
        <CancelWrapper>
          <Button onClick={() => callback()}>Cancel</Button>
        </CancelWrapper>
      </ModalBody>
    </Modal>
  );
};

export default ProjectDateLookupModal;
