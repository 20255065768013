import React from "react";
import {
  AppWrapperContainer,
  AppWrapperOutlet,
  PaddedOutlet,
} from "./AppWrapper.styles";
import generateRoutes from "../../routes";
import { useAppSelector } from "../../store/hooks";
import { BreadCrumbs } from "../../components/BreadCrumbs/BreadCrumbs";
import { Outlet } from "react-router-dom";
import SearchWithAlgolia from "../../components/Search";
import DashboardLayout from "../../components/Layout";

export const AppWrapper = () => {
  const user = useAppSelector((state) => state.auth.user);

  return (
    <AppWrapperContainer className="overflow-y-hidden">
      {user && (
        <DashboardLayout>
          <PaddedOutlet>
            <div
              className={`flex flex-col md:flex-row w-full 
              justify-between items-center`}
            >
              <div className="w-full md:w-1/3">
                <BreadCrumbs />
              </div>
              <div className="w-full md:w-2/3">
                <SearchWithAlgolia />
              </div>
            </div>
            <AppWrapperOutlet className="overflow-y-scroll">
              {generateRoutes()}
              <Outlet />
            </AppWrapperOutlet>
          </PaddedOutlet>
        </DashboardLayout>
      )}

      {!user && (
        <>
          <PaddedOutlet>
            {generateRoutes()}
            <Outlet />
          </PaddedOutlet>
        </>
      )}
    </AppWrapperContainer>
  );
};
