export const tryParseJSONObject = (jsonString: string) => {
  try {
    let o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}
  return false;
};

export const isNumeric = (value: string) => /^[+-]?\d+(\.\d+)?$/.test(value);
