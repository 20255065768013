import React from "react";
import styled from "styled-components";
import { responsiveTableFontSize } from "../../globals/globals.styles";

export const UnfilledShiftsTitle = styled.div`
   {
    display: flex;
    background-color: rgb(244, 244, 244);
    padding-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 1.25rem;
    align-items: center;
  }
`;
