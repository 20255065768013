import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Input, Table } from "reactstrap";
import { Worker } from "../../../models/Worker";
import SelfSortColumnHead from "../SelfSortColumnHead/SelfSortColumnHead";
import {
  SearchInput,
  SearchWrapper,
  SearchFilterWrapper,
  AvailableWorkersTableWrapper,
} from "./AvailableWorkersTable.styles";
import FilterAvailableWorkers, { Availability } from "./FilterModal";

interface RunFilterInterface {
  availability: Availability;
  orientationRating: number[];
  rating: number[];
  distance: number[];
}

const AvailableWorkersTable = ({ rows, reportSelections }: any) => {
  const [tableRows, setTableRows] = useState<any>([]);
  const [lastSortedColumn, setLastSortedColumn] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);
  const [lastCheckedItemId, setLastCheckedItemId] = useState<string | null>(
    null
  );

  const onChange = (e: any) => {
    setSearchTerm(e.target.value || "");
  };

  const toggleRow = (projectWorkerId: string) => {
    setSelectedIds((prevCheckedItemIds: string[]) => {
      if (!shiftKeyPressed) {
        return prevCheckedItemIds.includes(projectWorkerId)
          ? prevCheckedItemIds.filter((id) => id !== projectWorkerId)
          : [...prevCheckedItemIds, projectWorkerId];
      }

      if (lastCheckedItemId !== null) {
        const startIndex = tableRows.findIndex(
          (row: any) => row.workerId === lastCheckedItemId
        );
        const endIndex = tableRows.findIndex(
          (row: any) => row.workerId === projectWorkerId
        );
        const list = tableRows
          .slice(startIndex, endIndex + 1)
          .map((row: any) => row.workerId);
        for (let item of list) {
          if (prevCheckedItemIds.includes(item)) {
            prevCheckedItemIds.filter((id) => id !== item);
          } else {
            prevCheckedItemIds = [...prevCheckedItemIds, item];
          }
        }
        return prevCheckedItemIds;
      }

      return prevCheckedItemIds;
    });

    setLastCheckedItemId(projectWorkerId);

    // if (selectedIds.some((id: any) => id == projectWorkerId))
    //   setSelectedIds(
    //     [...selectedIds].filter((rowId: any) => rowId != projectWorkerId)
    //   );
    // else setSelectedIds([...selectedIds, projectWorkerId]);
  };

  const withinRange = (value: number, min: number, max: number) => {
    return value >= min && value <= max;
  };

  const runFilter = function (filter: RunFilterInterface) {
    let tempRows = [...rows];
    const ava = Object.entries(filter.availability)
      .filter((e) => e)
      .map(([day, value]) => {
        return day.charAt(0).toUpperCase();
      });

    tempRows = tempRows.filter((row: any) => {
      const exisitingAva: string[] = row.availability
        ? Object.entries(row.availability)
            .filter((e) => e)
            .map(([day, value]) => {
              return day.charAt(0).toUpperCase();
            })
        : [];

      return (
        withinRange(Number(row.rating), filter.rating[0], filter.rating[1]) &&
        withinRange(
          row.orientationRating,
          filter.orientationRating[0],
          filter.orientationRating[1]
        ) &&
        withinRange(
          row.distanceToProjectMiles,
          filter.distance[0],
          filter.distance[1]
        ) &&
        exisitingAva.some((e) => ava.includes(e))
      );
    });

    setTableRows(tempRows);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Shift") {
      setShiftKeyPressed(true);
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === "Shift") {
      setShiftKeyPressed(false);
    }
  };

  // Attach event listeners when the component mounts
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    // Clean up event listeners when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const sortRows = (sort: any) => {
    let tempRows = [...tableRows];
    const { column, order } = sort;
    switch (order) {
      case "ASC":
        tempRows = tempRows.sort((a: any, b: any): any =>
          String(a[column]).localeCompare(String(b[column]), undefined, {
            numeric: true,
            sensitivity: "base",
          })
        );
        break;
      case "DESC":
        tempRows = tempRows.sort((a: any, b: any): any =>
          String(b[column]).localeCompare(String(a[column]), undefined, {
            numeric: true,
            sensitivity: "base",
          })
        );
        break;
      default:
        break;
    }
    setLastSortedColumn(column);
    setTableRows(tempRows);
  };

  const filterRowsBySearch = (searchTerm: string) => {
    let tempRows = [...rows];

    if (searchTerm)
      tempRows = tempRows.filter(
        (row: any) =>
          JSON.stringify(row).toLowerCase().indexOf(searchTerm.toLowerCase()) !=
          -1
      );

    setTableRows(tempRows);
  };

  useEffect(() => {
    filterRowsBySearch(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    reportSelections(selectedIds);
  }, [selectedIds]);

  useEffect(() => {
    let tempRows = rows.map((row: any) => {
      const {
        level,
        positionName,
        firstName,
        lastName,
        availability,
        distanceToProjectMiles,
      } = row;
      row.workerName = `${firstName} ${lastName}`;
      row.levelPosition = `${level} - ${positionName}`;
      row.formattedAvailability = Worker.formatAvailability(availability);
      row.distanceCategory = !isNaN(+distanceToProjectMiles)
        ? `D${Math.floor(distanceToProjectMiles / 10)}`
        : "N/A";
      return row;
    });

    //initial sort by level ASC
    tempRows = tempRows.sort((a: any, b: any): any =>
      String(a["levelPosition"]).localeCompare(
        String(b["levelPosition"]),
        undefined,
        {
          numeric: true,
          sensitivity: "base",
        }
      )
    );

    setTableRows(tempRows);
  }, []);

  return (
    <AvailableWorkersTableWrapper>
      <SearchWrapper>
        <div>
          <h4>Available Workers</h4>
        </div>
        <SearchFilterWrapper>
          <FilterAvailableWorkers runFilter={runFilter} />
          <SearchInput type="text" placeholder="Search" onChange={onChange} />
        </SearchFilterWrapper>
      </SearchWrapper>
      <Table bordered responsive size="sm" striped>
        <thead>
          <tr>
            <th />
            <SelfSortColumnHead
              displayName={"ID"}
              column={"workerId"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "workerId"}
            />
            <SelfSortColumnHead
              displayName={"Worker Name"}
              column={"workerName"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "workerName"}
            />
            <SelfSortColumnHead
              displayName={"Rating"}
              column={"rating"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "rating"}
            />
            <SelfSortColumnHead
              displayName={"Distance"}
              column={"distanceToProjectMiles"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "distanceToProjectMiles"}
            />
            <SelfSortColumnHead
              displayName={"Ori Rating"}
              column={"orientationRating"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "orientationRating"}
            />
            {/* <SelfSortColumnHead
              displayName={"Availability"}
              column={"availability"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "availability"}
            /> */}
            <th>Availability</th>
            <SelfSortColumnHead
              displayName={"D. Category"}
              column={"distanceCategory"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "distanceCategory"}
            />
            <SelfSortColumnHead
              displayName={"Level"}
              column={"levelPosition"}
              reportSort={sortRows}
              reset={lastSortedColumn !== "levelPosition"}
            />
          </tr>
        </thead>
        <tbody>
          {!!tableRows &&
            tableRows.map((row: any, index: number) => {
              const {
                workerId,
                workerName,
                rating,
                orientationRating,
                formattedAvailability,
                distanceToProjectMiles,
                distanceCategory,
                levelPosition,
              } = row;

              return (
                <tr key={index}>
                  <td>
                    <Input
                      type="checkbox"
                      onChange={() => toggleRow(workerId)}
                      checked={selectedIds.includes(workerId)}
                    />
                  </td>
                  <td>{workerId}</td>
                  <td>
                    <NavLink to={`/manage/workers/${workerId}`}>
                      {workerName}
                    </NavLink>
                  </td>
                  <td>{rating}</td>
                  <td>{distanceToProjectMiles}</td>
                  <td>{orientationRating}</td>
                  <td>{formattedAvailability}</td>
                  <td>{distanceCategory}</td>
                  <td>{levelPosition}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </AvailableWorkersTableWrapper>
  );
};

export default AvailableWorkersTable;
