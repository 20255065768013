import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupSiteRequirements = async (searchTerm: string) =>
  await protectedFetch(
    `${API_ROOT}/lookup/siteRequirements?term=${searchTerm}`
  );

export const getSiteRequirements = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.siteRequirements;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/siteRequirements${filterString}`);
};

export const getSiteRequirementById = async (siteRequirementId: string) =>
  await protectedFetch(`${API_ROOT}/siteRequirements/${siteRequirementId}`);

export const createSiteRequirement = async (newSiteRequirement: any) => {
  return await protectedFetch(`${API_ROOT}/siteRequirements`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newSiteRequirement),
  });
};

export const updateSiteRequirement = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/siteRequirements/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteSiteRequirement = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/siteRequirements/${id}`, {
    method: "DELETE",
  });
};
