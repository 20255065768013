import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectSiteRequirementDetailsContainer } from "./ProjectSiteRequirementDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getProjectSiteRequirementById } from "../../services/projectSiteRequirementService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import ProjectSiteRequirementRead from "../../components/ProjectSiteRequirementRead/ProjectSiteRequirementRead";
import ProjectSiteRequirementUpdate from "../../components/ProjectSiteRequirementUpdate/ProjectSiteRequirementUpdate";
import ProjectSiteRequirementDelete from "../../components/ProjectSiteRequirementDelete/ProjectSiteRequirementDelete";

export const ProjectSiteRequirementDetails = () => {
  let { projectSiteRequirementId } = useParams();
  const [projectSiteRequirement, setProjectSiteRequirement] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Project-Site Requirement`);
    if (
      !Object.entries(projectSiteRequirement).length &&
      projectSiteRequirementId
    )
      getProjectSiteRequirementById(projectSiteRequirementId)
        .then((res) => {
          if (res.value) {
            setProjectSiteRequirement(res.value);
            setError(false);
            setTitle(`Project-Site Requirement (${res.value.id})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
  });

  const resetView = () => setActiveTab("view");

  const renderTab = (projectSiteRequirement: any) => {
    switch (activeTab) {
      case "view":
        return <ProjectSiteRequirementRead {...projectSiteRequirement} />;
      case "update":
        return (
          <ProjectSiteRequirementUpdate
            {...projectSiteRequirement}
            $resetView={resetView}
          />
        );
      case "delete":
        return (
          <ProjectSiteRequirementDelete
            projectSiteRequirement={projectSiteRequirement}
            $resetView={resetView}
          />
        );
      default:
        return <ProjectSiteRequirementRead {...projectSiteRequirement} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(projectSiteRequirement).length) {
    return (
      <>
        <ProjectSiteRequirementDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(projectSiteRequirement)}</TabContent>
        </ProjectSiteRequirementDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
