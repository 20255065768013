import React from "react";
import { Input } from "reactstrap";
import styled from "styled-components";

export const ProjectWorkerTableWrapper = styled.div`
   {
    margin-top: 20px;

    .rateInput {
      min-width: 75px;
    }
  }
`;
export const SearchWrapper = styled.div`
   {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
`;

export const SearchInput = styled(Input)`
   {
    width: 33%;
  }
`;
