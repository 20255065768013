import React, { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { throttle } from "throttle-debounce";
import { lookupProjectWorkers } from "../../services/projectWorkerService";
import BigSpinner from "../BigSpinner/BigSpinner";
import {
  CancelWrapper,
  SearchBar,
  SearchResult,
  SearchResultText,
  SelectButton,
} from "./ProjectWorkerLookupModal.styles";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ProjectWorkerLookupModal = ({ isOpen, callback }: any) => {
  let [projectWorkers, setProjectWorkers] = useState([]);
  let [loading, setloading] = useState(false);
  let [changed, setChanged] = useState(false);

  const onChange = throttle(400, false, (e: any) => {
    const { value } = e.target;
    if (!value) return;
    if (!changed) setChanged(true);

    setloading(true);
    lookupProjectWorkers(value).then((res: any) => {
      setProjectWorkers(res.value || []);
      setloading(false);
    });
  });

  const makeSelection = (id: number, name: string) => {
    if (id) callback(id, name);

    setChanged(false);
  };

  const renderResults = () => {
    if (!projectWorkers.length)
      return <p>No Project Workers found by that term</p>;

    return projectWorkers.map((projectWorker, index) => {
      const { id, projectDate, workerType, filledAmount, totalAmount }: any =
        projectWorker;

      const startsAtParsed = tz(
        projectDate.startsAt,
        "%c",
        "en_US",
        projectDate.timezone
      ).split(" ");

      const projectWorkerString = `${[
        `(${id})`,
        startsAtParsed[2],
        startsAtParsed[1],
        startsAtParsed[3] + ",",
        startsAtParsed[4].slice(0, -3),
        startsAtParsed[5],
        startsAtParsed[6],
        " | ",
        `L${workerType.level}`,
        `(${filledAmount}`,
        "/",
        `${totalAmount})`,
      ].join(" ")}`;

      return (
        <SearchResult key={index + Date.now()}>
          <SearchResultText>{projectWorkerString}</SearchResultText>
          <SelectButton
            size="sm"
            onClick={() => makeSelection(id, projectWorkerString)}
          >
            Select
          </SelectButton>
        </SearchResult>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} fade={false}>
      <ModalHeader>Select Project Worker</ModalHeader>
      <ModalBody>
        <p>Search by an ID or Project Date ID</p>
        <SearchBar>
          <Input
            name="projectWorkerId"
            type="text"
            placeholder="Search"
            onChange={onChange}
          />
        </SearchBar>
        {loading && <BigSpinner />}
        {!loading && renderResults()}
        <CancelWrapper>
          <Button onClick={() => callback()}>Cancel</Button>
        </CancelWrapper>
      </ModalBody>
    </Modal>
  );
};

export default ProjectWorkerLookupModal;
