import { tryParseJSONObject } from "../globals/helpers";

export class Worker {
  id: number;
  userId: number;
  workerTypeId: number;
  orientationScheduledAt?: string;
  orientationDate?: string;
  orientationRating?: number;
  stripeConnectedAccountId?: string;
  level?: string;
  instantPayActivated?: boolean;
  activeStatus?: number;
  firstShiftWorked?: boolean;
  rating?: number;
  availability?: JSON;
  zipCode?: string;
  yearsExperience?: string;
  referral?: string;
  twoYearsPlusExp?: JSON;
  otherManufacturer?: JSON;

  constructor(newWorker: IWorker) {
    const {
      id,
      userId,
      workerTypeId,
      orientationScheduledAt,
      orientationDate,
      orientationRating,
      stripeConnectedAccountId,
      level,
      instantPayActivated,
      activeStatus,
      firstShiftWorked,
      rating,
      availability,
      zipCode,
      yearsExperience,
      referral,
      twoYearsPlusExp,
      otherManufacturer,
    } = newWorker;

    this.id = id;
    this.userId = userId;
    this.workerTypeId = workerTypeId;
    this.orientationScheduledAt = orientationScheduledAt;
    this.orientationDate = orientationDate;
    this.orientationRating = orientationRating;
    this.stripeConnectedAccountId = stripeConnectedAccountId;
    this.level = level;
    this.instantPayActivated = instantPayActivated;
    this.activeStatus = activeStatus;
    this.firstShiftWorked = firstShiftWorked;
    this.rating = rating;
    this.availability = availability;
    this.zipCode = zipCode;
    this.yearsExperience = yearsExperience;
    this.referral = referral;
    this.twoYearsPlusExp = twoYearsPlusExp;
    this.otherManufacturer = otherManufacturer;
  }

  static getFilterConfig() {
    return {
      columns: [
        "id",
        "workerTypeId",
        "userId",
        "rating",
        "availability",
        "activeStatus",
        "orientationScheduledAt",
        "orientationDate",
        "firstName",
        "lastName",
      ],
      indexToFilter: "workers",
    };
  }

  static formatAvailability(json: any) {
    let parsedJson = tryParseJSONObject(json);
    if (json) {
      let formattedAvailability = "";

      formattedAvailability += json.Sunday || json.sunday ? "S" : "_";
      formattedAvailability += json.Monday || json.monday ? "M" : "_";
      formattedAvailability += json.Tuesday || json.tuesday ? "T" : "_";
      formattedAvailability += json.Wednesday || json.wednesday ? "W" : "_";
      formattedAvailability += json.Thursday || json.thursday ? "T" : "_";
      formattedAvailability += json.Friday || json.friday ? "F" : "_";
      formattedAvailability += json.Saturday || json.saturday ? "S" : "_";

      return formattedAvailability;
    }

    return "N/A";
  }

  static formatDate(date: string) {
    if (date) return new Date(date).toUTCString();

    return "N/A";
  }

  static formatStatus(number: number) {
    switch (number) {
      case 0:
        return "Inactive";
      case 1:
        return "Signed up";
      case 2:
        return "Attended Orientation";
      case 3:
        return "Stripe active";
      case 4:
        return "Worked";
      case 5:
        return "Terminated";
    }
  }
}

export interface IWorker {
  id: number;
  userId: number;
  workerTypeId: number;
  orientationScheduledAt: string;
  orientationDate: string;
  orientationRating: number;
  stripeConnectedAccountId: string;
  level: string;
  instantPayActivated: boolean;
  activeStatus: number;
  firstShiftWorked: boolean;
  rating: number;
  availability: JSON;
  zipCode: string;
  yearsExperience: string;
  referral: string;
  twoYearsPlusExp: JSON;
  otherManufacturer: JSON;
}
