import React, { useState, useEffect, useRef } from "react";
import algoliasearch from "algoliasearch/lite";
import { useNavigate } from "react-router-dom";
const searchClient = algoliasearch(
  "KH52QUFQED",
  "f66ef7b672cb22ac6a5d6c330b8bfeb0"
);

interface SearchResult {
  title: string;
  hits: any[];
}

interface AlgoliaUser {
  objectID: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

interface AlgoliaMarket {
  name: string;
}

interface AlgoliaWorkerType {
  positionName: string;
  market: AlgoliaMarket;
}

interface AlgoliaWorker {
  user: AlgoliaUser;
  objectID: string;
  workerType: AlgoliaWorkerType;
}

interface AlgoliaProject {
  objectID: string;
  projectName: string;
  description: string;
  user: AlgoliaUser;
  market: AlgoliaMarket;
  pocName: string;
  pocPhone: string;
}

interface AlgoliaBusiness {
  objectID: string;
  businessName: string;
  businessAddress: string;
  billingContact: string;
  typeOfBusiness: string;
  user: AlgoliaUser;
}

const SearchWithAlgolia: React.FC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [searchInProgress, setSearchProgress] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [searchFilters, setSearchFilters] = useState<string[]>([]);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const nFromS = function (path: string) {
    setIsFocused(false);
    navigate(path);
  };

  function ProjectHit({ hit: { user, ...rest } }: { hit: AlgoliaProject }) {
    return (
      <div
        className={`h-full w-full flex items-center px-2 cursor-pointer 
        text-xs justify-between`}
      >
        <div className="flex items-center gap-2 justify-start">
          <p className="mb-0 uppercase">{rest.projectName}</p>
          <div className="h-1 w-1 rounded-full bg-black" />
          <p className="mb-0">
            {user.firstName} {user.lastName}
          </p>
          <div className="h-1 w-1 rounded-full bg-black" />
          <p className="mb-0">{rest.market.name}</p>
          <div className="h-1 w-1 rounded-full bg-black" />
          <p className="mb-0">{rest.pocName}</p>
        </div>
        <div
          className={`py-1 px-2 text-xs border rounded-full 
          cursor-pointer hover:bg-gray-100 border-gray-300`}
          onClick={() => nFromS(`/manage/projects/${rest.objectID}`)}
        >
          View
        </div>
      </div>
    );
  }

  function UserHit({ hit }: { hit: AlgoliaUser }) {
    return (
      <div
        className={`h-full w-full flex items-center px-2 cursor-pointer 
        text-xs justify-between`}
      >
        <div className="flex items-center gap-2 justify-start">
          <p className="mb-0">
            {hit.firstName} {hit.lastName}
          </p>
          <div className="h-1 w-1 rounded-full bg-black" />
          <p className="mb-0">{hit.email}</p>
          <div className="h-1 w-1 rounded-full bg-black" />
          <p className="mb-0">{hit.phoneNumber}</p>
        </div>
        <div
          className={`py-1 px-2 text-xs border rounded-full 
          cursor-pointer hover:bg-gray-100 border-gray-300`}
          onClick={() => nFromS(`/manage/users/${hit.objectID}`)}
        >
          View
        </div>
      </div>
    );
  }

  function WorkerHit({ hit: { user: hit, objectID } }: { hit: AlgoliaWorker }) {
    return (
      <div
        className={`h-full w-full flex items-center px-2 cursor-pointer 
        text-xs justify-between`}
      >
        <div className="flex items-center gap-2 justify-start">
          <p className="mb-0">
            {hit.firstName} {hit.lastName}
          </p>
          <div className="h-1 w-1 rounded-full bg-black" />
          <p className="mb-0">{hit.email}</p>
          <div className="h-1 w-1 rounded-full bg-black" />
          <p className="mb-0">{hit.phoneNumber}</p>
        </div>
        <div
          className={`py-1 px-2 text-xs border rounded-full 
          cursor-pointer hover:bg-gray-100 border-gray-300`}
          onClick={() => nFromS(`/manage/workers/${objectID}`)}
        >
          View
        </div>
      </div>
    );
  }

  function BusinessHit({ hit: { user, ...rest } }: { hit: AlgoliaBusiness }) {
    return (
      <div
        className={`h-full w-full flex items-center px-2 cursor-pointer 
        text-xs justify-between`}
      >
        <div className="flex items-center gap-2 justify-start">
          <p className="mb-0 uppercase">{rest.businessName}</p>
          <div className="h-1 w-1 rounded-full bg-black" />
          <p className="mb-0">
            {user.firstName} {user.lastName}
          </p>
          <div className="h-1 w-1 rounded-full bg-black" />
          <p className="mb-0">{rest.billingContact}</p>
          <div className="h-1 w-1 rounded-full bg-black" />
          <p className="mb-0">{rest.businessAddress}</p>
        </div>
        <div
          className={`py-1 px-2 text-xs border rounded-full 
          cursor-pointer hover:bg-gray-100 border-gray-300`}
          onClick={() => nFromS(`/manage/businesses/${rest.objectID}`)}
        >
          View
        </div>
      </div>
    );
  }

  const filters = ["businesses", "users", "workers", "projects"];

  const addFilter = function (filter: string) {
    const updated = [...searchFilters];
    if (updated.includes(filter)) {
      const ind = updated.indexOf(filter);
      updated.splice(ind, 1);
    } else {
      updated.push(filter);
    }
    setSearchFilters(updated);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement;
      if (!clickedElement || !clickedElement.parentNode) return;

      let parentElement = clickedElement.parentNode as HTMLElement;

      if (!parentElement || !parentElement.parentNode) return;
      parentElement = parentElement.parentNode as HTMLElement;

      if (!parentElement || parentElement.id === "search-content") return;
      if (!parentElement || !parentElement.parentNode) return;
      parentElement = parentElement.parentNode as HTMLElement;
      if (!parentElement || parentElement.id === "search-content") return;
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    setSearchFilters(["users", "workers"]);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const searchAlgolia = async () => {
      try {
        if (searchQuery.length === 0) return;
        setSearchProgress(true);
        const results = await Promise.all(
          searchFilters.map((filter: string) => {
            return searchClient
              .initIndex(
                `${process.env.REACT_APP_ALGOLIA_SEARCH_INDEX}_${filter}`
              )
              .search(searchQuery);
          })
        );
        setSearchResults(
          searchFilters.map((filter: string, n: number) => {
            let title = "";
            switch (filter) {
              case "users":
                title = "User accounts";
                break;
              case "businesses":
                title = "Businesses";
                break;
              case "workers":
                title = "Workers";
                break;
              case "projects":
                title = "Projects";
                break;
              default:
                break;
            }
            return {
              title,
              hits: results[n].hits,
            };
          })
        );
        setSearchProgress(false);
      } catch (error) {
        setSearchProgress(false);
        console.error("Algolia search error:", error);
      }
    };

    if (isFocused) {
      searchAlgolia();
    } else {
      setSearchResults([]); // Clear results when input is not focused
    }
  }, [searchQuery, isFocused, searchFilters]);

  const handleDropdownClick = () => {
    setIsFocused(true);
  };

  return (
    <div id="search-content" className="relative">
      <div
        className={`relative z-10 p-2 
        ${
          isFocused
            ? `border-t border-r shadow-lg rounded-t-md 
              border-l border-gray-300`
            : ``
        }`}
      >
        <input
          type="text"
          className={`bg-white border rounded-full text-sm
          py-1 px-4 text-gray-700 w-full outline-none transition-width 
          duration-300`}
          placeholder="Search for users, workers, businesses and projects..."
          onFocus={() => setIsFocused(true)}
          ref={searchInputRef}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      {isFocused && (
        <div
          id="search-content"
          className={`absolute top-full rounded-b-md left-0 right-0 
          border-gray-300 shadow-lg
          bg-white border-l border-r border-b h-[500px] overflow-y-scroll
          z-20`}
          onMouseDown={handleDropdownClick}
        >
          <div className="px-3 text-xs mb-1">Filter</div>
          <div className="flex items-center gap-2 px-2 pb-4">
            {filters.map((filter: string, index: number) => {
              return (
                <div
                  key={`filter_${index}`}
                  className={`py-1 capitalize px-2 text-xs border rounded-full 
                  cursor-pointer hover:bg-gray-100 border-gray-300
                  ${
                    searchFilters.includes(filter) ? "bg-gray-200" : "bg-white"
                  }`}
                  onClick={() => addFilter(filter)}
                >
                  {filter}
                </div>
              );
            })}
          </div>
          {searchInProgress && (
            <div className="flex justify-center items-center pb-4">
              <div
                className={`animate-spin rounded-full h-10 w-10 border-t-4 
                border-blue-500 border-solid`}
              />
            </div>
          )}
          {!searchInProgress && (
            <div className={`h-96 overflow-y-scroll`}>
              {searchResults.map((result, index) => {
                const showAll = searchResults.length === 1;
                const slice = showAll ? result.hits : result.hits.slice(0, 5);
                const showMore = slice.length < result.hits.length;
                return (
                  <div key={index} className="px-4 py-2">
                    <div
                      className={`flex justify-start items border-b 
                      border-gray-300 gap-2 mb-1`}
                    >
                      <h5 className="mb-0 font-medium text-xs">
                        {result.title}
                      </h5>
                      <div
                        style={{ fontSize: "7px" }}
                        className={`bg-blue-500 text-white 
                        font-semibold px-2 rounded-full flex items-center 
                        justify-center h-4 w-4 mb-1`}
                      >
                        {result.hits.length}
                      </div>
                    </div>
                    {slice.map((hit: any, k: number) => (
                      <div
                        key={`hit_${result.title.toLowerCase()}_${k}`}
                        className="hover:bg-gray-100 rounded-full h-8 w-full"
                      >
                        {result.title.toLowerCase().includes("user") && (
                          <UserHit hit={hit} />
                        )}
                        {result.title.toLowerCase().includes("business") && (
                          <BusinessHit hit={hit} />
                        )}
                        {result.title.toLowerCase().includes("worker") && (
                          <WorkerHit hit={hit} />
                        )}
                        {result.title.toLowerCase().includes("project") && (
                          <ProjectHit hit={hit} />
                        )}
                      </div>
                    ))}
                    {showMore && (
                      <div
                        className={`text-center cursor-pointer 
                        text-xs hover:text-blue-700`}
                      >
                        show more results
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchWithAlgolia;
