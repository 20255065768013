export class SiteRequirement {
  id: number;
  name: string;

  constructor(newMarket: ISiteRequirement) {
    const { id, name } = newMarket;

    this.id = id;
    this.name = name;
  }

  static getFilterConfig() {
    return {
      columns: ["id", "name"],
      indexToFilter: "siteRequirements",
    };
  }
}

export interface ISiteRequirement {
  id: number;
  name: string;
}
