import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BusinessIndexContainer, TableLimited } from "./BusinessIndex.styles";
import { getBusinesses } from "../../services/businessService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";
import { Business } from "../../models/Business";

export const BusinessIndex = () => {
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["businesses"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["businesses"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["businesses"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["businesses"].limit
  );

  useEffect(() => {
    setTitle("Businesses");
    getBusinesses().then((res: any) => {
      setBusinesses(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={Business.getFilterConfig()} />
      <BusinessIndexContainer>
        {!!businesses.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"businesses"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"businesses"}
                    columnName={"userId"}
                    displayName={"User (ID)"}
                  />
                  <ColumnHeadWithSort
                    index={"businesses"}
                    columnName={"businessName"}
                    displayName={"Name"}
                  />
                  <ColumnHeadWithSort
                    index={"businesses"}
                    columnName={"billingContact"}
                    displayName={"Contact"}
                  />
                  <ColumnHeadWithSort
                    index={"businesses"}
                    columnName={"businessAddress"}
                    displayName={"Address"}
                  />
                  <ColumnHeadWithSort
                    index={"businesses"}
                    columnName={"accountsPayableEmail"}
                    displayName={"Email"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {businesses.map((business) => {
                  const {
                    id,
                    user,
                    userId,
                    billingContact,
                    businessAddress,
                    businessName,
                    accountsPayableEmail,
                  }: any = business;

                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>
                        <NavLink to={`/manage/users/${String(userId)}`}>
                          {`${user.firstName} ${user.lastName} (${userId})`}
                        </NavLink>
                      </td>
                      <td>{businessName}</td>
                      <td>
                        <a href={`tel:${billingContact}`}>{billingContact}</a>
                      </td>
                      <td>{businessAddress}</td>
                      <td>{accountsPayableEmail}</td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="businesses"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!businesses.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </BusinessIndexContainer>
    </>
  );
};
