import React, { useState } from "react";
import { WorkerBlackListsContainer } from "./WorkerBlackLists.styles";
import { ResourceNavbar } from "../../components/ResourceNavbar/ResourceNavbar";
import { TabContent } from "reactstrap";
import { WorkerBlackListCreate } from "../../components/WorkerBlackListCreate/WorkerBlackListCreate";
import { WorkerBlackListIndex } from "../../components/WorkerBlackListIndex/WorkerBlackListIndex";

export const WorkerBlackLists = () => {
  const [activeTab, setActiveTab] = useState("index");

  const renderTab = () => {
    switch (activeTab) {
      case "index":
        return <WorkerBlackListIndex />;
      case "create":
        return <WorkerBlackListCreate />;
      case "export":
        return <WorkerBlackListIndex />;
      default:
        return <WorkerBlackListIndex />;
    }
  };

  return (
    <>
      <WorkerBlackListsContainer>
        <ResourceNavbar
          activeTab={activeTab}
          passActiveTab={(newValue: string) => setActiveTab(newValue)}
        />
        <TabContent>{renderTab()}</TabContent>
      </WorkerBlackListsContainer>
    </>
  );
};
