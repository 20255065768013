import React from "react";
import { NavLink } from "react-router-dom";
import { TabPane } from "reactstrap";

import {
  Content,
  AutoSchedulerReadContainer,
  Group,
  Title,
} from "./AutoSchedulerRead.styles";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const AutoSchedulerRead = ({
  id,
  project,
  inviteTimeInterval,
  invitesPerInterval,
  proximity,
  disabledAt,
  ratingMinimum,
  autoScheduledProjects,
}: any) => {
  const { projectName, address } = project;
  let disabledAtParsed = disabledAt
    ? new Date(disabledAt).toUTCString()
    : false;

  const parsedProjectWorkers = autoScheduledProjects.map(
    (asp: any) => asp.projectWorker
  );

  const formatProjectWorker = (projectWorker: any) => {
    const { id, projectDate, workerType, filledAmount, totalAmount }: any =
      projectWorker;

    const startsAtParsed = tz(
      projectDate?.startsAt,
      "%c",
      "en_US",
      projectDate?.timezone
    ).split(" ");

    return `${[
      `(${id})`,
      startsAtParsed[2],
      startsAtParsed[1],
      startsAtParsed[3] + ",",
      startsAtParsed[4].slice(0, -3),
      startsAtParsed[5],
      startsAtParsed[6],
      " | ",
      `L${workerType.level}`,
      `(${filledAmount}`,
      "/",
      `${totalAmount})`,
    ].join(" ")}`;
  };

  return (
    <AutoSchedulerReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Project</Title>
          <Content>
            <NavLink to={`/manage/projects/${project.id}`}>
              ({project.id})&nbsp;
              {projectName}&nbsp;-&nbsp;
              {address}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Interval</Title>
          <Content>{inviteTimeInterval}</Content>
        </Group>
        <Group>
          <Title>Batch Size</Title>
          <Content>{invitesPerInterval}</Content>
        </Group>
        <Group>
          <Title>Minimum Rating</Title>
          <Content>{ratingMinimum}</Content>
        </Group>
        <Group>
          <Title>Proximity (D)</Title>
          <Content>{proximity}</Content>
        </Group>
        <Group>
          <Title>Disabled At (UTC)</Title>
          <Content>{disabledAtParsed}</Content>
        </Group>
        <Group>
          <Title>Project Workers</Title>
          <Content>
            {parsedProjectWorkers.map((pw: any, index: any) => (
              <>
                <NavLink key={index} to={`/manage/projectWorkers/${pw.id}`}>
                  {formatProjectWorker(pw)}
                </NavLink>
                <br />
              </>
            ))}
          </Content>
        </Group>
      </TabPane>
    </AutoSchedulerReadContainer>
  );
};

export default AutoSchedulerRead;
