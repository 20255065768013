import React from "react";
import { NavLink } from "reactstrap";
import styled from "styled-components";
import { colors } from "../../globals/globals.styles";

export const ResourceNavbarContainer = styled.div`
   {
    font-weight: bold;
    color: black;
    margin-bottom: 10px;

    background-color: ${(props) => {
      switch (props.color) {
        case "index":
          return colors.offWhiteBlue;
        case "create":
          return colors.offWhiteGreen;
        case "export":
          return colors.offWhiteGrey;
        default:
          return colors.bootstrapPrimaryBlue;
      }
    }};
  }
`;

export const IndexLink = styled(NavLink)`
   {
    border-radius: 0 !important;
    color: black;
    &:hover {
      cursor: pointer;
      color: ${colors.bootstrapPrimaryBlue};
    }
  }
`;

export const CreateLink = styled(NavLink)`
   {
    border-radius: 0 !important;
    color: black;
    &.active {
      background-color: ${colors.darkGreen} !important;
    }
    &:hover {
      cursor: pointer;
      color: ${colors.darkGreen};
    }
  }
`;

export const ExportLink = styled(NavLink)`
   {
    border-radius: 0 !important;
    color: black;
    &.active {
      background-color: ${colors.darkGrey} !important;
    }
    &:hover {
      cursor: pointer;
      color: ${colors.darkGrey};
    }
  }
`;
