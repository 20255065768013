import React, { useState } from "react";
import { UploadsContainer } from "./Uploads.styles";
import { ResourceNavbar } from "../../components/ResourceNavbar/ResourceNavbar";
import { TabContent } from "reactstrap";
import { UploadCreate } from "../../components/UploadCreate/UploadCreate";
import { UploadIndex } from "../../components/UploadIndex/UploadIndex";

export const Uploads = () => {
  const [activeTab, setActiveTab] = useState("index");

  const renderTab = () => {
    switch (activeTab) {
      case "index":
        return <UploadIndex />;
      case "create":
        return <UploadCreate />;
      case "export":
        return <UploadIndex />;
      default:
        return <UploadIndex />;
    }
  };

  return (
    <>
      <UploadsContainer>
        <ResourceNavbar
          activeTab={activeTab}
          passActiveTab={(newValue: string) => setActiveTab(newValue)}
        />
        <TabContent>{renderTab()}</TabContent>
      </UploadsContainer>
    </>
  );
};
