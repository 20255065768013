import React, { useEffect, useState } from "react";
import { BsCalendarPlus } from "react-icons/bs";
import { Button } from "reactstrap";

import { v4 as uuidv4 } from "uuid";
import { getWorkerTypes } from "../../../services/workerTypesService";

import { BulkDatesAndWorkersContainer } from "./BulkDatesAndWorkers.styles";
import ProjectDateFlyout from "./ProjectDateFlyout/ProjectDateFlyout";

const BulkDatesAndWorkers = ({
  projectId,
  timezone,
  projectDates,
  market,
  $reportChanges,
}: any) => {
  const [taggedData, setTaggedData] = useState<Array<any>>([]);
  const [workerTypes, setWorkerTypes] = useState<Array<any>>([]);
  const [newProjectDates, setNewProjectDates] = useState<Array<any>>([]);

  const [bulkActions, setBulkActions] = useState({});

  const setAction = (uuid: any, operation: any, type: any, data: any) => {
    setBulkActions({
      ...bulkActions,
      [uuid]: {
        uuid,
        operation, //CREATE, UPDATE, or DELETE
        type, //WORKER or DATE?
        data, // object data
      },
    });
  };

  const $deleteAction = (uuid: any) => {
    let temp: any = { ...bulkActions };
    delete temp[uuid];
    setBulkActions(temp);
  };

  const $newProjectDate = () => {
    let newUUID = uuidv4();
    let newDate = new Date().toISOString();
    let temp = {
      id: "NEW",
      uuid: newUUID,
      startsAt: newDate,
      endsAt: newDate,
      projectId,
      timezone,
    };
    setAction(newUUID, "CREATE", "DATE", { ...temp });

    setNewProjectDates([...newProjectDates, temp]);
  };

  useEffect(() => {
    let temp = projectDates.map((pd: any) => {
      pd.uuid = uuidv4();
      pd.projectWorkers = pd.projectWorkers.map((pw: any) => {
        pw.uuid = uuidv4();
        return pw;
      });
      return pd;
    });

    setTaggedData(temp);

    getWorkerTypes().then((res: any) => {
      if (res.error)
        alert(
          "There was an issue getting worker types. Please refresh the page"
        );

      setWorkerTypes(
        res.value.filter((wt: any) => wt.market?.id == market?.id)
      );
    });
  }, []);

  useEffect(() => {
    $reportChanges(bulkActions);
  }, [bulkActions]);

  return (
    <BulkDatesAndWorkersContainer>
      {Array.isArray(projectDates) &&
        taggedData.map((pd, index) => {
          return (
            <ProjectDateFlyout
              key={index}
              uuid={pd.uuid}
              {...pd}
              $setAction={setAction}
              $deleteAction={$deleteAction}
              availableWorkerTypes={workerTypes}
            />
          );
        })}

      {Array.isArray(newProjectDates) &&
        newProjectDates.map((pd, index) => {
          return (
            <ProjectDateFlyout
              key={index}
              uuid={pd.uuid}
              {...pd}
              newDate
              $setAction={setAction}
              $deleteAction={$deleteAction}
              availableWorkerTypes={workerTypes}
            />
          );
        })}
      <Button onClick={$newProjectDate}>
        <BsCalendarPlus /> New Date
      </Button>
    </BulkDatesAndWorkersContainer>
  );
};

export default BulkDatesAndWorkers;
