import React, { useEffect, useState } from "react";
import { ProjectDate } from "../../../../models/ProjectDate";
import ProjectWorkerFlyout from "../ProjectWorkerFlyout/ProjectWorkerFlyout";
import { BiUserPlus } from "react-icons/bi";
import { v4 as uuidv4 } from "uuid";

import {
  ExpandIcon,
  ExpandedIcon,
  ProjectDateFlyoutContainer,
  TitleBar,
  Group,
  Title,
  InputBox,
  CancelButton,
  DeleteButton,
  TitleWrapper,
  NewWorkerButton,
} from "./ProjectDateFlyout.styles";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ProjectDateFlyout = ({
  id,
  uuid,
  projectId,
  projectWorkers,
  startsAt,
  endsAt,
  timezone,
  cancelledAt,
  $setAction,
  $deleteAction,
  newDate,
  availableWorkerTypes,
}: any) => {
  const [showDetails, setShowDetails] = useState(newDate);
  const [newProjectWorkers, setNewProjectWorkers] = useState<Array<any>>([]);

  const formatForDatetimeLocal = (date: string) => {
    if (date) return tz(date, "%Y-%m-%dT%H:%M", timezone);
    return tz(new Date().toISOString(), "%Y-%m-%dT%H:%M", timezone);
  };

  const [formData, setFormData] = useState<any>({
    id,
    startsAt: startsAt,
    endsAt: endsAt,
    cancelledAt,
    projectId,
    timezone,
    toBeDeleted: false,
  });

  const onChange = (e: any) => {
    setFormData((prevState: any) => {
      const { name, value } = e.target;
      let formData: any = {
        ...prevState,
        [name]: value,
      };

      let temp = { ...prevState, [name]: value };

      if (name == "startsAt") {
        temp.startsAt = new Date(tz(value, timezone)).toUTCString();
        temp.endsAt = new Date(tz(temp.endsAt, timezone)).toUTCString();
      }
      if (name == "endsAt") {
        temp.startsAt = new Date(tz(temp.startsAt, timezone)).toUTCString();
        temp.endsAt = new Date(tz(value, timezone)).toUTCString();
      }

      let operation;

      if (temp.toBeDeleted) operation = "DELETE";
      else if (id == "NEW") operation = "CREATE";
      else operation = "UPDATE";

      delete temp.toBeDeleted;

      $setAction(uuid, operation, "DATE", temp);
      return formData;
    });
  };

  const $newProjectWorker = () => {
    let newUUID = uuidv4();

    let temp = {
      id: "NEW",
      uuid: newUUID,
      parentUUID: uuid,
      projectDateId: id,
      workerTypeId: availableWorkerTypes[0].id,
      filledAmount: 0,
      totalAmount: 0,
    };

    $setAction(temp.uuid, "CREATE", "WORKER", { ...temp });
    setNewProjectWorkers([...newProjectWorkers, temp]);
  };

  const cancelChanges = () => {
    $deleteAction(uuid);
    setFormData({
      id,
      startsAt,
      endsAt,
      cancelledAt,
      projectId,
      timezone,
      toBeDeleted: false,
    });
  };

  const toggleDelete = () => {
    if (!formData.toBeDeleted) {
      setFormData(() => {
        let temp: any = {
          ...formData,
          toBeDeleted: !formData.toBeDeleted,
        };

        $setAction(uuid, "DELETE", "DATE", temp);
        return temp;
      });
    } else {
      setFormData({
        ...formData,
        toBeDeleted: !formData.toBeDeleted,
      });
    }
  };

  if (newDate && formData.toBeDeleted) return <></>;

  if (formData.toBeDeleted)
    return (
      <ProjectDateFlyoutContainer toDelete={formData.toBeDeleted}>
        <TitleWrapper>
          <TitleBar
            onClick={() => setShowDetails(newDate ? true : !showDetails)}
          >
            <div>{showDetails ? <ExpandedIcon /> : <ExpandIcon />}</div>
            <div>
              {ProjectDate.getShiftStringFrom(startsAt, endsAt, timezone)}
              <p>(to be deleted)</p>
            </div>
          </TitleBar>
          <div>
            <CancelButton onClick={cancelChanges} />
          </div>
        </TitleWrapper>
      </ProjectDateFlyoutContainer>
    );

  return (
    <ProjectDateFlyoutContainer newDate={newDate}>
      <TitleWrapper>
        <TitleBar onClick={() => setShowDetails(newDate ? true : !showDetails)}>
          <div>{showDetails ? <ExpandedIcon /> : <ExpandIcon />}</div>
          <Group>
            {!newDate &&
              ProjectDate.getShiftStringFrom(startsAt, endsAt, timezone)}
            {!!newDate && "New Project Date"}
          </Group>
        </TitleBar>
        <div>
          {!newDate && <CancelButton onClick={cancelChanges} />}
          <DeleteButton onClick={toggleDelete} />
        </div>
      </TitleWrapper>
      {!!showDetails && (
        <>
          <Group>
            <Title>Starts: </Title>
            <InputBox
              name="startsAt"
              type="datetime-local"
              value={formatForDatetimeLocal(formData.startsAt)}
              onChange={onChange}
            />
          </Group>

          <Group>
            <Title>Ends:</Title>
            <InputBox
              name="endsAt"
              type="datetime-local"
              value={formatForDatetimeLocal(formData.endsAt)}
              onChange={onChange}
            />
          </Group>

          {!newDate && (
            <Group>
              <Title>Cancelled:</Title>
              <InputBox
                name="cancelledAt"
                type="datetime-local"
                value={
                  formData.cancelledAt
                    ? formatForDatetimeLocal(formData.cancelledAt)
                    : ""
                }
                onChange={onChange}
              />
            </Group>
          )}

          {Array.isArray(projectWorkers) &&
            projectWorkers.map((pw: any, index: any) => {
              return (
                <ProjectWorkerFlyout
                  key={index}
                  projectWorker={pw}
                  $setAction={$setAction}
                  $deleteAction={$deleteAction}
                  availableWorkerTypes={availableWorkerTypes}
                  parentUUID={uuid}
                  {...pw}
                />
              );
            })}
          {!!newProjectWorkers.length &&
            newProjectWorkers.map((pw: any, index: any) => {
              return (
                <ProjectWorkerFlyout
                  key={index}
                  projectDateId={id}
                  projectWorker={pw}
                  newWorker
                  $setAction={$setAction}
                  $deleteAction={$deleteAction}
                  availableWorkerTypes={availableWorkerTypes}
                  parentUUID={uuid}
                  {...pw}
                />
              );
            })}
          <NewWorkerButton onClick={$newProjectWorker} size="sm">
            <BiUserPlus /> New Worker
          </NewWorkerButton>
        </>
      )}
    </ProjectDateFlyoutContainer>
  );
};

export default ProjectDateFlyout;
