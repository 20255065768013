import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  ShiftInviteCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  LookupField,
} from "./ShiftInviteCreate.styles";
import { createShiftInvite } from "../../services/shiftInviteService";
import { useNavigate } from "react-router-dom";
import WorkerLookupModal from "../WorkerLookupModal/WorkerLookupModal";
import isInt from "validator/lib/isInt";
import ProjectDateLookupModal from "../ProjectDateLookupModal/ProjectDateLookupModal";
import ProjectWorkerLookupModal from "../ProjectWorkerLookupModal/ProjectWorkerLookupModal";

export const ShiftInviteCreate = () => {
  const [formData, setFormData] = useState({
    workerId: "",
    projectWorkerId: "",
    projectDateId: "",
    status: "",
    shiftRateCents: "",
  });
  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();
  const [showWorkerLookup, setShowWorkerLookup] = useState(false);
  const [workerString, setWorkerString] = useState("");
  const [showProjectDateLookup, setShowProjectDateLookup] = useState(false);
  const [projectDateString, setProjectDateString] = useState("");
  const [showProjectWorkerLookup, setShowProjectWorkerLookup] = useState(false);
  const [projectWorkerString, setProjectWorkerString] = useState("");

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const setWorker = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        workerId: String(id),
      }));
      setWorkerString(name || "");
    }
    setShowWorkerLookup(false);
  };

  const setProjectDate = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectDateId: String(id),
      }));
      setProjectDateString(name || "");
    }
    setShowProjectDateLookup(false);
  };

  const setProjectWorker = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectWorkerId: String(id),
      }));
      setProjectWorkerString(name || "");
    }
    setShowProjectWorkerLookup(false);
  };

  const clearForm = () => {
    setFormData({
      workerId: "",
      projectWorkerId: "",
      projectDateId: "",
      status: "",
      shiftRateCents: "",
    });
    setErrors([]);
    setNewId(0);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { workerId, status, projectWorkerId, projectDateId }: any = formData;

    if (!isInt(workerId || "")) foundErrors.push("workerId");
    if (!isInt(projectDateId || "")) foundErrors.push("projectDateId");
    if (!isInt(projectWorkerId || "")) foundErrors.push("projectWorkerId");

    if (!isInt(status || "")) foundErrors.push("status");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let response = await createShiftInvite(formData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    return (
      <ShiftInviteCreateContainer>
        <SuccessText>New Shift Invite successfully created.</SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to new Shift Invite
          </Button>
        </SuccessButtonWrapper>
      </ShiftInviteCreateContainer>
    );
  }

  return (
    <ShiftInviteCreateContainer>
      <LookupField>
        <Input
          type="text"
          placeholder="Select a Worker >"
          invalid={errors.indexOf("workerId") !== -1}
          value={workerString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowWorkerLookup(true)}>
          Select Worker
        </Button>
      </LookupField>
      {errors.indexOf("workerId") !== -1 && (
        <ErrorText>Invalid Worker Type</ErrorText>
      )}
      <WorkerLookupModal isOpen={showWorkerLookup} callback={setWorker} />
      <br />
      <LookupField>
        <Input
          type="text"
          placeholder="Select a Project Date >"
          invalid={errors.indexOf("projectDateId") !== -1}
          value={projectDateString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowProjectDateLookup(true)}>
          Select Project Date
        </Button>
      </LookupField>
      {errors.indexOf("projectDateId") !== -1 && (
        <ErrorText>Invalid Project Date</ErrorText>
      )}
      <ProjectDateLookupModal
        isOpen={showProjectDateLookup}
        callback={setProjectDate}
      />
      <br />
      <LookupField>
        <Input
          type="text"
          placeholder="Select a Project Worker >"
          invalid={errors.indexOf("projectWorkerId") !== -1}
          value={projectWorkerString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowProjectWorkerLookup(true)}>
          Select Project Worker
        </Button>
      </LookupField>
      {errors.indexOf("projectWorkerId") !== -1 && (
        <ErrorText>Invalid Project Worker</ErrorText>
      )}
      <ProjectWorkerLookupModal
        isOpen={showProjectWorkerLookup}
        callback={setProjectWorker}
      />

      <InputBox
        name="status"
        type="select"
        onChange={onChange}
        invalid={errors.indexOf("status") !== -1}
      >
        <option>Select a status</option>
        <option value="0">Pending</option>
        <option value="1">Accepted</option>
        <option value="2">Rejected</option>
        <option value="3">Canceled</option>
        <option value="4">Waitlisted</option>
        <option value="5">Deferred</option>
        <option value="6">Undetermined</option>
      </InputBox>
      {errors.indexOf("status") !== -1 && <ErrorText>Invalid Status</ErrorText>}

      <InputBox
        name="shiftRateCents"
        type="text"
        placeholder="Enter a Shift Rate (in cents)"
        invalid={errors.indexOf("shiftRateCents") !== -1}
        onChange={onChange}
        value={formData.shiftRateCents}
      />
      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </ShiftInviteCreateContainer>
  );
};
