import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteProjectDate } from "../../services/projectDateService";
import BigSpinner from "../BigSpinner/BigSpinner";
import ProjectDateRead from "../ProjectDateRead/ProjectDateRead";

import {
  ProjectDatesDeleteContainer,
  DeleteButton,
  CancelButton,
  ButtonBox,
  SuccessText,
  ErrorText,
} from "./ProjectDateDelete.styles";

const ProjectDatesDelete = ({
  projectDate,
  isFlyout,
  $resetView,
  $onChange,
}: any) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const removeProjectDate = async () => {
    setProcessing(true);

    const response = await deleteProjectDate(projectDate.id);

    if (!response.error) {
      if (isFlyout) $onChange();
      else {
        setDeleteSuccess(true);
        setTimeout(() => navigate("/manage/projectDates"), 2000);
      }
    } else setError(true);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (deleteSuccess)
    return (
      <SuccessText>ProjectDate Deleted. Redirecting you shortly.</SuccessText>
    );

  return (
    <ProjectDatesDeleteContainer>
      <h2>Are you sure?</h2>
      {!isFlyout && <ProjectDateRead {...projectDate} />}
      {error && (
        <ErrorText>There was a problem deleting this. Try again.</ErrorText>
      )}
      <ButtonBox>
        <DeleteButton size="lg" onClick={removeProjectDate}>
          Delete
        </DeleteButton>
        <CancelButton size="lg" onClick={$resetView}>
          Cancel
        </CancelButton>
      </ButtonBox>
    </ProjectDatesDeleteContainer>
  );
};

export default ProjectDatesDelete;
