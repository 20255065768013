import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  ProjectInviteCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  LookupField,
} from "./ProjectInviteCreate.styles";
import { createProjectInvite } from "../../services/projectInviteService";
import ProjectLookupModal from "../ProjectLookupModal/ProjectLookupModal";
import { useNavigate } from "react-router-dom";
import isInt from "validator/lib/isInt";
import WorkerLookupModal from "../WorkerLookupModal/WorkerLookupModal";

export const ProjectInviteCreate = () => {
  const [formData, setFormData] = useState({
    projectId: "",
    workerId: "",
    adminNotes: "",
  });
  const [showProjectLookup, setShowProjectLookup] = useState(false);
  const [projectString, setProjectString] = useState("");
  const [showWorkerLookup, setShowWorkerLookup] = useState(false);
  const [workerString, setWorkerString] = useState("");
  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    setFormData({
      projectId: "",
      workerId: "",
      adminNotes: "",
    });
    setErrors([]);
    setShowProjectLookup(false);
    setShowWorkerLookup(false);
    setNewId(0);
  };

  const setProject = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectId: String(id),
      }));
      setProjectString(name || "");
    }
    setShowProjectLookup(false);
  };

  const setWorker = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        workerId: String(id),
      }));
      setWorkerString(name || "");
    }
    setShowWorkerLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { projectId, workerId }: any = formData;

    if (!isInt(projectId || "")) foundErrors.push("projectId");
    if (!isInt(workerId || "")) foundErrors.push("workerId");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let response = await createProjectInvite(formData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    return (
      <ProjectInviteCreateContainer>
        <SuccessText>New ProjectInvite successfully created.</SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to new ProjectInvite
          </Button>
        </SuccessButtonWrapper>
      </ProjectInviteCreateContainer>
    );
  }

  return (
    <ProjectInviteCreateContainer>
      <LookupField>
        <Input
          type="text"
          placeholder="Select the Project >"
          invalid={errors.indexOf("projectId") !== -1}
          value={projectString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowProjectLookup(true)}>
          Select Project
        </Button>
      </LookupField>
      {errors.indexOf("projectId") !== -1 && (
        <ErrorText>Invalid Project</ErrorText>
      )}
      <ProjectLookupModal isOpen={showProjectLookup} callback={setProject} />
      <LookupField>
        <Input
          type="text"
          placeholder="Select the Worker >"
          invalid={errors.indexOf("workerId") !== -1}
          value={workerString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowWorkerLookup(true)}>
          Select Worker
        </Button>
      </LookupField>
      {errors.indexOf("workerId") !== -1 && (
        <ErrorText>Invalid Worker</ErrorText>
      )}
      <WorkerLookupModal isOpen={showWorkerLookup} callback={setWorker} />
      <h5>Admin Notes</h5>
      <Input type="textarea" name="adminNotes" onChange={onChange} />
      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </ProjectInviteCreateContainer>
  );
};
