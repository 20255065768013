import React, { useState } from "react";
import { Button, Input, InputGroup, TabPane } from "reactstrap";
import UserLookupModal from "../UserLookupModal/UserLookupModal";

import {
  ErrorText,
  BusinessUpdateContainer,
  SuccessText,
  Group,
  Title,
  ButtonBox,
  UpdateButton,
  CancelButton,
} from "./BusinessUpdate.styles";
import isEmpty from "validator/lib/isEmpty";
import isInt from "validator/lib/isInt";
import { updateBusiness } from "../../services/businessService";
import BigSpinner from "../BigSpinner/BigSpinner";

const BusinessUpdate = ({
  id,
  user,
  userId,
  stripeCustomerId,
  billingContact,
  businessAddress,
  businessName,
  ninjaInvoiceKey,
  typeOfBusiness,
  managerTitle,
  promoCode,
  accountsPayableEmail,
  $resetView,
}: any) => {
  const [formData, setFormData] = useState({
    id,
    user,
    userId,
    stripeCustomerId,
    billingContact,
    businessAddress,
    businessName,
    ninjaInvoiceKey,
    typeOfBusiness,
    managerTitle,
    promoCode,
    accountsPayableEmail,
  });
  const [userString, setUserString] = useState(
    `${user.firstName} ${user.lastName} (${userId})`
  );
  const [errors, setErrors] = useState<Array<any>>([]);
  const [showUserLookup, setShowUserLookup] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (!formModified) setFormModified(true);
  };

  const setUser = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        userId: String(id),
      }));
      setUserString(name || "");
    }
    setShowUserLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    let foundErrors = [];

    const {
      id,
      userId,
      stripeCustomerId,
      billingContact,
      businessAddress,
      businessName,
    }: any = formData;

    if (!isInt(userId)) foundErrors.push("userId");
    if (isEmpty(businessName || "")) foundErrors.push("businessName");
    if (isEmpty(stripeCustomerId || "")) foundErrors.push("stripeCustomerId");
    if (isEmpty(billingContact || "")) foundErrors.push("billingContact");
    if (isEmpty(businessAddress || "")) foundErrors.push("businessAddress");

    setProcessing(true);
    let response = await updateBusiness(id, formData);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <BusinessUpdateContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Input
            name="id"
            type="text"
            value={formData.id}
            bsSize="sm"
            readOnly={true}
          />
        </Group>
        <Group>
          <Title>User</Title>
          <InputGroup>
            <Input
              type="text"
              placeholder="Select User >"
              invalid={errors.indexOf("userId") !== -1}
              value={userString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowUserLookup(true)}>
              Select User
            </Button>
          </InputGroup>
        </Group>
        {errors.indexOf("userId") !== -1 && <ErrorText>Invalid User</ErrorText>}
        <UserLookupModal isOpen={showUserLookup} callback={setUser} />
        <Group>
          <Title>Stripe ID</Title>
          <Input
            name="stripeCustomerId"
            type="text"
            value={formData.stripeCustomerId}
            onChange={onChange}
            invalid={errors.indexOf("stripeCustomerId") !== -1}
          />
        </Group>
        {errors.indexOf("stripeCustomerId") !== -1 && (
          <ErrorText>Invalid Stripe ID</ErrorText>
        )}
        <Group>
          <Title>Contact</Title>
          <Input
            name="billingContact"
            type="text"
            value={formData.billingContact}
            onChange={onChange}
            invalid={errors.indexOf("billingContact") !== -1}
          />
        </Group>
        {errors.indexOf("billingContact") !== -1 && (
          <ErrorText>Invalid Contact Name</ErrorText>
        )}
        <Group>
          <Title>Address</Title>
          <Input
            name="businessAddress"
            type="text"
            value={formData.businessAddress}
            onChange={onChange}
            invalid={errors.indexOf("businessAddress") !== -1}
          />
        </Group>
        {errors.indexOf("businessAddress") !== -1 && (
          <ErrorText>Invalid Address</ErrorText>
        )}
        <Group>
          <Title>Business Name</Title>
          <Input
            name="businessName"
            type="text"
            value={formData.businessName}
            onChange={onChange}
            invalid={errors.indexOf("businessName") !== -1}
          />
        </Group>
        {errors.indexOf("businessName") !== -1 && (
          <ErrorText>Invalid Business Name</ErrorText>
        )}
        <Group>
          <Title>Ninja Invoice Key</Title>
          <Input
            name="ninjaInvoiceKey"
            type="text"
            onChange={onChange}
            value={formData.ninjaInvoiceKey}
            invalid={errors.indexOf("ninjaInvoiceKey") !== -1}
          />
        </Group>
        {errors.indexOf("ninjaInvoiceKey") !== -1 && (
          <ErrorText>Invalid Key</ErrorText>
        )}
        <Group>
          <Title>Type</Title>
          <Input
            name="typeOfBusiness"
            type="text"
            onChange={onChange}
            value={formData.typeOfBusiness}
            invalid={errors.indexOf("typeOfBusiness") !== -1}
          />
        </Group>
        {errors.indexOf("typeOfBusiness") !== -1 && (
          <ErrorText>Invalid Type</ErrorText>
        )}
        <Group>
          <Title>Manager Title</Title>
          <Input
            name="managerTitle"
            type="text"
            onChange={onChange}
            value={formData.managerTitle}
            invalid={errors.indexOf("managerTitle") !== -1}
          />
        </Group>
        {errors.indexOf("managerTitle") !== -1 && (
          <ErrorText>Invalid Title</ErrorText>
        )}
        <Group>
          <Title>Promo Code</Title>
          <Input
            name="promoCode"
            type="text"
            onChange={onChange}
            value={formData.promoCode}
            invalid={errors.indexOf("promoCode") !== -1}
          />
        </Group>
        {errors.indexOf("promoCode") !== -1 && (
          <ErrorText>Invalid Promo Code</ErrorText>
        )}
        <Group>
          <Title>Email</Title>
          <Input
            name="accountsPayableEmail"
            type="text"
            onChange={onChange}
            value={formData.accountsPayableEmail}
            invalid={errors.indexOf("accountsPayableEmail") !== -1}
          />
        </Group>
        {errors.indexOf("accountsPayableEmail") !== -1 && (
          <ErrorText>Invalid Email</ErrorText>
        )}

        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
      </TabPane>
    </BusinessUpdateContainer>
  );
};

export default BusinessUpdate;
