import React from "react";
import { TabPane } from "reactstrap";

import {
  Content,
  SiteRequirementReadContainer,
  Group,
  Title,
} from "./SiteRequirementRead.styles";

const SiteRequirementRead = ({ id, name }: any) => {
  return (
    <SiteRequirementReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Name</Title>
          <Content>{name}</Content>
        </Group>
      </TabPane>
    </SiteRequirementReadContainer>
  );
};

export default SiteRequirementRead;
