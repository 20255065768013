import React from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdDelete, MdUndo } from "react-icons/md";
import { Button, Input } from "reactstrap";
import styled from "styled-components";
import { colors } from "../../../../globals/globals.styles";

export const ProjectWorkerFlyoutContainer = styled.div<any>`
   {
    margin-left: 40px;
    padding-top: 10px;
    font-size: 14px !important;
    color: ${(p) => (p.newWorker ? `green` : "auto")} !important;
    color: ${(p) => (p.toDelete ? `red` : "auto")} !important;
  }
`;

export const TitleWrapper = styled.div`
   {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
`;

export const TitleBar = styled.div`
   {
    display: flex;
    gap: 10px;
    cursor: pointer;
  }
`;

export const EditIcon = styled(FiEdit)`
   {
    font-size: 16px;
  }
`;

export const ExpandIcon = styled(FaChevronRight)`
   {
    font-size: 16px;
  }
`;

export const ExpandedIcon = styled(FaChevronDown)`
   {
    font-size: 16px;
  }
`;

export const Group = styled.div`
   {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const Title = styled.span`
   {
    display: block;
    min-width: 20%;
    color: ${colors.darkGrey};
  }
`;

export const InputBox = styled(Input)`
   {
    margin-top: 10px;
    width: 66%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ButtonBox = styled.div`
   {
    width: 100%;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export const CancelButton = styled(MdUndo)`
   {
    font-size: 16px;
    cursor: pointer;
    margin-right: 25px;
    color: black !important;
  }
`;

export const DeleteButton = styled(MdDelete)`
   {
    color: ${colors.danger} !important;
    font-size: 16px;
    cursor: pointer;
  }
`;

export const SmallInput = styled(Input)`
   {
    width: 20%;
  }
`;
