import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WorkerDetailsContainer } from "./WorkerDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getWorkerById } from "../../services/workerService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import WorkerRead from "../../components/WorkerRead/WorkerRead";
import WorkerUpdate from "../../components/WorkerUpdate/WorkerUpdate";
import WorkerDelete from "../../components/WorkerDelete/WorkerDelete";

export const WorkerDetails = () => {
  let { workerId } = useParams();
  const [worker, setWorker] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Worker`);
    if (!Object.entries(worker).length && workerId)
      getWorkerById(workerId)
        .then((res) => {
          if (res.value) {
            setWorker(res.value);
            setError(false);
            setTitle(`Worker (${res.value.id})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
  });

  const resetView = () => {
    setActiveTab("view");
  };

  const renderTab = (worker: any) => {
    switch (activeTab) {
      case "view":
        return <WorkerRead {...worker} />;
      case "update":
        return <WorkerUpdate {...worker} $resetView={resetView} />;
      case "delete":
        return <WorkerDelete worker={worker} $resetView={resetView} />;
      default:
        return <WorkerRead {...worker} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(worker).length) {
    return (
      <>
        <WorkerDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(worker)}</TabContent>
        </WorkerDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
