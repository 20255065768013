import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupWorkerTypes = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/workerTypes?term=${searchTerm}`);

export const getWorkerTypes = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.workerTypes;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/workerTypes${filterString}`);
};

export const getWorkerTypeById = async (workerTypeId: string) =>
  await protectedFetch(`${API_ROOT}/workerTypes/${workerTypeId}`);

export const createWorkerType = async (newWorkerType: any) => {
  return await protectedFetch(`${API_ROOT}/workerTypes`, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newWorkerType),
  });
};

export const updateWorkerType = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/workerTypes/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteWorkerType = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/workerTypes/${id}`, {
    method: "DELETE",
  });
};
