import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BusinessDetailsContainer } from "./BusinessDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getBusinessById } from "../../services/businessService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import BusinessRead from "../../components/BusinessRead/BusinessRead";
import BusinessUpdate from "../../components/BusinessUpdate/BusinessUpdate";
import BusinessDelete from "../../components/BusinessDelete/BusinessDelete";

const colors = ["#be0000", "#fff7ee", "#ff8c00", "#8884d8"];

export const BusinessDetails = () => {
  let { businessId } = useParams();
  const [business, setBusiness] = useState<any>({});
  const [projects, setProjects] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Businesses`);

    if (!Object.entries(business).length && businessId)
      getBusinessById(businessId)
        .then((res: any) => {
          if (res.value) {
            setBusiness(res.value);
            setProjects(
              res.projects.map((project: any) => ({
                name: project.projectName,
                id: project.id,
                value: Number(project.totalHours),
                fill: colors[Math.floor(Math.random() * colors.length)],
              }))
            );
            setError(false);
            setTitle(`Business (${res.value.businessName})`);
          } else setError(true);
        })
        .catch((err: any) => {
          setError(true);
        });
  });

  const resetView = () => {
    setActiveTab("view");
  };

  const renderTab = (business: any, projects: any[]) => {
    switch (activeTab) {
      case "view":
        return <BusinessRead {...business} projects={projects} />;
      case "update":
        return <BusinessUpdate {...business} $resetView={resetView} />;
      case "delete":
        return <BusinessDelete business={business} $resetView={resetView} />;
      default:
        return <BusinessRead {...business} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(business).length) {
    return (
      <>
        <BusinessDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(business, projects)}</TabContent>
        </BusinessDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
