import React, { useState } from "react";
import { MarketsContainer } from "./Markets.styles";
import { ResourceNavbar } from "../../components/ResourceNavbar/ResourceNavbar";
import { TabContent } from "reactstrap";
import { MarketCreate } from "../../components/MarketCreate/MarketCreate";
import { MarketIndex } from "../../components/MarketIndex/MarketIndex";

export const Markets = () => {
  const [activeTab, setActiveTab] = useState("index");

  const renderTab = () => {
    switch (activeTab) {
      case "index":
        return <MarketIndex />;
      case "create":
        return <MarketCreate />;
      case "export":
        return <MarketIndex />;
      default:
        return <MarketIndex />;
    }
  };

  return (
    <>
      <MarketsContainer>
        <ResourceNavbar
          activeTab={activeTab}
          passActiveTab={(newValue: string) => setActiveTab(newValue)}
        />
        <TabContent>{renderTab()}</TabContent>
      </MarketsContainer>
    </>
  );
};
