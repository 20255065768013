import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteTimeLog } from "../../services/timeLogService";
import BigSpinner from "../BigSpinner/BigSpinner";
import TimeLogRead from "../TimeLogRead/TimeLogRead";

import {
  TimeLogsDeleteContainer,
  DeleteButton,
  CancelButton,
  ButtonBox,
  SuccessText,
  ErrorText,
} from "./TimeLogDelete.styles";

const TimeLogsDelete = ({ timeLog, $resetView }: any) => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const removeTimeLog = async () => {
    setProcessing(true);

    const response = await deleteTimeLog(timeLog.id);

    if (!response.error) {
      setDeleteSuccess(true);
      setTimeout(() => navigate("/manage/timeLogs"), 2000);
    } else setError(true);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (deleteSuccess)
    return <SuccessText>TimeLog Deleted. Redirecting you shortly.</SuccessText>;

  return (
    <TimeLogsDeleteContainer>
      <h2>Are you sure you want to delete this?</h2>
      <TimeLogRead {...timeLog} />
      {error && (
        <ErrorText>There was a problem deleting this. Try again.</ErrorText>
      )}
      <ButtonBox>
        <DeleteButton size="lg" onClick={removeTimeLog}>
          Delete
        </DeleteButton>
        <CancelButton size="lg" onClick={$resetView}>
          Cancel
        </CancelButton>
      </ButtonBox>
    </TimeLogsDeleteContainer>
  );
};

export default TimeLogsDelete;
