import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupProjectSiteRequirements = async (searchTerm: string) =>
  await protectedFetch(
    `${API_ROOT}/lookup/projectSiteRequirements?term=${searchTerm}`
  );

export const getProjectSiteRequirements = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.projectSiteRequirements;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(
    `${API_ROOT}/projectSiteRequirements${filterString}`
  );
};

export const getProjectSiteRequirementById = async (
  projectSiteRequirementId: string
) =>
  await protectedFetch(
    `${API_ROOT}/projectSiteRequirements/${projectSiteRequirementId}`
  );

export const createProjectSiteRequirement = async (
  newProjectSiteRequirement: any
) => {
  return await protectedFetch(`${API_ROOT}/projectSiteRequirements`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newProjectSiteRequirement),
  });
};

export const updateProjectSiteRequirement = async (
  id: number,
  updates: any
) => {
  return await protectedFetch(`${API_ROOT}/projectSiteRequirements/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteProjectSiteRequirement = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/projectSiteRequirements/${id}`, {
    method: "DELETE",
  });
};

export const bulkDeleteSiteRequirement = async (
  projectId: number,
  siteRequirementId: any
) => {
  return await protectedFetch(
    `${API_ROOT}/projectSiteRequirements/bulkDelete`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":
          "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      },
      body: JSON.stringify({ projectId, siteRequirementId }),
    }
  );
};
