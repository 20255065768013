import React, { useEffect, useRef } from "react";

export default function Drawer({
  children,
  open,
  toggle,
}: {
  children: React.ReactNode;
  open: boolean;
  toggle: () => void;
}) {
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = (e: MouseEvent) => {
    if (drawerRef.current && !drawerRef.current.contains(e.target as Node)) {
      // Clicked outside the drawer, so close it
      toggle();
    }
  };

  useEffect(() => {
    // Add click event listener when the drawer is open
    if (open) {
      setTimeout(
        () => document.addEventListener("click", handleClickOutside),
        10
      );
    } else {
      // Remove the event listener when the drawer is closed
      document.removeEventListener("click", handleClickOutside);
    }

    // Clean up the effect when unmounting
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open]);
  return (
    <div
      ref={drawerRef}
      className={`fixed top-0 left-0 z-40 h-screen py-4 overflow-y-auto 
      transition-transform bg-white transition-width shadow-lg
      ${
        open
          ? `-translate-x-full w-72 ease-in-out duration-300`
          : `transform-none w-0 ease-in-out duration-300`
      }`}
    >
      <div>{children}</div>
    </div>
  );
}
