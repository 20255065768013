import React from "react";
import { NavLink, TabPane } from "reactstrap";
import { NavLink as RRNavlink } from "react-router-dom";

import {
  Content,
  ForemenReadContainer,
  Group,
  Title,
} from "./ForemenRead.styles";

const ForemenRead = ({ id, name, phone, email, user, userId }: any) => {
  return (
    <ForemenReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Name</Title>
          <Content>{name}</Content>
        </Group>
        <Group>
          <Title>Phone</Title>
          <Content>
            <a href={`tel:${phone}`}>{phone}</a>
          </Content>
        </Group>
        <Group>
          <Title>Email</Title>
          <Content>
            <a href={`mailto:${email}`}>{email}</a>
          </Content>
        </Group>
        <Group>
          <Title>User (ID)</Title>
          <Content>
            <RRNavlink to={`../manage/users/${String(userId)}`}>
              {`${user.firstName} ${user.lastName} (${userId})`}
            </RRNavlink>
          </Content>
        </Group>
      </TabPane>
    </ForemenReadContainer>
  );
};

export default ForemenRead;
