import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  WorkerTypeIndexContainer,
  TableLimited,
} from "./WorkerTypeIndex.styles";
import { getWorkerTypes } from "../../services/workerTypesService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { WorkerType } from "../../models/WorkerType";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

export const WorkerTypeIndex = () => {
  const [workerTypes, setWorkerTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["workerTypes"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["workerTypes"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["workerTypes"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["workerTypes"].limit
  );

  useEffect(() => {
    setTitle("Worker Types");
    getWorkerTypes().then((res: any) => {
      setWorkerTypes(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={WorkerType.getFilterConfig()} />
      <WorkerTypeIndexContainer>
        {!!workerTypes.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"workerTypes"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"workerTypes"}
                    columnName={"positionName"}
                    displayName={"Name"}
                  />

                  <ColumnHeadWithSort
                    index={"workerTypes"}
                    columnName={"level"}
                    displayName={"Level"}
                  />
                  <ColumnHeadWithSort
                    index={"workerTypes"}
                    columnName={"marketId"}
                    displayName={"Market"}
                  />
                  <ColumnHeadWithSort
                    index={"workerTypes"}
                    columnName={"costCents"}
                    displayName={"Cost"}
                  />
                  <ColumnHeadWithSort
                    index={"workerTypes"}
                    columnName={"costCurrency"}
                    displayName={"Cost Currency"}
                  />
                  <ColumnHeadWithSort
                    index={"workerTypes"}
                    columnName={"paymentCents"}
                    displayName={"Payment"}
                  />
                  <ColumnHeadWithSort
                    index={"workerTypes"}
                    columnName={"paymentCurrency"}
                    displayName={"Payment Currency"}
                  />
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {workerTypes.map((workerType) => {
                  const {
                    id,
                    positionName,
                    market,
                    level,
                    costCents,
                    costCurrency,
                    paymentCents,
                    paymentCurrency,
                  }: any = workerType;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{positionName}</td>
                      <td>{level}</td>
                      <td>{market.name}</td>
                      <td>{(costCents / 100).toFixed(2)}</td>
                      <td>{costCurrency}</td>
                      <td>{(paymentCents / 100).toFixed(2)}</td>
                      <td>{paymentCurrency}</td>
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="workerTypes"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!workerTypes.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </WorkerTypeIndexContainer>
    </>
  );
};
