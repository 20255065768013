import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import isEmpty from "validator/lib/isEmpty";
import isInt from "validator/lib/isInt";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import UserLookupModal from "../../components/UserLookupModal/UserLookupModal";
import { resetPasswordForUserId } from "../../services/toolService";
import {
  ButtonBox,
  ErrorText,
  InputBox,
  ResetUserPasswordContainer,
  SuccessText,
  UpdateButton,
  UserField,
} from "./ResetUserPassword.styles";

export const ResetUserPassword = () => {
  const [formData, setFormData] = useState({
    userId: 0,
    password: "",
    confirmPassword: "",
  });

  const [userString, setUserString] = useState("");
  const [showUserLookup, setShowUserLookup] = useState(false);
  const [errors, setErrors] = useState<Array<any>>([]);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);

  const setUser = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        userId: id,
      }));
      setUserString(name || "");
    }
    setShowUserLookup(false);
  };

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    const { userId, password, confirmPassword }: any = formData;
    let foundErrors = [];

    if (!userId) foundErrors.push("userId");
    if (isEmpty(password || "")) foundErrors.push("password");
    if (isEmpty(confirmPassword || "")) foundErrors.push("confirmPassword");

    if (password != confirmPassword) foundErrors.push("passwordMismatch");
    if (password.length < 8) foundErrors.push("tooShort");
    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let response = await resetPasswordForUserId(formData);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <>
      <ResetUserPasswordContainer>
        <UserField>
          <Input
            type="text"
            placeholder="Select User >"
            invalid={errors.indexOf("userId") !== -1}
            value={userString}
            readOnly={true}
          />
          <Button size="sm" onClick={() => setShowUserLookup(true)}>
            Select User
          </Button>
        </UserField>
        {errors.indexOf("userId") !== -1 && <ErrorText>Invalid User</ErrorText>}
        <UserLookupModal isOpen={showUserLookup} callback={setUser} />
        <InputBox
          name="password"
          type="text"
          placeholder="Enter New Password"
          onChange={onChange}
          invalid={errors.indexOf("password") !== -1}
        />
        {errors.indexOf("confirmPassword") !== -1 && (
          <ErrorText>Invalid password</ErrorText>
        )}
        <InputBox
          name="confirmPassword"
          type="text"
          placeholder="Confirm Password"
          onChange={onChange}
          invalid={errors.indexOf("confirmPassword") !== -1}
        />
        {errors.indexOf("confirmPassword") !== -1 && (
          <ErrorText>Invalid Confirm Password</ErrorText>
        )}
        <UserLookupModal isOpen={showUserLookup} callback={setUser} />

        <ButtonBox>
          <UpdateButton size="lg" onClick={submitOnClick}>
            Update Password
          </UpdateButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
        {errors.indexOf("passwordMismatch") !== -1 && (
          <ErrorText>The password and confirm_password dont match</ErrorText>
        )}
        {errors.indexOf("tooShort") !== -1 && (
          <ErrorText>The password is too short</ErrorText>
        )}
      </ResetUserPasswordContainer>
    </>
  );
};
