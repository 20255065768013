import React from "react";
import styled from "styled-components";
import { colors } from "../../globals/globals.styles";

export const ProjectToolInviteContainer = styled.div`
   {
    padding-top: 10px;
  }
`;

export const Group = styled.div`
   {
    display: flex;
    align-items: center;
    padding: 10px 0;
    @media only screen and (max-width: 768px) {
      justify-content: space-between;
    }
  }
`;
export const Title = styled.div`
   {
    min-width: 30%;
    color: ${colors.darkGrey};
  }
`;
export const Content = styled.div`
   {
  }
`;

export const NoResults = styled.h4`
   {
    margin: 30px 0;
    color: darkorange;
  }
`;

export const InfoBox = styled.div`
   {
    font-size: 0.9rem;
    font-style: italic;

    a.nav-link {
      padding: 0 !important;
    }
    p {
      margin: 0 !important;
    }
  }
`;

export const SuccessText = styled.p`
   {
    margin-top: 20px;
    color: ${colors.darkGreen};
    font-weight: bold;
  }
`;
