import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupForemen = async (userId: string) =>
  await protectedFetch(`${API_ROOT}/lookup/foremen?userId=${userId}`);

export const getForemen = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.foremen;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/foremen${filterString}`);
};

export const getForemanById = async (foremanId: string) =>
  await protectedFetch(`${API_ROOT}/foremen/${foremanId}`);

export const createForeman = async (newForeman: any) => {
  return await protectedFetch(`${API_ROOT}/foremen`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newForeman),
  });
};

export const updateForeman = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/foremen/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteForeman = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/foremen/${id}`, {
    method: "DELETE",
  });
};
