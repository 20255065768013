import React from "react";
import { TabPane } from "reactstrap";
import { NavLink as RRNavlink } from "react-router-dom";

import {
  Content,
  ProjectDateReadContainer,
  Group,
  Title,
} from "./ProjectDateRead.styles";

const tz = require("timezone/loaded"); //until Temporal JS comes out

const ProjectDateRead = ({
  id,
  project,
  timezone,
  startsAt,
  endsAt,
  projectWorkers,
  cancelledAt,
  isFlyout,
}: any) => {
  const startsAtParsed = tz(startsAt, "%c", "en_US", timezone).split(" ");
  const endsAtParsed = tz(endsAt, "%c", "en_US", timezone).split(" ");
  let cancelledAtParsed = "";

  if (cancelledAt) {
    const temp = tz(cancelledAt, "%c", "en_US", timezone).split(" ");
    cancelledAtParsed = [
      temp[0] + ",",
      temp[2],
      temp[1] + ",",
      temp[4].slice(0, -3),
      temp[5],
      temp[6],
    ].join(" ");
  }

  return (
    <ProjectDateReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Starts at</Title>
          <Content>
            {[
              startsAtParsed[0] + ",",
              startsAtParsed[2],
              startsAtParsed[1] + ",",
              startsAtParsed[4].slice(0, -3),
              startsAtParsed[5],
              startsAtParsed[6],
            ].join(" ")}
          </Content>
        </Group>
        <Group>
          <Title>Ends at</Title>
          <Content>
            {[
              endsAtParsed[0] + ",",
              endsAtParsed[2],
              endsAtParsed[1] + ",",
              endsAtParsed[4].slice(0, -3),
              endsAtParsed[5],
              endsAtParsed[6],
            ].join(" ")}
          </Content>
        </Group>
        <Group>
          <Title>Canceled at</Title>
          <Content>{cancelledAtParsed}</Content>
        </Group>
        {!isFlyout && (
          <>
            <Group>
              <Title>Timezone</Title>
              <Content>{timezone}</Content>
            </Group>
            <Group>
              <Title>Project</Title>
              <Content>
                <RRNavlink to={`/manage/projects/${project.id}`}>
                  {project.id}
                  &nbsp;-&nbsp;
                  {project.address}
                </RRNavlink>
              </Content>
            </Group>
            <Group>
              <Title>Project Workers</Title>
              <Content>
                {projectWorkers.map((pw: any, index: number, array: any) => (
                  <div key={index}>
                    <RRNavlink to={`/manage/projectWorkers/${pw.id}`}>
                      L{pw.workerType.level}
                      &nbsp;({pw.filledAmount}/{pw.totalAmount})
                    </RRNavlink>
                    {index !== array.length - 1 && <> &nbsp;and</>}
                  </div>
                ))}
              </Content>
            </Group>
          </>
        )}
      </TabPane>
    </ProjectDateReadContainer>
  );
};

export default ProjectDateRead;
