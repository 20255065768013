import React, { useState } from "react";
import { UserContainer } from "./Users.styles";
import { UserIndex } from "../../components/UserIndex/UserIndex";
import { ResourceNavbar } from "../../components/ResourceNavbar/ResourceNavbar";
import { TabContent } from "reactstrap";
import { UserCreate } from "../../components/UserCreate/UserCreate";

export const Users = () => {
  const [activeTab, setActiveTab] = useState("index");

  const renderTab = () => {
    switch (activeTab) {
      case "index":
        return <UserIndex />;
      case "create":
        return <UserCreate />;
      case "export":
        return <UserIndex />;
      default:
        return <UserIndex />;
    }
  };

  return (
    <>
      <UserContainer>
        <ResourceNavbar
          activeTab={activeTab}
          passActiveTab={(newValue: string) => setActiveTab(newValue)}
        />
        <TabContent>{renderTab()}</TabContent>
      </UserContainer>
    </>
  );
};
