import React, { useEffect, useState, useRef } from "react";
import { TypeaheadRef } from "react-bootstrap-typeahead";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import isInt from "validator/lib/isInt";
import { ShiftInvite } from "../../../models/ShiftInvite";
import { bulkUpdateShiftInvitesForWorker } from "../../../services/projectService";
import BigSpinner from "../../BigSpinner/BigSpinner";
import {
  Group,
  SubmitWrapper,
  SuccessText,
  Title,
  TypeaheadInput,
} from "./BulkWorkerShiftInviteUpdateModal.styles";
import { Option } from "react-bootstrap-typeahead/types/types";
import { stat } from "fs";
import { ErrorText } from "../../WorkerUpdate/WorkerUpdate.styles";
const BulkWorkerShiftInviteUpdateModal = ({
  isOpen,
  projectId,
  workers,
  timezone,
  $toggle,
}: any) => {
  const [selWorkers, setSelWorkers] = useState<Option[]>([]);
  const [updateCount, setUpdateCount] = useState(0);
  const [status, setStatus] = useState<string>("");
  const [errors, setErrors] = useState<Array<any>>([]);
  const [uniqueWorkers, setUniqueWorkers] = useState<Array<any>>([]);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [includePastShiftInvites, setIncludePastShiftInvites] = useState(false);
  const [excludeTodaysShiftInvites, setIncludeTodaysShiftInvites] =
    useState(false);

  const togglePast = () => {
    setIncludePastShiftInvites(!includePastShiftInvites);
  };
  const toggleToday = () => {
    setIncludeTodaysShiftInvites(!excludeTodaysShiftInvites);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);
    const selectedWorkerId =
      selWorkers && selWorkers.length > 0
        ? (selWorkers[0] as WorkerOption).id
        : undefined;
    let worker = workers.filter((w: any) => w.workerId == selectedWorkerId)[0];
    let foundErrors = [];
    if (!worker) foundErrors.push("workerId");
    if (!isInt(status || "")) foundErrors.push("status");
    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let confirmed = confirm(
      `This will set all this project's ${
        includePastShiftInvites ? "" : "future"
      } shift invites\n` +
        `for ${worker.firstName} ${worker.lastName} ` +
        `to ${ShiftInvite.formatStatus(Number(status))}\n\n` +
        `Are you sure?`
    );
    if (!confirmed) {
      setProcessing(false);
      return;
    }
    let response = await bulkUpdateShiftInvitesForWorker({
      projectId,
      timezone,
      workerId: worker.workerId,
      status,
      includePastShiftInvites,
      excludeTodaysShiftInvites,
    });
    if (!response.error) {
      setChangeSuccess(true);
      setUpdateCount(response.value[0]);
      setTimeout(() => {
        $toggle();
        setChangeSuccess(false);
      }, 4000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  //Runs when prop isOpen changes
  //Do not execute code when modal closes
  useEffect(() => {
    if (isOpen === false) {
      return;
    }
    setErrors([]);
    setSelWorkers([]);
    setStatus("");
    setIncludePastShiftInvites(false);
    setIncludeTodaysShiftInvites(false);
    let unsortedWorkers = workers.filter(
      (value: any, index: any, self: any) => {
        return (
          self.findIndex((v: any) => v.workerId === value.workerId) === index
        );
      }
    );

    setUniqueWorkers(
      unsortedWorkers.sort((a: any, b: any) => {
        let aFirst = a.firstName.trim().toLowerCase().charCodeAt(0);
        let bFirst = b.firstName.trim().toLowerCase().charCodeAt(0);

        return aFirst - bFirst;
      })
    );
  }, [isOpen]);

  const statusNameFromCode = () => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "Accepted";
      case "2":
        return "Rejected";
      case "3":
        return "Canceled";
      case "4":
        return "Waitlisted";
      case "5":
        return "Deferred";
      case "6":
        return "Undetermined";
    }
  };

  const getUpdateSummary = () => {
    const worker = changeSuccess ? (selWorkers[0] as WorkerOption) : undefined;
    if (!worker) return "";
    const u = updateCount;
    const w = worker;
    const s = statusNameFromCode();
    return `${u} shifts updated to ${s} for worker ${w.name}`;
  };

  type WorkerOption = {
    id: number;
    name: string;
    value: number;
    key: number;
  };

  const options: WorkerOption[] = uniqueWorkers.map((w, index) => {
    const fullName = `(${w.workerId}) ${w.firstName} ${w.lastName}`;
    return {
      id: w.workerId,
      name: fullName,
      value: w.workerId,
      key: index,
    };
  });

  const ref = useRef<TypeaheadRef>(null);
  const updateReport = getUpdateSummary();
  return (
    <Modal isOpen={isOpen} fade={false}>
      <ModalHeader>Update All Shift Invites for Worker</ModalHeader>
      {processing && <BigSpinner />}
      {!processing && (
        <ModalBody>
          <p>Set all Shift Invites for worker. Use with care.</p>
          <Group>
            <Title>Worker</Title>
            <TypeaheadInput
              id="worker-select-typeahead"
              labelKey="name"
              onChange={setSelWorkers}
              options={options}
              placeholder="(select a worker)"
              selected={selWorkers ? selWorkers : undefined}
              isLoading={false}
              clearButton
              ref={ref}
              onBlur={() => ref.current?.blur()}
            />
          </Group>
          <p>..to the status of..</p>
          <Group>
            <Title>Status</Title>
            <Input
              name="status"
              type="select"
              invalid={errors.indexOf("status") !== -1}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">(select a status)</option>
              <option value="0">Pending</option>
              <option value="1">Accepted</option>
              <option value="2">Rejected</option>
              <option value="3">Canceled</option>
              <option value="4">Waitlisted</option>
              <option value="5">Deferred</option>
              <option value="6">Undetermined</option>
            </Input>
          </Group>
          <Group>
            <Title>Include past shift invites</Title>
            &nbsp;
            <Input
              type="checkbox"
              onChange={togglePast}
              checked={includePastShiftInvites}
            />
          </Group>
          <Group>
            <Title>Exclude today&apos;s shift invites</Title>
            &nbsp;
            <Input
              type="checkbox"
              onChange={toggleToday}
              checked={excludeTodaysShiftInvites}
            />
          </Group>

          <SubmitWrapper>
            <Button onClick={$toggle}>Cancel</Button>
            &nbsp;
            <Button
              onClick={submitOnClick}
              disabled={selWorkers.length === 0 || status === ""}
            >
              Submit
            </Button>
          </SubmitWrapper>
          {changeSuccess && (
            <SuccessText>
              {`Bulk change successful: ${updateReport}`}
            </SuccessText>
          )}
          {!!errors.length && (
            <ErrorText>{`Bulk Change failed: ${errors.join(",")}`}</ErrorText>
          )}
        </ModalBody>
      )}
    </Modal>
  );
};

export default BulkWorkerShiftInviteUpdateModal;
