import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TimeLogDetailsContainer } from "./TimeLogDetails.styles";
import { TabContent } from "reactstrap";
import { setTitle } from "../../services/titleService";
import { getTimeLogById } from "../../services/timeLogService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import NotFound from "../NotFound/NotFound";
import { DetailsNavbar } from "../../components/DetailsNavbar/DetailsNavbar";
import TimeLogRead from "../../components/TimeLogRead/TimeLogRead";
import TimeLogUpdate from "../../components/TimeLogUpdate/TimeLogUpdate";
import TimeLogDelete from "../../components/TimeLogDelete/TimeLogDelete";

export const TimeLogDetails = () => {
  let { timeLogId } = useParams();
  const [timeLog, setTimeLog] = useState<any>({});
  const [activeTab, setActiveTab] = useState("view");
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(`Time Log`);
    if (!Object.entries(timeLog).length && timeLogId)
      getTimeLogById(timeLogId)
        .then((res) => {
          if (res.value) {
            setTimeLog(res.value);
            setError(false);
            setTitle(`Time Log (${res.value.id})`);
          } else setError(true);
        })
        .catch((err) => {
          setError(true);
        });
  });

  const resetView = () => setActiveTab("view");

  const renderTab = (timeLog: any) => {
    switch (activeTab) {
      case "view":
        return <TimeLogRead {...timeLog} />;
      case "update":
        return <TimeLogUpdate {...timeLog} $resetView={resetView} />;
      case "delete":
        return <TimeLogDelete timeLog={timeLog} $resetView={resetView} />;
      default:
        return <TimeLogRead {...timeLog} />;
    }
  };

  if (error) return <NotFound />;

  if (Object.entries(timeLog).length) {
    return (
      <>
        <TimeLogDetailsContainer>
          <DetailsNavbar
            activeTab={activeTab}
            passActiveTab={(newValue: string) => setActiveTab(newValue)}
            tabsToDisplay={["view", "update", "delete"]}
          />
          <TabContent>{renderTab(timeLog)}</TabContent>
        </TimeLogDetailsContainer>
      </>
    );
  } else return <BigSpinner />;
};
