export class ShiftInvite {
  static formatStatus(number: number) {
    switch (number) {
      case 0:
        return "pending";
      case 1:
        return "accepted";
      case 2:
        return "rejected";
      case 3:
        return "canceled";
      case 4:
        return "waitlisted";
      case 5:
        return "deferred";
      case 6:
        return "undetermined";
      default:
        return "N/A";
    }
  }

  static getFilterConfig() {
    return {
      columns: [
        "id",
        "workerId",
        "projectWorkerId",
        "projectInviteId",
        "projectDateId",
        "status",
        "createdAt",
        "updatedAt",
      ],
      indexToFilter: "shiftInvites",
    };
  }
}

export interface IShiftInvite {}
