import React, { useState } from "react";
import { AutoSchedulersContainer } from "./AutoSchedulers.styles";
import { ResourceNavbar } from "../../components/ResourceNavbar/ResourceNavbar";
import { TabContent } from "reactstrap";
import { AutoSchedulerIndex } from "../../components/AutoSchedulerIndex/AutoSchedulerIndex";

export const AutoSchedulers = () => {
  const [activeTab, setActiveTab] = useState("index");

  const renderTab = () => {
    switch (activeTab) {
      case "index":
        return <AutoSchedulerIndex />;
      default:
        return <AutoSchedulerIndex />;
    }
  };

  return (
    <>
      <AutoSchedulersContainer>
        <ResourceNavbar
          activeTab={activeTab}
          passActiveTab={(newValue: string) => setActiveTab(newValue)}
        />
        <TabContent>{renderTab()}</TabContent>
      </AutoSchedulersContainer>
    </>
  );
};
