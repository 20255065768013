import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  ManufacturerCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  UserField,
} from "./ManufacturerCreate.styles";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import isInt from "validator/lib/isInt";
import { createManufacturer } from "../../services/manufacturerService";
import UserLookupModal from "../UserLookupModal/UserLookupModal";
import { useNavigate } from "react-router-dom";

export const ManufacturerCreate = () => {
  const [formData, setFormData] = useState({
    manufacturerName: "",
  });
  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    setFormData({
      manufacturerName: "",
    });
    setErrors([]);
    setNewId(0);
  };

  const setUser = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        userId: String(id),
      }));
    }
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { manufacturerName }: any = formData;

    if (isEmpty(manufacturerName || "")) foundErrors.push("name");
    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let response = await createManufacturer(formData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    return (
      <ManufacturerCreateContainer>
        <SuccessText>
          New Manufacturer &quot;{formData.manufacturerName}&quot; successfully
          created.
        </SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to {formData.manufacturerName}
          </Button>
        </SuccessButtonWrapper>
      </ManufacturerCreateContainer>
    );
  }

  return (
    <ManufacturerCreateContainer>
      <InputBox
        name="manufacturerName"
        type="text"
        placeholder="Name"
        onChange={onChange}
        invalid={errors.indexOf("manufacturerName") !== -1}
      />
      {errors.indexOf("manufacturerName") !== -1 && (
        <ErrorText>Invalid Name</ErrorText>
      )}
      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </ManufacturerCreateContainer>
  );
};
