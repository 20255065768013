import React, { useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { throttle } from "throttle-debounce";
import { lookupMarkets } from "../../services/marketService";
import BigSpinner from "../BigSpinner/BigSpinner";
import {
  CancelWrapper,
  SearchBar,
  SearchResult,
  SearchResultText,
  SelectButton,
} from "./MarketLookupModal.styles";

const MarketLookupModal = ({ isOpen, callback }: any) => {
  let [markets, setMarkets] = useState([]);
  let [loading, setloading] = useState(false);
  let [changed, setChanged] = useState(false);

  const onChange = throttle(400, false, (e: any) => {
    const { value } = e.target;
    if (!value) return;
    if (!changed) setChanged(true);

    setloading(true);
    lookupMarkets(value).then((res: any) => {
      setMarkets(res.value || []);
      setloading(false);
    });
  });

  const makeSelection = (id: number, name: string) => {
    if (id) callback(id, name);
    setMarkets([]);
    setChanged(false);
  };
  const renderResults = () => {
    if (!markets.length && changed) return <p>No markets found by that term</p>;

    return markets.map((market, index) => {
      const { id, name, timezone } = market;
      return (
        <SearchResult key={index + Date.now()}>
          <SearchResultText>
            {`${name} | ${timezone} - (${id})`}
          </SearchResultText>
          <SelectButton
            size="sm"
            onClick={() => makeSelection(id, `${name} | ${timezone} - (${id})`)}
          >
            Select
          </SelectButton>
        </SearchResult>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} fade={false}>
      <ModalHeader>Select Market</ModalHeader>
      <ModalBody>
        <p>Search by an ID, Name, or Timezone</p>
        <SearchBar>
          <Input
            name="marketId"
            type="text"
            placeholder="Search"
            onChange={onChange}
          />
        </SearchBar>
        {loading && <BigSpinner />}
        {!loading && renderResults()}
        <CancelWrapper>
          <Button onClick={() => callback()}>Cancel</Button>
        </CancelWrapper>
      </ModalBody>
    </Modal>
  );
};

export default MarketLookupModal;
