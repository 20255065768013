import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";
import { colors } from "../../globals/globals.styles";

export const ProjectUpdateContainer = styled.div`
   {
    width: 66%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const Group = styled.div`
   {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin-top: 10px;
    @media only screen and (max-width: 768px) {
      justify-content: space-between;
    }
  }
`;
export const Title = styled.span`
   {
    display: block;
    min-width: 20%;
    color: ${colors.darkGrey};
  }
`;

export const ButtonBox = styled.div`
   {
    width: 100%;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export const CancelButton = styled(Button)`
   {
    width: 33%;
    display: block;

    font-weight: bold;
    @media only screen and (max-width: 768px) {
      margin-top: 50px;
    }
    @media only screen and (max-width: 768px) {
      width: 66%;
    }
  }
`;

export const UpdateButton = styled(Button)`
   {
    width: 33%;
    display: block;

    font-weight: bold;
    background-color: ${colors.warn};
    &:hover {
      background-color: #ffa500;
    }
    @media only screen and (max-width: 768px) {
      width: 66%;
    }
  }
`;

export const ErrorText = styled.p`
   {
    padding-left: 20%;
    color: red;
  }
`;

export const SubmitButton = styled(Button)`
   {
    width: 66%;
    font-weight: bold;
  }
`;

export const SuccessText = styled.p`
   {
    margin-top: 20px;
    color: ${colors.darkGreen};
    font-weight: bold;
  }
`;

export const ProjectDates = styled.div`
   {
    display: flex;
    align-items: start;
    padding: 20px 0;

    p {
      line-height: 1;
    }
  }
`;

export const ItemLink = styled.div`
   {
    margin: 0;
    font-size: 18px;
    ul {
      list-style: none;
      font-size: 16px;
    }
  }
`;

export const EditItemLink = styled.div`
   {
    display: flex;
    align-items: center;
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const AddNewItemLink = styled.div`
   {
    display: flex;
    align-items: center;
    color: ${colors.darkGreen};
    margin: 0;
    margin-top: 10px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Manufacturers = styled.div`
   {
    width: 100%;
    display: flex;
    align-items: start;
    padding: 15px 0;
  }
`;

export const ChipContainer = styled.div`
   {
    width: 100%;
  }
`;

export const ManufacturerSelections = styled.div`
   {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
`;

export const AvailableManufacturers = styled.div`
   {
    width: 100%;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }
`;

export const ManufacturerChip = styled(Button)`
   {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

export const SiteRequirements = styled.div`
   {
    width: 100%;
    display: flex;
    align-items: start;
    padding: 15px 0;
  }
`;

export const SiteRequirementSelections = styled.div`
   {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
`;

export const AvailableSiteRequirements = styled.div`
   {
    width: 100%;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }
`;

export const DatesAndWorkers = styled.div`
   {
    padding: 10px 0;
  }
`;
