import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// This is a test comment
import {
  AutoSchedulerIndexContainer,
  TableLimited,
} from "./AutoSchedulerIndex.styles";
import { getAutoSchedulers } from "../../services/autoSchedulerService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { setTitle } from "../../services/titleService";
import { FilterBar } from "../FilterBar/FilterBar";
import { AutoScheduler } from "../../models/AutoScheduler";
import { useAppSelector } from "../../store/hooks";
import NotFound from "../../pages/NotFound/NotFound";
import ColumnHeadWithSort from "../ColumnHeadWithSort/ColumnHeadWithSort";
import { PaginationBar } from "../PaginationBar/PaginationBar";

const tz = require("timezone/loaded"); //until Temporal JS comes out

export const AutoSchedulerIndex = () => {
  const [autoSchedulers, setAutoSchedulers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["autoSchedulers"].value
  );
  const sortBy = useAppSelector(
    (state: any) => state.filters.forIndex["autoSchedulers"].sortBy
  );
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["autoSchedulers"].page
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["autoSchedulers"].limit
  );

  useEffect(() => {
    setTitle("Auto Schedulers");
    getAutoSchedulers().then((res: any) => {
      setAutoSchedulers(res.value || []);
      setResultCount(res.totalCount || 0);
      setLoading(false);
    });
  }, [filters, sortBy, page]);

  if (loading) return <BigSpinner />;

  return (
    <>
      <FilterBar config={AutoScheduler.getFilterConfig()} />
      <AutoSchedulerIndexContainer>
        {!!autoSchedulers.length && (
          <>
            <TableLimited bordered hover responsive striped>
              <thead>
                <tr>
                  <ColumnHeadWithSort
                    index={"autoSchedulers"}
                    columnName={"id"}
                    displayName={"ID#"}
                  />
                  <ColumnHeadWithSort
                    index={"autoSchedulers"}
                    columnName={"projectId"}
                    displayName={"Project"}
                  />
                  <ColumnHeadWithSort
                    index={"autoSchedulers"}
                    columnName={"inviteTimeInterval"}
                    displayName={"Interval"}
                  />
                  <ColumnHeadWithSort
                    index={"autoSchedulers"}
                    columnName={"invitesPerInterval"}
                    displayName={"Batch Size"}
                  />
                  <ColumnHeadWithSort
                    index={"autoSchedulers"}
                    columnName={"proximity"}
                    displayName={"Proximity (D)"}
                  />
                  <ColumnHeadWithSort
                    index={"autoSchedulers"}
                    columnName={"disabledAt"}
                    displayName={"Disabled At (UTC)"}
                  />
                  {/* <ColumnHeadWithSort
                    index={"autoSchedulers"}
                    columnName={"removeSameDay"}
                    displayName={"Remove Same Day"}
                  /> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {autoSchedulers.map((autoScheduler) => {
                  const {
                    id,
                    project,
                    inviteTimeInterval,
                    invitesPerInterval,
                    proximity,
                    disabledAt,
                    removeSameDay,
                    project: { projectName, address },
                  }: any = autoScheduler;

                  let disabledAtParsed = disabledAt
                    ? new Date(disabledAt).toUTCString()
                    : "N/A";

                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>
                        <NavLink to={`/manage/projects/${project.id}`}>
                          ({project.id})&nbsp;
                          {projectName}&nbsp;-&nbsp;
                          {address}
                        </NavLink>
                      </td>
                      <td>{inviteTimeInterval}</td>
                      <td>{invitesPerInterval}</td>
                      <td>{proximity}</td>
                      <td>{disabledAtParsed}</td>
                      {/* <td>{removeSameDay ? "Yes" : "No"}</td> */}
                      <td>
                        <NavLink to={String(id)}>Open</NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableLimited>
            <PaginationBar
              index="autoSchedulers"
              totalCount={resultCount}
              page={page}
              limit={limit}
            />
          </>
        )}
        {!autoSchedulers.length && (
          <NotFound hideBackButton message={"Nothing here by those filters"} />
        )}
      </AutoSchedulerIndexContainer>
    </>
  );
};
