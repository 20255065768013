import React, { useEffect, useState } from "react";
import { Button, Input, InputGroup, TabPane } from "reactstrap";
import UserLookupModal from "../UserLookupModal/UserLookupModal";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  ProjectSiteRequirementUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
  LookupField,
} from "./ProjectSiteRequirementUpdate.styles";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import isInt from "validator/lib/isInt";
import isEmail from "validator/lib/isEmail";
import { updateProjectSiteRequirement } from "../../services/projectSiteRequirementService";
import BigSpinner from "../BigSpinner/BigSpinner";
import { timeZoneNames } from "../../globals/constants";
import SiteRequirementLookupModal from "../SiteRequirementLookupModal/SiteRequirementLookupModal";
import ProjectLookupModal from "../ProjectLookupModal/ProjectLookupModal";

const ProjectSiteRequirementUpdate = ({
  id,
  projectId,
  project,
  siteRequirementId,
  siteRequirement,
  $resetView,
}: any) => {
  const [formData, setFormData] = useState({
    id,
    projectId,
    siteRequirementId,
  });
  const [showProjectLookup, setShowProjectLookup] = useState(false);
  const [projectString, setProjectString] = useState(
    `(${project.id}) ${project.address}`
  );
  const [showSiteRequirementLookup, setShowSiteRequirementLookup] =
    useState(false);
  const [siteRequirementString, setSiteRequirementString] = useState(
    siteRequirement.name
  );
  const [errors, setErrors] = useState<Array<any>>([]);

  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [formModified, setFormModified] = useState(false);

  const setProject = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        projectId: String(id),
      }));
      setProjectString(name || "");
      setFormModified(true);
    }
    setShowProjectLookup(false);
  };

  const setSiteRequirement = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        siteRequirementId: String(id),
      }));
      setSiteRequirementString(name || "");
      setFormModified(true);
    }
    setShowSiteRequirementLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    let foundErrors = [];

    const { projectId, siteRequirementId }: any = formData;
    if (!isInt(projectId || "")) foundErrors.push("projectId");
    if (!isInt(siteRequirementId || "")) foundErrors.push("siteRequirementId");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let response = await updateProjectSiteRequirement(id, formData);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <ProjectSiteRequirementUpdateContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Input
            name="id"
            type="text"
            value={formData.id}
            bsSize="sm"
            readOnly={true}
          />
        </Group>
        <Group>
          <Title>Project</Title>
          <LookupField>
            <Input
              type="text"
              placeholder="Select the Project >"
              invalid={errors.indexOf("projectId") !== -1}
              value={projectString}
              readOnly={true}
            />
            <Button size="sm" onClick={() => setShowProjectLookup(true)}>
              Select Project
            </Button>
          </LookupField>
        </Group>
        {errors.indexOf("projectId") !== -1 && (
          <ErrorText>Invalid Project</ErrorText>
        )}
        <Group>
          <Title>Site Requirement</Title>
          <LookupField>
            <Input
              type="text"
              placeholder="Select the Site Requirement >"
              invalid={errors.indexOf("siteRequirementId") !== -1}
              value={siteRequirementString}
              readOnly={true}
            />
            <Button
              size="sm"
              onClick={() => setShowSiteRequirementLookup(true)}
            >
              Select Site Requirement
            </Button>
          </LookupField>
        </Group>
        {errors.indexOf("siteRequirementId") !== -1 && (
          <ErrorText>Invalid Site Requirement</ErrorText>
        )}

        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={!formModified}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
        <ProjectLookupModal isOpen={showProjectLookup} callback={setProject} />
        <SiteRequirementLookupModal
          isOpen={showSiteRequirementLookup}
          callback={setSiteRequirement}
        />
      </TabPane>
    </ProjectSiteRequirementUpdateContainer>
  );
};

export default ProjectSiteRequirementUpdate;
