import React from "react";
import { NavLink } from "react-router-dom";
import { TabPane } from "reactstrap";

import {
  Content,
  ProjectSiteRequirementReadContainer,
  Group,
  Title,
} from "./ProjectSiteRequirementRead.styles";

const ProjectSiteRequirementRead = ({
  id,
  project,
  siteRequirement,
  createdAt,
  updatedAt,
}: any) => {
  const formatDateTime = (dateTime: string) =>
    new Date(dateTime).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

  return (
    <ProjectSiteRequirementReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Project</Title>
          <Content>
            <NavLink to={`/manage/projects/${String(project.id)}`}>
              {project.id}
              &nbsp;-&nbsp;
              {project.address}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Site Requirement</Title>
          <Content>
            <NavLink
              to={`/manage/siteRequirements/${String(siteRequirement.id)}`}
            >
              {siteRequirement.name}
            </NavLink>
          </Content>
        </Group>
        <Group>
          <Title>Created At</Title>
          <Content>{formatDateTime(createdAt)}</Content>
        </Group>
        <Group>
          <Title>Updated At</Title>
          <Content>{formatDateTime(updatedAt)}</Content>
        </Group>
      </TabPane>
    </ProjectSiteRequirementReadContainer>
  );
};

export default ProjectSiteRequirementRead;
