import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupWorkers = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/workers?term=${searchTerm}`);

export const getWorkers = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.workers;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/workers${filterString}`);
};

export const getWorkersForProject = async (id: string) => {
  return await protectedFetch(`${API_ROOT}/workers/projects/${id}`);
};

export const getWorkerById = async (workerId: string) =>
  await protectedFetch(`${API_ROOT}/workers/${workerId}`);

export const createWorker = async (newWorker: any) => {
  return await protectedFetch(`${API_ROOT}/workers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newWorker),
  });
};

export const updateWorker = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/workers/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteWorker = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/workers/${id}`, {
    method: "DELETE",
  });
};
