import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  MarketCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
} from "./MarketCreate.styles";
import isEmpty from "validator/lib/isEmpty";
import { timeZoneNames } from "../../globals/constants";
import { createMarket } from "../../services/marketService";
import { useNavigate } from "react-router-dom";

export const MarketCreate = () => {
  const [formData, setFormData] = useState({
    name: "",
    timezone: "",
    orientationLink: "",
  });
  const [errors, setErrors] = useState<Array<any>>([]);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    setFormData({
      name: "",
      timezone: "",
      orientationLink: "",
    });
    setErrors([]);
    setNewId(0);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors: any = [];

    const { name, timezone, orientationLink }: any = formData;

    if (isEmpty(name || "")) foundErrors.push("name");
    if (isEmpty(timezone || "")) foundErrors.push("timezone");
    if (isEmpty(orientationLink || "")) foundErrors.push("orientationLink");
    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    let response = await createMarket(formData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    return (
      <MarketCreateContainer>
        <SuccessText>
          New Market &quot;{formData.name}&quot; successfully created.
        </SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            Go to {formData.name}
          </Button>
        </SuccessButtonWrapper>
      </MarketCreateContainer>
    );
  }

  return (
    <MarketCreateContainer>
      <InputBox
        name="name"
        type="text"
        placeholder="Name"
        onChange={onChange}
        invalid={errors.indexOf("name") !== -1}
      />
      {errors.indexOf("name") !== -1 && <ErrorText>Invalid Name</ErrorText>}

      <InputBox
        name="timezone"
        type="select"
        onChange={onChange}
        invalid={errors.indexOf("timezone") !== -1}
      >
        <option>Select a Time Zone</option>
        {timeZoneNames.map((tzName, index) => (
          <option value={tzName} key={index}>
            {tzName}
          </option>
        ))}
      </InputBox>
      {errors.indexOf("timezone") !== -1 && (
        <ErrorText>Invalid Timezone</ErrorText>
      )}

      <InputBox
        name="orientationLink"
        type="text"
        placeholder="Orientation Link"
        onChange={onChange}
        invalid={errors.indexOf("orientationLink") !== -1}
      />
      {errors.indexOf("orientationLink") !== -1 && (
        <ErrorText>Invalid Orientation Link</ErrorText>
      )}

      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </MarketCreateContainer>
  );
};
