import React from "react";
import { NavLink, TabPane } from "reactstrap";
import { NavLink as RRNavlink } from "react-router-dom";

import {
  Content,
  WorkerTypeReadContainer,
  Group,
  Title,
} from "./WorkerTypeRead.styles";

const WorkerTypeRead = ({
  id,
  positionName,
  marketId,
  market,
  level,
  costCents,
  costCurrency,
  paymentCents,
  paymentCurrency,
}: any) => {
  return (
    <WorkerTypeReadContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Content>{id}</Content>
        </Group>
        <Group>
          <Title>Name</Title>
          <Content>{positionName}</Content>
        </Group>
        <Group>
          <Title>Level</Title>
          <Content>{level}</Content>
        </Group>
        <Group>
          <Title>Market (ID)</Title>
          <Content>
            <RRNavlink to={`../manage/markets/${String(marketId)}`}>
              {`${market.name} (${marketId})`}
            </RRNavlink>
          </Content>
        </Group>
        <Group>
          <Title>Cost</Title>
          <Content>{(costCents / 100).toFixed(2)}</Content>
        </Group>
        <Group>
          <Title>Cost Currency</Title>
          <Content>{costCurrency}</Content>
        </Group>
        <Group>
          <Title>Payment</Title>
          <Content>{(paymentCents / 100).toFixed(2)}</Content>
        </Group>
        <Group>
          <Title>Payment Currency</Title>
          <Content>{paymentCurrency}</Content>
        </Group>
      </TabPane>
    </WorkerTypeReadContainer>
  );
};

export default WorkerTypeRead;
