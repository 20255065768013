import React, { useEffect, useState } from "react";
import { Input, TabPane } from "reactstrap";

import {
  ButtonBox,
  CancelButton,
  ErrorText,
  UploadUpdateContainer,
  Group,
  SuccessText,
  Title,
  UpdateButton,
} from "./UploadUpdate.styles";
import isEmpty from "validator/lib/isEmpty";
import { updateUpload } from "../../services/uploadService";
import BigSpinner from "../BigSpinner/BigSpinner";

const UploadUpdate = ({ id, fileName, $resetView }: any) => {
  const [originalFileName] = useState(fileName);
  const [recordId, setRecordId] = useState();
  const [name, setName] = useState("");

  const [errors, setErrors] = useState<Array<any>>([]);

  const [processing, setProcessing] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);

  useEffect(() => {
    setRecordId(id);
    setName(fileName);
  }, []);

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    setChangeSuccess(false);

    const payload = { fileName: name };
    let foundErrors = [];

    if (isEmpty(payload.fileName || "")) foundErrors.push("name");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }

    setProcessing(true);
    let response = await updateUpload(id, payload);
    if (!response.error) {
      setChangeSuccess(true);
      setTimeout(() => location.reload(), 2000);
    } else setErrors((errors) => [...errors, "$server"]);

    setProcessing(false);
  };

  if (processing) return <BigSpinner />;
  if (changeSuccess)
    return (
      <SuccessText>
        The updates were successful. Redirecting you shortly.
      </SuccessText>
    );

  return (
    <UploadUpdateContainer>
      <TabPane>
        <Group>
          <Title>ID</Title>
          <Input
            name="id"
            type="text"
            value={recordId}
            bsSize="sm"
            readOnly={true}
          />
        </Group>
        <Group>
          <Title>Name</Title>
          <Input
            name="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            invalid={errors.indexOf("name") !== -1}
          />
        </Group>
        {errors.indexOf("name") !== -1 && <ErrorText>Invalid Name</ErrorText>}
        <ButtonBox>
          <UpdateButton
            size="lg"
            onClick={submitOnClick}
            disabled={originalFileName === name}
          >
            Update
          </UpdateButton>
          <CancelButton size="lg" onClick={$resetView}>
            Cancel
          </CancelButton>
        </ButtonBox>
        {errors.indexOf("$server") !== -1 && (
          <ErrorText>
            The server rejected the update. Please try again.
          </ErrorText>
        )}
      </TabPane>
    </UploadUpdateContainer>
  );
};

export default UploadUpdate;
