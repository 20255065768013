import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupProjectDates = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/projectDates?term=${searchTerm}`);

export const getProjectDates = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.projectDates;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/projectDates${filterString}`);
};

export const getProjectDateById = async (projectDateId: string) =>
  await protectedFetch(`${API_ROOT}/projectDates/${projectDateId}`);

export const createProjectDate = async (newProjectDate: any) => {
  return await protectedFetch(`${API_ROOT}/projectDates`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newProjectDate),
  });
};

export const updateProjectDate = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/projectDates/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

export const deleteProjectDate = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/projectDates/${id}`, {
    method: "DELETE",
  });
};

export const getProjectDatesByProjectId = async (projectId: string) =>
  await protectedFetch(`${API_ROOT}/projectDates/byProjectId/${projectId}`);
