import React, { useState } from "react";
import { WorkersContainer } from "./Workers.styles";
import { WorkerIndex } from "../../components/WorkerIndex/WorkerIndex";
import { ResourceNavbar } from "../../components/ResourceNavbar/ResourceNavbar";
import { TabContent } from "reactstrap";
import { WorkerCreate } from "../../components/WorkerCreate/WorkerCreate";

export const Workers = () => {
  const [activeTab, setActiveTab] = useState("index");

  const renderTab = () => {
    switch (activeTab) {
      case "index":
        return <WorkerIndex />;
      case "create":
        return <WorkerCreate />;
      case "export":
        return <WorkerIndex />;
      default:
        return <WorkerIndex />;
    }
  };

  return (
    <>
      <WorkersContainer>
        <ResourceNavbar
          activeTab={activeTab}
          passActiveTab={(newValue: string) => setActiveTab(newValue)}
        />
        <TabContent>{renderTab()}</TabContent>
      </WorkersContainer>
    </>
  );
};
