import React from "react";
import styled from "styled-components";

import { Card } from "reactstrap";

export const LoginContainer = styled.div`
   {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledCard = styled(Card)`
   {
    margin: 2%;
    padding: 2%;
    width: 500px;
  }
`;

export const ErrorText = styled.p`
   {
    color: red;
  }
`;
