import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import {
  ErrorText,
  BusinessCreateContainer,
  InputBox,
  SubmitButton,
  SuccessButtonWrapper,
  SuccessText,
  UserField,
} from "./BusinessCreate.styles";
import isEmpty from "validator/lib/isEmpty";
import isInt from "validator/lib/isInt";
import { createBusiness } from "../../services/businessService";
import UserLookupModal from "../UserLookupModal/UserLookupModal";
import { useNavigate } from "react-router-dom";
import isEmail from "validator/lib/isEmail";

export const BusinessCreate = () => {
  const [formData, setFormData] = useState({
    userId: 0,
    stripeCustomerId: "",
    billingContact: "",
    businessAddress: "",
    businessName: "",
    ninjaInvoiceKey: "",
    typeOfBusiness: "",
    managerTitle: "",
    promoCode: "",
    accountsPayableEmail: "",
  });
  const [errors, setErrors] = useState<Array<any>>([]);
  const [userString, setUserString] = useState("");
  const [showUserLookup, setShowUserLookup] = useState(false);
  const [newId, setNewId] = useState(0);
  const navigate = useNavigate();

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    setFormData({
      userId: 0,
      stripeCustomerId: "",
      billingContact: "",
      businessAddress: "",
      businessName: "",
      ninjaInvoiceKey: "",
      typeOfBusiness: "",
      managerTitle: "",
      promoCode: "",
      accountsPayableEmail: "",
    });
    setUserString("");
    setErrors([]);
    setShowUserLookup(false);
    setNewId(0);
  };

  const setUser = (id: any, name: string) => {
    if (id) {
      setFormData((prevState) => ({
        ...prevState,
        userId: id,
      }));
      setUserString(name || "");
    }
    setShowUserLookup(false);
  };

  const submitOnClick = async (e: any) => {
    e.preventDefault();
    setErrors([]);
    let foundErrors = [];

    const {
      userId,
      billingContact,
      businessAddress,
      businessName,
      accountsPayableEmail,
    }: any = formData;

    if (!isInt(String(userId) || "")) foundErrors.push("userId");
    if (isEmpty(businessName || "")) foundErrors.push("businessName");
    if (isEmpty(billingContact || "")) foundErrors.push("billingContact");
    if (isEmpty(businessAddress || "")) foundErrors.push("businessAddress");
    if (!isEmail(accountsPayableEmail || ""))
      foundErrors.push("accountsPayableEmail");

    if (foundErrors.length) {
      setErrors(foundErrors);
      return;
    }
    let response = await createBusiness(formData);
    if (!response.error) setNewId(response.value.id);
    else setErrors((errors) => [...errors, "$server"]);
  };

  if (newId) {
    const { businessName } = formData;
    return (
      <BusinessCreateContainer>
        <SuccessText>
          {`New business "${businessName}" successfully created.`}
        </SuccessText>
        <SuccessButtonWrapper>
          <Button onClick={clearForm}>Create another</Button>
          <Button onClick={() => navigate(`./${newId}`)}>
            {`Go to ${businessName}`}
          </Button>
        </SuccessButtonWrapper>
      </BusinessCreateContainer>
    );
  }

  return (
    <BusinessCreateContainer>
      <UserField>
        <Input
          type="text"
          placeholder="Select the user this belongs to >"
          invalid={errors.indexOf("userId") !== -1}
          value={userString}
          readOnly={true}
        />
        <Button size="sm" onClick={() => setShowUserLookup(true)}>
          Select User
        </Button>
      </UserField>
      {errors.indexOf("userId") !== -1 && <ErrorText>Invalid User</ErrorText>}
      <UserLookupModal isOpen={showUserLookup} callback={setUser} />
      <InputBox
        name="billingContact"
        type="text"
        placeholder="Contact Name"
        onChange={onChange}
        invalid={errors.indexOf("billingContact") !== -1}
      />
      {errors.indexOf("billingContact") !== -1 && (
        <ErrorText>Invalid Contact Name</ErrorText>
      )}
      <InputBox
        name="businessName"
        type="text"
        placeholder="Business Name"
        onChange={onChange}
        invalid={errors.indexOf("businessName") !== -1}
      />
      {errors.indexOf("businessName") !== -1 && (
        <ErrorText>Invalid Business Name</ErrorText>
      )}
      <InputBox
        name="businessAddress"
        type="text"
        placeholder="Business Address"
        onChange={onChange}
        invalid={errors.indexOf("businessAddress") !== -1}
      />
      {errors.indexOf("businessAddress") !== -1 && (
        <ErrorText>Invalid Address</ErrorText>
      )}

      <InputBox
        name="accountsPayableEmail"
        type="text"
        placeholder="Email"
        onChange={onChange}
        invalid={errors.indexOf("accountsPayableEmail") !== -1}
      />
      {errors.indexOf("accountsPayableEmail") !== -1 && (
        <ErrorText>Invalid email</ErrorText>
      )}
      <InputBox
        name="ninjaInvoiceKey"
        type="text"
        placeholder="Ninja Invoice Key"
        onChange={onChange}
        invalid={errors.indexOf("ninjaInvoiceKey") !== -1}
      />
      {errors.indexOf("ninjaInvoiceKey") !== -1 && (
        <ErrorText>Invalid Ninja Key</ErrorText>
      )}
      <InputBox
        name="typeOfBusiness"
        type="text"
        placeholder="Business Type"
        onChange={onChange}
        invalid={errors.indexOf("typeOfBusiness") !== -1}
      />
      {errors.indexOf("typeOfBusiness") !== -1 && (
        <ErrorText>Invalid Type</ErrorText>
      )}
      <InputBox
        name="managerTitle"
        type="text"
        placeholder="Manager Title"
        onChange={onChange}
        invalid={errors.indexOf("managerTitle") !== -1}
      />
      {errors.indexOf("password") !== -1 && (
        <ErrorText>Invalid Title</ErrorText>
      )}
      <InputBox
        name="promoCode"
        type="text"
        placeholder="Promo Code"
        onChange={onChange}
        invalid={errors.indexOf("promoCode") !== -1}
      />
      {errors.indexOf("promoCode") !== -1 && (
        <ErrorText>Invalid Promo Code</ErrorText>
      )}
      <InputBox
        name="stripeCustomerId"
        type="text"
        placeholder="Stripe Customer ID"
        onChange={onChange}
        invalid={errors.indexOf("stripeCustomerId") !== -1}
      />
      {errors.indexOf("stripeCustomerId") !== -1 && (
        <ErrorText>Invalid Stripe ID</ErrorText>
      )}
      <br />
      <SubmitButton color="primary" onClick={submitOnClick}>
        Submit
      </SubmitButton>
      {errors.indexOf("$server") !== -1 && (
        <ErrorText>There was a server issue. Please try again.</ErrorText>
      )}
    </BusinessCreateContainer>
  );
};
